import React from 'react'
import { Link } from 'react-router-dom'
import img from '../../../../_assets/images/no_history-removebg-preview.png';
import img1 from '../../../../_assets/images/spped.png';
import {  poolminingpage, pooloverviewpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
const miningOverview = () => {
    const {langchange} = LangState();

    return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/miningpool"> {poolminingpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/MiningDashboard"> {poolminingpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/miningOverview">  {poolminingpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/miningSetting">  {poolminingpage['0']['nav']['btn4'][langchange]} </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="over-account">
            <div className="container-overview">
                <div className="left-over">
                    <div className="card income-box">
                        <div className="card-header">
                            <div className="name-id">
                                <p> {pooloverviewpage['0']['income'][langchange]}  </p>
                                <p> <small> ( ID) </small> </p>
                            </div>
                            <i class='fas fa-eye-slash'></i>
                        </div>
                        <div className="card-body">
                            <h6> Yesterday's <small> Income <span>(BTC) </span> </small> </h6>
                            <p> 0 BTC </p>
                            <h5 className="mt-2">  = 0 CNY  </h5>
                        </div>
                    </div>
                    <div className="card tab-roll">
                        <h4> {pooloverviewpage['0']['overview']['Overview'][langchange]}  </h4>
                        <ul className="nav flex-column" role="tab">
                            <li className="nav-item"> 
                                <a className="nav-link active" data-toggle="tab" href="#income"> <i class='fas fa-wallet'></i>{pooloverviewpage['0']['overview']['Income'][langchange]}    </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " data-toggle="tab" href="#hashrate">  <i className="fa fa-chain"></i>{pooloverviewpage['0']['overview']['Hashrate'][langchange]}   </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="right-over">
                    <div className="tab-content">
                        <div id="income" className="tab-pane">
                            <div className="income-header">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a className="nav-link "> <i class='fas fa-angle-left'></i>  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link " href="#"> BTC </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link " href="#" > BCH  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link " href="#" > ETH  </a>
                                    </li>
                                    <li className="nav-item right-btn">
                                        <a className="nav-link " href="#" >  <i class='fas fa-angle-right'></i>  </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="card details-card">
                                <p> BCHTotal Income </p>
                                <h4> 0 BCH </h4>
                                <div className="line-area">
                                    <div className="line-orenge"></div>
                                    <div className="line-green"></div>
                                </div>
                                <div className="c-footer">
                                    <div className="f-left">
                                        <p> <i class='fas fa-external-link-square-alt'></i> Yesterday's Income  </p>
                                        <h4> 0 BTC </h4>
                                    </div>
                                    <div className="f-right">
                                        <p> <i class='fas fa-external-link-square-alt'></i> Estimated Today's Income  </p>
                                        <h4> 0 BTC </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card income-m">
                                <div className="card-header">
                                    <h4> BTC Income Movement  </h4>
                                    <button type="button" className="btn"> 30D </button>
                                </div>
                                <img src={img} alt="no History icon "  />
                            </div>
                            <div className="card income-m">
                                <div className="card-header">
                                    <h4> Income Distribution  </h4>
                                    <div className="">
                                        <button type="button" className="btn"> 30D </button>
                                        <button type="button" className="btn btn-2"> 90D </button>
                                    </div>
                                </div>
                                <img src={img} alt="no History icon " />
                            </div>
                        </div>

                        <div id="hashrate" className="tab-pane active">
                            <div className="income-header">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a className="nav-link "> <i class='fas fa-angle-left'></i>  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link " href="#"> SHA256d </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link " href="#" > Ethash  </a>
                                    </li>
                                    <li className="nav-item right-btn">
                                        <a className="nav-link " href="#" >  <i class='fas fa-angle-right'></i>  </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="card income-m">
                                <div className="card-header">
                                    <h4> {pooloverviewpage['0']['Trade'][langchange]} </h4>
                                    <div className="">
                                        <button type="button" className="btn"> 24H </button>
                                        <button type="button" className="btn btn-2"> 30H </button>
                                    </div>
                                </div>
                                <img src={img} alt="no History icon " />
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card speed-card">
                                        <div className="">
                                            <h5> 15min </h5>
                                            <h4> 0.00<small> <sub> H/s </sub> </small> </h4>
                                        </div>
                                        <img src={img1} alt="speed miter" title="15" />
                                        <span> 15 </span>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card speed-card">
                                        <div className="">
                                            <h5> 1 hr </h5>
                                            <h4> 0.00 <small> <sub> H/s </sub> </small> </h4>
                                        </div>
                                        <img src={img1} alt="speed miter" title="1h" />
                                        <span> 1h </span>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card speed-card">
                                        <div className="">
                                            <h5> Daily </h5>
                                            <h4> 0.00<small> <sub> H/s </sub> </small> </h4>
                                        </div>
                                        <img src={img1} alt="speed miter" title="24"/>
                                        <span> 24 </span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="table-header">   
                                        <ul className="nav">
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">  All(0) </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#">  Active(0) </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">  Inactive(0) </a>
                                            </li>
                                        </ul>   
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Mining Account"/> 
                                            </div>
                                        </form>
                                    </div>
                                    <div className="table-responsive p-2"> 
                                        <table className="table table-borderless"  style={{ maxWidth: '1202px', width: '150%'}}>
                                            <thead>
                                                <tr>
                                                    <th className="text-left"> {pooloverviewpage['0']['table']['lable1'][langchange]} </th>
                                                    <th className="text-center" >  {pooloverviewpage['0']['table']['lable2'][langchange]}	 </th>
                                                    <th className="text-center" >  {pooloverviewpage['0']['table']['lable3'][langchange]} </th>
                                                    <th className="text-center" >   {pooloverviewpage['0']['table']['lable4'][langchange]} </th>
                                                    <th className="text-right">  	 {pooloverviewpage['0']['table']['lable5'][langchange]} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colspan="9" className="text-center mt-4">
                                                        <img src={img}/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    )
}

export default miningOverview