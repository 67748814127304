import React, { useState, useEffect } from 'react'
// import text_logo from '../../_assets/images/botshot_text.png';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { connect, useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/actions/user.action';
import '../../_assets/css/pro-2.css';
import { CryptoState } from '../../CryptoContext';
import { createTheme, makeStyles, MenuItem, Select } from '@material-ui/core';
import { privateheader } from '../../lang/lang';
import { LangState } from '../../LangugeContext';
import { logo } from '../../_constants';
import { getUserDetails } from '../../redux/actions/dashboard.action';
import { get_wallet_details } from '../../redux/actions/assets.action';
const useStyles = makeStyles({
    title: {
        flex: 1,
        color: 'gold',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
});

const Header = ({ history, props }) => {
    const [restaurantStatus, setRestaurantStatus] = useState(false);
    const userDetails = useSelector(state => state.persist.userDetails);
    const toggleData = useSelector(state => state.menu.toggleMenu);
    const user = useSelector(state => state.dashboard.user);
    const wallet_details = useSelector(state => state.assets.wallet_details);
    const [toggle, setToggle] = useState(false)
    const dispatch = useDispatch();
    const { langchange, setlangchange } = LangState();
    const [profileMenu, SetProfileMenu] = useState(false);
    const [mainTotal, setmainTotal] = useState(0);
    const [fundingTotal, setfundingTotal] = useState(0);
    const [tradingTotal, settradingTotal] = useState(0);
    const classes = useStyles();
    // const history = useHistory();
    const { currency, setCurrency } = CryptoState();
    // console.log('currency',currency);
    const darkTheme = createTheme({
        palette: {
            primary: {
                main: '#fff',
            },
            // type:'dark',
        },
    });

    const homepagelink = () => {
        history.push("/");
    }



    const langData1 = localStorage.getItem("lang");
    // setlangchange(langData);
    if (typeof langData1 !== 'undefined' && langData1 !== null) {
        setlangchange(langData1);

    } else {
        setlangchange('en');
    }
    const logout = () => {
        dispatch(logoutUser(history));
    }
    const toggleMenu = (value) => {
        setToggle(value)
    }
    const showProfileMenu = () => {
        if (!profileMenu)
            SetProfileMenu(true)
        else
            SetProfileMenu(false)
    }
    const openNav = () => {
        document.getElementById('sidebar').className = 'sidebar s-left';
    }
    const closeNav = () => {
        document.getElementById('sidebar').className = 'sidebar ';
    }

    const openNavnoti = () => {
        const navDash = document.getElementById("belldata").className;
        const dropdown = 'bell-data';
        if (navDash === 'bell-data') {
            document.getElementById("belldata").style.display = "block";
            document.getElementById('belldata').className = 'bell-data drop';
        }
        else {
            document.getElementById("belldata").style.display = "none";
            document.getElementById('belldata').className = 'bell-data';
        }

    }
    const openNavmsg = () => {
        const navDash = document.getElementById("megdata").className;
        const dropdown = 'bell-data';
        if (navDash === 'bell-data') {
            document.getElementById("megdata").style.display = "block";
            document.getElementById('megdata').className = 'bell-data drop';
        }
        else {
            document.getElementById("megdata").style.display = "none";
            document.getElementById('megdata').className = 'bell-data';
        }

    }
    const openNavlink = () => {
        const navDash = document.getElementById("imgdata").className;
        const dropdown = 'img-data text-center bg-dark text-light';
        if (navDash === 'img-data text-center bg-dark text-light') {
            document.getElementById("imgdata").style.display = "block";
            document.getElementById('imgdata').className = 'img-data text-center bg-dark text-light drop';
        }
        else {
            document.getElementById("imgdata").style.display = "none";
            document.getElementById('imgdata').className = 'img-data text-center bg-dark text-light';
        }

    }
    const closeNavDashboard = () => {
        document.getElementById('dropdown').className = 'dropdown ';
    }
    const openlink = window.location.href;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //   const langData = localStorage.getItem("lang");
    //   setlangchange(langData);

    useEffect(() => {
        // localStorage.setItem('lang', lang);
        // setlang(langData);
        dispatch(getUserDetails())
        dispatch(get_wallet_details())
        return history.listen((location) => {
            // console.log('sadas', location.pathname);
            if (location.pathname != '/vendor/dashboard') {
                addListner();
                Dropdown_header();
            }
        })
    }, [langchange, history])

    function addListner() {
        var classSet = document.getElementById("header").className;
        if (classSet != "header") {
            document.getElementById("header").className = "header";
        }
    }

    useEffect(() => {

        setmainTotal(wallet_details?.wallets?.main_wallet?.balance);
        settradingTotal(wallet_details?.wallets?.trading_wallet?.balance);
        setfundingTotal(wallet_details?.wallets?.funding_wallet?.balance);

    }, [wallet_details]);

    function pvtheader() {
        console.log('ads');
        var abc = document.getElementById("header").classList.toggle("header_show");
    }

    function dropdownq(id) {
        console.log('abc');

        var abc = document.getElementById(id).classList.toggle("dropdown_show");
        console.log('abc',abc);
    }


    function Dropdown_header() {
        console.log('sad');
        // var abc = document.getElementById("dropdown_profile").classList.toggle("show");
    }
    window.scrollTo(0, document.body.scrollTop);
    return (
        <>

            <div className="header" id="header">
                <nav class="navbar navbar-expand-md  navbar-dark w-100  flex-nowrap">
                    <div className="left-header">
                        <div className="logo">
                            <Link to="/vendor/dashboard" className="css-1xckmdk"><img className="logo" src={logo}  alt="logo" /> </Link>
                        </div>
                        <div className="links lg-size-header" >
                            <ul className="nav flex-nowrap ">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">   <i class='fas fa-th'></i>   </a>
                                    {/* <div className="drop-box">
                                        <ol className="nav">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon">  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Api Services </p>
                                                        <h6> Exclusive Perkes For api Users  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Blog </p>
                                                        <h6> BlockChain add Crypto Infomation  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">  <p className="icon" >   <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Referral </p>
                                                        <h6> Refer Friends to Eran Rewards  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">  <p className="icon">  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> FXC Lebs </p>
                                                        <h6> FXC Investment and research laboratory </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">  <p className="icon" >   <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Affiliate </p>
                                                        <h6> join the FXC Affiliate Programs And Commission  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">  <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Api Services </p>
                                                        <h6> Exclusive Perke  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">  <p className="icon" >   <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Api Services </p>
                                                        <h6> Exclusive Perke  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">  <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Api Services </p>
                                                        <h6> Exclusive Perke  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                        </ol>
                                    </div> */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"> {privateheader['0']['BuyCrypto']['btn1'][langchange]} <i class='fas fa-caret-down'></i>    </a>

                                    <div className="drop-box drop-box-2">
                                        <ol className="nav flex-column">
                                            {/* <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/fastBuy">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['BuyCrypto']['Fast'][langchange]} </p>
                                                        <h6> Buy USDT with visa, mastercard, SEPA  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/swap-currency">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  Fast Swap</p>
                                                        <h6> Buy USDT with BTC,TRX  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li> */}
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/p2p">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> P2P </p>
                                                        <h6> Bank transfer and 20+ Option  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>

                                            {/* <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/thirdParty">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> {privateheader['0']['BuyCrypto']['Third'][langchange]} </p>
                                                        <h6> Banxa, Simple, BTC Direct  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li> */}
                                        </ol>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/vendor/market">  {privateheader['0']['market'][langchange]}    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"> Orders <i class='fas fa-caret-down'></i>  </a>
                                    <div className="drop-box drop-box-2">
                                        <ol className="nav flex-column">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/Order">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> Orders  </p>
                                                        <h6> View Orders </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            
                                        </ol>
                                    </div>

                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#">  {privateheader['0']['Trade']['Trade'][langchange]}  <i class='fas fa-caret-down'></i>  </a>
                                    <div className="drop-box drop-box-2">
                                        <ol className="nav flex-column">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Trade']['Spot'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Trade']['p1'][langchange]}  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>   {privateheader['0']['Trade']['Margin'][langchange]} </p>
                                                        <h6> {privateheader['0']['Trade']['p2'][langchange]}  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>   {privateheader['0']['Trade']['Trading'][langchange]} <span className="badge badge-success">Hot</span> </p>
                                                        <h6> {privateheader['0']['Trade']['p3'][langchange]} </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                        </ol>
                                    </div>

                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#"> {privateheader['0']['Derivatives']['Derivatives'][langchange]}  <i class='fas fa-caret-down'></i>   </a>

                                    <div className="drop-box drop-box-2">
                                        <ol className="nav flex-column">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> {privateheader['0']['Derivatives']['Classic'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Derivatives']['p1'][langchange]}  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> {privateheader['0']['Derivatives']['Lite'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Derivatives']['p2'][langchange]}   </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Derivatives']['Brawl'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Derivatives']['p3'][langchange]}</h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/leveraged">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Derivatives']['Tokens'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Derivatives']['p4'][langchange]} </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                        </ol>
                                    </div>

                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#">{privateheader['0']['Earn']['Earn'][langchange]} <i class='fas fa-caret-down'></i>   </a>

                                    <div className="drop-box drop-box-2">
                                        <ol className="nav flex-column">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/cloudMining">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Earn']['Cloud'][langchange]} <span className="badge badge-success">NEW</span>   </p>
                                                        <h6> {privateheader['0']['Earn']['p1'][langchange]}  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/lend">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> {privateheader['0']['Earn']['Lending'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Earn']['p2'][langchange]}  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p> {privateheader['0']['Earn']['Earn2'][langchange]}   </p>
                                                        <h6> {privateheader['0']['Earn']['p3'][langchange]} </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/spotlightCenter">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Earn']['Spotlight'][langchange]}   <span className="badge badge-success">HOT</span> </p>
                                                        <h6> {privateheader['0']['Earn']['p4'][langchange]}  </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/miningpool">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Earn']['Pool'][langchange]}  </p>
                                                        <h6> {privateheader['0']['Earn']['p6'][langchange]}</h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/vendor/NoData">
                                                    <p className="icon" >  <i class='fab fa-artstation'></i>  </p>
                                                    <div className="title">
                                                        <p>  {privateheader['0']['Earn']['Win'][langchange]} </p>
                                                        <h6> {privateheader['0']['Earn']['p7'][langchange]} </h6>
                                                    </div>
                                                    <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                        </ol>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                        {/* responsive nav */}
                        <div className="links md-size_header" >
                            <ul className="nav flex-nowrap flex-column mt-5 " id='lg-size-header'>
                                <li className="nav-item dropdown" onClick={() => dropdownq('lg-size-header')} >
                                    <p className="nav-link mb-0 main_liNK"> <span > Buy Crypto </span>  <i class='fas fa-caret-down'></i>    </p>
                                    <div className="md-drop-box">
                                        <ol className="nav">
                                            {/* <li className="nav-item" >
                                                <Link className="nav-link" to="/vendor/fastBuy" >
                                                    <div className="title d-flex align-items-center">
                                                        <p className="icon mb-0">  <i class='fab fa-artstation'></i>  </p>
                                                        <p className='mb-0 ml-4'>Fast Trade  </p>
                                                    </div>
                                                    <h5 className='m-0'> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li> */}
                                            <li className="nav-item" >
                                                <Link className="nav-link" to="/vendor/swap-currency" >
                                                    <div className="title d-flex align-items-center">
                                                        <p className="icon mb-0">  <i class='fab fa-artstation'></i>  </p>
                                                        <p className='mb-0 ml-4'>Fast Swap  </p>
                                                    </div>
                                                    <h5 className='m-0'> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item" >
                                                <Link className="nav-link" to="/vendor/soon" >
                                                    <div className="title d-flex align-items-center">
                                                        <p className="icon mb-0">  <i class='fab fa-artstation'></i>  </p>
                                                        <p className='mb-0 ml-4'>P2P </p>
                                                    </div>
                                                    <h5 className='m-0'> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                            {/* <li className="nav-item" >
                                                <Link className="nav-link" to="/vendor/thirdParty" >
                                                    <div className="title d-flex align-items-center">
                                                        <p className="icon mb-0">  <i class='fab fa-artstation'></i>  </p>
                                                        <p className='mb-0 ml-4'>Third-Party  </p>
                                                    </div>
                                                    <h5 className='m-0'> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li> */}
                                        </ol>
                                    </div>
                                </li>
                            </ul>

                            <ul className="nav flex-nowrap flex-column " id='lg-size-header-2'>
                                <li className="nav-item dropdown" onClick={() => dropdownq('lg-size-header-2')} >
                                    <p className="nav-link mb-0 main_liNK"> <span > Markets </span>  <i class='fas fa-caret-down'></i>    </p>
                                    <div className="md-drop-box">
                                        <ol className="nav">
                                            <li className="nav-item" >
                                                <Link className="nav-link" to="/vendor/market" >
                                                    <div className="title d-flex align-items-center">
                                                        <p className="icon mb-0">  <i class='fab fa-artstation'></i>  </p>
                                                        <p className='mb-0 ml-4'>Markets </p>
                                                    </div>
                                                    <h5 className='m-0'> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                            </ul>

                            <ul className="nav flex-nowrap flex-column " id='lg-size-header-3'>
                                <li className="nav-item dropdown" onClick={() => dropdownq('lg-size-header-3')} >
                                    <p className="nav-link mb-0 main_liNK"> <span > Orders </span>  <i class='fas fa-caret-down'></i>    </p>
                                    <div className="md-drop-box">
                                        <ol className="nav">
                                            <li className="nav-item" >
                                                <Link className="nav-link" to="/vendor/Order" >
                                                    <div className="title d-flex align-items-center">
                                                        <p className="icon mb-0">  <i class='fab fa-artstation'></i>  </p>
                                                        <p className='mb-0 ml-4'>Orders </p>
                                                    </div>
                                                    <h5 className='m-0'> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                                </Link>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="right-header">
                        <ul className="nav system justify-content-end">
                            <li className="nav-item">
                                <a className="nav-link" href="#"> <span className="message">1</span> <i class='far fa-bell'></i>   </a>
                            </li>
                            <li className="nav-item d-lg-hide">
                                <Link  to={'/vendor/soon'} className="nav-link" href="#">  <i class='far fa-newspaper'></i>  </Link>
                                <div className="drop-box">
                                    <ol className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" >  <i class='far fa-file-alt'></i> </p>
                                                <div className="title">
                                                    <p>   {privateheader['0']['Orders']['Spot'][langchange]}  </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" >  <i class='far fa-file-alt'></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['Margin'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" >  <i class='far fa-file-alt'></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['Margin'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" >  <i class='far fa-file-alt'></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['Earn'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item divider">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" >  <i class='far fa-file-alt'></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['Buy'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" ><i className="fa fa-history" aria-hidden="true"></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['SpotTrade'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" ><i className="fa fa-history" aria-hidden="true"></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['MarginTrade'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p className="icon" ><i className="fa fa-history" aria-hidden="true"></i> </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Orders']['FuturesTrade'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                    </ol>
                                </div>
                            </li>
                            <li className="nav-item d-lg-hide">
                                <a className="nav-link" href="#"> <i class='fas fa-wallet'></i>  </a>
                                <div className="drop-box drop-wallet">
                                    <ol className="nav flex-column">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                <div className="details">
                                                    <p>   {privateheader['0']['Account']['Overview'][langchange]}</p>
                                                    <h6>
                                                        {/* <span>0</span> BTC<br /> */}
                                                        <span>{(tradingTotal + fundingTotal + mainTotal)?.toFixed(2)} </span>USD<br />
                                                    </h6>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/mainAccount">
                                                <p className="icon" >Ma</p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Account']['Main'][langchange]}  <span className='text-success'>{mainTotal?.toFixed(2)} USD</span></p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/tradeAccount">
                                                <p className="icon" > tr</p>
                                                <div className="title">
                                                    <p>  {privateheader['0']['Account']['Trading'][langchange]}  <span className='text-success'>{tradingTotal?.toFixed(2)} USD</span></p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/marginAccount">
                                                <p className="icon" > Mg</p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Account']['Margin'][langchange]} <span className='text-success'>{fundingTotal?.toFixed(2)} USD</span> </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                <p className="icon" > fu</p>
                                                <div className="title">
                                                    <p> {privateheader['0']['Account']['Futures'][langchange]} </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                <p className="icon" > Px</p>
                                                <div className="title">
                                                    <p>  {privateheader['0']['Account']['Financial'][langchange]}  </p>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </a>
                                        </li> */}
                                    </ol>
                                    <div className="btn-area">
                                        <Link className="btn" to="/vendor/swap-currency"> {privateheader['0']['Account']['Buy'][langchange]}  <i class='fab fa-cc-mastercard'></i> </Link>
                                        <Link className="btn" to="/vendor/depositassest">{privateheader['0']['Account']['Deposit'][langchange]} <i class='fas fa-coins'></i> </Link>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item md-size-hide">
                                <Link className="nav-link circle">
                                    <span><i class="fa fa-user" aria-hidden="true"></i></span>
                                     <span> {user?.firstname?.slice(0, user?.firstname?.length + 1 - user?.firstname?.length)} {user?.lastname?.slice(0, user?.lastname?.length + 1 - user?.lastname?.length)} </span>
                                </Link>
                                <div className="drop-box drop-circle pb-0" >
                                    <ol className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to='/vendor/user-profile'>
                                                <div className="details">
                                                    <h4> {user?.firstname != undefined ? user?.firstname : ' '} <spa className="badge badge-warning"> {user?.kycStatus ?  user?.kycStatus : ' '}</spa>  </h4>
                                                    <Link to='/vendor/user-profile'><p className="user-id"> {user?.firstname != undefined ? user?.firstname : ' '} <i class='fas fa-copy'></i> </p></Link>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item divider">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p> {privateheader['0']['profile']['Maker'][langchange]}:0.1%  </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['profile']['Taker'][langchange]}:0.1% </p>
                                                </div>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p>  {privateheader['0']['profile']['Trading'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/security">
                                                <p> {privateheader['0']['profile']['Security'][langchange]}   </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/kyc">
                                                <p>  {privateheader['0']['profile']['KYC'][langchange]}  </p><span className='ml-4'> {user?.kycStatus ? ' Status: ' + user?.kycStatus : ' '}</span>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/Referral">
                                                <p> {privateheader['0']['profile']['Referral'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p>  {privateheader['0']['profile']['API'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p> {privateheader['0']['profile']['SubAccount'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/CreateTicket">
                                                <p> Ticket  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                    </ol>
                                    <h6 className="text-center mt-3" style={{ cursor:'pointer'  }} onClick={logout}> {privateheader['0']['profile']['logout'][langchange]} </h6>
                                </div>
                            </li>
                            {/* responsive nav */}
                            <li className="nav-item dropdown md-size-show">
                                <Link className="nav-link circle" data-toggle="dropdown" onClick={() => Dropdown_header()} > 
                                <span><i class="fa fa-user" aria-hidden="true"></i></span>

                                <span> {user?.firstname?.slice(0, user?.firstname?.length + 1 - user?.firstname?.length)} {user?.lastname?.slice(0, user?.lastname?.length + 1 - user?.lastname?.length)} </span>
                                </Link>
                                <div className="drop-box drop-circle dropdown-menu" style={{ opacity: '1', visibility: 'visible' }} id="dropdown_profile">
                                    <ol className="nav flex-column">
                                        <li className="nav-item">
                                            <Link className="nav-link" to='/vendor/user-profile'>
                                                <div className="details">
                                                    <h4> {user?.firstname != undefined ? user?.firstname : ' '} <spa className="badge badge-warning"> {user?.kycStatus ?  user?.kycStatus : ' '}</spa>  </h4>
                                                    <Link to='/vendor/user-profile'><p className="user-id"> {user?.firstname != undefined ? user?.firstname : ' '} <i class='fas fa-copy'></i> </p></Link>
                                                </div>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>
                                            </Link>
                                        </li>
                                        <li className="nav-item divider">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p> {privateheader['0']['profile']['Maker'][langchange]}:0.1%  </p>
                                                <div className="title">
                                                    <p> {privateheader['0']['profile']['Taker'][langchange]}:0.1% </p>
                                                </div>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p>  {privateheader['0']['profile']['Trading'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/security">
                                                <p> {privateheader['0']['profile']['Security'][langchange]}   </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/kyc">
                                                <p>  {privateheader['0']['profile']['KYC'][langchange]}  </p><span className='ml-md-4'> {user?.kycStatus ? ' Status: ' + user?.kycStatus : ' '}</span>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/Referral">
                                                <p> {privateheader['0']['profile']['Referral'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p>  {privateheader['0']['profile']['API'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/vendor/NoData">
                                                <p> {privateheader['0']['profile']['SubAccount'][langchange]}  </p>
                                                <h5> <i class='fas fa-long-arrow-alt-right'></i> </h5>

                                            </Link>
                                        </li>
                                        
                                    </ol>
                                    <h6 className="text-center" onClick={logout}> {privateheader['0']['profile']['logout'][langchange]} </h6>
                                </div>
                            </li>
                            <li className="nav-item d-xl-hide">
                                <Link className="nav-link" to="/vendor/NoData">  <i class='fab fa-internet-explorer'></i> </Link>
                            </li>
                            {/* <li className="nav-item d-xl-hide">
                                <a className="nav-link" href="#"> USD  </a>
                            </li> */}
                            {/* <li className="nav-item d-xl-hide">
                                <a className="nav-link download" href="#"> <i class='fas fa-download'></i></a>
                                <div className="drop-download">
                                    <p>   Scan QR Code to<br /> Download App  </p>
                                    <div className="Qr-code">
                                    </div>
                                    <button type="button" className="btn btn-success">  View More <i class='fas fa-download'></i> </button>
                                </div>
                            </li> */}
                        </ul>

                    </div>
                    {/* <!-- Toggler/collapsibe Button --> */}
                    <button class="navbar-toggler PVT_header" type="button" onClick={() => pvtheader()} >
                        <i class="fa fa-bars" aria-hidden="true"></i>
                    </button>
                </nav>
            </div>
        </>
    )
};

export default withRouter(Header);
