import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import { useDispatch, useSelector } from 'react-redux';
import { get_deposit_tokens, get_deposit_transactions } from '../../../redux/actions/dashboard.action';
import { new_swap_transactions } from '../../../redux/actions/assets.action';

const SwapPageTable = (props) => {
	const { langchange } = LangState();
	const dispatch = useDispatch();
	const new_swap_trans = useSelector(state => state.assets.new_swap_trans);

	console.log('new_swap_trans', new_swap_trans)
	useEffect(() => {

		// console.log('senData',senData)
		const interval = setInterval(() => {
			dispatch(new_swap_transactions());

		}, 5000);
		return () => clearInterval(interval);
	}, [])

	const [search, setsearch] = useState();
	const handleSearch = () => {
		if (search != undefined) {
			return new_swap_trans?.deposit_tokens?.filter(
				(iii) =>
					iii.coinName.toLowerCase().includes(search)
			)
		} else {
			return (new_swap_trans?.deposit_tokens);
		}
	}

	function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr);
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	}


	return (
		<div>
			<div className='row mt-5'>
				<div className='col-md-12'>
					<h2>Swap History</h2>
					<div className='mining-sec-3 table-responsive-xl'>
						<table className="table table-borderless" >
							<thead className='thead-light'>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Swap Token</th>
									<th scope="col">Currency</th>
									<th scope="col">Amount</th>
									<th scope="col">Currency Amount</th>
									<th scope="col">Usd Amount</th>
									<th scope="col">Mode</th>
									<th scope="col">createdAt</th>
								</tr>
							</thead>
							<tbody>
								{new_swap_trans?.records?.map((val, key) => {
									return (
										<tr className='borderless'>
											<th >{key + 1}</th>
											<td>{val?.swaptoken}</td>
											<td>{val?.currency}</td>
											<td>{val?.amount}</td>
											<th >{val?.currency_amount}</th>
											<td>{val?.usd_amount}</td>
											<td>{val?.mode}</td>
											<td>{secondsToHms(val?.createdAt)}</td>

										</tr>
									);
								})}
								{/* <tr>
							<td colspan="6" className='text-center'>
								<img src={emptyImg} className='mt-5' /><br/>
								<span>No data</span>
							</td>
						</tr> */}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SwapPageTable