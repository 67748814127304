import React, { Component } from "react";
import { BrowserRouter as Router, Switch ,HashRouter } from "react-router-dom";
import LoaderComponent from "../components/loader/Loader";

/* Guard of Routes */
import UnAuthGuard from "./guards/unAuth.guard";
import AuthGuard from "./guards/auth.guard";

/* Routes Paths */
import PublicRoutes from "./public/public.routes";
import PrivateRoutes from "./private/private.routes";

class ApplicationRoutes extends Component {
  
  render() {
    return (
      <>
      {/* // <React.Fragment> */}
        {/* <LoaderComponent /> */}
        {/* <HashRouter> */}
          <Switch>
            <AuthGuard path="/vendor" component={PrivateRoutes} />
            <UnAuthGuard path="/" component={PublicRoutes} />
          </Switch>
        {/* </HashRouter> */}
      {/* // </React.Fragment> */}
      </>
    );
  }
}

export default ApplicationRoutes;
