import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
const TermsofUse = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Term of Use</h2>
            <p> Legal Statement</p>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
     
        <div className='about_box-1 mt-5'>
          <div className='text-center mb-4'>
            <h2>Legal Statement</h2>
            
          </div>
          <div className='row m-0 align-items-center values'>
            
            <div className='col-md-12'>
              <div className='about-story-text text-primary'>
                <h5 className='mb-0'>Article 1</h5>
                <p>The purpose of this website is to provide professional international-standard trading platform and financial products to the digital asset enthusiasts and investors all over the world as much as possible without violating relevant laws and regulations of the UK. It is forbidden to use this website for any illegal trading activity such as money laundering, smuggling, commercial bribery, etc. If any such activity is found, the website will freeze the account and report it to the authorities immediately.</p>
                <h5 className='mb-0'>Article 2</h5>
                <p>When requested by competent authorities with corresponding investigation documents to assist in the investigation of designated users or to seal, freeze, transfer their accounts, this website will provide relevant data accordingly or take the appropriate action. This site is not responsible for the leakage of user privacy, the inability of user account, or loss caused by the investigation.</p>
                <h5 className='mb-0'>Article 3</h5>
                <p>As the service provider, this website is obliged to improve the rules and service if its users violate the relevant laws of the United Kingdom due to the breach of this statement. However, this website does not have motivation or fact to violate relevant British laws, and it does not take any joint responsibility, too.</p>
                <h5 className='mb-0'>Article 4</h5>
                <p>Anyone who logs into this website in any way or uses the website directly or indirectly is deemed to be willing to accept the restrictions of this statement.</p>
                <h5 className='mb-0'>Article 5</h5>
                <p>Please refer to the relevant laws and regulations of the United Kingdom for issues not covered by this statement. When this statement conflicts with the relevant laws and regulations of the United Kingdom, please take the latter as standard.</p>
                
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default TermsofUse