import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 

const saveP2pMyPosting = (data) => {
    return {
        type: types.SAVE_P2P_MY_POSTING,
        payload: data
    }
}
export const p2p_my_posting = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.p2p_my_posting_api({}, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveP2pMyPosting(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const craete_p2p_post = (data, setOpen) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.create_p2p_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                setOpen(false);
                toast.success('Post Created  successfully.');
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

const saveBuyP2p = (data) => {
    return {
        type: types.SAVE_BUY_P2P,
        payload: data
    }
}
export const buy_p2p = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.buy_p2p_api({}, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveBuyP2p(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const submitBuyP2p = (data,setOpen) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.submit_buy_p2p_api(data, options).then((res) => { 
        //  const  res1 = res.data.user;
            if(res.data.success  == 1){
                // dispatch(save_user_assets(res1));
                toast.success(res.data.message);
                setOpen(false);
            }else{
                dispatch(stopLoader());
                toast.error(res.data.message);

            }
        }).catch((error) => {
            dispatch(stopLoader());

            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const saveSellP2p = (data) => {
    return {
        type: types.SAVE_SELL_P2P,
        payload: data
    }
}
export const sell_p2p = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.sell_p2p_api({}, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveSellP2p(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const saveOrderP2p = (data) => {
    return {
        type: types.SAVE_ORDER_P2P,
        payload: data
    }
}
export const order_p2p = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.order_p2p_api({}, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveOrderP2p(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}


const saveViewP2pPosting = (data) => {
    return {
        type: types.SAVE_P2P_POSTING_DETAILS,
        payload: data
    }
}
export const view_Posting_details_p2p = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.view_post_details_p2p_api(data, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveViewP2pPosting(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const saveViewUserOrderP2p = (data) => {
    return {
        type: types.SAVE_VIEW_USER_ORDER_P2P,
        payload: data
    }
}
export const view_user_order_p2p = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.view_user_order_p2p_api(data, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveViewUserOrderP2p(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const action_sell_status = (data, setOpen) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.action_sell_status_api(data, options).then((res) => {

            console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                toast.success(res.data.message);
                setOpen(false);
                dispatch(stopLoader());
            } else {
                
                toast.error(res.data.error);
                dispatch(stopLoader());
            }
            
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}



const saveReferral = (data) => {
    return {
        type: types.SAVE_REFERRAL,
        payload: data
    }
}
export const view_Referral = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.view_Referral_api(data, options).then((res) => { 
         const  res1 = res.data;
            dispatch(saveReferral(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}