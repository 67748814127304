import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const FudxLabs = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
           

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  {PROJECT_NAME} Labs</h2>
            <p>{PROJECT_NAME} Labs identifies, invests, and empowers viable blockchain entrepreneurs, startups, and communities,<br /> providing financing to industry projects that help grow the wider blockchain ecosystem.</p>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        <div class="about_story">
          <div className='row m-0 align-items-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner_story_top'>
                <img className='img-about-1' src={Ab} width='100%' alt='Fudxlabs/img' />
                <img src={abcd} width='100%' alt='Fudxlabs/img' />
              </div>
              <div className='banner_story_top-2'>
                <img className='img-about-1' src={abc} width='100%' alt='Fudxlabs/img' />
                <img src={abcde} width='100%' />
              </div>
            </div>
            <div className='col-lg-7 col-md-6'>
              <h3 className=''> About {PROJECT_NAME} Labs</h3>
              <div className='about-story-text'>
                <h6>
                {PROJECT_NAME} Labs identifies, invests, and empowers viable blockchain entrepreneurs, startups, and communities, providing financing to industry projects that help grow the wider blockchain ecosystem.
                </h6>
                <h6>{PROJECT_NAME} Labs is committed to supporting fast-executing, technical teams who positively impact the crypto space and build the decentralised web.</h6>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default FudxLabs