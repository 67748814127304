import React, { useEffect, useState } from "react";
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import { createTheme, makeStyles } from "@material-ui/core";
// import SelectButton from './SelectButton';
// import { tradeButton } from '../../../config/data';
// import { Link } from 'react-router-dom';
// import OrderDetail from './OrderDetail';
// import  '../../../App.css';
import SpotSection from "./SpotSection";
import OrderTable from "./OrderTable";
import notification from "../../../_assets/images/notification-icon.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTredeOrder_data } from "../../../redux/actions/Chart.action";
import SpotSectionTradingPassword from "./SpotSectionTradingPassword";
import { CryptoState } from "../../../CryptoContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrderSectionLogined = (props) => {
  const [selected, setselectbtn] = useState("myElement");
  const dispatch = useDispatch();
  const { checkTRXPass, setcheckTRXPass } = CryptoState();

  function myFunction(myElement, btn) {
    document.getElementById(myElement).className = "order__data active";
    document.getElementById(btn).className = "order__button active";
    if (myElement != "myElement") {
      document.getElementById("myElement").className =
        "order__data stop__orders__data";
      document.getElementById("btn").className = "order__button";
    }
    if (myElement != "myElement1") {
      document.getElementById("myElement1").className =
        "order__data stop__orders__data";
      document.getElementById("btn1").className = "order__button";
    }
    if (myElement != "myElement2") {
      document.getElementById("myElement2").className =
        "order__data stop__orders__data";
      document.getElementById("btn2").className = "order__button";
    }
    if (myElement != "myElement3") {
      document.getElementById("myElement3").className =
        "order__data stop__orders__data";
      document.getElementById("btn3").className = "order__button";
    }
    // console.log(myElement);
  }

  const isSignedIn = useSelector((state) => state.persist.isSignedIn);
  const isAuthenticated = isSignedIn;
  // console.log("isAuthenticated", props.props);

  const passId = props.props == "enj" ? "STA" : props.props;
  // console.log("passId", passId);
  const shiftCoinStatus = (id) => {
    dispatch(
      getTredeOrder_data({ status: id, tradingCurrency: passId?.toUpperCase() })
    );
  };

  const history = useHistory();

  useEffect(() => {
    // window.location.reload();
    // dispatch(getTredeOrder_data({status:'PENDING',tradingCurrency: props?.props?.toUpperCase()}))
  }, []);

  return (
    <>
        <div className="col-xl-7 col-lg-6 order__tabs p-0">
          <div className="bg___add_only">
            <div className="assets__overview">
              <div className="assets__view">
                <a href="">Assets Overview</a>
                <span>
                  <img src="assets/svg/eye-icon.svg" alt="" />
                </span>
              </div>
              <div className="tour__popup" id="assetsView">
                <h4>Assets Overview</h4>
                <p>
                  The balance in your Main Account, Trading Account and Margin
                  Account is shown here.
                </p>
                <div className="popup__footer">
                  <div className="pagination">
                    <span className="active"></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="next__prev__btn">
                    <button className="next__button" onclick="BuyingSelling()">
                      Next
                    </button>
                  </div>
                </div>
                <button className="close__btn" onclick="closePopup()">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                  ></svg>
                </button>
              </div>
              <div
                className="account__balance active"
                id="spot"
                value="spotBtn"
              >
                <a className="main__account" href="/">
                  Main Account
                </a>
                <div className="balance">
                  <div className="balance__inner">
                    <h6 className="balance__heading">
                      BTC balance:
                      <span>- -</span>
                    </h6>
                    <h6 className="balance__heading">
                      USDT balance:
                      <span>- -</span>
                    </h6>
                  </div>
                  <a className="trading__account" href="/">
                    Trading Account
                  </a>
                  <div className="balance__inner">
                    <h6 className="balance__heading">
                      BTC balance:
                      <span>- -</span>
                    </h6>
                    <h6 className="balance__heading">
                      USDT balance:
                      <span>- -</span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="account__balance" id="cross" value="crossBtn">
                <div>
                  <a className="main__account cross__margin__account" href="/">
                    Cross Margin Account
                  </a>
                  <h6 className="balance__heading">
                    Estimated Value:
                    <span>- -</span>
                  </h6>
                </div>
                <div className="balance">
                  <div className="balance__inner">
                    <h6 className="balance__heading">
                      BTC balance:
                      <span>- -</span>
                    </h6>
                    <h6 className="balance__heading">
                      USDT balance:
                      <span>- -</span>
                    </h6>
                  </div>
                  <div className="balance__inner">
                    <h6 className="balance__heading">
                      Debt Ratio:
                      <span className="cross__Dassed">- -</span>
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="account__balance"
                id="isolated"
                value="isolatedBtn"
              >
                <div>
                  <a
                    className="main__account cross__margin__account"
                    href="log-in.html"
                  >
                    Isolated Margin BTC-USDT
                  </a>
                  <h6 className="balance__heading">
                    Estimated Value:
                    <span>- -</span>
                  </h6>
                </div>
                <div className="balance">
                  <div className="balance__inner">
                    <h6 className="balance__heading">
                      BTC balance:
                      <span>- -</span>
                    </h6>
                    <h6 className="balance__heading">
                      USDT balance:
                      <span>- -</span>
                    </h6>
                  </div>
                  <div className="balance__inner">
                    <h6 className="balance__heading">
                      Debt Ratio:
                      <span className="cross__Dassed">- -</span>
                    </h6>
                    <h6 className="balance__heading">
                      Liquidation price:
                      <span className="cross__Dassed">--</span>
                      <span className="usdt">USDT</span>
                    </h6>
                  </div>
                  <div className="balance__inner">
                    <h6 className="balance__heading ">
                      <div className="pnl">PNL(USDT)</div>
                      <span className="cross__Dassed">:- -</span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="login__singin">
                <a>Transfer</a>
              </div>
            </div>
            <div className="order__details">
              <div className="order__details__add_S d-flex jusify-content-between">
                <div className="order__details__inner d-sm-flex">
                  <button
                    className=" order__button active"
                    id="btn"
                    onClick={() => {
                      myFunction("myElement", "btn");
                      shiftCoinStatus("PENDING");
                    }}
                  >
                    Open Orders
                  </button>
                  <button
                    className="order__button "
                    id="btn1"
                    onClick={() => {
                      myFunction("myElement1", "btn1");
                      shiftCoinStatus("CANCELLED");
                    }}
                    selected={selected == "myElement1"}
                  >
                    Stop Orders
                  </button>
                  <button
                    className="order__button "
                    id="btn2"
                    onClick={() => {
                      myFunction("myElement2", "btn2");
                      shiftCoinStatus("ALL");
                    }}
                    selected={selected == "myElement2"}
                  >
                    Order History
                  </button>
                  <button
                    className="order__button "
                    id="btn3"
                    onClick={() => {
                      myFunction("myElement3", "btn3");
                      shiftCoinStatus("ALL");
                    }}
                    selected={selected == "myElement3"}
                  >
                    Trade History
                  </button>
                </div>
                <div className="margin__tabs ml-sm-5">
                  <button
                    className="order__margin__button  spot__btn active"
                    id="spotbtn"
                    onClick={() => {
                      openSpot("spot");
                    }}
                    value="spotBtn"
                  >
                    Spot
                  </button>
                  <button
                    className="order__margin__button cros__btn"
                    id="crossbtn"
                    onClick={() => {
                      openSpot("cross");
                    }}
                    value="crossBtn"
                  >
                    Cross
                  </button>
                  <button
                    className="order__margin__button Isolated__btn"
                    id="isolatedbtn"
                    onClick={() => {
                      openSpot("isolated");
                    }}
                    value="isolatedBtn"
                  >
                    Isolated
                  </button>
                </div>
              </div>
              <button className="notification__icon select__sound">
                <img src={notification} alt="" />
              </button>
              <p className="notify__sound">Notify Sound for the Trades</p>
            </div>

            <OrderTable props={passId} />
          </div>
        </div>
        {/* {checkTRXPass == true ?<SpotSection  props={passId}/>:<SpotSectionTradingPassword />} */}
        {<SpotSection props={passId?.toUpperCase()} />}
    </>
  );
};

export default OrderSectionLogined;

export function openSpot(id) {
  var data = id + "btn";
  document.getElementById(data).className =
    "order__margin__button  spot__btn active";
  document.getElementById(id).className = "account__balance active";

  if (id != "isolated") {
    document.getElementById(id + 1).className =
      "aside__tabs spot__tab__btn active";
    // document.getElementById(id+2).className = "account__balance active";
  }

  if (id != "spot") {
    document.getElementById("spotbtn").className =
      "order__margin__button  spot__btn";
    document.getElementById("spot").className = "account__balance";
    document.getElementById("spot1").className = "aside__tabs spot__tab__btn";
  }
  if (id != "cross") {
    document.getElementById("cross").className = "account__balance";
    document.getElementById("crossbtn").className =
      "order__margin__button cros__btn";
    document.getElementById("cross1").className = "aside__tabs spot__tab__btn";
  }
  if (id != "isolated") {
    document.getElementById("isolatedbtn").className =
      "order__margin__button Isolated__btn";
    document.getElementById("isolated").className = "order__data";
  }
}
