import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
const ContactUs = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'92px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  CONTACT US</h2>
            
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row'>
            <div className='col-md-6'>
              <div className='about-story-text'>
              <h3>  CONTACT US</h3>

                <h6>
                We are committed to respecting the freedoms and rights of all our Estbitex users who have placed their trust in our service. If you should have any questions or concerns regarding this Privacy Policy, or if you would like to file a Data Protection request, please refer to our FAQ and contact us on our Support Page.</h6>
              
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about1} width='100%' alt='ContactUs/img' />
            </div>
          </div>
        </div>
        
        
        
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs