import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import mediaicon4 from '../../../../_assets/images/media-icon4.png';
import mediaicon1 from '../../../../_assets/images/media-icon1.png';
import mediaicon2 from '../../../../_assets/images/media-icon2.png';
import mediaicon3 from '../../../../_assets/images/media-icon3.png';
import { LangState } from '../../../../LangugeContext';
import { home_faq, home_media_slider, home_sec__crypto__journey } from '../../../../lang/lang';
import { Link } from 'react-router-dom';

const Mediaslider = () => {
    const {langchange} = LangState();

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };
    
    const items = [
        <div className="swiper-slide">
        <div className="media__logo">
            <img src={mediaicon1} alt="media-icon/img" />
            <span>
                TokenInsight
            </span>
        </div>
        <div className="prernt">
            <div className="slider__bottom__text">
                <span>Heavy hitter when it comes to the sheer amount of coins available, SOLAR TECHNO ALLIANCE
                    provides
                    access to a
                    wide library of altcoins at low fees.</span>
            </div>
        </div>

    </div>,
        <div className="swiper-slide">
        <div className="media__logo">
            <img src={mediaicon2} alt="MarketWatch" />
            <span>
                MarketWatch
            </span>
        </div>
        <div className="prernt">
            <div className="slider__bottom__text">
                <p>Cash Fudx is the exchange with the most widely distributed visitors. Also, 70% of
                    its
                    users come from North America, Europe, Russia and SEA.</p>
            </div>
        </div>

    </div>,
        <div className="swiper-slide">
        <div className="media__logo">
            <img src={mediaicon3} alt="Coinmarketcap" />
            <span>
                Coinmarketcap
            </span>
        </div>
        <div className="prernt">
            <div className="slider__bottom__text a">
                <p>SOLAR TECHNO ALLIANCE has, as a reputable and one of the most well-known crypto platforms, been
                    persistent in its pursuit of finding and supporting blockchain and
                    cryptocurrency
                    projects with real potential.</p>
            </div>
        </div>
    </div>,
       <div className="swiper-slide">
       <div className="media__logo">
           <img src={mediaicon4} alt="Forbes" />
           <span>
               Forbes
           </span>
       </div>
       <div className="slider__bottom__text">
           <p>Cash Fudx is a top 5 spot exchange in terms of overall performance</p>
       </div>
   </div>,
    ];


  return (
    <div><section className="sec__media__saying">
    <div className="container">
        <div className="sec__content">
            <h2 className="sec__heading">
                {home_media_slider['0']['h1'][langchange]}
            </h2>
        </div>
        <div className="sec__slider">
            <div className="swiper mySwiper media__slider">
                <div className="swiper-wrapper">

                                
                    <AliceCarousel
                            mouseTracking
                            // infinite
                            autoPlayInterval={1000}
                            animationDuration={1500}
                            disableDotsControls
                            disableButtonsControls
                            responsive={responsive}
                            autoPlay
                            items={items}
                        />   
                    
                    
                    
                    
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
        </div>
    </div>
</section>
<section className="sec__faq">
    <div className="container">
        <h2 className="sec__heading">
            {home_faq['0']['h1'][langchange]}
        </h2>
        <div className="sec__content">
            <div className="faq__card">
                <h3 className="card__heading">
                    {home_faq['0']['box1']['h1'][langchange]}
                </h3>
                <p className="card__info">
                     {home_faq['0']['box1']['p'][langchange]}
                </p>
            </div>
            <div className="faq__card">
                <h3 className="card__heading">
                {home_faq['0']['box2']['h1'][langchange]}
                </h3>
                <p className="card__info">
                     {home_faq['0']['box2']['p'][langchange]}
                </p>
            </div>
            <div className="faq__card">
                <h3 className="card__heading">
                    {home_faq['0']['box3']['h1'][langchange]}
                </h3>
                <p className="card__info">
                    {home_faq['0']['box3']['p'][langchange]}
                </p>
            </div>
            <div className="faq__card">
                <h3 className="card__heading">
                    {home_faq['0']['box4']['h1'][langchange]}
                </h3>
                <p className="card__info">
                    {home_faq['0']['box4']['p'][langchange]}
                </p>
            </div>
        </div>
    </div>
</section>

<section className="sec__crypto__journey">
    <div className="container">
        <h2 className="sec__heading">
            {home_sec__crypto__journey['0']['h1'][langchange]}
        </h2>
        <p className="sec__info">
            {home_sec__crypto__journey['0']['p'][langchange]}
        </p>
        <Link to="/register" className="sec__btn">
            {home_sec__crypto__journey['0']['btn'][langchange]}
        </Link>
    </div>
</section></div>
  )
}

export default Mediaslider