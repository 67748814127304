import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import usdt from '../../../_assets/images/usdt.png'
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { get_deposit_tokens, get_deposit_tokens_details,get_token_Address } from '../../../redux/actions/dashboard.action';
import { toast } from '../../../components/toast';
import qrImg from '../../../_assets/images/qrcode.b2b57e3a.svg';
import testQr from '../../../_assets/images/appDownload.png';
import DepositTable from './DepositTable';
import { PROJECT_NAME } from '../../../_constants';
import WithdrawTable from './WithdrawTable';
import { available_amount, get_withdraw_otp, submit_withdraw_req } from '../../../redux/actions/withdraw.action';
const UseStyles = makeStyles({
  assetsBtn: {
      marginTop:'77px',
      display: 'flex',
      background: 'rgba(125, 135, 146, 0.07840000000000003)',
      alignItems: 'center'
  },
  btnDesign:{
      width: '1200px',
      margin:  '0 auto',
      display: 'flex',
      marginBottom: '-16px',
      justifyContent: 'space-between',
      overflowX: 'scroll',
      // width: '100%',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
          height: '0px',
        },

  },
  btnUl:{
      display: 'flex',
      marginBottom: '1em',
  },
  btnTag:{
      height: '64px!important',
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '.1px',
      borderRadius: '0!important',
      color: 'hsla(0,0%,100%,.6)',
      padding: '0 12px',
  },
  MuiButtonlabel:{
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
  },
  MuiTouchRippleroot:  {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: 'hidden',
      position: 'absolute',
      borderRadius: 'inherit',
      pointerEvents: 'none',
      },
  
});
const WithdrawAssets = () => {
	const classes = UseStyles();
	const {langchange} = LangState();
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
    const [walletAddress , setwalletAddress] = useState('');

	const [coinName, setcoinName] = useState('Select Coin');
	const [coinImage, setcoinImage] = useState('');
	const [networkid, setnetworkid] = useState();
	const [tokenid, settokenid] = useState();

	const [detailcoinName, setdetailcoinName] = useState('Select Network');
	const [amount, setamount] = useState('Select Network');
	const [otp, setotp] = useState('Select Network');
	const [twofa_code, settwofa_code] = useState('');

	
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const onOpenModal2 = () => setOpen2(true);
	const onCloseModal2 = () => setOpen2(false);
	const dispatch = useDispatch();
    const deposite = useSelector(state => state.token.deposit_token);
    const token_details = useSelector(state => state.token.token_details.tokenDetails);
	const token_address = useSelector(state => state.token.token_address);
	const [coinaddress, setcoinaddress] = useState(token_address?.address?.walletAddress);
	const available_amount_res = useSelector(state => state.assets.available_amount)
	useEffect(() => {
		dispatch(get_deposit_tokens());
		
	}, [])
	function dropdownq() {
        //  document.getElementById("withdraw-box-1").style.display = 'none';
		document.getElementById("withdraw-box-2").classList.toggle("show");
		document.getElementById("withdraw-box-1").classList.toggle("hide");
    }
	const [search, setsearch] = useState();
	const handleSearch = () => {	
		if(search != undefined){
			return deposite.deposit_tokens?.filter(
				(iii) => 
				iii.coinName.toLowerCase().includes(search)
			)
		}else{
			return(deposite.deposit_tokens);
		}
    }

	const selectCoin = (data) => {
		setcoinName(data.coinName);
		setcoinImage(data.image);
		setOpen(false);
		setdetailcoinName('Select Network');
		settokenid(data._id)
		const id = {
			tokenID:data._id,
		}
		dispatch(get_deposit_tokens_details(id))
	}
	const selectNetwork = (id,name) =>{
		setdetailcoinName(name);
		setnetworkid(id);
		const data1 = {
			networkID: id,
		}
		dispatch(get_token_Address(data1))
		setOpen2(false);
	}
	const getotp = () => {
        dispatch(get_withdraw_otp())
    }
    const onHandelSubmit = (e) => {
        e.preventDefault();
        var data = {
            otp:otp,
            amount:amount,
            token_id:tokenid?.toString(),
            network_id:networkid?.toString(),
			wallet_address:walletAddress,
			twofa_code:twofa_code
        }
        dispatch(submit_withdraw_req(data,dropdownq()));
        e.target.reset();
    }
	const get_available_amount = (val) => {
		dispatch(available_amount({token_id:tokenid,network_id:val}));
	}
	function dropdownq() {
        //  document.getElementById("withdraw-box-1").style.display = 'none';
		document.getElementById("withdraw-box-2").classList.toggle("show");
		document.getElementById("withdraw-box-1").classList.toggle("hide");
    }
	const passProps = [];
	return (
	<div>
		<div className={classes.assetsBtn}>
			<div className={classes.btnDesign}>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
				</ul>

				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
							<Link to='/vendor/swap-currency' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['swap'][langchange]}</Link>

							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/depositassest' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/withdrawAssets' style={{color:'rgb(40 205 149)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/bonus' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
				</ul>
			</div>
			
		</div>
		<div className='container'>
			<div className='row'>
				<div  className='col-lg-7'>
					<div className='row'>
						<div className='col-md-12 mt-5'>
							<h1>Withdraw</h1> <h3 className='text-success'>Available Amount:  { available_amount_res?.balance}</h3>
						</div>
					</div>
					<div className='mt-5 margin_zero_add ' style={{marginLeft:'9%'}}>
						<div className='new_depost'>
						<form onSubmit={onHandelSubmit}>
								<div className='withdrawal-input-box-1' id="withdraw-box-1">
									<div className='select-field'>
										<span className='lable'>Coin</span>
										<div className='row'>
											<div className='col-md-3'>
												<p className='select-coin'>Select a Coin</p>
											</div>
											<div className='col-md-9'>
												<div className='select-box-div d-flex justify-content-between' onClick={onOpenModal}> 
													<p className='d-flex m-0'><span className='img'><img src={coinImage} width='30' /></span><h5>{coinName}</h5><span className='spam-label'> </span></p><p className='p-1'><i classname="fas fa-chevron-down"></i></p>
												</div>
												<Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
													<div className='container'>
													<div className='row deposit-modal m-0'>
														<div className='col-md-12'>
														<h2>Coin</h2>
														<div classname="input-group md-form form-sm form-1 pl-0">
															<div classname="input-group-prepend">
															<span classname="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i classname="fas fa-search text-white"
																aria-hidden="true"></i></span>
															</div>
															<input classname="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e) => {setsearch(e.target.value)}} />
														</div>
														{/* <div className='deposit-his mt-3'>
															<p>History</p>
															<div className='deposit-his-btn'>
															<button className='btn'>TRX</button>
															<button className='btn'>USDT</button>
															<button className='btn'>BTC</button>

															</div>
														</div>
														<div className='deposit-his mt-3'>
															<p>Top</p>
															<div className='deposit-his-btn'>
															<button className='btn'>BNB</button>
															<button className='btn'>TRX</button>
															<button className='btn'>USDT</button>
															<button className='btn'>BTC</button>

															</div>
														</div>  */}
														<div className='deposit-coin-list'>
															<ul>  
															{handleSearch()?.map((i,ii) => {
																return  (
																	<>
																		<li onClick={() => {selectCoin(i)}}><div className='img'><img src={i.image}  width='40' key={ii}/></div><div className='text'><p className='symbole'>{i.coinName}</p><span className='name'>{i.coinName}</span></div></li>
																	</>
																);
															})}

															</ul>
														</div> 
														</div>
													</div>
													</div>
												</Modal>
											</div>
										</div>
									</div>
									<div className='select-field mt-3'>
										<span className='lable'>Network</span>
										<div className='row'>
											<div className='col-md-3'>
												<p className='select-coin'>Select Network</p>
											</div>
											<div className='col-md-9'>
												<div  className='select-box-div d-flex justify-content-between' onClick={onOpenModal2} > 
														<h5 className='h4  my-2'>{detailcoinName}</h5> <p className='p-1'><i classname="fas fa-chevron-down"></i></p>
												</div>
												<Modal open={open2} onClose={onCloseModal2}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
													<div className='container'>
													<div className='row deposit-modal m-0'>
														<div className='col-md-12'>
														<h2>Select Network</h2>
														<div className='info'><p>Ensure that the selected deposit network is the same as the withdrawal network. Otherwise, your assets may be lost.</p></div>
														<div className='deposit-coin-list' >
															<ul> 

																{token_details?.[0]?.networkDetails?.map((iiii,iiiii) =>{
																	return(
																		<li  onClick={() => {selectNetwork(iiii?._id,iiii?.Network);get_available_amount(iiii._id)}} key={iiiii}><div className='text'><p className='symbole'>{iiii?.Network}</p><span className='name'>{token_details?.[0]?.networkDetails?.[iiiii]?.Network}</span></div></li>

																	);	
																})} 
															
															</ul>
														</div> 
														</div>
													</div>
													</div>
												</Modal>
											</div>
										</div>
									</div>
									<div className='form-group withdrawal-input mt-4'>
										<div className='row m-0 align-items-center'>
											<div className='col-md-3 sm_p0'>
												<p className='m-0'>Enter Your Amount</p>
											</div>
											<div className='col-md-9 pr-0 pl-md-2 pl-0'>
													<input type='text' className='form-control' onChange={(e) => setamount(e.target.value)} placeholder='Enter Your Amount'/>
											</div>
										</div>
										
									</div>
									<div className='form-group withdrawal-input mt-4'>
										<div className='row m-0 align-items-center'>
											<div className='col-md-3 sm_p0'>
												<p className='m-0'>Enter Your Wallet Address</p>
											</div>
											<div className='col-md-9 pr-0 pl-md-2 pl-0'>
													<input type='text' className='form-control' onChange={(e) => setwalletAddress(e.target.value)} placeholder='Enter Your Wallet Address'/>
											</div>
										</div>
										
									</div>
									<div className='form-group withdrawal-input mt-4 d-flex justify-content-center align-items-center'>
										<a  className='btn submit-button ' onClick={() => dropdownq()} > Submit </a><br />
									</div>
								</div>
								<div className='withdrawal-input-box' id="withdraw-box-2">								
									<div className='form-group withdrawal-input mt-4'>
										<div className='row m-0 align-items-center'>
											<div className='col-md-3 sm_p0'>
												<p className='m-0'>Enter Your 2fa Code</p>
											</div>
											<div className='col-md-9 pr-0 pl-md-2 pl-0'>
													<input type='text' className='form-control' onChange={(e) => settwofa_code(e.target.value)} placeholder='Enter Your 2fa Code'/>
											</div>
										</div>
										{/* <div className='row m-0 align-items-center'>
											<div className='col-md-3 sm_p0'>
												<p className='m-0'>Enter Email Your OTP</p>
											</div>
											<div className='col-md-9 pr-0 pl-md-2 pl-0 d-flex align-items-center justify-content-between'>
													<input type='text' className='form-control' onChange={(e) => setotp(e.target.value)} placeholder='Enter Email Your OTP'/>

													<a onClick={() => getotp()} className='btn text-nowrap otp-btn ml-3'> Get Otp</a>
											</div>
										</div> */}
										
									</div>
									<div className='form-group withdrawal-input mt-4'>
										<div className='row m-0 align-items-center'>
											<div className='col-md-3 sm_p0'>
												<p className='m-0'>Enter Phone Your OTP</p>
											</div>
											<div className='col-md-9 pr-0 pl-md-2 pl-0 d-flex align-items-center justify-content-between'>
													<input type='text' className='form-control' onChange={(e) => setotp(e.target.value)} placeholder='Enter Phone Your OTP'/>

													<a onClick={() => getotp()} className='btn text-nowrap otp-btn ml-3'> Get Otp</a>
											</div>
										</div>
										<div >
												<button  className='btn submit-button float-right'type='submit'> Submit </button>
										</div>
									</div>
								</div>
                            
                            </form>                            




							<div className=" row  m-0 " style={{width:'100%'}}>
								<div className="col-12 mt-5">
									<div className=" card bg-transparent border-0 padding_zero_add" style={{paddingLeft:'22%'}}>
										
										<div className="col-12 tip">
											<div className='row'>
												<div className='col-md-6'>
													<p  className='m-0'>Available Balance: </p>
													<p>0.00 USDT</p>
												</div>
												<div className='col-md-6'>
													<p  className='m-0'>Minimum Withdrawal: </p>
													<p>1.00 USDT</p>
												</div>
                                                <div className='col-md-6 mt-2'>
													<p  className='m-0'>Fees: </p>
													<p>0.10-35.00 USDT</p>
												</div>
												<div className='col-md-6 mt-2'>
													<p className='m-0'>Remaining daily withdrawal amount:</p>
													<p>1 BTC</p>
												</div>
												
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
				</div>
				<div  className='col-lg-5'>
					<div className='row'>
						<div className='col-md-12 mt-5'>
							<Link to={'/vendor/withdrawAssets'} className='faq-Deposit-btn'>Withdraw Fiat <span><i classname="fas fa-arrow-right"></i></span></Link>
						</div>
						<div className='depost-faq mt-4'>
							<h2>FAQ</h2>
							<div className='faq-con'>
								<ul>
									<li className='d-flex'><i classname="fas fa-arrow-right"></i><p className='ml-2'><Link> How do I withdraw crypto from my {PROJECT_NAME} account?</Link></p></li>
									<li className='d-flex'><i classname="fas fa-arrow-right"></i><p className='ml-2'><Link>What should I do if I didn't receive my withdrawal or if I made a withdrawal to an incorrect address? </Link></p></li>
									<li className='d-flex'><i classname="fas fa-arrow-right"></i><p className='ml-2'><Link>Is there a limit on 24h withdrawal?</Link></p></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<WithdrawTable props={passProps} />
			{/* <div className='row'>
				<div className='col-md-12'>
					<h2>Deposit History</h2>
					<table className="table table-borderless">
						<thead className='thead-light'>
							<tr>
								<th className="w-25"> Amount   </th>
								<th>Network</th>
								<th>Blockchain Record </th>
								<th>Status</th>
								<th> Remarks </th>
								<th className="text-right">  Time </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="6" className='text-center'>
									<img src={emptyImg} className='mt-5' /><br/>
									<span>No data</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div> */}
		</div>
	</div>
	)
}

export default WithdrawAssets