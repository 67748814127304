import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import usdt from '../../../_assets/images/usdt.png'
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import thired from '../../../_assets/images/thired-1.png'
import rightImg from '../../../_assets/images/need-verify.fb79090f.svg'

const UseStyles = makeStyles({
  assetsBtn: {
      marginTop:'77px',
      display: 'flex',
      background: 'rgba(125, 135, 146, 0.07840000000000003)',
      alignItems: 'center'
  },
  btnDesign:{
      width: '1200px',
      margin:  '0 auto',
      display: 'flex',
      marginBottom: '-16px',
      justifyContent: 'space-between',
      overflowX: 'scroll',
      // width: '100%',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
          height: '0px',
        },

  },
  btnUl:{
      display: 'flex',
      marginBottom: '1em',
  },
  btnTag:{
      height: '64px!important',
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '.1px',
      borderRadius: '0!important',
      color: 'hsla(0,0%,100%,.6)',
      padding: '0 12px',
  },
  MuiButtonlabel:{
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
  },
  MuiTouchRippleroot:  {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: 'hidden',
      position: 'absolute',
      borderRadius: 'inherit',
      pointerEvents: 'none',
      },
  
});
const Deposit = () => {
	const classes = UseStyles();
	const {langchange} = LangState();
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const onOpenModal2 = () => setOpen2(true);
	const onCloseModal2 = () => setOpen2(false);
	return (
	<div>
		<div className={classes.assetsBtn}>
			<div className={classes.btnDesign}>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
				</ul>
				<ul className={classes.btnUl}>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/swap-currency' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['swap'][langchange]}</Link>
							<Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/depositassest' style={{color:'rgb(40 205 149)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/withdrawAssets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/bonus' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
				</ul>
			</div>
		</div>
		<div className='container'>
			<div className='row'>
				<div  className='col-md-6'>
					<div className='row'>
						<div className='col-md-12 mt-5'>
								<h1>Deposit Fiat</h1>
						</div>
					</div>
					<div className='mt-5'>
						<div className='new_depost'>
							<div className='select-field'>
							<span className=''>Coin</span>
							<div className='row'>
								<div className='col-md-12'>
									<div className='select-box-div d-flex justify-content-between'>
										<p className='d-flex m-0'><span className='img'><img src={usdt} width='30' /></span><h5>USDT</h5><span className='spam-label'>Tether</span></p><p className='p-1'><i class="fas fa-chevron-down"></i></p>
									</div>
								</div>
								
							</div>
						</div>
						<div className='select-field mt-3'>
							<span className=''>Payment Method</span>
							<div className='row'>
								<div className='col-md-12 depost-fiat'>
									<div className='select-box-div d-flex justify-content-between  hilight'> 
										<p className='d-flex m-0'><span className='img mt-2 '><img src={thired} width='30' /></span><div><h5 className='h4'>Bank Card(Visa/MC)</h5><span className='spam-label m-0'>Direct debit and fast processing of deposits.</span></div></p><p className='p-1'><span className='spam-label m-0'>3.80% Free Rate</span></p>
									</div>

								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
				<div  className='col-md-6 depost-fiat'>
					<div className='row'>
						<div className='col-md-12 mt-5'>
							<Link to={'/vendor/depositassest'} className='faq-Deposit-btn'>Deposit Crypto <span><i class="fas fa-arrow-right"></i></span></Link>
						</div>
					</div>
					<div className=' mt-4 ' style={{border:'1px solid #dcdcdc',borderRadius:'5px'}}>
						<h2 className='m-4'>Security Settings</h2>
						<div className='side-img'>
							<img src={rightImg} />
						</div>
							<p className='m-4'>Complete the following settings to ensure your account security:</p>

						<div className='justify-content-between d-flex mx-4 info'>
							<p>Identity Verification</p> <Link  to='/vendor/kycform'><p className='text-danger'>Unverified <i className='fas fa-angle-right'> </i></p></Link>
						</div>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-md-12'>
					<h2>Deposit History</h2>
					<table className="table table-borderless">
						<thead className='thead-light'>
							<tr>
								<th className="w-25"> Amount   </th>
								<th>Network</th>
								<th>Blockchain Record </th>
								<th>Status</th>
								<th> Remarks </th>
								<th className="text-right">  Time </th>
							</tr>
						</thead>
						<tbody>
							<tr>
							<td colspan="6" className='text-center'>
									<img src={emptyImg} className='mt-5' /><br/>
									<span>No data</span>
							</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	)
}

export default Deposit