import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
const service = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'92px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Service</h2>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        
        <div className='about_box-1 mt-5'>
          <div className='text-center mb-3'>
            <h2>SERVICE</h2>
           
          </div>
          <div className='row m-0 values  about-page'>
           
            <div className='col-md-12 '>
              <div className='about-story-text text-primary'>
                <div>
                  <table className='table table-bordered'>
                    <thead>

                    </thead>
                    <tbody>
                      <tr>
                        <td>Services</td>
                        <td>Support Center</td>
                        <td>Submit a ticket</td>
                        <td>Fees</td>
                      </tr>
                      <tr>
                        <td>Identity Verification</td>
                        <td>Technical Support</td>
                        <td>Bug Bounty</td>
                        <td>Downloads</td>
                      </tr>
                      <tr>
                        <td>Desktop Application</td>
                        <td>Buy crypto</td>
                        <td>Institutional & VIP Services</td>
                        <td>Referral</td>
                      </tr><tr>
                        <td>Execution Solutions</td>
                        <td>Affiliate</td>
                        <td>OTC Trading</td>
                        <td>Listing Application</td>
                      </tr>
                      <tr>
                        <td>P2p merchant Application</td>
                        <td>P2p PRO merchant Application</td>
                        <td>Historical Mrket Data</td>
                        <td>Welcome Bonus</td>
                      </tr>
                      <tr>
                        <td>
                        Deposit Bonus
                        </td>
                        <td>All - Star Program</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </div>
  )
}

export default service