import React ,{useEffect, useState} from 'react'
import { LangState } from '../../../../LangugeContext';
import { kycpage, registerlang } from '../../../../lang/lang';

import { Link } from 'react-router-dom';
import { CountryData1 } from '../../../public/register/countrycode';
import { acc_data_update, getUserDetails, update_acc_data_update } from '../../../../redux/actions/dashboard.action';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
const VerifyEmail = ({history}) => {
    const {langchange} = LangState();
    const [ activeclass, setactiveclass ] = useState('icon' );
    const [email,setemail] = useState();
    const [country, setcountry] = useState(1)
    const [search,setsearch] = useState('');
    const [phone,setphone] = useState(1);
    const [otp,setotp] = useState();
    console.log(history.push);
    const handleSubmotForm = () => {
        // handleSubmit();
    }

    function dropdownq() {
        //  document.getElementById("withdraw-box-1").style.display = 'none';
		document.getElementById("withdraw-box-2").classList.toggle("show");
		document.getElementById("withdraw-box-1").classList.toggle("hide");
    }
    function dropdownq2() {
        //  document.getElementById("withdraw-box-1").style.display = 'none';
		document.getElementById("withdraw-box-4").classList.toggle("show");
		document.getElementById("withdraw-box-3").classList.toggle("hide");
    }
    const user = useSelector(state => state.dashboard.user);
    // console.log('user',user);
    const dispatch = useDispatch();
    const handleSubmitForm1 = () => {
        var data ={
            phone:phone,
            country_code:'+'+country,
            user_type:'phone',
            otp:otp
        }
        dispatch(update_acc_data_update(data,history));
        console.log('hiii');
        
        // handleSubmit();
    }
    const handleSubmitForm = () => {
        var data ={
            email:email,
            user_type:'email',
            otp:otp
        }
        dispatch(update_acc_data_update(data,history));
    }
    
    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";
    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";     
    }
    const SendOTP = () => {
        console.log('phone OTP');
        var data ={
            phone:phone,
            country_code:'+'+country,
            user_type:'phone',
        }
        // console.log('phone OTP',data);

        dispatch(acc_data_update(data));
        dropdownq()
    }
    const emialOTP = () => {
        console.log('emialOTP');
        var data = {
            email:email,
            user_type:'email',
        }
        dispatch(acc_data_update(data));
        dropdownq2();
    }

    useEffect(() => {
        dispatch(getUserDetails())        

    },[])

  return (
    <div>
         <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#"> {kycpage['0']['nav']['btn4'][langchange]}   </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#"> {kycpage['0']['nav']['btn5'][langchange]}  </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">  {kycpage['0']['nav']['btn6'][langchange]}  </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className='container mt-5' style={{overflow: 'visible'}}>
  <div className='row'>
    <div className='col-md-5'>
    <div className="form__tabs__wrapper">
                
                <Form onSubmit={handleSubmitForm1} className={user.phone ? 'd-none' : ' '}>
                    <div className="form__tabs active" id="phoneform">
                        <div className="tab__content">
                            
                            <div  className='withdrawal-input-box-1' id="withdraw-box-1">
                                <div className="form__control">
                                    <label for="phone login__number">{registerlang['0']['mobilebtn'][langchange]}</label>
                                    <div className="input__dropdown__wrapper">
                                        <div className="input__dropdown new-input-verify-data login__number__input d-flex align-items-center">
                                            <div className="form-group  d-flex align-items-center mb-0">

                                                <button type="button" className="dropdown__btn text-nowrap " onClick={dropdown}> <span>+{country}</span>
                                                    <svg className="down_svg__icon" width="24" height="24"
                                                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor">
                                                        <path
                                                            d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                        </path>
                                                    </svg>
                                                </button>
                                                <div className="number__dropdown " id='countryDrop'>
                                                    <div className="search__input">
                                                        <label for="search">
                                                            <svg className="search_svg__icon" viewBox="0 0 1024 1024"
                                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" fill="currentColor">
                                                                <defs>
                                                                    <style></style>
                                                                </defs>
                                                                <path
                                                                    d="M719.104 688.896l134.23 134.272a21.333 21.333 0 01-30.166 30.165L688.896 719.104l30.208-30.208zm-249.77-518.23a298.667 298.667 0 110 597.334 298.667 298.667 0 010-597.333zm0 42.667a256 256 0 100 512 256 256 0 000-512z">
                                                                </path>
                                                            </svg>
                                                        </label>
                                                        <input type="text" id="search"  onChange={(e) => {setsearch(e.target.value)} }  className="search__input" />
                                                    </div>
                                                    <div className="select__countery">
                                                    
                                                    {CountryData1.filter((key) => {
                                                        if(search == ""){
                                                            return key;
                                                        }else if(key.en.toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                                            return key
                                                        }
                                                    }).map(key =>(

                                                        <div className="countery"  onClick={ () => {setcountry(key.mobileCode);dropdownClose()}}>
                                                            <div className="flag" >
                                                                <img src={key.ico} width='30' />
                                                                <p className="countery__code" >{key.en}</p>
                                                            </div>
                                                            <div className="number">+{key.mobileCode}</div>
                                                        </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            </div>
                                            <input type="number"  placeholder="phone" className='w-100%'   onChange={(e) => {setphone(e.target.value)}}
                                                required />
                                            <a  className="send__code code_send_button" onClick={SendOTP}>{registerlang['0']['otp'][langchange]}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                            <div className='withdrawal-input-box' id="withdraw-box-2">
                                <div className="form__control">
                                    <label for="verification">OTP</label>
                                    <div className="email__verification">
                                        <input type="password" id="verification" name="verification" onChange={(e) => {setotp(e.target.value)}} required />
                                        
                                    </div>
                                </div>
                                <div className="submit__btn__wrapper sign__up__btn">
                                    <button type="submit" className="btn" style={{backgroundColor:'#24ae8f',color:'#fff',padding: '10px 15px'}}>Submit</button>
                                    {/* <button className="receive__sms" href="reset-password.html"
                                        className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button> */}
                                </div>
                            </div>
                            
                        </div>
                       
                       
                    </div>
                </Form>



                <Form onSubmit={handleSubmitForm} className={user.email ? 'd-none' : ' '}>
                <div className="form__tabs active" id="emailform">
                    <div className="tab__content">
                        <div className='withdrawal-input-box-1' id="withdraw-box-3">
                            <div className="form__control ">
                                <label for="Email">{registerlang['0']['emailbtn'][langchange]}</label>
                                <div className="email__verification new-input-verify-data">
                                    <input className="reset__input" type="Email" id="Email" placeholder="Email"
                                    name="Email" onChange={(e) => {setemail(e.target.value)}} required />
                                    <a  onClick={emialOTP} className="send__code code_send_button">{registerlang['0']['otp'][langchange]}</a>
                                </div>
                            </div>
                        </div>
                        <div className='withdrawal-input-box' id="withdraw-box-4">
                            <div className="form__control">
                                <label for="verification">OTP</label>
                                <div className="email__verification">
                                    <input type="password" id="verification" name="verification" onChange={(e) => {setotp(e.target.value)}} required />
                                    
                                </div>
                            </div>
                            
                            <div className="submit__btn__wrapper sign__up__btn">
                                <button type="submit" className="btn" style={{backgroundColor:'#24ae8f',color:'#fff',padding: '10px 15px'}}>Submit</button>
                            {/* <button className="receive__sms" href="reset-password.html"
                                className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button> */}
                            </div>
                        </div>
                    </div>
                   
            
                   
                </div>
                
                </Form>
            </div>
    </div>
 </div>
 </div>
    </div>
  )
}

export default VerifyEmail