import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import madel from '../../../../_assets/images/madel.svg';
import visa from '../../../../_assets/images/visa2.png';
import { LangState } from '../../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import {sell_p2p} from '../../../../redux/actions/p2p.action'
const P2pSell = () => {
    const dispatch = useDispatch();
    const {langchange} = LangState();
    const sell_p2pData = useSelector(state => state.p2p.sell_p2p);
    console.log('sell_p2p',sell_p2pData);
    useEffect(() => {
        dispatch(sell_p2p());
    },[]);

    const tableData = () => {
        return sell_p2pData?.postings?.map((val) => {
            return(
                <tr>
                    <td className="name"> <p> {val?.user_id?.[0]?.firstname?.slice(0, val?.user_id?.[0]?.firstname?.length + 1 - val?.user_id?.[0]?.firstname?.length)} {val?.user_id?.[0]?.lastname?.slice(0, val?.user_id?.[0]?.lastname?.length + 1 - val?.user_id?.[0]?.lastname?.length)}  </p>  {val?.token_id?.[0]?.coinName}
                        {/* <img src={madel}/>  */}
                    </td>
                    <td className="text-center">
                        {val?.UserPaymentMethodID?.[0]?.PaymentMethodFields?.map((method) => {
                            return(<div>{method?.field?.replace('_',' ') }: {method.value} </div>)

                        })}
                        {/* <img src={visa}/>   */}
                    </td>
                    <td>  {val?.orderId?.[0]?.price} {val?.orderId?.[0]?.sell_currency}  </td>
                    <td>  {val?.quantity} </td>
                    <td>   {val?.orderId?.[0]?.price * val?.quantity} 	</td>
                    <td>   {val?.status} 	</td>

                    <td className="p-0 text-right" > <Link to={'/vendor/order-chat/' + val?._id} className="btn"> View </Link>   </td>
                </tr>
            );
        })
    }
  return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-new-style">
            <div className="tab-container">
                <div className="left-sidebar" id="left-sidebar">
                    <ul className="nav flex-column"> 
                        <p> P2P </p>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange] } </Link>
                        </li>
                        <p> {p2ppage['0']['other'][langchange] } </p>
                        <li className="nav-item">
                            <a className="nav-link" href="#"> {p2ppage['0']['support'][langchange] } </a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange] }  </Link>
                        </li> 
                    </ul>
                </div>
                <div className="right-tab">
                    <div className="tab-header">
                        <ul className="nav" role="tab">
                            <li className="nav-item" id="more" > 
                            <a className="nav-link active" data-toggle="tab" href="#USDT"> USDT </a>
                        </li>
                        <li className="nav-item"> 
                            <a className="nav-link " data-toggle="tab" href="#BTC">   STA </a>
                        </li>
                        {/* <li className="nav-item"> 
                            <a className="nav-link " data-toggle="tab" href="#ETH">   ETH </a>
                        </li>
                        
                        <li className="nav-item"> 
                            <a className="nav-link " data-toggle="tab" href="#USDC">  USDC </a>
                        </li> */}
                    </ul>
                </div>
                <div className="tab-content">
                    <div id="USDT" className="tab-pane active">
                        <div className="table-responsive-xl"> 
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th className="w-25"> {p2ppage['0']['table']['label1'][langchange] }    </th>
                                    <th>  {p2ppage['0']['table']['label2'][langchange] }	  </th>
                                    <th>  Price  </th>
                                    <th>Quantity</th>
                                    <th>  Total </th>
                                    <th>Status</th>
                                    <th className="text-right">  {p2ppage['0']['table']['label6'][langchange] } <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData()}
                               
                            </tbody>
                        </table>
                    </div>
                </div>                                        
              
                
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default P2pSell