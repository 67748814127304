import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import { useDispatch, useSelector } from 'react-redux';
import { withdraw_history } from '../../../redux/actions/withdraw.action';
import { Pagination, Icon } from 'semantic-ui-react';

const WithdrawTable = (props) => {
	const { langchange } = LangState();
	const dispatch = useDispatch();
	const [page, setpage] = useState(1);
	const [listno, setlistno] = useState(0);
	const withdraw_history1 = useSelector(state => state.assets.withdraw_history);

	useEffect(() => {
		dispatch(withdraw_history({ skip: 0, limit: 5 }));

	}, [])
	const pageChange = (e, data) => {
		let page = data.activePage;
		let pageNo = page === 1 ? 0 : ((page - 1) * 5);
		dispatch(withdraw_history({ skip: pageNo, limit: 5 }));
		setlistno(pageNo)
	}

	const [search, setsearch] = useState();
	const handleSearch = () => {
		// if(search != undefined){
		// 	return deposite.deposit_tokens?.filter(
		// 		(iii) => 
		// 		iii.coinName.toLowerCase().includes(search)
		// 	)
		// }else{
		// 	return(deposite.deposit_tokens);
		// }
	}




	return (
		<div>
			<div className='row Withdraw_Table_1 mt-3'>
				<div className='col-md-12'>
					<h2>Withdraw History</h2>
					<div className='table-responsive'>
						<table className="table table-borderless">
							<thead className='thead-light'>
								<tr>
									<th > id  </th>
									<th>Token</th>
									<th>Network</th>
									<th className=''> Amount  </th>
									{/* <th>Network</th> */}
									<th > Blockchain Record	  </th>
									<th>Wallet Address	</th>
									<th > Status  </th>
									<th>Remarks</th>
									<th className="">  Start Time	 </th>
								</tr>
							</thead>
							<tbody>
								{withdraw_history1?.transaction?.map((val, key) => {
									return (
										<tr>
											<td>{listno + key + 1}</td>
											<td>{val?.tokenName}</td>
											<td><span>{val?.networkname}</span></td>
											<td>{val?.amount}</td>
											{/* <td><span>{val?.amount}</span></td> */}
											<td></td>
											<td>{val?.wallet_address}</td>
											<td>{val?.status}</td>
											<td>{val?.remark}</td>
											<td>{val?.createdAt}</td>
										</tr>
									);
								})}

							</tbody>
						</table>
					</div>
					<div className='pagination_table text-center mt-3'>

						<Pagination className="mx-auto"
							defaultActivePage={1}
							onPageChange={pageChange}
							ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
							firstItem={{ content: <Icon name='angle double left' />, icon: true }}
							lastItem={{ content: <Icon name='angle double right' />, icon: true }}
							prevItem={{ content: <Icon name='angle left' />, icon: true }}
							nextItem={{ content: <Icon name='angle right' />, icon: true }}
							totalPages={Math.ceil(withdraw_history1?.records / 5)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WithdrawTable