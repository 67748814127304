import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Loading from '../common/Loading'
import Ticker from './Ticker'
import TradeHistory from './TradeHistory'
import OrderBook from './OrderBook'

class Trade extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            bids:' ',
            trades:' ',
            time: '',
        };
        this.tradesCount = 100;
        this.streams = ['@ticker','@depth20','@trade'];
    }

    componentDidMount() {
        window.location.reload();
      }
    

    _connectSocketStreams(streams) {
        streams = streams.join('/');
        console.log('streams',streams);
        let connection = btoa(streams);
        this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);

        this[connection].onmessage = evt => { 
            let eventData = JSON.parse(evt.data);

            // console.log('eventData',eventData)
            this.setState({
                time: eventData.data.T,
            })
            
            if(eventData.stream.endsWith('@ticker')){
                eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
                this.props.dispatch({
                    type: 'SET_TICKER',
                    data: eventData.data
                })     
                this.setState({
                    loadedTicker: true
                })   
            }
            // console.log('eventData.stream',eventData.stream)
            if(eventData.stream.endsWith('@trade')){
                // console.log('this.props.trades',this.props)
                if(this.props.trades && Object.keys(this.props.trades).length > 0){
                    let trades = this.props.trades;
                    trades.push(eventData.data);
                    trades = trades.slice(-1*this.tradesCount);
                   var data1 = this.props.dispatch({
                        type: 'SET_TRADES',
                        data: trades
                    }) 
                    // console.log('this.props.data',trades);

                    this.setState({
                        loadedTrades: true,
                        trades: data1.data
                    }) 
                }              
            }
            if(eventData.stream.endsWith('@depth20')){
              var data =  this.props.dispatch({
                    type: 'SET_DEPTH',
                    data: eventData.data
                }) 

                data1 =  this.props.dispatch({
                    type: 'SET_DEPTH',
                    data: eventData,
                }) 


                // console.log('data' , data1);

                this.setState({
                    loadedDepth: true,
                    bids:data.data,
                }) 
            }
            this.setState({
                isLoaded: true
            })  
        };
        this[connection].onerror = evt => {
            console.error(evt);
        }
    }

    _disconnectSocketStreams(streams){
        streams = streams.join('/');
        let connection = btoa(streams);
        if (this[connection]?.readyState === WebSocket.OPEN) {
            this[connection].close();
        }
    } 

    componentDidMount() {

        let symbol = this.props.currencyId.toLowerCase();
        console.log('symbol',symbol)
        this._connectSocketStreams(this.streams.map(i => `${symbol}${i}`));

        axios({
            method: 'get',
            url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${this.props.currencyId}`
        })
        .then(response => {
            this.props.dispatch({
                type: 'SET_TRADES',
                data: response.data
            }) 
            this.setState({
                isLoaded: true,
                loadedTrades: true
            }) 
        })
        .catch(error => {
            this.setState({
                isLoaded: false,
                error: error
            })
        });
    }

    

    componentWillUnmount() {
        let symbol = this.props.currencyId.toLowerCase();
        // console.log('symbol',symbol)
        this._disconnectSocketStreams(this.streams.map(i => `${symbol}${i}`))
    }

    render() {
        // console.log('this.props.depth.bids',this.state.bids);
        const { error, isLoaded, loadedDepth, loadedTicker, loadedTrades } = this.state;
        if (error) {
        //   return <div className="alert alert-danger">{error.message}</div>;
        // console.log('error',error)
        }
        if (!isLoaded) {
          return <Loading />;
        }
        return (
            <React.Fragment>
                {/* <div className="row">
                    <div className="col-12">{loadedTicker ? <Ticker {...this.props.ticker} /> : <Loading />}</div>
                </div> */}
                {/* <div className="row m-0 sideprogress"> */}
                    {/* <div className="col-12 col-sm-6">{loadedTrades ? <TradeHistory trades={this.props.trades}/> : <Loading />}</div> */}
                    {/* <div className="col-12 col-sm-12 m-0" > */}
                        {loadedDepth ? <OrderBook bids={this.state.bids.bids} asks={this.state.bids.asks} id={this.props.currencyId} time={this.state.time} /> : <Loading />}
                        {/* </div> */}
                {/* </div> */}
            </React.Fragment>    
      )
    }

}

export default connect(
    state => state
)(Trade)