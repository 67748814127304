import * as types from '../actions/_types';

const initialState = {
  
  my_posting : [],
  buy_p2p:[],
  all_markit_coin:[],
  order_p2p:[],
  sell_p2p:[],
  view_user_sell:[],
  p2p_posting_more_details: [],
};

export default function p2p(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_P2P_MY_POSTING:
      return { ...state, my_posting: action.payload }; 
    case types.SAVE_BUY_P2P:
      return{...state, buy_p2p: action.payload};
    case types.SAVE_SELL_P2P:
      return {...state, sell_p2p: action.payload};
    case types.SAVE_ORDER_P2P:
      return{...state, order_p2p: action.payload};
    case types.SAVE_VIEW_USER_ORDER_P2P:
      return{...state, view_user_sell: action.payload};  
    case types.SAVE_P2P_POSTING_DETAILS:
      return {...state, p2p_posting_more_details: action.payload};
    case types.SAVE_REFERRAL:
      return{...state, referral: action.payload};  
    default:
      return state;
  }
}

