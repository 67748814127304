import React from 'react';
import { Link } from 'react-router-dom';
import { cloudMiningpage, elcMiningpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
const elecMining = () => {
    const {langchange} = LangState();

    return (
    <div>
        <div className="elec">
            <div className="container-elec">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/cloudMining"> {cloudMiningpage['0']['nav']['btn1'][langchange]} </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/orderMining"> {cloudMiningpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link  className="nav-link active" to="/vendor/elecMining">  {cloudMiningpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="elec-container">
            <div className="l-container">
                <div className="row">
                    <div className="col-md-2 nav-box">
                        <ul className="nav flex-column" role="tab">
                            <li className="nav-item"> 
                                <a className="nav-link active" data-toggle="tab" href="#recharge"> <i class='fas fa-wallet'></i>  {elcMiningpage['0']['Recharge'][langchange]} </a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " data-toggle="tab" href="#deductions" to="/vendor/NoData"> <i class='fas fa-parachute-box'></i>  {elcMiningpage['0']['Deductions'][langchange]} </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-10">
                        <div className="tab-content">
                            <div id="recharge" className="tab-pane active">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card f-rechage">
                                                <div className="card-header">
                                                    {elcMiningpage['0']['Recharge'][langchange]}
                                                </div>
                                                <div className="card-body">
                                                    <p>  {elcMiningpage['0']['Available'][langchange]} </p>
                                                    <h1>  0 USDT</h1>
                                                    <h4>  {elcMiningpage['0']['Recharge'][langchange]} </h4>
                                                    <div className="input-box-rechage d-flex">
                                                        <form>
                                                            <div className="input-group W-100" >
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text"> USDT</span>
                                                                </div>
                                                                <input type="text" className="form-control" />
                                                                <div className="input-group-append">
                                                                    <button className="btn text-right" type="submit"> {elcMiningpage['0']['All'][langchange]}</button>
                                                                </div>                                        
                                                            </div>
                                                        </form>
                                                        <button type="submit" className="btn submit-btn">  {elcMiningpage['0']['Recharge'][langchange]} </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4">
                                            <div className="card s-reachage">
                                                <div className="card-header">
                                                    {elcMiningpage['0']['Recharge'][langchange]}
                                                </div>
                                                <div className="card-footer">
                                                    <p>  {elcMiningpage['0']['time'][langchange]} </p>
                                                    <p>  {elcMiningpage['0']['Recharge'][langchange]} </p>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="deductions" className="tab-pane">
                                    <div className="container-table">
                                        <div className="table-responsive"> 
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th className="text-left"> {elcMiningpage['0']['table']['label1'][langchange]}  </th>
                                                    <th className="text-center" > {elcMiningpage['0']['table']['label2'][langchange]}	 </th>
                                                    <th className="text-center" > {elcMiningpage['0']['table']['label3'][langchange]} </th>
                                                    <th className="text-center" >  {elcMiningpage['0']['table']['label4'][langchange]} </th>
                                                    <th className="text-right">  {elcMiningpage['0']['table']['label5'][langchange]} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colspan="9" className="text-center mt-4">
                                                        <img src="img/no_history-removebg-preview.png" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default elecMining