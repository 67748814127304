import { Accordion } from 'semantic-ui-react';
import { Activation } from '../../Services/api/api.service';
import * as types from '../actions/_types';

const initialState = {
 
    assetsData : [],
    withdraw_history:[],
    swap_tokens:[],
    swap_sell_cal:[],
    swap_buy_cal:[],
    available_amount:[],
    swap_trans:[],
    swap_balance:[],
    swap_2nd_balance:[],
    token_prices:[],
    wallet_details:[],
    new_swap_trans: [],
    balance_with_type: [],
    allDepostHistory:[],
    get_Price:[],
};

export default function assets(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_USER_ASSETS:
      return { ...state, assetsData: action.payload }; 
    case types.SAVE_WITHDRAW_HISTORY:
      return{...state, withdraw_history: action.payload};
    case types.SAVE_ALL_DEPOST_HISTORY:
      return{...state,allDepostHistory: action.payload};  
    case types.SAVE_AVAILABLE_AMOUNT:
      return{...state, available_amount : action.payload};  
    case types.SAVE_SWAP_TOKENS:
      return {...state, swap_tokens : action.payload};
    case types.SAVE_CALCU_SWAP_SELL_PRICE:
      return {...state, swap_sell_cal : action.payload};  
    case types.SAVE_CALCU_SWAP_BUY_PRICE:
      return {...state, swap_buy_cal :action.payload}
    case types.SAVE_SWAP_TRANSACTION:
      return {...state, swap_trans :action.payload};
    case types.SAVE_SWAP_BALANCE:
      return {...state,swap_balance : action.payload};  
    case types.SAVE_COIN_SWAP_BALANCE_WALLET_TYPE:
      return{...state, swap_2nd_balance:action.payload};  
    case types.SAVE_SWAP_BUY_BALANCE:
      return {...state , buy_swap_balance : action.payload};
    case types.SAVE_TOKEN_AS_PRICE:
      return {...state, token_prices :action.payload}; 
    case types.SAVE_WALLET_DETAILS:
      // console.log('action.payload',action.payload);

      return {...state , wallet_details : action.payload};  
    case types.SAVE_NEW_SWAP_TRANSACTON:
      return {...state , new_swap_trans : action.payload};
    case types.SAVE_SWAP_BALANCE_WALLET_TYPE:
      return {...state , balance_with_type : action.payload};  
    case types.SAVE_COIN_PRICE:
      return {...state ,get_Price : action.payload}    
    default:
      return state;
  }
}

