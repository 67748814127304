import React from 'react';
import marginimg from '../../../../_assets/images/margin.svg';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { marginAccountpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';

const lend = () => {
    const {langchange} = LangState();
    return (
        <div>
            <div className="box___3B-ao" style={{marginTop:'80px'}}>
                <div className="wrapper___1F-PJ">
                    <div>
                        <div className="maskPanel___3oYx7 card" style={{width: '100%', height: '600px'}}>
                            <div>
                                <div className="imgPanel___1ZRFL">
                                    <img className="maskImage___2WeuO" src={marginimg} alt="" />
                                </div>
                                <div className="maskTip___HDeGJ">{marginAccountpage['0']['p'][langchange]}</div>
                                <div className="imgPanel___1ZRFL">
                                    <button className="MuiButtonBase-root MuiButton-root jss57 MuiButton-contained jss60 jss11813 MuiButton-containedPrimary jss61 jss11814" tabindex="0" type="button">
                                        <span className="MuiButton-label" style={{clolor:'#fff'}} >{marginAccountpage['0']['btn'][langchange]}</span>
                                        <span className="MuiTouchRipple-root"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default lend