import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { CryptoState } from '../../../CryptoContext';
// import Moment from 'react-moment';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { get_swap_tokens } from '../../../redux/actions/assets.action';
import { CoinList } from '../../../config/api';
import axios from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom';
// import down from '../../_assets/images/price-down-icon.svg';
// import up from '../../_assets/images/up-icon.svg';
export function numberWithCommma(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const OrderRow = (props) => (
    <>
    {/* <li className="data" style={{backgroundColor:'#151c30'}} key={`${props.i}:${props.ba[0]}:${props.ba[1]}`}>
        <div className="row"> */}
            {/* <div className="col">
                <div className="order-book progress">
                    <div className={`progress-bar ${props.class}`} role="progressbar" style={{width: `${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2)}%`,backgroundColor:'#000'}}  ></div>
                </div>
            </div> */}
            {/* <div className="col">{new BigNumber(props.ba[0]).toFormat(null,1)}</div>          
            <div className="col">{new BigNumber(props.ba[1]).toFormat(null,1)}</div>
        </div>
    </li>   */}
    
    <li  className="data add__323"  key={`${props.i}:${props.ba[0]}:${props.ba[1]}`}>
        <ul>
            <li>
            {new BigNumber(props.ba[0]).toFormat(null,1)}
            </li>
            <li className='text-lg-right' >
            {new BigNumber(props.ba[1]).toFormat(null,1)}
            </li>
            <li className='text-right'>
            {/* {((props.ba[0]) * (props.ba[1])).toFixed(2)} */}
            {(new BigNumber(props.ba[0]*props.ba[1]).toFixed(2))}
            {/* {(new BigNumber(props.ba[0]).toFormat(null,1) * new BigNumber(props.ba[1]).toFormat(null,1) ).toFixed(2)} */}

            </li>
        </ul>
    </li>
    </>



)

const OrderRowTimer = (props) => (
    <>
    
    
    <li  className="data"  key={`${props.i}:${props.ba[0]}:${props.ba[1]}`}>
        <ul>
            <li>
            {new BigNumber(props.ba[0]).toFormat(null,1)}
            </li>
            <li className='text-lg-left'>
            {new BigNumber(props.ba[1]).toFormat(null,1)}
            </li>
            <li className='text-right' >
            {/* {((props.ba[0]) * (props.ba[1])).toFixed(2)} */}
          {moment(props.times).format('hh:mm:ss')}
            {/* {(new BigNumber(props.ba[0]).toFormat(null,1) * new BigNumber(props.ba[1]).toFormat(null,1) ).toFixed(2)} */}

            </li>
        </ul>
    </li>
    </>



)

const OrderBook = (props) => {
    const { currency,symbol,currencyId,livePrice, setlivePrice,pairName} = CryptoState();
    const [coins, setcoins] = useState([]);
    const dispatch = useDispatch();
    const [page, setpage] = useState(1);
    const [loading, setloading] = useState(true);

    const [search, setsearch] = useState('');
    const swap_tokens = useSelector(state => state.assets.swap_tokens.swap_tokens);
    const history = useHistory();
    useEffect(() => {
        fatchcoins();
        dispatch(get_swap_tokens());

        // dispatch(get_all_market_coin());
    },[])
    
    const fatchcoins = async () => {
        const { data } = await axios.get(CoinList('USD'));
        setcoins(data);
        setloading(false);
    }

    // console.log('order props',props.asks[0][0])
    let bids = [];
    let asks = [];
    let asksTime = [];
    let numRowsBid = Math.min(20, props.bids.length);
    let numRowsAsk = Math.min(20, props.asks.length);
    let maxBid = BigNumber.maximum(props.bids.map(bid => bid[0])).toFormat()
    let minAsk = BigNumber.minimum(props.asks.map(ask => ask[0])).toFormat()
    let minBid = new BigNumber(maxBid).minus(BigNumber.minimum(props.bids.map(bid => bid[0]))).toFormat()
    let maxAsk = new BigNumber(minAsk).minus(BigNumber.maximum(props.asks.map(ask => ask[0]))).toFormat()
    for (var b = 0; b < numRowsBid; b++) {
        bids.push(
            <OrderRow i={b} ba={props.bids[b]} diff={maxBid} max={minBid} class="bg-success" />         
        )
    }
    for (var a = 0; a < numRowsAsk; a++) {
        asks.unshift(
            <OrderRow i={a} ba={props.asks[a]} diff={minAsk} max={maxAsk} class="bg-danger" />         
        )
    }

    for (var a = 0; a < numRowsAsk; a++) {
        asksTime.unshift(
            <OrderRowTimer i={a} ba={props.asks[a]} diff={minAsk} max={maxAsk} times={props.time} class="bg-danger" />         
        )
    }



    const handleSearch = () => {
        return coins?.filter(
            (coin) =>
                coin.name.toLowerCase().includes(search) ||
                coin.symbol.toLowerCase().includes(search)
        );
    }

    const handleSearch2 = () => {
        return swap_tokens?.filter(
            (coin) =>
                coin.tokenName.toLowerCase().includes(search) ||
                coin.tokenName.toLowerCase().includes(search)
        );
    }
    // console.log('props',props);
    return (
        <>
        {/* <div className=" my-2 w-100 order-book">
            <div className="card-header">Order Book <span className="text-muted small">Bid-Ask Spread</span></div>
            <ul className="list-group list-group-flush">
            <div className="row">

            <div className="col-12 col-sm-6 text-danger">{asks}</div>
            <div className="col-12 col-sm-6 text-success" >  {bids}</div>
            </div>
            </ul>
        </div>   */}





        <div className="aside__tradde__main row">
                        <div className='col-md-6 w-100 Lg__paddding  Md__paddding p-0'>
                            <h2 className="sec__heading">
                                Order Book
                            </h2>
                            <div className="inner__heading">
                                <span>Price(USDT)</span>
                                <span>Amount({(props.id).toUpperCase()})</span>
                                <span>Total({(props.id).toUpperCase()})</span>
                            </div>

                            <div className="tred__data grid__tred__data down--price  tabs__data__tmb">

                                <ul className="price">
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="chnage__trade">
                                    <div>
                                        <span>42,061.5</span>
                                        <span>≈ 3,185,629.03 </span>
                                        <span>RUB</span>
                                    </div>
                                    <div className="mark__price">
                                        <span>40633.8</span>
                                        <span>Mark Price</span>
                                    </div>
                                </div>
                            </div>
                            <div className="tred__data grid__tred__data up--price tabs__data__tmb">
                                <div className="chnage__trade">
                                    <div>
                                        <span>42,061.5</span>
                                        <span>≈ 3,185,629.03 </span>
                                        <span>RUB</span>
                                    </div>
                                    <div className="mark__price">
                                        <span>40633.8</span>
                                        <span>Mark Price</span>
                                    </div>
                                </div>
                                <ul className="price">
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                43111.7
                                            </li>
                                            <li>
                                                0.18398302
                                            </li>
                                            <li>
                                                3.57245302
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="tred__data grid__tred__data active tabs__data__tmb">
                                 <ul className="price down--price">
                                <div >{asks}</div>
                                  
                                </ul>
                                <div className="chnage__trade">
                                    <div>
                                    {/* {setlivePrice(new BigNumber(props.asks[0][0]).toFormat(null,1))} */}
                                        <span>{new BigNumber(props.asks[0][0]).toFormat(null,1)}</span>
                                        {/* <span>≈ 3,185,629.03 </span> */}
                                        {/* <span>RUB</span> */}
                                        <span>USDT</span>
                                    </div>
                                    <div className="mark__price">
                                        {/* <span>40633.8</span>
                                        <span>Mark Price</span> */}
                                    </div>
                                </div>
                                <ul className="price up--price">

                                 <div >  {bids}</div>
                                </ul>
                                
                            </div>
                            <div className="aside__recent__trades">
                                <div className="aside__bottom__trade">
                                    <div className="order__tabs__inner__Data tabs__dropdown">

                                        <button className="pairs__button">
                                            <span>
                                                1 decimal
                                            </span>
                                            <img src="assets/svg/dropdown-icon.svg" alt="" />
                                        </button>
                                        <div className="dropdown__content">
                                            <h3>Groups</h3>
                                            <option className="dropdown__option">
                                                1 decimal
                                            </option>
                                            <option className="dropdown__option">
                                                Tens
                                            </option>
                                            <option className="dropdown__option">
                                                Hundreds
                                            </option>
                                        </div>
                                        <div className="dropdown__bg__layer">

                                        </div>
                                    </div>
                                    <div className="aside__bottom__tab">
                                        <button className="tab__btn tmb__tab__btn">

                                        </button>
                                        <button className="tab__btn tmb__tab__btn">

                                        </button>
                                        <button className="tab__btn tmb__tab__btn active">

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row m-0 Main______height___lg' >
                            <div className='col-12 h-50 Lg__paddding xl__padding Padding___none p-0'>
                              <div className='price ul.price'>
                                <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="🔍 Search" style={{background: '#01081e', color : '#fff', borderColor: '#716c6c' }} onChange={(e) => setsearch(e.target.value)}/>
                                    </div>
                                </form>
                              </div>
                              <div className='new__data Lg__paddding'>
                                <table className='table mb-0 mt-1 MD___width__s'>
                                    <tr className='table__header'>
                                        <th> Pair</th>
                                        <th className='text-center'> Price</th>
                                        <th className='text-right'> Change 24h</th>
                                    </tr>
                                    <tbody>
                                        {/* <tr>
                                            <td>
                                                STA
                                            </td>
                                            <td>
                                                12
                                            </td>
                                            <td>
                                                +3.33%
                                            </td>
                                        </tr> */}


                                        { handleSearch2()?.map((valCoin) =>{
                                        const profit1 = valCoin?.sellPrice - valCoin?.buyPrice;
                                            return(

                                                <tr style={{cursor: 'pointer',padding:0}} onClick={() => window.location.reload()}>
                                                    <td>
                                                      <Link to={"/vendor/coin/enj/"+pairName}>  {valCoin.tokenName?.toUpperCase()}</Link>
                                                    </td>
                                                    <td>
                                                         {numberWithCommma(valCoin?.sellPrice?.toFixed(2))}
                                                    </td>
                                                    <td className={profit1 > 0 ? 'text-success':'text-danger' }>
                                                        {profit1 > 0 ? '+'+profit1.toFixed(3) : profit1.toFixed(3)}
                                                    </td>
                                                </tr>




                                                
                    
                                            )
                                        } )}
                                    {handleSearch().map((row) => {
                                        const profit = row.price_change_percentage_24h > 0;
                                        return(
                                             <tr style={{cursor: 'pointer',padding:0}} onClick={() => window.location.reload()}>
                                                    <td>
                                                    <Link to={`/vendor/coin/${row.symbol}/${pairName}`}>  {row.symbol?.toUpperCase() }</Link>
                                                    </td>
                                                    <td>
                                                         {numberWithCommma(row.current_price?.toFixed(2))}
                                                    </td>
                                                    <td className={profit > 0 ? 'text-success':'text-danger' }>
                                                        {profit > 0 ? '+'+row.price_change_percentage_24h : ''+row.price_change_percentage_24h}
                                                        {/* {profit > 0 ? '+'+profit?.toFixed(3) : profit?.toFixed(3)} */}
                                                    </td>
                                                </tr>

                                        )})}







                                    </tbody>
                                </table>
                              </div>
                            </div>
                            <div className='col-12 h-50 Lg__paddding xl__padding Padding___none p-0' style={{background: '#01081e'}}>
                                <div className="tabs__recent__trades d-flex justify-contect-between align-items-center" >
                                    <button className="sec__heading tmb__tab__btn active" >
                                        Recent Trades 
                                    </button>
                                    <button className="sec__heading tmb__tab__btn" >
                                        Market Depth
                                    </button>
                                </div>
                                <div className="tabs__recent__trades__content tabs__data__tmb active">
                                    <div className="inner__heading">
                                        <span>Price(USDT)</span>
                                        <span>Amount({(props.id).toUpperCase()})</span>
                                        <span className='text-right' >Time</span>
                                    </div>
                                    <div className="tred__data recent__trade">
                                        <ul className="price price--height  trade Lg__paddding"  > 
                                        <div className='text-success' style={{color:'green !importent'}} >  {asksTime}</div>

                                            {/* <div className='text-danger' >{asks}</div> */}
                                        </ul>

                                    </div>
                                </div>
                                <div className="tabs__recent__trades__content tabs__data__tmb">
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
    </>
    )
}

export default OrderBook;