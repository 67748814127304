import * as types from '../actions/_types';

const initialState = {
  
  create2faQr : [],
  
};

export default function google2fa(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_2FA_QR:
      return { ...state, create2faQr: action.payload }; 
    
    default:
      return state;
  }
}

