export const AuthorizationHeaderImage = (token) => {
    return {
        "authorization": token,
        "Content-Type": "multipart/form-data",
    }
};

export const AuthorizationHeader = (token) => {
    return {
        "authorization": token,
    }
};

export const SimpleHeader = () => {
    return {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
};