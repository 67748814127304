import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 
import { getUserDetails } from './dashboard.action';

const savegenerate_2fa_qrcode = (data) => {
    return {
        type: types.SAVE_2FA_QR,
        payload: data
    }
}

export const generate_2fa_qrcode = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.generate_2fa_qrcodeApi({}, options).then((res) => { 
         const  res1 = res.data;
            dispatch(savegenerate_2fa_qrcode(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const enable_2fa = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.enable_2faApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
                dispatch(getUserDetails());
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
