import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { startLoader, stopLoader } from './loader.action';
import { AuthorizationHeader } from './header.action';
import { PAGE_LIMIT } from '../../_constants';
import { reset } from 'redux-form';

const save_withdrarw_history = (data) => {
    return {
        type: types.SAVE_WITHDRAW_HISTORY,
        payload: data
    }
}
export const withdraw_history = (data) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.withdraw_history_api(data, options).then((res) => {
            // res = res.data.data;
            dispatch(stopLoader());
            dispatch(save_withdrarw_history(res.data));
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
const save_AllDepost_history = (data) => {
    return {
        type: types.SAVE_ALL_DEPOST_HISTORY,
        payload: data
    }
}
export const AllDepositHistory = (data) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.AllDeposit_history_api(data, options).then((res) => {
            // res = res.data.data;
            dispatch(stopLoader());
            dispatch(save_AllDepost_history(res.data));
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const get_withdraw_otp = (data) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_withdraw_otp_api({}, options).then((res) => {
            // res = res.data.data;
            dispatch(stopLoader());
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const submit_withdraw_req = (data,dropdownq) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.submit_withdraw_req_api(data, options).then((res) => {
            // res = res.data.data;
            dispatch(stopLoader());
            if(res.data.success == 1){
                toast.success(res.data.message);
                dispatch(withdraw_history({ skip: 0, limit: 5 }));
                dispatch(dropdownq());

            }else{
                toast.error(res.data.message);
                dispatch(dropdownq());

            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

const save_available_amount = (data) => {
    return {
        type: types.SAVE_AVAILABLE_AMOUNT,
        payload: data
    }
}
export const available_amount = (data) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.available_amount_api(data, options).then((res) => {
            // res = res.data.data;
            dispatch(stopLoader());
            dispatch(save_available_amount(res.data));
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}