import React from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, finacialAccountpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
const UseStyles = makeStyles({
    assetsBtn: {
        marginTop:'80px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign:{
        width: '1200px',
        margin:  '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
		'&::-webkit-scrollbar': {
            height: '0px',
          },

    },
    btnUl:{
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag:{
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel:{
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot:  {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        },
    
  });
const financialAccount = () => {
	const classes = UseStyles();
    const {langchange} = LangState();

	return (
	<div>
		<div className={classes.assetsBtn}>
			<div className={classes.btnDesign}>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
				</ul>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/financialAccount' style={{color:'rgb(40 205 149)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/depositassest' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/withdrawAssets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/bonus' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
				</ul>
			</div>
		</div>
		<div className="box___3B-ao">
			<div className="wrapper___1F-PJ">
				<section className="flexBetween___3fV9_ secWrapper___GJz-g">
					<div className="flexBetween___3fV9_ titleBox___3yzfN">
						<div className="title___2318j">{assetsnav['0']['Financial'][langchange]}</div>
					</div>
					{/* <div>
						<a>
							<button className="MuiButtonBase-root MuiButton-root jss57 MuiButton-outlined jss63 jss11833 button___3uMgG MuiButton-outlinedPrimary jss64 jss11834" tabindex="0" type="button">
								<span className="MuiButton-label">{finacialAccountpage['0']['btn1'][langchange]}</span>
								<span className="MuiTouchRipple-root"></span>
							</button>
						</a>
						<a style={{marginLeft: '16px'}}>
							<button className="MuiButtonBase-root MuiButton-root jss57 MuiButton-outlined jss63 jss11843 button___3uMgG MuiButton-outlinedPrimary jss64 jss11844" tabindex="0" type="button">
								<span className="MuiButton-label">{finacialAccountpage['0']['btn2'][langchange]}</span>
								<span className="MuiTouchRipple-root"></span>
							</button>
						</a>
					</div> */}
				</section>
				<section>
					<div className="assetListWrapper___3xpbj">
						<div className="itemWrapper___1nIfF">
							<div className="label___3sFKt">Total Assets</div>
							<div className="value___88X4c">
								<span>0 <span className="coin___1iyqi">&nbsp;USDT</span>
									<span className="legal___VZZoC">
										<span>≈ 0</span>
										<span style={{marginLeft: '4px'}}>AUD</span>
									</span>
								</span>
							</div>
						</div>
						<div className="itemWrapper___1nIfF">
							<div className="label___3sFKt">Yesterday's Profit</div>
							<div className="value___88X4c">
								<span>0 <span className="coin___1iyqi">&nbsp;USDT</span>
									<span className="legal___VZZoC">
										<span>≈ 0</span>
										<span style={{marginLeft: '4px'}}>AUD</span>
									</span>
								</span>
							</div>
						</div>
						<div className="itemWrapper___1nIfF">
							<div className="label___3sFKt">Total Profit</div>
							<div className="value___88X4c">
								<span>0 <span className="coin___1iyqi">&nbsp;USDT</span>
									<span className="legal___VZZoC">
										<span>≈ 0</span>
										<span style={{marginLeft: '4px'}}>AUD</span>
									</span>
								</span>
							</div>
						</div>
					</div>
				</section>
				<section className="rowWrapper___351aR">
					<div className="tabsWrapper___zj760" style={{width:'76%'}}>
						<div className="MuiTabs-root jss11850">
							<div className="MuiTabs-scrollable" style={{width: '99px', height: '99px', position: 'absolute', top: '-9999px', overflow: 'scroll'}}></div>
							<div className="MuiTabs-scroller MuiTabs-scrollable" style={{marginBottom: '0px'}}>
								<div className="MuiTabs-flexContainer justify-content-between" role="tablist">
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11860 jss11867 jss11868 MuiTab-textColorPrimary Mui-selected jss11856 jss11861" tabindex="0" type="button" role="tab" aria-selected="true">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq active___ZeDOD">Savings(0)</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11873 jss11867 jss11878 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq">Staking(0)</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11879 jss11867 jss11884 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq">Promotions(0)</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11885 jss11867 jss11890 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq">BurningDrop(0)</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11891 jss11867 jss11896 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq">Polkadot(0)</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11897 jss11867 jss11902 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq">ETH2.0</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
									<button className="MuiButtonBase-root MuiTab-root jss11855 jss11903 jss11867 jss11908 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false">
										<span className="MuiTab-wrapper jss11858">
											<span className="label___2liAq">NFT</span>
										</span>
										<span className="MuiTouchRipple-root"></span>
									</button>
								</div>
								<span className="jss11869 jss11870 MuiTabs-indicator jss11851 jss11853" style={{left: '0px', width:' 113.739px'}}></span>
							</div>
						</div>
					</div>
					<div className="searchWrapper___3jqsQ">
						<div className="MuiFormControl-root MuiTextField-root jss11909 jss11910 textField___2KS4y">
							<div className="MuiInputBase-root MuiInput-root jss11920 MuiInput-underline jss11912 jss11916 MuiInputBase-formControl MuiInput-formControl">
								<input aria-invalid="false" placeholder="Search" type="text" className="finance-search MuiInputBase-input MuiInput-input jss11921 jss11922" value="" />
							</div>
						</div>
					</div>
				</section>
				<div className="tableWrapper___o4Uc7 " style={{marginTop:'20px'}}>
					<div>
						<table className="MuiTable-root tableHeader___ACU7v" data="" favonsymbol="[object Object]" style={{width: '100%',tableLayout: 'fixed'}}>
							<thead className="MuiTableHead-root">
								<tr className="MuiTableRow-root MuiTableRow-head" style={{background:'rgb(245 246 246)'}}>
									<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss11923" scope="col" dataindex="name" style={{width: '15%', overflowWrap: 'break-word', padding: '9px 16px'}}>Product</th>
									<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss11925" scope="col" dataindex="avg7_return" style={{width: '15%', overflowWrap: 'break-word', padding: '9px 16px'}}>7-Day APY</th>
									<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss11927" scope="col" dataindex="lock_amount" style={{width: '15%', overflowWrap: 'break-word', padding: '9px 16px'}}>Total Holdings</th>
									<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss11929" scope="col" dataindex="recent_income_amount" style={{width: '15%', overflowWrap: 'break-word', padding: '9px 16px'}}>Yesterday's Profit</th>
									<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss11931" scope="col" dataindex="income_release_amount" style={{width: '15%', overflowWrap: 'break-word', padding: '9px 16px'}}>Total Profit</th>
									<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss11933" scope="col" dataindex="operate" style={{width: '15%', overflowWrap: 'break-word', padding: '9px 16px'}}>Operation</th>
								</tr>
							</thead>
						</table>
						<div>
							<div fonts="[object Object]" className="jss11935">
								<div className="jss11986">
									<div className="empty___1bBVr">
										<img width="150" src="https://assets.staticimg.com/kucoin-main-web/1.5.26/static/tableEmpty.c42b8da6.svg" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

export default financialAccount