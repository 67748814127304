import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { startLoader, stopLoader } from './loader.action';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { reset } from 'redux-form';
import { coinName } from '../../coinList';

export const save_user_assets = (data) => {
    return {
        type: types.SAVE_USER_ASSETS,
        payload: data
    }
}

export const get_user_assets = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_user_assets_api(data, options).then((res) => {
            
                dispatch(save_user_assets(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const transfer_user_assets = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.transfer_user_assets_api(data, options).then((res) => { 
         const  res1 = res.data.user;
            if(res.data.success  == 1){
                // dispatch(save_user_assets(res1));
                toast.success(res.data.message);
            }else{
                dispatch(stopLoader());
                toast.error(res.data.message);

            }
        }).catch((error) => {
            dispatch(stopLoader());

            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const save_swap_tokens = (data) => {
    return {
        type: types.SAVE_SWAP_TOKENS,
        payload: data
    }
}
export const get_swap_tokens = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_swap_tokens_api(data, options).then((res) => {
            
                dispatch(save_swap_tokens(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_calculate_swap_sell_price = (data) => {
    return {
        type: types.SAVE_CALCU_SWAP_SELL_PRICE,
        payload: data
    }
}

export const get_calculate_swap_sell_price = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_calculate_swap_sell_price_api(data, options).then((res) => {
            
                dispatch(save_calculate_swap_sell_price(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}



export const save_calculate_swap_buy_price = (data) => {
    return {
        type: types.SAVE_CALCU_SWAP_BUY_PRICE,
        payload: data
    }
}

export const get_calculate_swap_buy_price = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_calculate_swap_buy_price_api(data, options).then((res) => {
            
                dispatch(save_calculate_swap_buy_price(res.data));
            // console.log('res from api',res.data);
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        
        dispatch(stopLoader());
    }
}
export const sell_swap_token = (data,setscreenLoader) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.sell_swap_token_api(data, options).then((res) => { 
        //  const  res1 = res.data.user;
            // dispatch(setscreenLoader('false'));
            // console.log('res.data.success',res.data.success);
            if(res.data.success  == 1){
                // dispatch(save_user_assets(res1));
                toast.success(res.data.message);
                setscreenLoader('false');

            }else{
                dispatch(stopLoader());
                setscreenLoader('false');

                toast.error(res.data.message);

            }
        }).catch((error) => {
            dispatch(stopLoader());
            setscreenLoader('false');

            console.log('Failed', error);
        });
    } catch (error) {
        setscreenLoader('false');

        console.log('Failed', error);
    }
}
export const buy_swap_token = (data,setscreenLoader) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.buy_swap_token_api(data, options).then((res) => { 
         const  res1 = res.data.user;
            if(res.data.success  == 1){
                // dispatch(save_user_assets(res1));
                setscreenLoader('false');
                toast.success(res.data.message);
            }else{
                dispatch(stopLoader());
                toast.error(res.data.message);
                setscreenLoader('false');

            }
        }).catch((error) => {
            dispatch(stopLoader());
            setscreenLoader('false');

            console.log('Failed', error);
        });
    } catch (error) {
        setscreenLoader('false');

        console.log('Failed', error);
    }
}


export const save_swap_transactions = (data) => {
    return {
        type: types.SAVE_SWAP_TRANSACTION,
        payload: data
    }
}
export const get_swap_transactions = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_swap_transactions_api(data, options).then((res) => {
            
                dispatch(save_swap_transactions(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_swap_balance = (data) => {
    return {
        type: types.SAVE_SWAP_BALANCE,
        payload: data
    }
}
export const get_swap_balance = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_swap_balance_api(data, options).then((res) => {
            
                dispatch(save_swap_balance(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_swap_balance_wallet_type = (data) => {
    return {
        type: types.SAVE_SWAP_BALANCE_WALLET_TYPE,
        payload: data
    }
}
export const get_swap_balance_wallet_type = (data,setData) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_swap_balance_wallet_type_api(data, options).then((res) => {
                // console.log('token balance ',res);
                if(res.data.tokenName == data.tokenName){
                    setData(res.data.balance)

                     dispatch(save_swap_balance_wallet_type(res.data));

                }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_2nd_swap_balance_wallet_type = (data) => {
    return {
        type: types.SAVE_COIN_SWAP_BALANCE_WALLET_TYPE,
        payload: data
    }
}
export const get_2nd_swap_balance_wallet_type = (data,setData) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_swap_2nd_balance_wallet_type_api(data, options).then((res) => {
            // console.log('token balance ',res.data.tokenName);
            if(res.data.tokenName == data.tokenName){
                setData(res.data.balance)

                dispatch(save_2nd_swap_balance_wallet_type(res.data));
            }
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_CoinPrice = (data) => {
    return {
        type: types.SAVE_COIN_PRICE,
        payload: data
    }
}
export const getCoinPrice = (data,setbtc,setsellbtc) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.getCoinPrice_api(data, options).then((res) => {
            // console.log('token balance ',res.data.tokenName);
                dispatch(save_CoinPrice(res.data));
            if(res.data.success == 1){
                setbtc(res.data?.tokenPrices);
                setsellbtc(res.data?.tokenPrices)
            }
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_buy_swap_balance = (data) => {
    return {
        type: types.SAVE_SWAP_BUY_BALANCE,
        payload: data
    }
}
export const get_buy_swap_balance = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.get_swap_balance_api(data, options).then((res) => {
            
                dispatch(save_buy_swap_balance(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_wallet_details = (data) => {
    return {
        type: types.SAVE_WALLET_DETAILS,
        payload: data
    }
}
export const get_wallet_details = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.wallet_details_api(data, options).then((res) => {
            
                dispatch(save_wallet_details(res.data));
                // console.log(res.data);
                var tokenName ;
                // console.log('res',coinName[0]['trx'])
                // get_token_price_as_token
                if(res.data.success == 1){
                    // res.data.wallets.main_wallet.map((val) => {

                    //     if(tokenName != undefined){
                    //         tokenName = tokenName + "," +coinName[0][val?.tokenname];
                    //     }else{
                    //         tokenName = coinName[0][val?.tokenname];
                    //     }
                    // })
                    // var newdata = tokenName;
                    // dispatch(get_token_price_as_token(newdata));
                }
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}


export const save_tron_price_as_token = (data) => {
    return {
        type: types.SAVE_TOKEN_AS_PRICE,
        payload: data
    }
}
export const get_token_price_as_token = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.tron_price_as_token_api(data, options).then((res) => {
            
                dispatch(save_tron_price_as_token(res));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const save_new_swap_transactions = (data) => {
    return {
        type: types.SAVE_NEW_SWAP_TRANSACTON,
        payload: data
    }
}
export const new_swap_transactions = (data) => (dispatch, getState) => {
    try {
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);

        return apiService.new_swap_transactions_api(data, options).then((res) => {
            
                dispatch(save_new_swap_transactions(res.data));
            
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

