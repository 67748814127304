import React,{useState,useEffect} from 'react';
import Banner from './banner/Banner';
import CoinTable from './CoinTable';
import Chart1 from './Chart'
import { SingleCoin } from '../../../config/api';
import axios from 'axios';
import CoinInfo from './CoinInfo';
import { useParams } from 'react-router-dom';
import OrderSection from './OrderSection';
// import  '../../../App.css';
import Header from './Header';
import ChartComponent from './ChartComponent';
import Component from './index/Component';
import Coinabout from './index/Coinabout';
import Mediaslider from './index/Mediaslider';
import Footer from './index/Footer';
import '../../../_assets/css/mainStyle.css'

const Homepage = () => {

  const { id } = 'bitcoin';
  const [coin,setCoin] = useState()
  
  
  const fatchCoin = async () =>{
    const { data } = await axios.get(SingleCoin(id));
    setCoin(data);
  }

  useEffect(() => {
    fatchCoin();
  },[])
  return (
    <>
      {/* <div class="tradingview-widget-container"> */}

    <Header />
    <Component />
    <Coinabout />
    <Mediaslider />
    <Footer />
    <main>
    {/* <ChartComponent /> */}
        {/* <Chart1 coin={coin}/> */}
        
        {/* <Banner/> */}
        {/* <CoinTable/> */}
        {/* </div> */}
    </main>

    </>
  );
};

export default Homepage;



