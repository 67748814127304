import React from 'react';
import CommingSoon from '../../../_assets/images/Coming-soon.png';


const NoData = () => {
  // console.log('NoDataNoDataNoDataNoData')
  return (
    <div>
      <section className="Soon_page_2A">
        <div className="">
          <div className="private-empty_soon">
            <div className="private-data-empty">
              <h1 className='Soon-text-secondary'>
                <img className='comming_soon_no_data' src={CommingSoon} />
              </h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NoData