import React from 'react';
import { kycpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import img1 from '../../../../_assets/images/img/login-pwd.26b79b9d.svg';
import img2 from '../../../../_assets/images/img/google-verify.7ba19c58.svg';
import img3 from '../../../../_assets/images/img/sec-phone.a24afef6.svg';
import img4 from '../../../../_assets/images/img/sec-email.6b24ac96.svg';
import img5 from '../../../../_assets/images/trading-key.svg';

import { Link } from 'react-router-dom';

const Security = () => {
    const {langchange} = LangState();

  return (
    <div>
         <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn4'][langchange]}   </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn5'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData">  {kycpage['0']['nav']['btn6'][langchange]}  </Link>
                    </li>
                </ul>
            </div>
        </div>
         <div className="user-profile mt-5">
            <div className="container con-profile">
                <div className="row m-0">
                    <div className="col-12 security-header">
                        <h1 className="security-title">Security Settings</h1>
                        <span className="">To ensure your account security, use a combination of Google
                            Verification + Email Binding + Trading Password or Phone Number Binding + Trading
                            Password.</span>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="option-1 card ">
                            <div className="img-123">
                                <div className="img-a">
                                    <img src={img1} />
                                </div>
                                <div className="text-b">
                                    <h6 className="text-left"> Login Password </h6>
                                    <p className="mb-0"> Used for account login. </p>
                                </div>
                            </div>

                            <div className="text-123">
                                <a href="#" className="btn"> Change </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- ----- --> */}
                    <div className="col-12 mt-3">
                        <div className="option-1 card ">
                            <div className="img-123">
                                <div className="img-a img-a-2">
                                    <img src={img2} />
                                </div>
                                <div className="text-b">
                                    <h6 className="text-left">
                                        Google Verification </h6>
                                    <p className="mb-0">Used for verification in account login, withdrawal, API creation, and
                                        other functions. Can be switched with SMS verification mode at any time (and vice
                                        versa). Click <a href="#"> here </a> to view the user guide. </p>
                                </div>
                            </div>

                            <div className="text-123">
                                <a href="/" className="btn btn-2"> Change </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- ----- --> */}
                    <div className="col-12 mt-3">
                        <div className="option-1 card ">
                            <div className="img-123">
                                <div className="img-a img-a-2">
                                    <img src={img3} />
                                </div>
                                <div className="text-b">
                                    <h6 className="text-left">Phone </h6>
                                    <p className="mb-0">Used for verification in account login, withdrawal, API creation, and other functions. Can be switched with Google verification mode at any time (and vice versa). Click here to view the supported country list. </p>
                                </div>
                            </div>

                            <div className="text-123">
                                <a href="/" className="btn"> Change </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- ----- --> */}
                    <div className="col-12 mt-3">
                        <div className="option-1 card ">
                            <div className="img-123">
                                <div className="img-a img-a-3">
                                    <img src={img4} />
                                </div>
                                <div className="text-b">
                                    <h6 className="text-left">Email</h6>
                                    <p className="mb-0">Used for verification in withdrawal, API creation, and other functions. </p>
                                </div>
                            </div>

                            <div className="text-123">
                                <a href="/" className="btn"> Change </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 mt-3">
                        <div className="option-1 card ">
                            <div className="img-123">
                                <div className="img-a img-a-4 ">
                                    <img src={img5} />
                                </div>
                                <div className="text-b">
                                    <h6 className="text-left">Trading Password</h6>
                                    <p className="mb-0">Used for verification in transaction, withdrawal, API creation, and other functions. Make sure the password is adequately strong and keep it secure.</p>
                                </div>
                            </div>

                            <div className="text-123">
                                <Link to="/vendor/trading-otp" className="btn"> Change </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Security