import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { PROJECT_NAME } from '../../../_constants';
import { buy_swap_token, get_buy_swap_balance, get_calculate_swap_buy_price, get_calculate_swap_sell_price, get_swap_balance, get_swap_tokens, sell_swap_token } from '../../../redux/actions/assets.action';
import tronImage from '../../../_assets/images/tron.png';
import bnbImage from '../../../_assets/images/busd1.png';
import { logDOM } from '@testing-library/react';
import SwapPageTable from './SwapPageTable';

const UseStyles = makeStyles({
  assetsBtn: {
      marginTop:'80px',
      display: 'flex',
      background: 'rgba(125, 135, 146, 0.07840000000000003)',
      alignItems: 'center'
  },
  btnDesign:{
      width: '1200px',
      margin:  '0 auto',
      display: 'flex',
      marginBottom: '-16px',
      justifyContent: 'space-between',
      overflowX: 'scroll',
      // width: '100%',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
          height: '0px',
        },

  },
  btnUl:{
      display: 'flex',
      marginBottom: '1em',
  },
  btnTag:{
      height: '64px!important',
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '.1px',
      borderRadius: '0!important',
      color: 'hsla(0,0%,100%,.6)',
      padding: '0 12px',
  },
  MuiButtonlabel:{
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
  },
  MuiTouchRippleroot:  {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: 'hidden',
      position: 'absolute',
      borderRadius: 'inherit',
      pointerEvents: 'none',
      },
  
});
const SwapCurrency = () => {
	const classes = UseStyles();
	const {langchange,screenLoader,setscreenLoader} = LangState();
    const [inputdata , setinputdata] = useState('');

	const [coinName, setcoinName] = useState('Select Token');
	const [coinName2, setcoinName2] = useState('Select Token');

	const [coinImage, setcoinImage] = useState('');
	const [networkid, setnetworkid] = useState();
	const [tokenid, settokenid] = useState();
	const [tokenName, settokenName] = useState('Select Token');
	const [tokenImage, settokenImage] = useState('');
	const [tokenName2, settokenName2] = useState('Select Token');
	const [tokenImage2, settokenImage2] = useState('');
	const [quantity2, setquantity2] = useState();

	const [detailcoinName, setdetailcoinName] = useState('Select Network');
	const [quantity, setquantity] = useState();
	const [otp, setotp] = useState('Select Network');
    // modal
	const [open, setOpen] = useState(false);
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const [open2, setOpen2] = useState(false);
	const onOpenModal2 = () => setOpen2(true);
	const onCloseModal2 = () => setOpen2(false);
	const [open3, setOpen3] = useState(false);
	const onOpenModal3 = () => setOpen3(true);
	const onCloseModal3 = () => setOpen3(false);
	const [open4, setOpen4] = useState(false);
	const onOpenModal4 = () => setOpen4(true);
	const onCloseModal4 = () => setOpen4(false);
	// close modal
	const dispatch = useDispatch();
    const swap_buy_cal = useSelector(state => state.assets.swap_buy_cal);
    const swap_tokens = useSelector(state => state.assets.swap_tokens);
	const swap_sell_cal = useSelector(state => state.assets.swap_sell_cal);
	// const [coinaddress, setcoinaddress] = useState(token_address?.address?.walletAddress);
	const swap_balance = useSelector(state => state.assets.swap_balance);
	const buy_swap_balance =  useSelector(state => state.assets.buy_swap_balance)

	console.log('buy_swap_balance',buy_swap_balance);

	useEffect(() => {
		dispatch(get_swap_tokens());
		
	}, [])

	useEffect(() => {
		if(swap_sell_cal?.receive_amount){ 

			setquantity(swap_sell_cal?.receive_amount);
		}
		if(swap_buy_cal?.receive_amount){ 
			setquantity2(swap_buy_cal?.receive_amount);
		// console.log(buy_swap_balance,quantity2);
		}
	},[swap_sell_cal,swap_buy_cal])

	const [search, setsearch] = useState();
	const handleSearch = () => {	
		if(search != undefined){
			return swap_tokens.swap_tokens?.filter(
				(iii) => 
				iii.tokenName.toLowerCase().includes(search)
			)
		}else{
			return(swap_tokens.swap_tokens);
		}
    }
	const sellSubmitData = (e) =>{
		const data1 = {
			tokenName: coinName,
			quantity:e.target.value,
			sell_currency:tokenName,
		}
		dispatch(get_calculate_swap_sell_price(data1))
		setOpen2(false);
	}
	const sellSubmitTokenData = (val) =>{
		const data1 = {
			tokenName: coinName,
			quantity:quantity,
			sell_currency:val,
		}
		dispatch(get_calculate_swap_sell_price(data1))
		setOpen2(false);
	}

	console.log('quantity',quantity);
	const sellSubmitTokenChange = (val,id) =>{
		const data1 = {
			tokenName: val,
			quantity:quantity,
			sell_currency:tokenName,
		}
		dispatch(get_calculate_swap_sell_price(data1));
		dispatch(get_swap_balance({tokenName: val}));
		setOpen2(false);
	}
	const getsellTokenChange = (val,id) =>{
		const data1 = {
			tokenName: coinName,
			buy_quantity:val,
			sell_currency:tokenName,
		}
		dispatch(get_calculate_swap_sell_price(data1));
		// dispatch(get_swap_balance({tokenName: val}));
		setOpen2(false);
	}
	const onHandelSubmitSell = (e) => {
        e.preventDefault();
        var data = {
           tokenName: coinName,
			quantity:quantity,
			sell_currency:tokenName,
        }
        dispatch(sell_swap_token(data,setscreenLoader));
		setscreenLoader(true)
        e.target.reset();
    }









	const sellSubmitData2 = (e) =>{
		const data1 = {
			tokenName: coinName2,
			quantity:e.target.value,
			buy_currency:tokenName2,
		}
		dispatch(get_calculate_swap_buy_price(data1))
		setOpen2(false);
	}
	const sellSubmitTokenData2 = (val) =>{
		const data1 = {
			tokenName: val,
			quantity:quantity2,
			buy_currency:tokenName2,
		}
		dispatch(get_calculate_swap_buy_price(data1))
		setOpen2(false);
	}
	const sellSubmitTokenChange2 = (val) =>{
		const data1 = {
			tokenName: coinName2,
			quantity:quantity2,
			buy_currency:val,
		}
		dispatch(get_calculate_swap_buy_price(data1));
		dispatch(get_buy_swap_balance({tokenName: val}));

		setOpen2(false);
	}
	const getsellTokenChange2 = (val) =>{
		const data1 = {
			tokenName: coinName2,
			sell_quantity:val,
			buy_currency:tokenName2,
		}
		// console.log(quantity,quantity2);

		dispatch(get_calculate_swap_buy_price(data1));
		// dispatch(get_buy_swap_balance({tokenName: val}));

		setOpen2(false);
	}
	
	// console.log('swap_balance',swap_balance);
   
	const onHandelSubmitBuy = (e) => {
        e.preventDefault();
        var data = {
            tokenName: coinName2,
			quantity:quantity2,
			buy_currency:tokenName2,
        }
        dispatch(buy_swap_token(data,setscreenLoader));
		setscreenLoader(true)

        e.target.reset();
    }

	const passProps = [];
	return (
	<div>
		<div className={classes.assetsBtn}>
			<div className={classes.btnDesign}>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
				</ul>

				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							{/* <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link> */}
                            <Link to='/vendor/swap-currency' style={{color:'rgb(40 205 149)'}}> Swap Currency</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/depositassest' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/withdrawAssets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/bonus' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
				</ul>
			</div>
			
		</div>
		<div className='container'>
			<div className='row m-0'>
				<div className='col-md-12 mt-4 '>
					<Link to='/vendor/SwapTable' className='view-link-button float-right'>View Report</Link>
				</div>
				<div  className='col-md-6'>
                	<div className='row'>
						<div className='col-md-12 mt-5'>
							<h3>Sell  <span  className='text-success float-right h5 m-0 p-0'>Available Token: {swap_balance?.balance} {swap_balance?.tokenName} </span></h3>
							
						</div>
					</div>
					<div className='mt-5 swap_currency_row' style={{marginLeft:'9%'}}>
						<div className='new_depost'>
							<div className='select-field'>
								{/* <span className='lable'>Coin</span> */}
								<div className='row'>
									{/* <div className='col-md-3'>
										<p className='select-coin'>Select a Coin</p>
									</div> */}
									<div className='col-md-12'>
										<div className='select-box-div d-flex justify-content-between align-items-center' > 
											<div className='form-group mb-0 w-75' >
												<label> You Pay </label>
												<input type='number' className='form-control value-input' onChange={(e) => {sellSubmitData(e);setquantity(e.target.value)}} value={quantity} placeholder='0'/>
											</div>
											<p className='d-flex justify-content-between  pl-5 align-items-center m-0 w-sm-50' onClick={onOpenModal}> <span className='img btn' style={{border:'1px solid #ddd'}} ><img src={coinImage} width='30' alt={coinName} /> <span className='spam-label'>{coinName}</span></span></p><p className='p-1'><i classname="fas fa-chevron-down"></i></p>
										</div>
										<Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
											<div className='container'>
												<div className='row deposit-modal'>
													<div className='col-md-12'>
														<h2>Coin</h2>
														<div classname="input-group md-form form-sm form-1 pl-0">
															<div classname="input-group-prepend">
															<span classname="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i classname="fas fa-search text-white"
																aria-hidden="true"></i></span>
															</div>
															<input classname="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e) => {setsearch(e.target.value)}} />
														</div>
														
														<div className='deposit-coin-list'>
															<ul>  
															{handleSearch()?.map((i,ii) => {
																return  (
																	<>
																		<li onClick={() => {setcoinName(i.tokenName);onCloseModal();sellSubmitTokenChange(i.tokenName,i._id)}}><div className='img'><img src={i.image}  width='40'/></div><div className='text'><p className='symbole'>{i.tokenName}</p><span className='name'>{i.tokenName}</span></div></li>
																	</>
																);
															})}
															</ul>
														</div> 
													</div>
												</div>
											</div>
										</Modal>
									</div>
								</div>
							</div>
							<div className='select-field mt-3'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='select-box-div d-flex justify-content-between align-items-center' > 
											<div className='form-group mb-0 w-75' >
												<label> You Get </label>
												<input type='number' className='form-control value-input' value={swap_sell_cal?.amount} onChange={(e) => getsellTokenChange(e.target.value)} placeholder='0'	 />
											</div>
											<p className='d-flex justify-content-between  pl-5 align-items-center m-0 w-sm-50' onClick={onOpenModal2}> <span className='img btn' style={{border:'1px solid #ddd'}}><img src={tokenImage} width='30' alt={tokenName} /> <span className='spam-label'>{tokenName} </span></span></p><p className='p-1'><i classname="fas fa-chevron-down"></i></p>
										</div>
										<Modal open={open2} onClose={onCloseModal2}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center >
											<div className='container'>
											<div className='row deposit-modal'>
												<div className='col-md-12'>
												<h2>Token</h2>
												<div classname="input-group md-form form-sm form-1 pl-0">
													<div classname="input-group-prepend">
													<span classname="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i classname="fas fa-search text-white"
														aria-hidden="true"></i></span>
													</div>
													<input classname="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e) => {setsearch(e.target.value)}} />
												</div>
												
												<div className='deposit-coin-list'>
													<ul>  
														<li onClick={() => {settokenName('TRX');onCloseModal2();settokenImage(tronImage);sellSubmitTokenData('TRX')}}><div className='img'><img src={tronImage}  width='40'/></div><div className='text'><p className='symbole'>TRX</p><span className='name'>TRX</span></div></li>
														<li onClick={() => {settokenName('BNB');onCloseModal2();settokenImage(bnbImage);sellSubmitTokenData('BNB')}}><div className='img'><img src={bnbImage}  width='40'/></div><div className='text'><p className='symbole'>BNB</p><span className='name'>BNB</span></div></li>
													</ul>
												</div> 
												</div>
											</div>
											</div>
										</Modal>
									</div>
								</div>
							</div>
                            <form onSubmit={onHandelSubmitSell}>
                                <div className='form-group withdrawal-input mt-4'>
								<input type='hidden' className='form-control' style={{padding:"30px"}}  placeholder='' value='sell'/>

                                    {/* <div className='row m-0 align-items-center'>
                                        
                                        <div className='col-12 pr-0 pl-0'>
                                                <input type='text' className='form-control' style={{padding:"30px"}} onChange={(e) => setamount(e.target.value)} placeholder='Enter Your Amount'/>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='form-group withdrawal-input  text-center'>
									<button  className='btn submit-button'type='submit'> Sell  </button>
                                </div>
                            </form>                            
						</div>
					</div>
				</div>







				<div  className='col-md-6'>
                <div className='row'>
						<div className='col-md-12 mt-5'>
							<h3>Buy  <span  className='text-success float-right h5 m-0 p-0'>Available Token: {buy_swap_balance?.balance} {buy_swap_balance?.tokenName} </span></h3>
						</div>
					</div>
					<div className='mt-5 swap_currency_row' style={{marginLeft:'9%'}}>
						<div className='new_depost'>
						<div className='select-field'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='select-box-div d-flex justify-content-between align-items-center' > 
											<div className='form-group mb-0 w-75' >
												<label> You Pay </label>
												<input type='number' className='form-control value-input' value={swap_buy_cal?.receive_amount} onChange={(e) => {sellSubmitData2(e);setquantity2(e.target.value)}} placeholder='0'/>
											</div>
											<p className='d-flex justify-content-between  pl-5 align-items-center m-0 w-sm-50' onClick={onOpenModal3}> <span className='img btn' style={{border:'1px solid #ddd'}}><img src={tokenImage2} width='30' alt={tokenName2} /> <span className='spam-label'>{tokenName2} </span></span></p><p className='p-1'><i classname="fas fa-chevron-down"></i></p>
										</div>
										<Modal open={open3} onClose={onCloseModal3}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
											<div className='container'>
											<div className='row deposit-modal'>
												<div className='col-md-12'>
												<h2>Coin</h2>
												<div classname="input-group md-form form-sm form-1 pl-0">
													<div classname="input-group-prepend">
													<span classname="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i classname="fas fa-search text-white"
														aria-hidden="true"></i></span>
													</div>
													<input classname="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e) => {setsearch(e.target.value)}} />
												</div>
												<div className='deposit-coin-list'>
													<ul>  
														<li onClick={() => {settokenName2('TRX');onCloseModal3();settokenImage2(tronImage);sellSubmitTokenChange2("TRX")}}><div className='img'><img src={tronImage}  width='40'/></div><div className='text'><p className='symbole'>TRX</p><span className='name'>TRX</span></div></li>
														<li onClick={() => {settokenName2('BNB');onCloseModal3();settokenImage2(bnbImage);sellSubmitTokenChange2("BNB")}}><div className='img'><img src={bnbImage}  width='40'/></div><div className='text'><p className='symbole'>BNB</p><span className='name'>BNB</span></div></li>
													</ul>
												</div> 
												</div>
											</div>
											</div>
										</Modal>
									</div>
								</div>
							</div>
							<div className='select-field mt-3'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='select-box-div d-flex justify-content-between align-items-center' > 
											<div className='form-group mb-0 w-75' >
												<label> You Get </label>
												<input type='number' className='form-control value-input' placeholder='0' value={swap_buy_cal?.amount}  onChange={(e) => getsellTokenChange2(e.target.value)}/>
											</div>
											<p className='d-flex justify-content-between  pl-5 align-items-center m-0 w-sm-50' onClick={onOpenModal4}> <span className='img btn' style={{border:'1px solid #ddd'}}><img src={coinImage} width='30' alt={coinName2} /> <span className='spam-label'>{coinName2} </span></span></p><p className='p-1'><i classname="fas fa-chevron-down"></i></p>
										</div>
										<Modal open={open4} onClose={onCloseModal4}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
											<div className='container'>
												<div className='row deposit-modal'>
													<div className='col-md-12'>
														<h2>Coin</h2>
														<div classname="input-group md-form form-sm form-1 pl-0">
															<div classname="input-group-prepend">
															<span classname="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i classname="fas fa-search text-white"
																aria-hidden="true"></i></span>
															</div>
															<input classname="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e) => {setsearch(e.target.value)}} />
														</div>
														
														<div className='deposit-coin-list'>
															<ul>  
																{handleSearch()?.map((i,ii) => {
																	return  (
																		<>
																			<li onClick={() => {setcoinName2(i.tokenName);onCloseModal4();sellSubmitTokenData2(i.tokenName)}}><div className='img'><img src={i.image}  width='40'/></div><div className='text'><p className='symbole'>{i.tokenName}</p><span className='name'>{i.tokenName}</span></div></li>
																		</>
																	);
																})}

															</ul>
														</div> 
													</div>
												</div>
											</div>
										</Modal>
									</div>
								</div>
							</div>
                            <form onSubmit={onHandelSubmitBuy}>
                                <div className='form-group withdrawal-input mt-4'>
								<input type='hidden' className='form-control' style={{padding:"30px"}} value='buy'/>

                                    {/* <div className='row m-0 align-items-center'>
                                        
                                        <div className='col-12 pr-0 pl-0'>
                                                <input type='text' className='form-control' style={{padding:"30px"}} onChange={(e) => setamount(e.target.value)} placeholder='Enter Your Amount'/>
                                        </div>
                                    </div> */}
                                    
                                </div>
                                <div className='form-group withdrawal-input  text-center'>
                                    <button  className='btn submit-button'type='submit'> Buy </button>
                                </div>
                            </form>                            
							{/* <div className=" row  m-0 " style={{width:'100%'}}>
								<div className="col-12 mt-5">
									<div className=" card bg-transparent border-0" style={{paddingLeft:'22%'}}>
										
										<div className="col-12 tip">
											<div className='row'>
												<div className='col-md-6'>
													<p  className='m-0'>Available Balance: </p>
													<p>0.00 USDT</p>
												</div>
												<div className='col-md-6'>
													<p  className='m-0'>Minimum Withdrawal: </p>
													<p>1.00 USDT</p>
												</div>
                                                <div className='col-md-6 mt-2'>
													<p  className='m-0'>Fees: </p>
													<p>0.10-35.00 USDT</p>
												</div>
												<div className='col-md-6 mt-2'>
													<p className='m-0'>Remaining daily withdrawal amount:</p>
													<p>1 BTC</p>
												</div>
												
											</div>
										</div>
									</div>
								</div>
								
							</div> */}
						</div>
					</div>
					
				</div>
			</div>
			<SwapPageTable />
			<div className="loading" style={{display: screenLoader == 'false' ? 'none':'block' }} >Loading&#8230;</div>

		</div>
	</div>
	)
}

export default SwapCurrency