import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import Login from "../../pages/public/login/Login";
import ForgotPassword from "../../pages/public/forgotPassword/ForgotPassword";
import ResetPassword from "../../pages/public/resetPassword/ResetPassword";
import Register from "../../pages/public/register/register";
import verifyAccount from "../../pages/public/verify_account/verifyAccount"
import Header from "../../pages/public/dashboard/Header";
import Homepage from "../../pages/public/dashboard/Homepage";
import { makeStyles } from '@material-ui/core/styles';
import CoinPage from "../../pages/public/dashboard/CoinPage";
import NoData from "../../pages/public/empty/NoData";
import About from "../../pages/public/otherpage/About";
import JoinUs from "../../pages/public/otherpage/JoinUs";
import MediaKit from "../../pages/public/otherpage/MediaKit";
import AffiliateProgram from "../../pages/public/otherpage/AffiliateProgram";
import BugBounty from "../../pages/public/otherpage/BugBounty";
import TechnicalSupport from "../../pages/public/otherpage/TechnicalSupport";
import SpotTrade from "../../pages/public/otherpage/SpotTrade";
import MarginTrade from "../../pages/public/otherpage/MarginTrade";
import Contract from "../../pages/public/otherpage/Contract";
import Landing from "../../pages/public/otherpage/Landing";
import TradingBot from "../../pages/public/otherpage/TradingBot";
import FudxEarn from "../../pages/public/otherpage/FudxEarn";
import SupportCentre from "../../pages/public/otherpage/SupportCentre";
import Fee from "../../pages/public/otherpage/Fee";
import VipPrevilege from "../../pages/public/otherpage/VipPrevilege";
import TokenListing from "../../pages/public/otherpage/TokenListing";
import FudxLabs from "../../pages/public/otherpage/FudxLabs";
import Spotlight from "../../pages/public/otherpage/Spotlight";
import MarketProgram from "../../pages/public/otherpage/MarketProgram";
import P2PMerchant from "../../pages/public/otherpage/P2PMerchant";
import TermsofUse from "../../pages/public/otherpage/TermsofUse";
import PrivacyPolicy from "../../pages/public/otherpage/PrivacyPolicy";
import ContactUs from "../../pages/public/otherpage/ContactUs";
import RiskDisclosure from "../../pages/public/otherpage/RiskDisclosure";
import service from "../../pages/public/otherpage/service";
import Soon from "../../pages/public/empty/soon";

const Styles = makeStyles({
    App: {
      backgroundColor: '#fff !important',
      color:'#fff',
      minHeight:'100vh'
    },
  });
const PublicRoutes = () => {
    
        const classes = Styles();

        return (
            <div className={classes.App}>
                <Route path="/" component={Homepage} exact={true} />
                <Route path="/coin/:id" component={CoinPage} exact={true} />
                <Route path="/login" component={Login} exact={true} />
                 <Route path="/forgot_password" component={ForgotPassword} exact={true} />
                <Route path="/resetPassword" component={ResetPassword} exact={true} />
                <Route path="/register" component={Register} exact={true} />
                <Route path="/register/:sponser" component={Register} exact={true} />
                <Route path="/verifyAccount" component={verifyAccount} exact={true} />
                <Route path="/NoData" component={NoData} exact={true} />
                <Route path="/soon" component={Soon} exact={true} />

                <Route path="/about" component={About} exact={true} />
                <Route path="/JoinUs" component={JoinUs} exact={true} />
                <Route path="/media-kit" component={MediaKit} exact={true} />
                <Route path="/affiliate-program" component={AffiliateProgram} exact={true} />
                <Route path="/bug-bounty" component={BugBounty} exact={true} />
                <Route path="/technical-support" component={TechnicalSupport} exact={true} />
                <Route path="/spot-trade" component={SpotTrade} exact={true} />
                <Route path="/margin-trade" component={MarginTrade} exact={true} />
                <Route path="/contract" component={Contract} exact={true} />
                <Route path="/landing" component={Landing} exact={true} />
                <Route path="/trading-bot" component={TradingBot} exact={true} />
                <Route path="/fudx-earn" component={FudxEarn} exact={true} />
                <Route path="/support-centre" component={SupportCentre} exact={true} />
                <Route path="/fee" component={Fee} exact={true} />
                <Route path="/vip-previlege" component={VipPrevilege} exact={true} />
                <Route path="/TokenListing" component={TokenListing} exact={true} />
                <Route path='/fudx-labs' component={FudxLabs} exact={true} />
                <Route path="/spotlight" component={Spotlight} exact={true} />
                <Route path="/MarketProgram" component={MarketProgram} exact={true} />
                <Route path="/P2PMerchant" component={P2PMerchant} exact={true} />
                <Route path="/TermsofUse" component={TermsofUse} exact={true} />
                <Route path="/PrivacyPolicy" component={PrivacyPolicy} exact={true} />
                <Route path="/ContactUs" component={ContactUs} exact={true} />
                <Route path="/RiskDisclosure" component={RiskDisclosure} exact={true} />
                <Route path="/service" component={service} exact={true} />

            </div>
        );
    
}

export default withRouter(PublicRoutes);
