// import firebase from 'firebase';
import firebase from "firebase/compat/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAY6TU3np5Chrs9QWt-rhZse2xsAv1EK04",
    authDomain: "react-chat-7f589.firebaseapp.com",
    projectId: "react-chat-7f589",
    storageBucket: "react-chat-7f589.appspot.com",
    messagingSenderId: "802569936275",
    appId: "1:802569936275:web:36f9f164fedbde99331c66",
    measurementId: "G-VWWY7PMQ9N"
});

const db = getFirestore(firebaseApp);
// const  auth = firebase.auth();
const auth = getAuth();

export {auth,db};