import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LangState } from '../../../LangugeContext';
import { assetsnav, recordAccountpage } from '../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import { AllDepositHistory } from '../../../redux/actions/withdraw.action';

const UseStyles = makeStyles({
    assetsBtn: {
        marginTop: '77px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
        },

    },

    btnUl: {
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag: {
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
    },

});
const Record = () => {
    const classes = UseStyles();
    const dispatch = useDispatch();
    const [page, setpage] = useState(1);
	const [listno, setlistno] = useState(0);
    const allDepostHistory  = useSelector(state => state.assets.allDepostHistory)
    const { langchange } = LangState();
    const pageChange = (e, data) => {
		let page = data.activePage;
		let pageNo = page === 1 ? 0 : ((page - 1) * 10);
		dispatch(AllDepositHistory({ skip: pageNo, limit: 10 }));
		setlistno(pageNo)
	}
    useEffect(() => {
		dispatch(AllDepositHistory({ skip: 0, limit: 10 }));

	}, [])
    function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr);
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	}
    return (
        <div>
            <div className={classes.assetsBtn}>
                <div className={classes.btnDesign}>
                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/assets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Assets'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/mainAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Main'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/tradeAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Trading'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/marginAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Margin'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                    </ul>

                    <ul className={classes.btnUl}>
                        {/* <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/swap-currency' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['swap'][langchange]}</Link>
                                    <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button> */}
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/depositassest' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/withdrawAssets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Withdraw'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/record' style={{ color: 'rgb(40 205 149)' }}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        {/* <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/bonus' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Bonus'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button> */}
                    </ul>
                </div>
            </div>

            <div class="box___3B-ao">
                <div class="wrapper___1F-PJ">
                    <div class="coin_record___2KAq3">
                        <div style={{ minHeight: '680px' }}>
                            <div class="MuiTabs-root jss222">
                                <div class="MuiTabs-scrollable" style={{ width: '99px', height: '99px', position: 'absolute', top: '-9999px', overflow: 'scroll' }}></div>
                                <div class="MuiTabs-scroller MuiTabs-scrollabl" style={{ marginBottom: '0px' }}>
                                    <div class="MuiTabs-flexContainer" role="tablist">
                                        <Link to={'/vendor/record'}>
                                            <button class="MuiButtonBase-root MuiTab-root jss227 jss232 jss239 jss240 MuiTab-textColorPrimary Mui-selected jss228 jss233" tabindex="0" type="button" role="tab" aria-selected="true">
                                                <span class="MuiTab-wrapper jss230">{recordAccountpage['0']['btn1'][langchange]}</span>
                                                <span class="MuiTouchRipple-root"></span>
                                            </button>
                                        </Link>
                                        <Link to={'/vendor/Withdrawrecord'}>
                                            <button class="MuiButtonBase-root MuiTab-root jss227 jss245 jss239 jss250 MuiTab-textColorPrimary" tabindex="-1" type="button" role="tab" aria-selected="false" style={{ backgroundColor: '#fff' }}>
                                                <span class="MuiTab-wrapper jss230">{recordAccountpage['0']['btn2'][langchange]}</span>
                                                <span class="MuiTouchRipple-root"></span>
                                            </button>
                                        </Link> 
                                    </div>
                                    <span class="jss241 jss242 MuiTabs-indicator jss223 jss225" style={{ left: '0px', width: '127px' }}></span>
                                </div>
                            </div>
                            <div class="filters___2QCzu">
                                <div class="ant-row-flex ant-row-flex-middle row m-0" style={{ marginLeft: '-6px', marginRight: '-6px' }}>
                                    {/* <div className='col-lg-3  p-0'>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '200px' }}>
                                            <div class="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon" role="combobox" aria-expanded="false">
                                                <div class="MuiFormControl-root MuiTextField-root jss254 jss255 MuiFormControl-fullWidth">
                                                    <div class="MuiInputBase-root MuiFilledInput-root jss256 jss262 MuiAutocomplete-inputRoot jss253 MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                                                        <input aria-invalid="false" autocomplete="off" placeholder="Time" readonly="" type="text" class="record-input" aria-autocomplete="list" autocapitalize="none" spellcheck="false" value={recordAccountpage['0']['drop1'][langchange]} id="mui-39187" />
                                                        <div class="MuiAutocomplete-endAdornment">
                                                            <button class="MuiButtonBase-root MuiIconButton-root MuiAutocomplete-popupIndicator" tabindex="-1" type="button" aria-label="Open" title="Open">
                                                                <span class="MuiIconButton-label">
                                                                    <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                        <path d="M7 10l5 5 5-5z"></path>
                                                                    </svg>
                                                                </span>
                                                                <span class="MuiTouchRipple-root"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3  p-0'>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '200px' }}>
                                            <div class="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon" role="combobox" aria-expanded="false" data-__meta="[object Object]" data-__field="[object Object]">
                                                <div class="MuiFormControl-root MuiTextField-root jss254 jss267 textField___EFW2n MuiFormControl-fullWidth">
                                                    <div class="MuiInputBase-root MuiFilledInput-root jss256 jss268 MuiAutocomplete-inputRoot jss253 MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                                                        <input aria-invalid="false" autocomplete="off" id="status" placeholder="Status" type="text" class="record-input" aria-autocomplete="list" autocapitalize="none" spellcheck="false" value={recordAccountpage['0']['drop2'][langchange]} />
                                                        <div class="MuiAutocomplete-endAdornment">
                                                            <button class="MuiButtonBase-root MuiIconButton-root MuiAutocomplete-popupIndicator" tabindex="-1" type="button" aria-label="Open" title="Open">
                                                                <span class="MuiIconButton-label">
                                                                    <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                        <path d="M7 10l5 5 5-5z"></path>
                                                                    </svg>
                                                                </span>
                                                                <span class="MuiTouchRipple-root"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 p-0 '>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '200px' }}>
                                            <div class="jss273 jss276">
                                                <div fonts="[object Object]" class="jss279 jss280 jss274 jss277">
                                                    <div class="jss278 jss281 jss286">
                                                        <div class="MuiCircularProgress-root jss282 jss287 MuiCircularProgress-colorPrimary" role="progressbar" aria-valuenow="100" style={{ width: '18px', height: '18px', transform: 'rotate(270deg)' }}>
                                                            <svg class="MuiCircularProgress-svg" viewBox="22 22 44 44">
                                                                <circle class="MuiCircularProgress-circle" cx="44" cy="44" r="20" fill="none" stroke-width="4" style={{ strokeDasharray: ' 125.664', strokeDashoffset: '0px' }}></circle>
                                                            </svg>
                                                        </div>
                                                        <div class="MuiCircularProgress-root jss283 MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style={{ width: '18px', height: '18px' }}>
                                                            <svg class="MuiCircularProgress-svg" viewBox="22 22 44 44">
                                                                <circle class="MuiCircularProgress-circle MuiCircularProgress-circleDisableShrink MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="20" fill="none" stroke-width="4"></circle>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon" role="combobox" aria-expanded="false" data-__meta="[object Object]" data-__field="[object Object]">
                                                    <div class="MuiFormControl-root MuiTextField-root jss254 jss289 jss275 MuiFormControl-fullWidth">
                                                        <div class="MuiInputBase-root MuiFilledInput-root jss256 jss290 MuiAutocomplete-inputRoot jss253 MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiFilledInput-adornedStart MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                                                            <div style={{ width: '23px' }}></div>
                                                            <input aria-invalid="false" autocomplete="off" id="currency" placeholder="Coin" type="text" class="record-input" aria-autocomplete="list" autocapitalize="none" spellcheck="false" value={recordAccountpage['0']['drop2'][langchange]} style={{ background: 'unset' }} />
                                                            <div class="MuiAutocomplete-endAdornment">
                                                                <button class="MuiButtonBase-root MuiIconButton-root MuiAutocomplete-popupIndicator" tabindex="-1" type="button" aria-label="Open" title="Open">
                                                                    <span class="MuiIconButton-label">
                                                                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                            <path d="M7 10l5 5 5-5z"></path>
                                                                        </svg>
                                                                    </span>
                                                                    <span class="MuiTouchRipple-root"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 p-0 '>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                                            <button class="MuiButtonBase-root MuiButton-root jss50 MuiButton-contained jss53 jss295 MuiButton-containedPrimary jss54 jss296" tabindex="0" type="button">
                                                <span class="MuiButton-label">
                                                    <span class="MuiButton-startIcon MuiButton-iconSizeMedium">
                                                        
                                                        <i className='far fa-file'></i>
                                                    </span>{recordAccountpage['0']['csv'][langchange]} </span>
                                                <span class="MuiTouchRipple-root"></span>
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div fonts="[object Object]" class="jss278 jss309">
                                <div class="jss590">
                                    <div className='table-responsive-lg'>
                                        <div class="MuiTableContainer-root" style={ { width: '900px' }}>
                                        <table class="MuiTable-root" coin_in_record="[object Object]" coin_out_record="[object Object]" user="[object Object]" chaininfo="[object Object]">
                                            <thead class="MuiTableHead-root">
                                                <tr class="MuiTableRow-root MuiTableRow-head">
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss592" scope="col" style={{ padding: '4px 16px', height: '40px' }}>{recordAccountpage['0']['label1'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss594" scope="col" style={{ padding: '4px 16px', height: '40px' }}>{recordAccountpage['0']['label2'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss596 MuiTableCell-alignCenter" scope="col" style={{ padding: '4px 16px', height: '40px' }}>{recordAccountpage['0']['label3'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss598" scope="col" style={{ padding: '4px 16px', height: '40px' }}>{recordAccountpage['0']['label4'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss600" scope="col" style={{ padding: '4px 16px', height: '40px' }}>{recordAccountpage['0']['label5'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss602" scope="col" style={{ padding: '4px 16px', height: '40px' }}>{recordAccountpage['0']['label6'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss604" scope="col" style={{ padding: '4px 16px', height: '40px' }}></th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <div class="jss307">
                                            <img src="https://assets.staticimg.com/kucoin-main-web/1.5.27/static/tableEmpty.c42b8da6.svg" alt="empty" />
                                        </div>
                                        <div class="jss385">
                                            <div class="MuiTablePagination-root MuiTablePagination-root jss606">
                                                <div class="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiTablePagination-toolbar MuiToolbar-gutters">
                                                    <div class="MuiTablePagination-spacer"></div>
                                                    <p class="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit"></p>
                                                    <div class="jss386 d-none">
                                                        <button class="MuiButtonBase-root MuiButton-root jss50 MuiButton-outlined jss56 jss610 MuiButton-outlinedPrimary jss57 jss611 MuiButton-outlinedSizeSmall MuiButton-sizeSmall jss51 Mui-disabled jss62 jss616 Mui-disabled" tabindex="-1" type="button" disabled="" aria-label="previous page">
                                                            <span class="MuiButton-label">
                                                                <svg class="MuiIcon-icon jss617 MuiIcon-small" aria-hidden="true">
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        <button class="MuiButtonBase-root MuiButton-root jss50 MuiButton-outlined jss56 jss621 nextButton MuiButton-outlinedPrimary jss57 jss622 MuiButton-outlinedSizeSmall MuiButton-sizeSmall jss51 Mui-disabled jss62 jss627 Mui-disabled" tabindex="-1" type="button" disabled="" aria-label="next page">
                                                            <span class="MuiButton-label">
                                                                <svg class="MuiIcon-icon jss628 MuiIcon-small" aria-hidden="true">
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='row Withdraw_Table_1 mt-3'>
                                <div className='col-md-12'>
                                    <h2>Deposit History</h2>
                                    <div className='table-responsive'>
                                        <table className="table table-borderless">
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th > id  </th>
                                                    <th>Token Name</th>
                                                    <th > Amount  </th>
                                                    <th>Transaction Type</th>

                                                    <th className="">  Date	 </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {allDepostHistory?.deposit_transactions?.map((val, key) => {
                                                        return (
                                                            <tr>
                                                                <td>{listno + key + 1}</td>
                                                                
                                                                <td>{val?.tokenName}</td>
                                                                <td><span>{val?.amount}</span></td>
                                                                <td><span>{val?.transactionType.replace('_', ' ')}</span></td>

                                                                <td>{secondsToHms(val?.createdAt)}</td>
                                                            </tr>
                                                        );
                                                    })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pagination_table text-center mt-3'>

                                        {/* <Pagination className="mx-auto"
                                            defaultActivePage={1}
                                            onPageChange={pageChange}
                                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                            totalPages={Math.ceil(withdraw_history1?.records / 10)}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Record;