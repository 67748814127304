import { AppBar, Button, Container, createTheme, Icon, makeStyles, MenuItem, Select, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CryptoState } from '../../../CryptoContext';
import sunny from "../../../_assets/images/sunny-day-icon.svg";
import news from "../../../_assets/images/news-icon.svg";
import wifi from "../../../_assets/images/wifi-icon.svg";
import { LangState } from '../../../LangugeContext';
import { publicheader } from '../../../lang/lang';
import { logo } from '../../../_constants';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
  title: {
    flex: 1,
    color: 'gold',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
});
const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currency, setCurrency } = CryptoState();
  const { langchange, setlangchange } = LangState();
  const [lang, setlang] = useState()

  // console.log('currency',currency);
  const darkTheme = createTheme({
    palette: {
      primary: {
        main: '#fff',
      },
      // type:'dark',
    },
  });

  const homepagelink = () => {
    history.push("/");
  }
  const user = useSelector(state => state.dashboard.user);
  // localStorage.setItem('lang', lang);
  const langData = localStorage.getItem("lang");
  // setlangchange(langData);
  if (typeof langData !== 'undefined' && langData !== null) {
    setlangchange(langData);

  } else {
    setlangchange('en');

  }
  useEffect(() => {
    }, [langchange])
  const langfun = (datalang) => {
    localStorage.setItem('lang', datalang);

    setlangchange(datalang);
    // alert(datalang)
    const urlTest = window.onload
    if(window.onload == urlTest){
    // window.scrollTo(0, document.body.scrollTop);
}

  }
  function publicheadernavres() {
    var header11 = document.getElementById("public_header").classList.toggle("show");
  }

  const isSignedIn = useSelector(state => state.persist.isSignedIn);
  const isAuthenticated = isSignedIn;

  const loginBtn = () => {
    return(<>
      <li className="header__item">
        <Link to='/login'> <button className="login">
          {publicheader['0']['login'][langchange]}
        </button></Link>
      </li>
      <li className="header__item">
        <Link to="/register" className="Sign__up">
          {publicheader['0']['signup'][langchange]}
        </Link>

      </li>
    </>)
  }
  const userData = () => {
    return(<>
      <li className="header__item " style={{color:'#28cd95',fontWeight:'700'}}>
      {user?.firstname + " " + user?.lastname}

      </li>
    </>)
  }
  
  return (
    <>
      <header className="header chart--header" >
        <nav class="navbar navbar-expand-md  navbar-dark w-100">

          <Link className="header__logo" to="/" >
            <img src={logo} alt="img/header-1" className='logo_01'/>
          </Link>

          {/* Toggler/collapsibe Button  */}
          <button class="navbar-toggler" type="button" data-toggle="collapse" onClick={() => publicheadernavres()} >
              <i class="fa fa-bars" aria-hidden="true"></i>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="public_header">
            <ul className="header__list">

            <li className="header__item public-header-drop-main">
              <a href="#" ><button className="wifi__svg__icon text-white">
                NFT
              </button></a>
              {/* <div className='public-header-drop'>
                <Link to='#' >NFT</Link>
                <Link to='#' >NFT</Link>

              </div> */}
            </li>
            {isAuthenticated === true? userData() :loginBtn() }
            <li className="header__item">
              {/* <select>
                  <option>hi</option>
                </select> */}
              <Select
                variant="outlined"
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={langchange}
                style={{ width: 100, height: 40, marginLeft: 15, color: '#fff' }}
                onChange={(e) => langfun(e.target.value)}
              >
                <MenuItem value={"en"}>En</MenuItem>
                <MenuItem value={"hi"}>Hi</MenuItem>
              </Select>

              {/* <select onChange={(e) => setlangchange(e.target.value)}>
              <option value='en'>en</option>
              <option value='hi'>hi</option>
            </select> */}

            </li>
            <li className="header__item">
              {/* <select>
                  <option>hi</option>
                </select> */}
              <Select
                variant="outlined"
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currency}
                style={{ width: 100, height: 40, marginLeft: 15, color: '#fff' }}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"INR"}>INR</MenuItem>
                <MenuItem value={"EUR"}>EUR</MenuItem>
              </Select>

            </li>
            </ul>
          </div>
        </nav>
      </header>
    </>);
};

export default Header;
