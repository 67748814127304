import React from 'react'
import { Link } from 'react-router-dom'
import { kycpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import img1 from '../../../../_assets/images/withdraw.5cd4ed10.svg';
import img2 from '../../../../_assets/images/contract.86b30107.svg';
import img3 from '../../../../_assets/images/otc.f48afe62.svg';



const kyc = () => {
    const {langchange} = LangState();

  return (
    <div>
        <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn4'][langchange]}   </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn5'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData">  {kycpage['0']['nav']['btn6'][langchange]}  </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="verification">
            <div className="container-xl">
                <div className="ver-header">
                    <h2 className="ver-title">  {kycpage['0']['h1'][langchange]}  <span>Unverified</span></h2>
                    <p> <a className="link" href="#">   <i class='fas fa-university'></i>  Switch to Institutional Verification </a>  </p>
                </div>

                <div className="ver-sub-header">
                    <h5>  {kycpage['0']['h6'][langchange]} </h5>
                    <div className="work">
                        <p>   Deposit <i class='fas fa-check'></i></p>
                        <p>   Exchange   <i class='fas fa-check'></i>  </p>
                        <p>  Margin Trade <i class='fas fa-check'></i> </p>
                    </div>
                </div>

                <p className="card-title" > {kycpage['0']['p1'][langchange]} </p>
                <div className="row p-0 m-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="card border-0">
                            <img src={img1} alt="icon image" />
                            <div className="content">
                                <p className="title" >{kycpage['0']['box1']['p1'][langchange]}</p>
                                <h6 className="h6-1" >  {kycpage['0']['box1']['h1'][langchange]} </h6>
                                <h6 className="h6-2" > ({kycpage['0']['box1']['h6'][langchange]})</h6>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-6">
                        <div className="card border-0">
                            <img src={img2} alt="icon image" />
                            <div className="content">
                                <p className="title" >{kycpage['0']['box2']['p1'][langchange]} </p>
                                <h6 className="h6-1" >  {kycpage['0']['box2']['h1'][langchange]}</h6>
                                <br />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-4 col-md-12">
                        <div className="card border-0">
                            <img src={img3} alt="icon image" />
                            <div className="content">
                                <p className="title" >{kycpage['0']['box3']['p1'][langchange]}</p>
                                <h6 className="h6-1" >  {kycpage['0']['box3']['h1'][langchange]} </h6>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-area">
                    <Link className="btn" to="/vendor/kycform"> {kycpage['0']['form'][langchange]} </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default kyc