import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const Landing = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content about-page'>
            <h2 >  LENDING  </h2>
            <h4 className='text-white mt-3 mb-0' >Let Your Crypto Earn  for You</h4>
            <h6>
            Put your cryptos to work and earn passive income. Hold your cryptos for a minimum of 7 days & earn up to 16.25%* APR
            </h6>
           </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container '>
       
        <div className='about_box-1 about-page mt-5'>
          <div className='row m-0  First_row'>
            <div className='col-md-6 border p-5'>
              <div className='about-story-text'>
                <h3 className='mb-0'>FULLY AUTOMATED PLATFORM</h3>
                <h6 className='mb-3'>
                An instant automated lending platform through which users earn a fixed passive income on their crypto assets.
                </h6>
                <h3 className='mb-0'>PROTECTED BY INSURANCE</h3>
                  <h6>
                  All your funds are stored in secure multi signature wallets protected by BitGo’s USD 100 million insurance policy.                  </h6>
              </div>
            </div>
            <div className='col-md-6 border p-5'>
              <div className='about-story-text'>
                <h3 className='mb-0'>HIGH ANNUAL INTEREST</h3>
                <h6 className='mb-3'>
                Earn up to 16.25% of annual fixed interest, which is much higher than the fixed deposit interest provided by the banks.                </h6>
                <h3 className='mb-0'>NO TRANSACTION FEE</h3>
                  <h6>
                  We don’t charge our users for accessing their own funds. Deposit and withdraw your funds anytime with zero transaction fees.                  </h6>
              </div>
            </div>
            <div className='col-md-12 border p-5'>
              <div className='about-story-text text-center'>
              <h3 className='mb-0'>Cash Fudx</h3>
                <p>That why {PROJECT_NAME} is Best Crypto Exchange in
                  India. So, start investing in digital currency. Shiba
                  INU is good in each and every way. Crypto Prices
                  are really getting higher in market. Enter in Web
                  3.0Crypto, to trade with ease.</p>
               </div>
            </div>
          </div>
          <div className='mt-5 d-flex justify-content-center '>
            <button className='btn-btn'>Visit lend</button>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Landing