import React, { useEffect, useState } from 'react'
// import { db } from '../../../firebaseData';
// import db from '../../../firebaseData'; 
// import { getStorage, ref, list } from "firebase/storage";
// import { getDatabase, ref, query, orderByChild } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set ,query, orderByChild,runTransaction,onValue,push} from "firebase/database";
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

const Chat = () => {
  const { id,buyer_id } = useParams();


    const [message,setmessage] = useState();
    const [data,setdata] = useState();
    const [userdata,setuserdata] = useState();
    const user = useSelector(state => state.dashboard.user);

    const timestamp = Date.now(); // This would be the timestamp you want to format
    const date = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);
    console.log(date);
    useEffect(() => {
        const starCountRef = ref(db, 'users/' );
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          console.log('data data',data);
          setdata(data)
          if(data?.[id]){
            setuserdata(Object.entries(data?.[id]));
          }
        //   updateStarCount( data);
        });
        // pageTokenExample()
    },[])
   
      const db = getDatabase();
      console.log('db',db);
  const auth = getAuth();
  const userId = id;
  // const topUserPostsRef = query(ref(db, 'users/' + userId), orderByChild('message'));
  function writeUserData() {
    const db = getDatabase();

    const postListRef = ref(db, 'users/' + id);
    const newPostRef = push(postListRef);

    set(newPostRef, {
      user_id: user._id,
      message:message,
      createdAt:date,
      buyer_id:buyer_id
    });
    
    setmessage(' ')
  }

  const onHandelSubmit =(e) =>{
    e.preventDefault();
    writeUserData()
  }
  console.log('auth',userdata?.[0]?.[1]);

//  Object.entries(data.kuldeep).forEach(([key, value]) => {
//     console.log(`${key}`, value); // "a 5", "b 7", "c 9"
//   }) 

const  chatView = () => {
  if( userdata?.[0]?.[1]?.user_id == user?._id ||  userdata?.[0]?.[1]?.buyer_id == user?._id){
    return (
      <div style={{margin:'200px'}}>  
       <h1> Chat</h1>
        <br/>
          <div className='chat'>
              {userdata?.map((val,key) =>{
                  return(           
                    <div className={val?.[1]?.user_id == user?._id ?'user' :'sender' }  key={key}>
                      <div className='chat_box'>
                        <p>{val?.[1]?.message}</p>
                      </div>
                    </div>)
                  }
              )}
          </div>
          
          <form className='chat_form' onSubmit={onHandelSubmit}>
            <input type='text' className='chat_input' onChange={(e) => setmessage(e.target.value)} value={message}  placeholder='message'  style={{fontSize:'22px'}} />
            <input type='submit' name='Send Message' value={'Send'} className='btn table-btn btn-success m-2' />
          </form>
      </div>
    )
  }else{
    return<div style={{margin:'200px'}}>  
     <h1 className='text-danger '> Chat Not Found</h1>
      
    </div>
  }
 
} 
  return (
    <div>  
    {chatView()}
    </div>
  )
}

export default Chat