import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LangState } from '../../../LangugeContext';
import { assetsnav, recordAccountpage } from '../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import { cancelTradeOrder, ViewAllOrders } from '../../../redux/actions/Chart.action';

const UseStyles = makeStyles({
    assetsBtn: {
        marginTop: '77px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
        },

    },

    btnUl: {
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag: {
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
    },

});
const Order = () => {
    const dispatch = useDispatch()
    const classes = UseStyles();
    const { langchange } = LangState();
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const [dropdownVisible1, setdropdownVisible1] = useState(false);
    const [dropdownVisible2, setdropdownVisible2] = useState(false);
    const [active2ndnav, setactive2ndnav] = useState('order');

    const viewAllOrder = useSelector(state => state.chart.viewAllOrder);
    console.log('viewAllOrder',viewAllOrder);
    const removePopup = () => {
        setdropdownVisible(false);
        setdropdownVisible1(false);
        setdropdownVisible2(false);

    }
    useEffect(() => {
        dispatch(ViewAllOrders({status:'PENDING'}))
    },[]);
    function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr); 
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
	}

    const cancelOrder = (id) => {
        if (window.confirm('Are you sure to cancel order!') == true) {
            dispatch(cancelTradeOrder({order_id:id}))
        }
    }

    const  getAccordingTabData = (type) => {
        dispatch(ViewAllOrders({status: type}))

    }
    console.log('active2ndnav',active2ndnav);
    return (
        <div>
            <div className={classes.assetsBtn}>
                <div className={classes.btnDesign}>
                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/Order' style={{ color: 'rgb(40 205 149)' }}> Sport</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/soon' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> Cross Margin Account</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/soon' style={{ color: 'rgba(0, 20, 42, 0.6)' }}>Isolated Margin Account </Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                       
                    </ul>

                   
                </div>
            </div>

            <div class="box___3B-ao">
                <div class="wrapper___1F-PJ">
                    <div class="coin_record___2KAq3">
                        <div style={{ minHeight: '680px' }}>
                            <div class="MuiTabs-root jss222">
                                <div class="MuiTabs-scrollable" style={{ width: '99px', height: '99px', position: 'absolute', top: '-9999px', overflow: 'scroll' }}></div>
                                <div class="MuiTabs-scroller MuiTabs-scrollabl" style={{ marginBottom: '0px' }}>
                                    <div class="MuiTabs-flexContainer" role="tablist">
                                        <Link to='/vendor/Order'>
                                            <button class="MuiButtonBase-root MuiTab-root jss227 jss232 jss239 jss240 MuiTab-textColorPrimary Mui-selected jss228 jss233" style={{color:active2ndnav == 'order' ?'rgb(40 205 149)' :' gray'}}  tabindex="0" type="button" role="tab" aria-selected="true">
                                                <span class="MuiTab-wrapper jss230">Open Orders </span>
                                                <span class="MuiTouchRipple-root"></span>
                                            </button>
                                        </Link>
                                        <Link to='/vendor/order-history'>
                                            <button class="MuiButtonBase-root MuiTab-root jss227 jss245 jss239 jss250 MuiTab-textColorPrimary" style={{color:active2ndnav == 'orderHistory' ?'rgb(40 205 149)' :' gray',backgroundColor: '#fff'}}  tabindex="-1" type="button" role="tab" aria-selected="false" >
                                            <span class="MuiTab-wrapper jss230"> Order History</span>
                                            <span class="MuiTouchRipple-root"></span>
                                        </button>
                                        </Link>
                                        <Link to='/vendor/trade-history'>
                                            <button class="MuiButtonBase-root MuiTab-root jss227 jss245 jss239 jss250 MuiTab-textColorPrimary" style={{color:active2ndnav == 'tradeHistory' ?'rgb(40 205 149)' :' gray',backgroundColor: '#fff'}}  tabindex="-1" type="button" role="tab" aria-selected="false" >
                                                <span class="MuiTab-wrapper jss230"> Trade History</span>
                                                <span class="MuiTouchRipple-root"></span>
                                            </button>
                                        </Link>
                                    </div>
                                    {/* <span class="jss241 jss242 MuiTabs-indicator jss223 jss225" style={{ left: '0px', width: '127px' }}></span> */}
                                </div>
                            </div>
                            <div class="filters___2QCzu">
                                <div class="ant-row-flex ant-row-flex-middle row m-0" style={{ marginLeft: '-6px', marginRight: '-6px' }}>
                                    <div className='col-lg-3  p-0'>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '200px' }}>
                                            <div class="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon" role="combobox" aria-expanded="false">
                                                <div class="MuiFormControl-root MuiTextField-root jss254 jss255 MuiFormControl-fullWidth">
                                                    <div class="MuiInputBase-root MuiFilledInput-root jss256 jss262 MuiAutocomplete-inputRoot jss253 MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                                                        <input  placeholder="Pair"   onFocus={() => setdropdownVisible(true)}  type="text" class="record-input"   id="mui-39187" />
                                                       


                                                        {dropdownVisible && (
                                                            <div className="autocomplete-dropdown TEXT w-75">
                                                                <ul className="autocomplete-search-results-list w-100" >
                                                                    {/* {assetsData?.assets?.map((val) => {

                                                                        return (
                                                                            <li className="autocomplete-search-result" onClick={() => { settrnsferId(val?.token_id?.[0]); setdropdownVisible(false); settrnsferIdname(val?.tokenname);cal_bbalance(val?.tokenname) }}>{val?.tokenname}</li>
                                                                        );
                                                                    })} */}
                                                                        <li className="autocomplete-search-result" onClick={() => removePopup()}>test</li>

                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3  p-0'>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '200px' }}>
                                            <div class="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon" role="combobox" aria-expanded="false" data-__meta="[object Object]" data-__field="[object Object]">
                                                <div class="MuiFormControl-root MuiTextField-root jss254 jss267 textField___EFW2n MuiFormControl-fullWidth">
                                                    <div class="MuiInputBase-root MuiFilledInput-root jss256 jss268 MuiAutocomplete-inputRoot jss253 MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                                                        <input  placeholder="Side" type="text"  onFocus={() => setdropdownVisible1(true)} class="record-input"   />
                                                        {dropdownVisible1 && (
                                                            <div className="autocomplete-dropdown TEXT w-75">
                                                                <ul className="autocomplete-search-results-list w-100" >
                                                                    {/* {assetsData?.assets?.map((val) => {

                                                                        return (
                                                                            <li className="autocomplete-search-result" onClick={() => { settrnsferId(val?.token_id?.[0]); setdropdownVisible(false); settrnsferIdname(val?.tokenname);cal_bbalance(val?.tokenname) }}>{val?.tokenname}</li>
                                                                        );
                                                                    })} */}
                                                                        <li className="autocomplete-search-result" onClick={() => removePopup()} >test</li>

                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 p-0 '>
                                        <div class="filters___2QCzu_card" style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '200px' }}>
                                            <div class="jss273 jss276">
                                                {/* <div fonts="[object Object]" class="jss279 jss280 jss274 jss277">
                                                    <div class="jss278 jss281 jss286">
                                                        <div class="MuiCircularProgress-root jss282 jss287 MuiCircularProgress-colorPrimary" role="progressbar" aria-valuenow="100" style={{ width: '18px', height: '18px', transform: 'rotate(270deg)' }}>
                                                            <svg class="MuiCircularProgress-svg" viewBox="22 22 44 44">
                                                                <circle class="MuiCircularProgress-circle" cx="44" cy="44" r="20" fill="none" stroke-width="4" style={{ strokeDasharray: ' 125.664', strokeDashoffset: '0px' }}></circle>
                                                            </svg>
                                                        </div>
                                                        <div class="MuiCircularProgress-root jss283 MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style={{ width: '18px', height: '18px' }}>
                                                            <svg class="MuiCircularProgress-svg" viewBox="22 22 44 44">
                                                                <circle class="MuiCircularProgress-circle MuiCircularProgress-circleDisableShrink MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="20" fill="none" stroke-width="4"></circle>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div class="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon" role="combobox" aria-expanded="false" data-__meta="[object Object]" data-__field="[object Object]">
                                                    <div class="MuiFormControl-root MuiTextField-root jss254 jss289 jss275 MuiFormControl-fullWidth">
                                                        <div class="MuiInputBase-root MuiFilledInput-root jss256 jss290 MuiAutocomplete-inputRoot jss253 MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiFilledInput-adornedStart MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                                                            <div style={{ width: '23px' }}></div>
                                                            <input  placeholder="Type" type="text" class="record-input" onFocus={() => setdropdownVisible2(true)}   style={{ background: 'unset' }} />
                                                            {dropdownVisible2 && (
                                                            <div className="autocomplete-dropdown TEXT w-75">
                                                                <ul className="autocomplete-search-results-list w-100" >
                                                                    {/* {assetsData?.assets?.map((val) => {

                                                                        return (
                                                                            <li className="autocomplete-search-result" onClick={() => { settrnsferId(val?.token_id?.[0]); setdropdownVisible(false); settrnsferIdname(val?.tokenname);cal_bbalance(val?.tokenname) }}>{val?.tokenname}</li>
                                                                        );
                                                                    })} */}
                                                                        <li className="autocomplete-search-result" onClick={() => removePopup()} >test</li>

                                                                </ul>
                                                            </div>
                                                        )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div fonts="[object Object]" class="jss278 jss309">
                                <div class="jss590">
                                    <div className='table-responsive-lg'>
                                        <div class="MuiTableContainer-root" >
                                        <table class="MuiTable-root" coin_in_record="[object Object]" coin_out_record="[object Object]" user="[object Object]" chaininfo="[object Object]">
                                            <thead class="MuiTableHead-root">
                                                <tr class="MuiTableRow-root MuiTableRow-head">
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss592" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Time</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss594" scope="col" style={{ padding: '4px 16px', height: '40px' , whiteSpace: 'nowrap' }}>All Pairs</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss596 MuiTableCell-alignCenter" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>All Types</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss598" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Buy/Sell</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss600" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Price</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss602" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Amount</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss604" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Filled</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss604" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Unfilled</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss604" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Status</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss604" scope="col" style={{ padding: '4px 16px', height: '40px', whiteSpace: 'nowrap' }}>Cancel All</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                            {viewAllOrder?.orders?.map((val) => {
                                                
                                                return(
                                                    <tr>
                                                        <td>{secondsToHms(val?.createdAt)}</td>
                                                        <td>{val?.tradingCurrency}</td>
                                                        <td>{val?.orderType}</td>
                                                        <td>{val?.mode}</td>
                                                        <td>{val?.price}</td>
                                                        <td>{val?.amount}</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>{val?.status}</td>
                                                        <td><button className='btn btn-sm table-btn border' onClick={() => cancelOrder(val?._id)} style={{color:'rgb(40 205 149)'}}>Cancel</button></td>

                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                        {/* <div class="jss307">
                                            <img src="https://assets.staticimg.com/kucoin-main-web/1.5.27/static/tableEmpty.c42b8da6.svg" alt="empty" />
                                        </div> */}
                                        {/* <div class="jss385">
                                            <div class="MuiTablePagination-root MuiTablePagination-root jss606">
                                                <div class="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiTablePagination-toolbar MuiToolbar-gutters">
                                                    <div class="MuiTablePagination-spacer"></div>
                                                    <p class="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit"></p>
                                                    <div class="jss386 d-none">
                                                        <button class="MuiButtonBase-root MuiButton-root jss50 MuiButton-outlined jss56 jss610 MuiButton-outlinedPrimary jss57 jss611 MuiButton-outlinedSizeSmall MuiButton-sizeSmall jss51 Mui-disabled jss62 jss616 Mui-disabled" tabindex="-1" type="button" disabled="" aria-label="previous page">
                                                            <span class="MuiButton-label">
                                                                <svg class="MuiIcon-icon jss617 MuiIcon-small" aria-hidden="true">
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        <button class="MuiButtonBase-root MuiButton-root jss50 MuiButton-outlined jss56 jss621 nextButton MuiButton-outlinedPrimary jss57 jss622 MuiButton-outlinedSizeSmall MuiButton-sizeSmall jss51 Mui-disabled jss62 jss627 Mui-disabled" tabindex="-1" type="button" disabled="" aria-label="next page">
                                                            <span class="MuiButton-label">
                                                                <svg class="MuiIcon-icon jss628 MuiIcon-small" aria-hidden="true">
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order;