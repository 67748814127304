export const SAVE_USER_PROFILE = '[USER] SAVE_USER_PROFILE';
export const SAVE_BINANCE_BALANCE = '[USER] SAVE_BINANCE_BALANCE';
export const SAVE_USDT_TRANSACTIONS = '[USER] SAVE_USDT_TRANSACTIONS';
export const SAVE_ACTIVE_TRADES = '[USER] SAVE_ACTIVE_TRADES';
export const SAVE_CLOSED_TRADES = '[USER] SAVE_CLOSED_TRADES';
export const SAVE_WITHDRAW_REQUESTS = '[USER] SAVE_WITHDRAW_REQUESTS';
export const SAVE_PROFIT_HISTORY = '[USER] SAVE_PROFIT_HISTORY';
export const SAVE_BANNERS = '[USER] SAVE_BANNERS';
export const SAVE_WALLET_TRANSACTIONS = '[USER] SAVE_WALLET_TRANSACTIONS';
export const SAVE_INCOME_TRANSACTIONS = '[USER] SAVE_INCOME_TRANSACTIONS';
export const SAVE_INCOME_TYPE_TRANSACTIONS = '[USER] SAVE_INCOME_TYPE_TRANSACTIONS';
export const SAVE_PACKAGE = '[USER] SAVE_PACKAGE';
export const SAVE_VIEW_TICKET = '[USER] SAVE_VIEW_TICKET';
export const SAVE_CLOSE_TICKET = '[USER] SAVE_CLOSE_TICKET';
export const SAVE_COIN_ADDRESS = '[USER] SAVE_COIN_ADDRESS';
export const SAVE_COIN_TABLE_ADDRESS = '[USER] SAVE_COIN_TABLE_ADDRESS';
export const SAVE_DEPOSIT_DETAILS = '[USER] SAVE_DEPOSIT_DETAILS';
export const SAVE_DEPOSIT_TOKEN_DETAILS = '[USER] SAVE_DEPOSIT_TOKEN_DETAILS';
export const SAVE_TOKEN_ADDRESS = '[USER] SAVE_TOKEN_ADDRESS';
export const SAVE_DEPOSIT_TRANSACTION = '[USER] SAVE_DEPOSIT_TRANSACTION';
export const SAVE_P2P_PAYMENT_METHOD = '[USER] SAVE_P2P_PAYMENT_METHOD';
export const SAVE_P2P_PAYMENT_FIELD = '[USER] SAVE_P2P_PAYMENT_FIELD';
export const SAVE_P2P_GET_PAYMENT_METHOD = '[USER] SAVE_P2P_GET_PAYMENT_METHOD';
export const SAVE_USER_ASSETS = '[USER] SAVE_USER_ASSETS';
export const SAVE_P2P_MY_POSTING  = '[USER] SAVE_P2P_MY_POSTING';
export const SAVE_WITHDRAW_HISTORY = '[USER] SAVE_WITHDRAW_HISTORY';
export const SAVE_ALL_DEPOST_HISTORY = '[USER] SAVE_ALL_DEPOST_HISTORY';
export const SAVE_AVAILABLE_AMOUNT = '[USER] SAVE_AVAILABLE_AMOUNT'; 
export const SAVE_SWAP_TOKENS = '[USER] SAVE_SWAP_TOKENS';
export const SAVE_CALCU_SWAP_SELL_PRICE = '[USER] SAVE_CALCU_SWAP_SELL_PRICE';
export const  SAVE_CALCU_SWAP_BUY_PRICE = '[USER] SAVE_CALCU_SWAP_BUY_PRICE';
export const SAVE_SWAP_TRANSACTION = '[USER] SAVE_SWAP_TRANSACTION';
export const SAVE_SWAP_BALANCE = '[USER] SAVE_SWAP_BALANCE';
export const SAVE_COIN_SWAP_BALANCE_WALLET_TYPE = '[USER] SAVE_COIN_SWAP_BALANCE_WALLET_TYPE';
export const SAVE_SWAP_BUY_BALANCE = '[USER] SAVE_SWAP_BUY_BALANCE';
export const SAVE_TOKEN_AS_PRICE = '[USER] SAVE_TOKEN_AS_PRICE';
export const SAVE_WALLET_DETAILS  = '[USER] SAVE_WALLET_DETAILS';
export const SAVE_NEW_SWAP_TRANSACTON = '[USER] SAVE_NEW_SWAP_TRANSACTON';
export const SAVE_SWAP_BALANCE_WALLET_TYPE = '[USER] SAVE_SWAP_BALANCE_WALLET_TYPE';
export const SAVE_BUY_P2P = '[USER] SAVE_BUY_P2P';
export const SAVE_SELL_P2P = '[USER] SAVE_SELL_P2P';
export const SAVE_ORDER_P2P = '[USER] SAVE_ORDER_P2P';
export const SAVE_VIEW_USER_ORDER_P2P = '[USER] SAVE_VIEW_USER_ORDER_P2P';
export const SAVE_P2P_POSTING_DETAILS = '[USER] SAVE_P2P_POSTING_DETAILS';
export const SAVE_NEWS = '[USER] SAVE_NEWS';
export const SAVE_REFERRAL = '[USER] SAVE_REFERRAL';
export const SAVE_TRADE_ORDER_DATA = '[USER] SAVE_TRADE_ORDER_DATA';
export const SAVE_ALL_ORDER_DETAILS = '[USER] SAVE_ALL_ORDER_DETAILS';
export const SAVE_2FA_QR = '[USER] SAVE_2FA_QR';
export const SAVE_COIN_PRICE = '[USER] SAVE_COIN_PRICE';
/***Old code */

export const START_LOADER = '[LOADER] START LOADER';
export const STOP_LOADER = '[LOADER] STOP LOADER';
export const LOGOUT = '[AUTH] LOGOUT';

export const IS_FORGOT = "[USER] IS_FORGOT";
export const SAVE_LOGIN_USER_DETAIL = '[USER] SAVE_LOGIN_USER_DETAIL';
export const SAVE_SHOW_MODAL = '[MODAL] SAVE_SHOW_MODAL';
export const SAVE_SHOW_MODAL_CUSTOMIZE = '[MODAL] SAVE_SHOW_MODAL_CUSTOMIZE'

export const SAVE_MENUS = '[MENU] SAVE_MENUS';
export const SAVE_SINGLE_MENU = '[MENU], SAVE_SINGLE_MENU';
export const SAVE_SINGLE_MENU_FOR_SHOW = '[MENU[ SAVE_SINGLE_MENU_FOR_SHOW';

export const SAVE_DISHES = '[DISHES] SAVE_DISHES';
export const SAVE_DISH_DETAIL = '[DISHES] SAVE_DISH_DETAIL';

export const SAVE_TOPPINGS = '[TOPPING] SAVE_TOPPINGS';
export const SAVE_SUB_TOPPINGS = '[SUB TOPPING] SAVE_SUB_TOPPINGS';

export const SAVE_ORDERS = '[ORDERS] SAVE_ORDERS';
export const SAVE_SINGLE_ORDER = '[ORDERS] SAVE_SINGLE_ORDER';

export const SAVE_RESTAURANT_PROFILE = '[RESTAURANT] SAVE_RESTAURANT_PROFILE';
export const TOGGLE_MENU = '[TOGGLE_MENU] TOGGLE_MENU';

export const SAVE_FINANCE_LIST = '[FINANCE] SAVE_FINANCE_LIST';
export const SAVE_REVIEWS_LIST = '[REVIEW] SAVE_REVIEWS_LIST';
export const SAVE_NOTIFICATION = '[NOTIFICATIONS] SAVE_NOTIFICATION';
export const SAVE_WORKINGTIMES = '[WORKING TIMES] SAVE_WORKINGTIMES';