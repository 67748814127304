import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';
import posi1 from '../../../_assets/images/img/Position_1.svg';
import posi2 from '../../../_assets/images/img/Position_2.svg';
import posi3 from '../../../_assets/images/img/Position_3.svg';
import posi4 from '../../../_assets/images/img/Position_4.svg';
import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { PROJECT_NAME } from '../../../_constants';
const P2PMerchant = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <a href='#' className='nav-link active'>  About US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Join US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  P2P Merchant Application </h2>
            
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container' id='Join_container'>
        
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision'>
          <div className='col-md-6 border-right'>
              <div className='about-story-text'>
                <h3 className='mb-0'>What is P2P on {PROJECT_NAME} ? </h3>
                <h6>
                P2P on {PROJECT_NAME}  is an easy and secure peer-to-peer trading platform. It facilitates the buying and selling of two users' holdings at an optimal, agreed-upon price between them. Please note that {PROJECT_NAME}  does not provide the buy and sell offers on the P2P page.                  </h6>
                  
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h3 className='mb-0'>Is P2P on {PROJECT_NAME}  secure?  </h3>
                <h6>
                Once a buy order is submitted, the amount of coin specified will be reserved on the P2P platform. This means that if the seller doesn’t release the coin within 10 minutes after receiving the payment, our customer support has the right to release the coin from the reserved funds to the buyer after verification.
                <br />
                If you’re a seller, please make sure you’ve received the funds from the buyer before releasing your coin.
                </h6>
                
              </div>
            </div>
            <div className='col-md-6 pt-5 border-top border-right'>
              <div className='about-story-text'>
                <h3 className='mb-0'> How can I get in touch if there’s any problem with my P2P order?</h3>
                <h6>
                  If you encounter any issues with your order, you can log in to your {PROJECT_NAME}  Account and click on the support icon in the bottom right corner to contact {PROJECT_NAME}  LiveChat support — or drop an email to, <b>support@{PROJECT_NAME}.com</b>.   
                  <br />To help you solve any problems more efficiently, please provide your UID, P2P order number and any applicable screenshot(s).
                </h6>
                
              </div>
            </div>

            <div className='col-md-6 pt-5 border-top'>
              <div className='about-story-text'>
                <h3 className='mb-0'>   Which coins can I trade on the P2P Platform? </h3>
                <h6>Currently, only USDT is supported.</h6>
                 
              </div>
            </div>
            <div className='col-md-6 pt-5 border-top border-right'>
              <div className='about-story-text'>
                <h3 className='mb-0'> Which fiat currencies does the platform support?</h3>
                <h6>
                ARS, AUD, COP, EGP, EUR, GHS,  HKD, IDR, INR, JPY, KES, KGS, KZT, MXN, MYR, NGN, PHP, PKR, RUB, TWD, UAH, VND, THB, TRY, UZS, USD                </h6>
                
              </div>
              </div>
              <div className='col-md-6 pt-5 border-top'>
              <div className='about-story-text'>
                <h3 className='mb-0'>  How much are transaction fees on the P2P Platform?</h3>
                <h6>
                P2P on {PROJECT_NAME}  offers zero transaction fees for either buyer or seller. However, traders may need to pay transaction fees to the payment provider based on the payment method selected. 
                  </h6>
                 
              </div>
            </div>
            <div className='col-md-6 pt-5 border-top border-right'>
              <div className='about-story-text'>
                <h3 className='mb-0'>Why am I ineligible to buy or sell my coin?</h3>
                <h6>
                This is because you did not fully meet the requirement set by the advertiser. You can click on the ineligible button to check on the missing criteria.                 </h6>
                
              </div>
            </div>
          </div>
        </div>

        <div className='about_box-1 mt-5 d-none'>
          <div className='row m-0 align-items-center justify-content-center  First_row vision'>
          <div className='col-md-6'>
          {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe78WW0wUnTpZiDOQHiusWkw9ZwgIsIW6or-iqrzDNEtZPE_g/viewform?embedded=true" width="640" height="1967" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
            </div>
            
          </div>
        </div>

        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default P2PMerchant