import React from "react";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.css';
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/lib/integration/react";
import { history } from "./redux/history.reducer";
import configureStore from "./redux/store.reduxStore";
import ApplicationRoutes from "./routes/Application.routes";
import 'semantic-ui-css/semantic.min.css'
import "./App.scss";
import 'react-alice-carousel/lib/alice-carousel.css';

// import "./_assets/css/style.css";

// import "./_assets/css/sidebar.css";
// import 'sweetalert2/src/sweetalert2.scss'

let { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ApplicationRoutes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
