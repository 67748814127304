import { makeStyles } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import { assetsnav, bonusAccountpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import bonusImg from '../../../_assets/images/newImages/spotlight.png';

import { PROJECT_NAME } from '../../../_constants';
const UseStyles = makeStyles({
    assetsBtn: {
        marginTop: '80px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
        },

    },
    btnUl: {
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag: {
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
    },

});
const bonus = () => {
    const classes = UseStyles();
    const { langchange } = LangState();

    return (
        <div>
            <div className={classes.assetsBtn}>
                <div className={classes.btnDesign}>
                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/assets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Assets'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/mainAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Main'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/tradeAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Trading'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/marginAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Margin'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                    </ul>

                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/swap-currency' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['swap'][langchange]}</Link>
                                    {/* <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link> */}
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/depositassest' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/withdrawAssets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Withdraw'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/record' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/bonus' style={{ color: 'rgb(40 205 149)' }}> {assetsnav['0']['Bonus'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                    </ul>
                </div>
            </div>
            <div className="box___3B-ao" style={{ background: '#f3f5f9' }}>
                <div className="wrapper___1F-PJ">
                    <div>
                        <div className="bonus-sec-box-1  overview___1pkxr ant-card-bordered ant-card-wider-padding ant-card-padding-transition h-auto ">
                            <div className="ant-card-body">
                                <div className="row ant-row d-flex  m-0" >
                                    <div className="col-lg-4 bonus-sec-box-1-column ">
                                        <div className="title___UzwEk">
                                            <div> {bonusAccountpage['0']['Referral'][langchange]}</div>
                                            <a href="/referral">{bonusAccountpage['0']['Details'][langchange]}</a>
                                        </div>
                                        <div className="starBonus___Fv4TX">
                                            <img src="https://assets.staticimg.com/kucoin-main-web/1.5.27/static/star.8c169984.svg" alt="star" />
                                            <span>0</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 bonus-sec-box-1-column">
                                        <div className="title___UzwEk">
                                            <div>{bonusAccountpage['0']['Bonus'][langchange]}  <span> (2022/03/06)</span>
                                            </div>
                                            <Link tp="/vendor/encouragement">{bonusAccountpage['0']['Details'][langchange]}</Link>
                                        </div>
                                        <div className="lastTime___2VAmy">
                                            <span>≈</span>
                                            <span>0</span>
                                            <span>&nbsp;</span>
                                        </div>
                                        <div className="total___1p6-V">{bonusAccountpage['0']['Total'][langchange]}:&nbsp;≈ &nbsp;0&nbsp; <i className="fa fa-question"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 bonus-sec-box-1-column">
                                        <div className="title___UzwEk">
                                            <div> {bonusAccountpage['0']['Rewards'][langchange]} <span> (2022/03/06)</span>
                                            </div>
                                            <a href="/assets/bonus/rewards">{bonusAccountpage['0']['Details'][langchange]}</a>
                                        </div>
                                        <div className="lastTime___2VAmy">
                                            <span>≈</span>
                                            <span>0</span>
                                            <span>BTC</span>
                                        </div>
                                        <div className="total___1p6-V">{bonusAccountpage['0']['Total'][langchange]}:&nbsp;≈ &nbsp;0&nbsp;BTC<i className="fa fa-question"></i>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ant-card container___1zwi_ ant-card-bordered ant-card-wider-padding ant-card-padding-transition">
                            <div className="ant-card-body">
                                <div className="bonusPanel___32Bei">
                                    <div className="left___3dnaa">
                                        <div className="bannerImg___17lSC video___3Lhj8"></div>
                                        <div className="text___3vJwX">
                                            <h3>{bonusAccountpage['0']['box1']['h1'][langchange]}<span>
                                                <a href="#" target="_blank" rel="noopener noreferrer">{bonusAccountpage['0']['box1']['link'][langchange]}</a>
                                            </span>
                                            </h3>
                                            <p>
                                                <span>{bonusAccountpage['0']['box1']['p1'][langchange]}:&nbsp;rBHJ679</span>
                                                {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh- ml-6" aria-hidden="true">
                                                </svg> */}
                                            </p>
                                            <p>{bonusAccountpage['0']['box1']['p2'][langchange]}:&nbsp; <span>
                                                <span>0</span> Person </span>
                                            </p>
                                            <p>{bonusAccountpage['0']['box1']['p3'][langchange]}:&nbsp;0 </p>
                                        </div>
                                    </div>
                                    <div className="opt___7pz67">
                                        <button type="button" className="ant-btn  kc-button ant-btn-primary">
                                            <a href="/assets/bonus/referral">
                                                <span>{bonusAccountpage['0']['View'][langchange]}</span>
                                                {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh- arrowIcon___2vyQF" aria-hidden="true">
                                                </svg> */}
                                            </a>
                                            <canvas className="water-wave-canvas"></canvas>
                                        </button>
                                    </div>
                                </div>
                                <div className="bonusPanel___32Bei">
                                    <div className="left___3dnaa">
                                        <div className="bannerImg___17lSC video___3Lhj8">
                                            {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh-" aria-hidden="true">
                                            </svg> */}
                                        </div>
                                        <div className="text___3vJwX">
                                            <h3>{bonusAccountpage['0']['box2']['h1'][langchange]} <span>
                                                <a href="#" target="_blank" rel="noopener noreferrer">{bonusAccountpage['0']['box2']['link'][langchange]}</a>
                                            </span>
                                            </h3>
                                            <p>{bonusAccountpage['0']['box2']['p1'][langchange]}:&nbsp;0
                                                {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh- ml-6" aria-hidden="true">
                                                </svg> */}
                                            </p>
                                            <p>{bonusAccountpage['0']['box2']['p2'][langchange]}:&nbsp;0 </p>
                                        </div>
                                    </div>
                                    <div className="opt___7pz67">
                                        <button type="button" className="ant-btn  kc-button ant-btn-primary" >
                                            <a href="/assets/bonus/encouragement">
                                                <span>{bonusAccountpage['0']['View'][langchange]}</span>
                                                {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh- arrowIcon___2vyQF" aria-hidden="true">
                                                </svg> */}
                                            </a>
                                            <canvas className="water-wave-canvas"></canvas>
                                        </button>
                                    </div>
                                </div>
                                <div className="bonusPanel___32Bei">
                                    <div className="left___3dnaa">
                                        <div className="bannerImg___17lSC marginBonus___2JYbL"></div>
                                        <div className="text___3vJwX">
                                            <h3>{bonusAccountpage['0']['box3']['h1'][langchange]}</h3>
                                            <p>{bonusAccountpage['0']['box3']['p1'][langchange]}</p>
                                            <p>{bonusAccountpage['0']['box3']['p2'][langchange]}：0 USDT</p>
                                        </div>
                                    </div>
                                    <div className="opt___7pz67">
                                        <button type="button" className="ant-btn  kc-button ant-btn-primary" >
                                            <a href="/assets/bonus/margin-bonus">
                                                <span>{bonusAccountpage['0']['View'][langchange]}</span>
                                                {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh- arrowIcon___2vyQF" aria-hidden="true">
                                                </svg> */}
                                            </a>
                                            <canvas className="water-wave-canvas"></canvas>
                                        </button>
                                    </div>
                                </div>
                                <div className="recentActivity___3smq3 ">
                                    <p className='mb-4'>{bonusAccountpage['0']['recent'][langchange]}</p>
                                    <div className="ant-spin-nested-loading">
                                        <div className="ant-spin-container">
                                            <div className="row activityList___OD7Jk m-0">
                                                <div className='col-lg-4 col-md-6'>
                                                    <div className="activityItem___3qGTl" style={{ backgroundImage: `url(${bonusImg})` }}>
                                                        <div className="tag___3wfZL">
                                                            <span className="stop___jfwkx">Ended</span>
                                                        </div>
                                                        <div className="activityTitle___QINeJ">
                                                            <p>{PROJECT_NAME} Spotlight (South Asia Exclusive) - Gari Network (GARI) </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6'>
                                                    <div className="activityItem___3qGTl" style={{ backgroundImage: `url(${bonusImg})` }}>
                                                        <div className="tag___3wfZL">
                                                            <span className="stop___jfwkx">Ended</span>
                                                        </div>
                                                        <div className="activityTitle___QINeJ">
                                                            <p>{PROJECT_NAME} Spotlight - Gari Network (GARI)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6'>
                                                    <div className="activityItem___3qGTl" style={{ backgroundImage: `url(${bonusImg})` }}>
                                                        <div className="tag___3wfZL">
                                                            <span className="stop___jfwkx">Ended</span>
                                                        </div>
                                                        <div className="activityTitle___QINeJ">
                                                            <p>{PROJECT_NAME} Spotlight - ClearDAO (CLH)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6'>
                                                    <div className="activityItem___3qGTl" style={{ backgroundImage: `url(${bonusImg})` }}>
                                                        <div className="tag___3wfZL">
                                                            <span className="stop___jfwkx">Ended</span>
                                                        </div>
                                                        <div className="activityTitle___QINeJ">
                                                            <p>{PROJECT_NAME} Spotlight - ClearDAO (CLH)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6'>
                                                    <div className="activityItem___3qGTl" style={{ backgroundImage: `url(${bonusImg})` }}>
                                                        <div className="tag___3wfZL">
                                                            <span className="stop___jfwkx">Ended</span>
                                                        </div>
                                                        <div className="activityTitle___QINeJ">
                                                            <p>{PROJECT_NAME} Spotlight - ClearDAO (CLH)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6'>
                                                    <div className="activityItem___3qGTl" style={{ backgroundImage: `url(${bonusImg})` }}>
                                                        <div className="tag___3wfZL">
                                                            <span className="stop___jfwkx">Ended</span>
                                                        </div>
                                                        <div className="activityTitle___QINeJ">
                                                            <p>{PROJECT_NAME} Spotlight -Cryowar (CWAR)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more___3MFio" style={{ marginBottom: '28px', marginTop: '32px' }}>
                                        <button type="button" className="ant-btn  kc-button ant-btn-primary" >
                                            <a href="/spotlight-center">
                                                <span className="mr-4">{bonusAccountpage['0']['ViewMore'][langchange]}</span>
                                                {/* <svg className="_2XBlS6kVGebKQ6luHq1Bh-" aria-hidden="true" style={{width: '16px', height: '16px'}}> */}
                                                {/* <use xlink:href="#icon-next"></use> */}
                                                {/* </svg> */}
                                            </a>
                                            <canvas className="water-wave-canvas"></canvas>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default bonus