import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const BugBounty = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          {/* <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul> */}
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  BUG BOUNTY</h2>
            <h6> At {PROJECT_NAME} we believe in the power of crowd wisdom. As we are looking to
                continuously improve our platform to <br/>make it more secure and easy to use for
                our traders, we’ve launched a bug-bounty program.</h6>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0 align-items-center First_row'>
            <div className='col-md-6' >
              <div style={{borderRadius:'20%',overflow:'hidden'}}>
                <img className='img-about-1' src={about1} width='100%' alt='Bug/img' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h6>
                  At {PROJECT_NAME} we believe in the power of crowd wisdom. As we are looking to
                  continuously improve our platform to make it more secure and easy to use for
                  our traders, we’ve launched a bug-bounty program. If you find any bugs on our
                  platform or have feedback on how we can further improve our service, please
                  kindly reach out.<br/> <br/>
                  The bounties we offer range from  25 USDT to 5000 USDT  for confirmed
                  vulnerability reports. We will rate each submission and pay out the bounty in
                  accordance with our vulnerability rating. As you can imagine, some bugs are
                  more significant than others. All pay-outs are defined in our guidelines and
                  subject to change. We process all pay-outs in USDT to ensure you receive the
                  promised   amount   of   your   well-deserved   bounty   no   matter   the   market
                  conditions.
                </h6>
              </div>
            </div>
            
          </div>
        </div>
       
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row vision'>
            <div className='col-md-6'>
              <div className='about-story-text'>

                <h6>You’ll be immediately disqualified when asking for payment in exchange for
                  vulnerability details. Please make sure that any report submitted includes a clear
                  and   detailed   description   of   the   vulnerability.   Please   also   include   detailed
                  instructions on how to reproduce your findings.<br /><br />
                  Unfortunately, when we cannot reproduce your findings, your report will not be
                  eligible for pay out. Therefore, please make sure that you describe how you
                  found the bug and how we can reproduce it in as much detail as possible.<br /><br />
                  All   our   reward   payments   are   made   in   Tether   (USDT)   directly   to   your
                  {PROJECT_NAME} account.</h6>
                
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about3} width='100%' alt='Bug/img' />
            </div>
          </div>
        </div>

        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision'>
          <div className='col-md-6'>
              <div className='about-story-text about-page'>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>All   our   reward   payments   are   made   in   Tether   (USDT)   directly   to   your
                    {PROJECT_NAME} account.</h6>
                  </li>
                  <li><h6>Please submit any bug reports to --------------(MAIL ID).</h6>
                  </li>
                  <li><h6>We will check your report within 4 weeks after it’s submission.</h6> </li>
                  <li><h6>Our minimum pay out for a bug report is 25 USDT. <br />(PLEASE DO CHECK THE RATES, ACC TO YOUR COMPANY)</h6>
                  </li>
                  <li><h6>{PROJECT_NAME} Bug Bounty Program Statistics.<br />(Please note that this data is updated every month)</h6>
                  </li>
                  <li><h6>12 reports were reviewed and rewarded in the last quarter</h6> </li>
                  <li><h6>5 days is the average vulnerability review period in the last quarter</h6></li>
                  <li><h6>$200 is the average pay-outs in the last quarter</h6></li>
                </ul>
                
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text table-responsive'>
                <h3>WALL OF FAME</h3>
                <p>(Please note that this data is updated every month)</p>
               <table className='table table-bordered text-center'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>REWARD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr><tr>
                    <td></td>
                    <td></td>
                  </tr><tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
               </table>
                
              </div>
            </div>
           
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision'>
            <div className='col-md-6 border-right'>
              <div className='about-story-text about-page'>
                <div className='text-center m-3'><h3>In-Scope Properties</h3></div>
                <p>The   following   properties   are   in   scope   for   the   bug   bounty   program   of
                      {PROJECT_NAME} All rewards will be issued in USDT.</p>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>https://{PROJECT_NAME}/</h6>
                  </li>
                  <li><h6>https://api.{PROJECT_NAME}/</h6>
                  </li>
                  <li><h6>wss://api.{PROJECT_NAME}/</h6> </li>
                 
                </ul>
                
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>
                <div className='text-center m-3'><h3>Out of Scope Properties</h3></div>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>Please note that the following properties are out of scope for bug bounty
                      rewards. Any other {PROJECT_NAME} properties that aren’t included in the in-scope
                      properties above</h6>
                  </li>
                  <li><h6>Additionally, all other 3rd party hosted assets that are not explicitly mentioned
                        in our “In Scope Properties” don’t fall under our Bug Bounty program either.
                        Check In Scope Properties for an overview of included properties.
                      </h6>
                  </li>
                  
                </ul>
                
              </div>
            </div>
           
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-4'>
                <h2>Out of Scope Vulnerabilities</h2>
               
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 about-page'>
                  <ol start="1"  >
                    <li><p>Low version browsers, platforms, etc.</p>
                    </li>
                    <li><p>Lack of Secure and HTTP Only cookie flags.</p>
                    </li>
                    <li><p>Clickjacking/Tap jacking</p>
                    </li>
                    <li><p>Physical attacks against {PROJECT_NAME} and Social engineering attacks against
                          {PROJECT_NAME} employees.
                        </p>
                    </li>
                    <li><p>Cross-Site Request Forgery attacks</p></li>
                    <li><p>Descriptive error messages</p></li>
                    <li><p>Content Spoofing without embedded links/HTML</p></li>
                    <li><p>Theoretical risk vulnerabilities</p></li>
                    <li><p>Self-XSS</p></li>
                    <li><p>Vulnerabilities in 3rd party applications</p></li>
                    <li><p>Weak Captcha/Captcha Bypass</p></li>
                    <li><p>Some bugs that don't bring security risks</p></li>
                    <li><p>Spam (SMS, email, etc), phishing, social engineering</p></li>
                    <li><p>NGINX and/or other middleware leaks</p></li>
                    <li><p>Publicly login panels</p></li>
                    <li><p>Interface brute force blasting of registered user name vulnerabilities</p></li>
                    <li><p>Mixed HTTP Content</p></li>
                    <li><p>Recently (less than 30 days) disclosed 0day vulnerabilities</p></li>
                    <li><p>Issues related to best practices</p></li>
                    <li><p>Man-in-the-middle and local attacks</p></li>
                    <li><p>Missing HTTP security headers</p></li>
                    <li><p>Cross-Site Request  Forgery in forms that  are available to anonymous and
unregistered users</p></li>
                    {/* <li><p>Email enumeration via Login/Forgot Password and other pages error messages</p></li>
                    <li><p>Login & Logout Cross-Site Request Forgery</p></li>
                    <li><p>HTML injection</p></li>
                    <li><p>Certificate/TLS/SSL related vulnerabilities</p></li>
                    <li><p>Manipulation with Password Reset/Withdrawal confirmation/2FA activation or
any other tokens</p></li>
                    <li><p>Any Distributed-Denial-of-Service/Denial-of-Service issues</p></li>
                    <li><p>Content Security Policy</p></li>
                    <li><p>Any HTTPS content scripts</p></li> */}
                  </ol>
                </div>
                <div className='col-md-6 about-page'>
                <img className='img-about-1' src={about4} width='100%' alt='Bug/img' />
                <ol start="23"  >
                   
                    <li><p>Email enumeration via Login/Forgot Password and other pages error messages</p></li>
                    <li><p>Login & Logout Cross-Site Request Forgery</p></li>
                    <li><p>HTML injection</p></li>
                    <li><p>Certificate/TLS/SSL related vulnerabilities</p></li>
                    <li><p>Manipulation with Password Reset/Withdrawal confirmation/2FA activation or
any other tokens</p></li>
                    <li><p>Any Distributed-Denial-of-Service/Denial-of-Service issues</p></li>
                    <li><p>Content Security Policy</p></li>
                    <li><p>Any HTTPS content scripts</p></li>
                  </ol>
                </div>
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>
        <div className='about_box-1 mt-5'>
        <div className='text-center m-4'><h2>{PROJECT_NAME} Bug Bounty Program Rules and Policy</h2></div>

          <div className='row m-0  First_row about-page vision'>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>{PROJECT_NAME} ({PROJECT_NAME} Ltd.) agrees to refrain from initiating legal
                          action against any security research performed that complies with the
                          program rules of the Exchange Bug Bounty program. This includes good
                          faith and violations that are non-deliberate. All activities that fall under
                          “authorized” conduct as specified under the DMCA, the CFAA, the
                          Computer Misuse Act, and other hacking laws such as Cal. Penal Code
                          503(c) we won’t bring legal claims against the individual or institution
                          who circumvented security measures to contribute to the bug bounty
                          program and the continuous improvement of our platform.
                      </h6>
                  </li>
                  <li><h6>Don’t access or modify other {PROJECT_NAME} users' data. Keep all your tests
                          limited to your accounts. If you fail to comply, it will entail a lawsuit
                          against you.
                    </h6>
                  </li>
                  <li><h6>If we receive several reports on the same vulnerability, only the first
                          received report will be eligible for a reward.
                      </h6>
                  </li>
                  <li><h6>Please refrain from exploiting the vulnerability neither by making it
                          public nor by enriching yourself. Failure to comply will result in legal
                          action against you.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Don’t break any laws and limit yourself to the defined scope. Failure to
                    comply will result in legal action.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please refrain from publicly disclosing any vulnerabilities without our
                    consent.   We   will   only   approve   Public   Disclosure   requests   once   the
                    vulnerability has been resolved.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please limit yourself to submitting one vulnerability at a time. If you
                    deem it necessary to chain vulnerabilities to illustrate the impact, you can
                    submit more than one.
                    </h6>
                  </li>
                  
                  
                </ul>
                
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li>
                    <h6>
                    Please ensure not to hinder the availability or level of service of our
                    exchange when conducting your testing.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please ensure that you’re not compromising personal data, and do not
                    interrupt or degrade our service. Failure to comply will result in legal
                    action.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Refrain from spam bots to spam our sign-up form or automated scanners
                    to interrupt our account creation flow.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please   don’t   use   any   applications   that   scan   automatically   for
                    vulnerabilities by generating massive traffic.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Don’t   launch   any   Distributed-Denial-of-Service/Denial-of-Service
                    attacks, social engineering attacks, or any form of spam; otherwise, it will
                    entail a lawsuit against you.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please don’t share details on any vulnerabilities you discovered with
                      anyone outside the {PROJECT_NAME} team without explicit permission given by
                      us.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    The {PROJECT_NAME} reserves the right to cancel or amend these terms or
                    conditions   without   prior   notification.   All   the   terms   and   conditions
                    applicable   to   usage   of   {PROJECT_NAME}   also   apply   to   this   Bug   Bounty
                    Program.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    By submitting a report, you agree to be bound and comply with the rules outlined here.
                    </h6>
                  </li>
                  <li>
                    <h6>
                    By submitting a report, you acknowledge and agree to the rules outlined
                    in the program. You also agree that any decision taken by {PROJECT_NAME} is
                    final and binding.
                    </h6>
                  </li>
                </ul>
                
              </div>
            </div>

            
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default BugBounty