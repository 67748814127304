import * as types from '../actions/_types';

const initialState = {
  user: {
    name : 'BT',
    email : '',
    api_key : '',
  }, 
  balance : 'waiting',
  transactions : {
    transactions : []
  },
  banners : [],
  viewTocket: []
};

export default function dashboard(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_USER_PROFILE:
      return { ...state, user: action.payload }; 
    case types.SAVE_BINANCE_BALANCE:
      return { ...state, balance: action.payload }; 
    case types.SAVE_USDT_TRANSACTIONS:
      return { ...state, transactions: action.payload }; 
    case types.SAVE_BANNERS:
      return { ...state, banners: action.payload }; 
    case types.SAVE_COIN_ADDRESS:
      return { ...state, coinAddress: action.payload }; 
    case types.SAVE_COIN_TABLE_ADDRESS:
      return { ...state, coinAddresstable: action.payload }; 
    case types.SAVE_VIEW_TICKET:
      return { ...state, viewTocket: action.payload};  
    default:
      return state;
  }
}

