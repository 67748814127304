import React from 'react'
import insta from "../../../../_assets/images/insta.svg";
import telegram from "../../../../_assets/images/linked-in.svg";
import twitter from "../../../../_assets/images/twitter.svg";
import fb from "../../../../_assets/images/fb.svg";

import { footer } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import { logo, PROJECT_NAME } from '../../../../_constants';
import { Link } from 'react-router-dom';
const Footer = () => {
    const {langchange} = LangState();

  return (
    <div>


        
    <footer class="sec__footer">
        <div class="container">
            <a class="footer__logo" href="/">
                <img src={logo} alt="logo" className='footer_logo' />
            </a>
            <ul class="footer__list">
                <li class="footer__item">
                    <h2 class="footer__heading">
                        <Link to="/ContactUs" >{footer['0']['box1']['h1'][langchange]}</Link>
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <Link class="footer__link" to="/about">
                                {footer['0']['box1']['p1'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/JoinUs">
                            {footer['0']['box1']['p2'][langchange]}
                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/media-kit">
                                {footer['0']['box1']['p3'][langchange]}
                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/affiliate-program">
                                {footer['0']['box1']['p4'][langchange]}
                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/bug-bounty">
                                {footer['0']['box1']['p5'][langchange]}
                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/technical-support">
                                {footer['0']['box1']['p6'][langchange]}
                            </Link>
                        </li>


                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        {footer['0']['box2']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <Link class="footer__link" to="/spot-trade">
                                {footer['0']['box2']['p1'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/margin-trade">
                                {footer['0']['box2']['p2'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/contract">
                                {footer['0']['box2']['p3'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/fudx-earn">
                                {footer['0']['box2']['p4'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/landing">
                                {footer['0']['box2']['p5'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/trading-bot">
                                {footer['0']['box2']['p6'][langchange]}
                            </Link>
                        </li>

                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        <Link to='/service' style={{color:'rgba(0, 0, 0, 0.6)'}}>{footer['0']['box3']['h1'][langchange]}</Link>
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <Link class="footer__link" to="/support-centre">
                                {footer['0']['box3']['p1'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box3']['p2'][langchange]}


                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box3']['p3'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/fee">
                                 {footer['0']['box3']['p4'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/vip-previlege">
                                {footer['0']['box3']['p5'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="">
                                {footer['0']['box3']['p6'][langchange]}

                            </Link>
                        </li>
                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                         {footer['0']['box4']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <Link class="footer__link" to="/TokenListing">
                                {footer['0']['box4']['p1'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/fudx-labs">
                                {footer['0']['box4']['p2'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box4']['p3'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/spotlight">
                                {footer['0']['box4']['p4'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/MarketProgram">
                                {footer['0']['box4']['p5'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/P2PMerchant">
                                {footer['0']['box4']['p6'][langchange]}

                            </Link>
                        </li>

                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        {footer['0']['box5']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <Link class="footer__link" to="/TermsofUse">
                                {footer['0']['box5']['p1'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                 {footer['0']['box5']['p6'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/PrivacyPolicy">
                                {footer['0']['box5']['p2'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/RiskDisclosure">
                                {footer['0']['box5']['p3'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box5']['p4'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box5']['p5'][langchange]}

                            </a>
                        </li>


                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        {footer['0']['box6']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box6']['p1'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box6']['p2'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box6']['p3'][langchange]}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                            {footer['0']['box7']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box7']['p1'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box7']['p2'][langchange]}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        {footer['0']['box8']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box8']['p1'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box8']['p2'][langchange]}

                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                {footer['0']['box8']['p3'][langchange]}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        {footer['0']['box9']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List">
                        <li class="footer__itm">
                            <Link class="footer__link" to="/soon">
                                {footer['0']['box9']['p1'][langchange]}

                            </Link>
                        </li>
                        <li class="footer__itm">
                            <Link class="footer__link" to="/soon">
                                {footer['0']['box9']['p2'][langchange]}
                            </Link>
                        </li>
                    </ul>
                </li>
                <li class="footer__item">
                    <h2 class="footer__heading">
                        {footer['0']['box10']['h1'][langchange]}
                    </h2>
                    <ul class="footer__inner__List footer__icon">
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                            {/* <i class="fab fa-instagram"></i> */}
                                <img src={fb} alt="fb-icon/img" />
                            </a>
                            {/* <div class="dropdown__language">
                                <ul>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                </ul>
                                <span></span>
                            </div> */}
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src={twitter} alt="twitter-icon/img" />
                            </a>
                            {/* <div class="dropdown__language">
                                <ul>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> English Facebook</a>
                                    </li>
                                </ul>
                                <span></span>
                            </div> */}
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src={telegram} alt="telegram-icon/img" />
                            </a>
                            {/* <div class="dropdown__language">
                                <ul>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                    <li>
                                        <a href=""> Telegram Facebook</a>
                                    </li>
                                </ul>
                                <span></span>
                            </div> */}
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src={insta} alt="YouTude-icon/img" />
                            </a>
                        </li>
                        {/* <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/footer-icon1.svg" alt="img/icon" />
                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/footer-icon2.svg" alt="img/icon" />
                            </a>
                            <div class="dropdown__language">
                                <ul>
                                    <li>
                                        <a href=""> Turkish Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Vietnamese Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Italian Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Turkish Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Vietnamese Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Italian Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Turkish Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Vietnamese Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Italian Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Turkish Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Vietnamese Reddit</a>
                                    </li>
                                    <li>
                                        <a href=""> Italian Reddit</a>
                                    </li>
                                </ul>
                                <span></span>
                            </div>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/footer-icon-3.svg" alt="img/icon"/>
                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/linkdin-icon.svg" alt="img/icon" />
                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/instagram-icon.svg" alt="img/icon" />
                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/footer-icon4.svg" alt="img/icon" />
                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/footer-icon5.svg" alt="img/icon"/>
                            </a>
                        </li>
                        <li class="footer__itm">
                            <a class="footer__link" href="#">
                                <img src="assets/svg/footer-icon6.svg" alt="img/icon"/>
                            </a>
                        </li> */}
                    </ul>
                </li>
            </ul>
            <div class="copy__right  text-center justify-content-center ">
                <div class="text  ">
                    <p>CopyRight © 2021 - 2022 {PROJECT_NAME}.com. All Rights Reserved. </p>
                </div>

            </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer