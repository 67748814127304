import React, { useState,useMemo, useEffect } from "react";
import { Form } from 'semantic-ui-react';
// import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { HiMail} from 'react-icons/hi'
import { BiKey } from 'react-icons/bi'
import { reduxForm, Field } from "redux-form";
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link, useLocation, useParams } from 'react-router-dom'
import { ReactCountryDropdown } from 'react-country-dropdown'
import countryList from 'react-select-country-list'
// import Select from 'react-select'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// import CountryData, { lookup } from "country-data";
import axios from "axios";
import { CountryData1 } from "./countrycode";
import { InputBase,Select, MenuItem, TextField} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import SelectSearch from 'react-select-search';
// import { Select, Page, setOptions } from '@mobiscroll/react-lite';
import { register , checkSponser ,sentMobileOtp } from "../../../redux/actions/user.action";
import { useDispatch } from "react-redux";
import { LangState } from "../../../LangugeContext";
import { registerlang } from "../../../lang/lang";
const RegisterForm = (props) => {
    const {sponser} = useParams();
    const dispatch = useDispatch();
    const {langchange} = LangState();
    const [ passwordType, setPasswordType ] = useState("password");
    const [ activeclass, setactiveclass ] = useState('icon' );
    const [country, setcountry] = useState(1)
    const [phone,setphone] = useState(1);
    const [password,setpassword] = useState();
    const [referral,setreferral] = useState();
    const [userName,setuserName] = useState();
    const [email,setemail] = useState();
    const [otp,setotp] = useState();
    const [search,setsearch] = useState('');
    const [myData, setMyData] = useState()
    const[formData,setformData] = useState();
    const[sponserId,setsponserId] = useState();
    var formSubmit = '';
     formSubmit = {
        email: email,
        otp:otp,
        password :password,
        sponserid:referral,
        register_type:'email',
        sponser_id: sponserId,
        
    }
    const validateSponser = (sponserId) => {
        dispatch(checkSponser(sponserId))
    }
    const formSubmit1 = {
        country_code:'+'+country,
        phone: phone,
        otp:otp,
        password :password,
        sponserid:referral,
        register_type:'phone',
        sponser_id: sponserId,
    }

    const handleSubmitForm = () => {
        dispatch(register(formSubmit1,props.history));        
    }

    const handleSubmitForm1 = () => {
        // dispatch(register());
        dispatch(register(formSubmit,props.history));
        console.log('hiii');
        
        // handleSubmit();
    }
    const data3 = {
        phone: phone,
        country_code :'+'+country,
        register_type:'phone',
    }
    const SendOTP = () => {
        console.log('phone OTP');
        dispatch(sentMobileOtp(data3));
    }

    const emaildata = {
        email: email,
        register_type:'email',
    }
    const emialOTP = () => {
        console.log('emialOTP');
        dispatch(sentMobileOtp(emaildata));
    }

    const passwordTypeFn = () => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    }
    const findSponser = (sponserid) => {
        checkSponser(sponserid)
    }
    
    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";
    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";     
    }


    function loginFun(myElement) {
        const newId = myElement + 'form';
        document.getElementById(myElement).className = "form__tab__btn  line active";
        document.getElementById(newId).className = "form__tabs active";
        if(myElement != 'email'){
            document.getElementById('email').className = "form__tab__btn  line";
            document.getElementById('emailform').className = "form__tabs d-none";
        }
        if(myElement != 'phone'){
            document.getElementById('phone').className = "form__tab__btn  line";
            document.getElementById('phoneform').className = "form__tabs d-none";
        } 
       
    }

    const Referal = () => {
        document.getElementById('Referal').classList.toggle("d-none");
        document.getElementById('Referal2').classList.toggle("d-none")

    }
    console.log(sponser);   
    useEffect(() =>{
        if(sponser != undefined){
            setsponserId(sponser);
        }
    },[]); 
    return (
        <>
            <h2 className="form__heading">Sign Up</h2>
            <div className="form__tabs__wrapper">
                <div className="form__tabs__btn">
                    <button className="form__tab__btn active line" id="phone"  onClick={() => {loginFun('phone')}}>{registerlang['0']['mobilebtn'][langchange]} </button>
                    <button className="form__tab__btn Email " id="email" onClick={() => {loginFun('email')}}>{registerlang['0']['emailbtn'][langchange]}</button>
                </div>
                <Form onSubmit={handleSubmitForm}>
                    <div className="form__tabs active" id="phoneform">
                        <div className="tab__content">
                            

                            <div className="form__control">
                                <label for="phone login__number">{registerlang['0']['mobilebtn'][langchange]}</label>
                                <div className="input__dropdown__wrapper">
                                    <div className="input__dropdown login__number__input">
                                        <div className="form-group">

                                            <button type="button" className="dropdown__btn" onClick={dropdown}> <span>+{country}</span>
                                                <svg className="down_svg__icon" width="24" height="24"
                                                    viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor">
                                                    <path
                                                        d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                    </path>
                                                </svg>
                                            </button>
                                            <div className="number__dropdown " id='countryDrop'>
                                                <div className="search__input">
                                                    <label for="search">
                                                        <svg className="search_svg__icon" viewBox="0 0 1024 1024"
                                                            xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" fill="currentColor">
                                                            <defs>
                                                                <style></style>
                                                            </defs>
                                                            <path
                                                                d="M719.104 688.896l134.23 134.272a21.333 21.333 0 01-30.166 30.165L688.896 719.104l30.208-30.208zm-249.77-518.23a298.667 298.667 0 110 597.334 298.667 298.667 0 010-597.333zm0 42.667a256 256 0 100 512 256 256 0 000-512z">
                                                            </path>
                                                        </svg>
                                                    </label>
                                                    <input type="text" id="search"  onChange={(e) => {setsearch(e.target.value)} }  className="search__input" />
                                                </div>
                                                <div className="select__countery">
                                                
                                                {CountryData1.filter((key) => {
                                                    if(search == ""){
                                                        return key;
                                                    }else if(key.en.toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                                        return key
                                                    }
                                                }).map(key =>(

                                                    <div className="countery"  onClick={ () => {setcountry(key.mobileCode);dropdownClose()}}>
                                                        <div className="flag" >
                                                            <img src={key.ico} width='30' />
                                                            <p className="countery__code" >{key.en}</p>
                                                        </div>
                                                        <div className="number">+{key.mobileCode}</div>
                                                    </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                        <input type="number"  placeholder="phone"   onChange={(e) => {setphone(e.target.value)}}
                                            required  />
                                    </div>
                                    <a  className="send__code" onClick={SendOTP}>{registerlang['0']['otp'][langchange]}</a>
                                </div>
                            </div>
                            <div className="form__control">
                                <label for="verification">{registerlang['0']['mobilelable1'][langchange]}</label>
                                <input type={passwordType} id="verification" name="verification" onChange={(e) => {setotp(e.target.value)}}  required />
                                <div className="show__password">
                                    <span>
                                    <div className="pass__icon text-dark" onClick={() => passwordTypeFn()}><AiFillEye /></div>

                                    </span>
                                </div>
                            </div>
                            
                            <div className="form__control">
                                <label for="verificationa">{registerlang['0']['mobilelable2'][langchange]}</label>
                                <input className="password__input" type={passwordType} onChange={(e) => {setpassword(e.target.value)}}  id="verificationa"
                                    name="verification" required="" />

                                <div className="show__password">
                                    <span>
                                    <div className="pass__icon text-dark" onClick={() => passwordTypeFn()}><AiFillEye /></div>

                                    </span>
                                </div>
                            </div>
                            <p className="m-0" style={{color:'#28cd95',cursor:'pointer'}} onClick={() => Referal()}>Referral Code (Optional) <i class="fas fa-caret-down"></i></p>

                            <div className="form__control" id="Referal">
                                <label for="verification">Referal Code</label>
                                <input type="text" id="verification" name="sponser" onChange={(e) => setsponserId(e.target.value)}  onBlur={(e) => { validateSponser(e.target.value)}}  value={sponserId}  />
                            </div>
                            
                            <div className="terms__condition">
                                <input id="chTerms2" name="chTerms" type="checkbox" required="required"/>  
                                <label for='chTerms2' className="text-dark">{registerlang['0']['agree'][langchange]}</label>
                                
                            </div>
                            <span className="tick__terms">
                                Please tick the box to confirm that you consent to the terms of use.
                            </span>
                        </div>
                        <div className="submit__btn__wrapper sign__up__btn">
                <button type="submit" className="submit__btn">{registerlang['0']['submit'][langchange]}</button>
                <button className="receive__sms" href="reset-password.html"
                    className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button>
            </div>
                    </div>
                </Form>
                <Form onSubmit={handleSubmitForm1}>
                <div className="form__tabs " id="emailform">
                    <div className="tab__content">
                        <div className="form__control">
                            <label for="Email">{registerlang['0']['emailbtn'][langchange]}</label>
                            <div className="email__verification">
                                <input className="reset__input" type="Email" id="Email" placeholder="Email"
                                name="Email" onChange={(e) => {setemail(e.target.value)}} required />
                                <a  onClick={emialOTP} className="send__code">{registerlang['0']['otp'][langchange]}</a>
                            </div>
                        </div>
                        <div className="form__control">
                            <label for="verification">{registerlang['0']['emaillable1'][langchange]}</label>
                            <div className="email__verification">
                                <input type={passwordType} id="verification" name="verification" onChange={(e) => {setotp(e.target.value)}} required />
                                
                            </div>
                            <div className="show__password">
                                <span>
                                <div className="pass__icon text-dark" onClick={() => passwordTypeFn()}><AiFillEye /></div>

                                </span>
                            </div>
                        </div>
                        <div className="form__control">
                            <label for="verificationa">{registerlang['0']['mobilelable2'][langchange]}</label>
                            <input className="password__input" type={passwordType} onChange={(e) => {setpassword(e.target.value)}}  id="verificationa"
                                name="verification" required="" />

                            <div className="show__password">
                                <span>
                                <div className="pass__icon text-dark" onClick={() => passwordTypeFn()}><AiFillEye /></div>

                                </span>
                            </div>
                        </div>
                        
                        <p className="m-0" style={{color:'#28cd95',cursor:'pointer'}} onClick={() => Referal()}>Referral Code (Optional) <i class="fas fa-caret-down"></i></p>

                            <div className="form__control" id="Referal2">
                                <label for="verification">Referal Code</label>
                                <input type="text" id="verification" name="sponser" onChange={(e) => setsponserId(e.target.value)} onBlur={(e) => {validateSponser(e.target.value)}}  value={sponserId}  />
                            </div>
                        <div className="terms__condition">
                            <input id="chTerms" name="chTerms" type="checkbox" required="required"/>  
                                <label className="text-dark" for='chTerms'>{registerlang['0']['agree'][langchange]}</label>
                                
                        </div>
                        <span className="tick__terms">
                            Please tick the box to confirm that you consent to the terms of use.
                        </span>

                    </div>
                
                    <div className="submit__btn__wrapper sign__up__btn">
                <button type="submit" className="submit__btn">{registerlang['0']['submit'][langchange]}</button>
                <button className="receive__sms" href="reset-password.html"
                    className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button>
            </div>
                
                </div>
                
                </Form>
            </div>
            {/* <div className="submit__btn__wrapper sign__up__btn">
                <button type="submit" className="submit__btn">{registerlang['0']['submit'][langchange]}</button>
                <button className="receive__sms" href="reset-password.html"
                    className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button>
            </div> */}
                    
        </>
    );
};

    export default reduxForm({ form: 'RegisterForm', enableReinitialize: true }) (RegisterForm);