import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import assetsSide from '../../../_assets/images/assetsSide.svg';
import assetsBox from '../../../_assets/images/assetsBox.png';
// import mainAccount from './mainAccount';
import { Link } from 'react-router-dom';
import { LangState } from '../../../LangugeContext';
import { assetsnav, asstespage } from '../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../redux/actions/dashboard.action';
import assets from '../../../redux/reducer/assets.reducer';
import {get_swap_balance, get_swap_balance_wallet_type, get_token_price_as_token, get_user_assets, get_wallet_details, transfer_user_assets} from '../../../redux/actions/assets.action';
import { coinAddress } from '../../../Services/api/api.service';
import { coinName } from '../../../coinList';
import Modal from 'react-responsive-modal';
import transferdata from '../../../_assets/images/transferdata.svg';
import { available_amount } from '../../../redux/actions/withdraw.action';
import {TOKEN_PROJECT_NAME} from '../../../_constants/index'
const useStyles = makeStyles({
    assetsBtn: {
        marginTop: '77px',
        display: 'flex', 
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center',
    },
    btnDesign: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
        },

    },
    btnUl: {
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag: {
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
    },
    sectionBox: {
        width: '100%',
        padding: '40px 15px',
    },
    sectionWrapper: {
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    sectionHeading: {
        color: 'rgb(1, 8, 30)',
        fontSize: '34px',
        fontWeight: '500',
        lineHeight: '48px',
        marginBottom: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    headerEye: {
        marginLeft: '8px',
        cursor: 'pointer',
    },
    middlebtn: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    middleCoinName: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',

    },
    coin0: {
        fontSize: '20px',
        lineHeight: '48px',
        color: 'rgb(1, 8, 30)',
        fontWeight: 500,
    },
    jss3694: {
        color: 'rgb(1, 8, 30)',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '22px',
        marginLeft: '4px',
    },
    jss3695: {
        color: 'rgba(1, 8, 30, 0.38)',
        fontWeight: '500',
        lineHeight: '32px',
        fontSize: '14px',
        marginLeft: '4px',

    },
    selectCoin___1SVoA: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(1,8,30,.04)',
        borderRadius: '16px',
        padding: '4px 14px 4px 12px',
        fontFamily: 'URWDIN-Regular',
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: '16px',
        cursor: 'pointer',

    },
    arrow___2S8Lc: {
        display: 'inline-block',
        content: " ",
        marginLeft: '6px',
        width: '8px',
        height: '8px',
        borderTop: ' 1px solid rgba(1,8,30,.87)',
        borderRight: '1px solid rgba(1,8,30,.87)',
        transform: 'rotate(45deg)',
        transition: ' all .2s',
        '&:hover': {
            borderColor: "rgb(45, 189, 150)",
            transform: 'rotate(135deg)'
        },
    },

    jss179: {
        height: '70px',
        display: 'flex',
        alignItems: 'flex-end',
        display: 'none',
    },
    jss178: {
        textAlign: 'right',
        padding: '20px 0'
    },
    jss53: {
        minWidth: '88px',
        color: 'rgb(255, 255, 255)',
        boxShadow: 'rgb(1 8 30 / 8%) 0px 0px 8px 0px',
        height: '40px',
        padding: '0 12px',
        fontSize: '14px',
        fontWeight: '500',
        backgroundColor: 'rgb(40 205 149)',
        borderRadius: '3px',
    },
    jss53_btn: {
        color: 'rgb(40 205 149)',
        backgroundColor: '#fff',
        border: '1px solid rgb(40 205 149)',

        minWidth: '88px',
        boxShadow: 'rgb(1 8 30 / 8%) 0px 0px 8px 0px',
        height: '40px',
        padding: '0 12px',
        fontSize: '14px',
        fontWeight: '500',
        borderRadius: '3px',

    },
    jss174: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    jss1261: {
        margin: '10px 0px',
        width: '100%',
        cursor: 'pointer',
        display: 'block',
        padding: '28px 24px 24px',
        overflow: 'hidden',
        position: 'relative',
        maxWidth: '100%',
        // background: `url(${assetsBox}) 0px 0px no-repeat padding-box padding-box`,
        boxShadow: 'rgb(209 217 230 / 60%) 6px 6px 24px 0px',
        backgroundPosition: '108% 226%',
        borderRadius: '8px',
        // borderTop:'1px solid red',
    },
    jss1262: {
        color: 'rgb(1, 8, 30)',
        display: 'flex',
        fontSize: '16px',
        lineHeight: '24px',
        justifyContent: 'space-between',
    },
    jss1264: {
        color: 'rgb(40 205 149)',
        textDecoration: 'none',
        fontSize: '14px',
        lineHeight: '22px',
    },
    jss300: {
        padding: '24px 24px 26px',
        fontSize: '16px',
        background: ' rgba(0, 10, 30, 0.02)',
        lineHeight: '24px',
    },
    jss295: {
        display: 'flex',
        alignItems: 'center',
    },
    jss301: {
        color: 'rgb(0, 0, 0)',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '28px',
    },
    jss302: {
        color: 'rgb(40 205 149)',
        textDecoration: 'none',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
    },
    jss303: {
        color: 'rgba(1, 8, 30, 0.87)',
        position: 'relative',
        fontSize: '16px',
        lineHeight: '24px',
        // paddingLeft: '16px',


    },
    jss304: {
        color: 'rgba(1, 8, 30, 0.6)',
        fontSize: '14px',
        lineHeight: '22px',
    }

});
const Assets = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const wallet_details =  useSelector(state => state.assets.wallet_details);
    const token_prices = useSelector(state => state.assets.token_prices.data);
    // console.log('token_prices',token_prices);
    // console.log('wallet_details',wallet_details);
    const [allBalance , setallBalance] = useState(0);

    const [mainTotal , setmainTotal] = useState(0);
    const [fundingTotal , setfundingTotal] = useState(0);
    const [tradingTotal , settradingTotal] = useState(0);
    const [coinType , setcoinType] = useState('USDT');
    const [INR , setINR] = useState(0);
    const [USD , setUSD] = useState(0);
    const [BTC , setBTC] = useState(0);
    const [BNB , setBNB] = useState(0);
    const [FXC , setFXC] = useState(0);
    const [coinPrice , setcoinPrice] = useState(1);
    const assetsData = useSelector(state => state.assets.assetsData)
    const swap_balance  = useSelector(state => state.assets.balance_with_type);


    const [open, setOpen] = useState(false);
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const [dropdownVisible1, setdropdownVisible1] = useState(false);
    const [dropdownVisible2, setdropdownVisible2] = useState(false);
    const [first_wallet, setfirst_wallet] = useState('main_wallet');
    const [first_wallet_name, setfirst_wallet_name] = useState('Main Account');

    const [second_wallet, setsecond_wallet] = useState('trading_wallet');
    const [second_wallet_name, setsecond_wallet_name] = useState('Trading Account');
    const [trnsferId, settrnsferId] = useState();
    const [trnsferIdname, settrnsferIdname] = useState();
    const [trensferAmount, settrensferAmount] = useState();

    const onOpenModal = (val) => {
        setOpen(true);
        setfirst_wallet(val)
        dispatch(get_user_assets({ wallet_type: val }));
        dispatch(get_swap_balance_wallet_type({tokenName: trnsferIdname,wallet_type:val}));

        if(val == 'main_wallet'){
            setsecond_wallet_name('Trading Account')
            setsecond_wallet('trading_wallet')
        }else if(val == 'funding_wallet'){
            setsecond_wallet_name('Trading Account')
            setsecond_wallet('trading_wallet')
        }else{
            setsecond_wallet_name('Main Account')
            setsecond_wallet('main_wallet')
        }
    }
    const onCloseModal = () => setOpen(false);
    const [methodName, setmethodName] = useState();
    const [methodId, setmethodId] = useState();
    const [search, setsearch] = useState();
    const sender_wallet = (id, name) => {
        setfirst_wallet(id);
        setfirst_wallet_name(name);
        setdropdownVisible2(false)
    }
    const receiver_wallet = (id, name) => {
        setsecond_wallet(id);
        setsecond_wallet_name(name);
        setdropdownVisible1(false)
    }

    const flipValue = (val1, name1, val2, name2) => {
        setfirst_wallet(val2);
        setsecond_wallet(val1);
        setfirst_wallet_name(name2);
        setsecond_wallet_name(name1);

    }
    

    const handelSubmit = (e) => {
        e.preventDefault();

        const trn = {
            sender_wallet: first_wallet,
            receiver_wallet: second_wallet,
            amount: trensferAmount,
            token_id: trnsferId,
        }
        // console.log('ghjk');
        dispatch(transfer_user_assets(trn));
        onCloseModal()
    }


const [walletArr ,setwalletArr] = useState(0)
    useEffect(() => {
        dispatch(getUserDetails())
        // dispatch(getBanners())
        // dispatch(get_token_price_as_token());
        dispatch(get_wallet_details())

    }, []);
    const { langchange } = LangState();
    const classes = useStyles();
    useEffect(() => {
        setINR(wallet_details?.prices?.inr); 
        setBTC(wallet_details?.prices?.btc);
        setUSD(wallet_details?.prices?.usd);
        setBNB(wallet_details?.prices?.bnb);
        setFXC(wallet_details?.prices?.FXC);
        setwalletArr(wallet_details?.prices);
        // res_cal();
        var allBalanceTotal = wallet_details?.wallets?.main_wallet?.balance + wallet_details?.wallets?.trading_wallet?.balance +wallet_details?.wallets?.funding_wallet?.balance;
        setallBalance(allBalanceTotal);
        setmainTotal(wallet_details?.wallets?.main_wallet?.balance);
        settradingTotal(wallet_details?.wallets?.trading_wallet?.balance);
        setfundingTotal(wallet_details?.wallets?.funding_wallet?.balance);
        coinDrop();
    },[wallet_details,coinType]);
    
    // console.log('wallet_details',wallet_details?.prices);

    const  coinDrop = () =>{
        if(walletArr != undefined){
           var newarr =  Object.entries(walletArr)

           return newarr?.map((val) => {
                return  <a  onClick={() => {setcoinType(val?.[0].toLocaleUpperCase());setcoinPrice(val?.[1])}}> Calculate by {val?.[0].toLocaleUpperCase()} </a>

            })
        
        }
    }

    const cal_bbalance = (val) =>{
		
		dispatch(get_swap_balance_wallet_type({tokenName: val,wallet_type:first_wallet}));
	}
    // const res_cal = () =>{
        
    //     var main_Total = 0; ;
    //     wallet_details?.main_wallet?.map((val) => {
    //         if(token_prices?.[coinName[0][val?.tokenname]]?.[coinType] != undefined){
    //             main_Total = main_Total+val?.balance*token_prices?.[coinName[0][val?.tokenname]]?.[coinType];
    //             setmainTotal(main_Total)

    //         }
    //     })
    //     var trading_Total = 0;
    //     wallet_details?.trading_wallet?.map((val) => {
    //         if(token_prices?.[coinName[0][val?.tokenname]]?.[coinType] != undefined){
    //             trading_Total = trading_Total+val?.balance*token_prices?.[coinName[0][val?.tokenname]]?.[coinType];
    //             settradingTotal(trading_Total)

    //         }
    //     })
    //     var funding_Total = 0;
    //     wallet_details?.funding_wallet?.map((val) => {
    //         if(token_prices?.[coinName[0][val?.tokenname]]?.[coinType] != undefined){
    //             funding_Total = funding_Total+val?.balance*token_prices?.[coinName[0][val?.tokenname]]?.[coinType];
    //             setfundingTotal(funding_Total)

    //         }
    //     })


    // }

    return (
        <>
            <section>
                <div className={classes.assetsBtn} >
                    <div className={classes.btnDesign}>
                        <ul className={classes.btnUl}>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/assets' style={{ color: 'rgb(40 205 149)' }}> {assetsnav['0']['Assets'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/mainAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Main'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/tradeAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Trading'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/marginAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Margin'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                        </ul>
                        <ul className={classes.btnUl}>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/swap-currency' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['swap'][langchange]}</Link>
                                        <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/depositassest' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/withdrawAssets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Withdraw'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/record' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button>
                            {/* <button className={classes.btnTag}>
                                <span className={classes.MuiButtonlabel}>
                                    <li>
                                        <Link to='/vendor/bonus' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Bonus'][langchange]}</Link>
                                    </li>
                                </span>
                                <span className={classes.MuiTouchRippleroot}></span>
                            </button> */}
                        </ul>
                    </div>
                </div>
                <div className={classes.sectionBox}>
                    <div className={classes.sectionWrapper}>
                        <div>
                            <div className={classes.sectionHeading}>
                                {assetsnav['0']['Assets'][langchange]}
                                <span className={classes.headerEye}><i className="fa fa-eye" aria-hidden="true"></i></span>
                            </div>
                            <div className={classes.middlebtn} style={{ justifyContent: 'space-between', marginBottom: '24px' }}>
                                <div>
                                    <div className={classes.middlebtn} >
                                        <img src={assetsSide} style={{ marginRight: '8px' }} />
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ position: 'relative' }}>
                                                <div className={classes.middleCoinName}>
                                                    <div>
                                                        <span className={classes.coin0}>{allBalance ? (allBalance * coinPrice).toFixed(2) : '0'}</span>
                                                        <span className={classes.jss3694}>{coinType}</span>
                                                    </div>
                                                    {/* <span className={classes.jss3695}>
                                                        <span>≈ 0</span>
                                                        <span style={{ marginLeft: '4px' }}>AUD</span>
                                                    </span> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='makeStyles-selectCoin___1SVoA-17'>
                                            <span style={{ color: 'rgba(1,8,30,.87)', transition: 'all .2s' }}>{asstespage['0']['btns'][langchange]} {coinType}</span>
                                            <span className={classes.arrow___2S8Lc}></span>

                                            <div className='dropdown_selectcoin shadow'>
                                                    {wallet_details?.prices ?
                                                        coinDrop() : ' '
                                                    }
                                                {/* <a  onClick={() => {setcoinType('USDT');setcoinPrice(USD)}}> Calculate by USDT </a>
                                                <a onClick={() => {setcoinType('BTC');setcoinPrice(BTC)}}> Calculate by BTC </a>
                                                <a onClick={() => {setcoinType('INR');setcoinPrice(INR)}}> Calculate by INR </a>
                                                <a onClick={() => {setcoinType('BNB');setcoinPrice(BNB)}}> Calculate by BNB </a>
                                                <a onClick={() => {setcoinType('FXC');setcoinPrice(FXC)}}> Calculate by FXC </a> */}

                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div>
                                    <div className={classes.jss179}></div>
                                    <div className={classes.jss178}>
                                        <a className='m-2'>
                                            <button className={classes.jss53} >
                                                <Link to='/vendor/depositassest' className='text-white'><span className={classes.MuiButtonlabel}>{assetsnav['0']['Deposit'][langchange]}</span>
                                                    <span className={classes.MuiTouchRippleroot}></span></Link>
                                            </button>
                                        </a>
                                        {/* <a className='m-2'>
                                            <Link to='/vendor/swap-currency' className='text-white'>
                                                <button className={classes.jss53_btn} >
                                                    <span className={classes.MuiButtonlabel}>{'Swap '+TOKEN_PROJECT_NAME}</span>
                                                    <span className={classes.MuiTouchRippleroot}></span>
                                                </button>
                                            </Link>
                                        </a> */}
                                        <a className='m-2'>
                                            <button className={classes.jss53_btn} >
                                                <Link to='/vendor/withdrawAssets' className='' style={{ color: 'rgb(40 205 149)' }}> <span className={classes.MuiButtonlabel}>{assetsnav['0']['Withdraw'][langchange]}</span></Link>
                                                <span className={classes.MuiTouchRippleroot}></span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='coin__area'>
                                <div className='row m-0'>
                                    <div className='col-xl-2 col-lg-4 col-4 add___padding__S'>
                                        <div className='coin-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className='mb-2'> <span>BTC</span>/<small>USDT</small> </h5>
                                                    <h6 className='value__s text-danger'>-1.5% </h6>
                                            </div>
                                            <div className='price__coin'>
                                                <h3> 1</h3>
                                            </div>
                                            <h6 className='dollat__rs'>$<span>19,173.21</span> </h6>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-4 add___padding__S'>
                                        <div className='coin-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className='mb-2'> <span>ETH</span>/<small>USDT</small> </h5>
                                                    <h6 className='value__s text-danger'>-1.5% </h6>
                                            </div>
                                            <div className='price__coin'>
                                                <h3> 1</h3>
                                            </div>
                                            <h6 className='dollat__rs'>$<span>1,295.69</span> </h6>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-4 add___padding__S'>
                                        <div className='coin-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className='mb-2'> <span>BNB</span>/<small>USDT</small> </h5>
                                                    <h6 className='value__s text-success'>+1.5% </h6>
                                            </div>
                                            <div className='price__coin'>
                                                <h3> 1</h3>
                                            </div>
                                            <h6 className='dollat__rs'>$<span>272.52</span> </h6>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-4 add___padding__S'>
                                        <div className='coin-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className='mb-2'> <span>USDC</span>/<small>USDT</small> </h5>
                                                    <h6 className='value__s text-danger'>-1.5% </h6>
                                            </div>
                                            <div className='price__coin'>
                                                <h3> 1</h3>
                                            </div>
                                            <h6 className='dollat__rs'>$<span>1.00</span> </h6>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-4 add___padding__S'>
                                        <div className='coin-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className='mb-2'> <span>SOL</span>/<small>USDT</small> </h5>
                                                    <h6 className='value__s text-danger'>-1.5% </h6>
                                            </div>
                                            <div className='price__coin'>
                                                <h3> 1</h3>
                                            </div>
                                            <h6 className='dollat__rs'>$<span> 29.29</span> </h6>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-4 add___padding__S'>
                                        <div className='coin-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className='mb-2'> <span>DOT</span>/<small>USDT</small> </h5>
                                                    <h6 className='value__s text-success'>+1.1%</h6>
                                            </div>
                                            <div className='price__coin'>
                                                <h3> 1</h3>
                                            </div>
                                            <h6 className='dollat__rs'>$<span>6.12</span> </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-0' style={{ position: 'relative' }}>
                                <div style={{ position: 'relative', width:'100%' }}>
                                    <div className={classes.jss174}>
                                        <div className='col-xl-4 col-md-6 padding__none__sm'>
                                            <div className={classes.jss1261} style={{ borderTop: '2px solid rgb(51, 198, 170)' }}>
                                                <div className={classes.jss234}>
                                                    <Link to='/vendor/mainAccount'>

                                                    <span style={{ fontWeight: '500' }}> {assetsnav['0']['Main'][langchange]}</span>
                                                    <span className={classes.arrow___2S8Lc}></span>
                                                    </Link>

                                                    <div style={{ marginTop: '7px' }}>
                                                        <div className={classes.middleCoinName}>
                                                            <div>
                                                                <span className={classes.coin0}>{mainTotal ? (mainTotal * coinPrice).toFixed(2) : '0'}</span>
                                                                <span className={classes.jss3694}>{coinType}</span>
                                                            </div>
                                                            {/* <span className={classes.jss3695}>
                                                                <span>≈ 0</span>
                                                                <span style={{ marginLeft: '4px' }}>AUD</span>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '18px' }}>
                                                        <span className={classes.jss1264} onClick={() => {onOpenModal('main_wallet');setfirst_wallet_name('Main Account')}}>{asstespage['0']['Transfer'][langchange]} </span>
                                                        <hr style={{ height: '0.9em', margin: '0px 8px', display: 'inline-block', }} />
                                                        {/* <span className={classes.jss1264}> {asstespage['0']['Lend'][langchange]} </span> */}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-md-6 padding__none__sm'>
                                            <div className={classes.jss1261} style={{ borderTop: '2px solid rgb(99, 169, 240)' }} >
                                                <div className={classes.jss234}>
                                                    <Link to='/vendor/tradeAccount'>
                                                        <span style={{ fontWeight: '500' }}> {assetsnav['0']['Trading'][langchange]}</span>
                                                        <span className={classes.arrow___2S8Lc}></span>
                                                    </Link>
                                                    <div style={{ marginTop: '7px' }}>
                                                        <div className={classes.middleCoinName}>
                                                            <div>
                                                                <span className={classes.coin0}>{tradingTotal ? (tradingTotal * coinPrice).toFixed(2) : 0}</span>
                                                                <span className={classes.jss3694}>{coinType}</span>
                                                            </div>
                                                            {/* <span className={classes.jss3695}>
                                                                <span>≈ 0</span>
                                                                <span style={{ marginLeft: '4px' }}>AUD</span>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '18px' }}>
                                                        <span className={classes.jss1264} onClick={() => {onOpenModal('trading_wallet');setfirst_wallet_name('Trading Account')}}>{asstespage['0']['Transfer'][langchange]}</span>
                                                        <hr style={{ height: '0.9em', margin: '0px 8px', display: 'inline-block', }} />
                                                        {/* <span className={classes.jss1264}>{asstespage['0']['Lend'][langchange]}  </span> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-md-6 padding__none__sm'>
                                            <div className={classes.jss1261} style={{ borderTop: '2px solid rgb(144, 174, 201)' }}>
                                                <div className={classes.jss234}>
                                                    <Link to='/vendor/marginAccount'>
                                                        <span style={{ fontWeight: '500' }}> Funding Account</span>
                                                        <span className={classes.arrow___2S8Lc}></span>
                                                    </Link>
                                                    <div style={{ marginTop: '7px' }}>
                                                        <div className={classes.middleCoinName}>
                                                            <div>
                                                                <span className={classes.coin0}>{fundingTotal ? (fundingTotal * coinPrice).toFixed(2) : 0}</span>
                                                                <span className={classes.jss3694}>{coinType}</span>
                                                            </div>
                                                            {/* <span className={classes.jss3695}>
                                                                <span>≈ 0</span>
                                                                <span style={{ marginLeft: '4px' }}>AUD</span>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '18px' }}>
                                                        <span className={classes.jss1264} onClick={() => {onOpenModal('funding_wallet');setfirst_wallet_name('Funding Account')}} >{asstespage['0']['Transfer'][langchange]}</span>
                                                        <hr style={{ height: '0.9em', margin: '0px 8px', display: 'inline-block', }} />
                                                        {/* <span className={classes.jss1264}>{asstespage['0']['Lend'][langchange]}  </span> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        {/* <div className='col-xl-4 col-md-6 padding__none__sm'>
                                            <div className={classes.jss1261} style={{  borderTop: '2px solid rgb(203, 223, 240)' }}>
                                                <div className={classes.jss234}>
                                                    <Link to='/vendor/financialAccount'>
                                                        <span style={{ fontWeight: '500' }}> {assetsnav['0']['Financial'][langchange]}</span>
                                                        <span className={classes.arrow___2S8Lc}></span>
                                                    </Link>
                                                    <div style={{ marginTop: '7px' }}>
                                                        <div className={classes.middleCoinName}>
                                                            <div>
                                                                <span className={classes.coin0}>0</span>
                                                                <span className={classes.jss3694}>BTC</span>
                                                            </div>
                                                            <span className={classes.jss3695}>
                                                                <span>≈ 0</span>
                                                                <span style={{ marginLeft: '4px' }}>AUD</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '18px' }}>
                                                        <span className={classes.jss1264}>{asstespage['0']['Transfer'][langchange]}</span>
                                                        <hr style={{ height: '0.9em', margin: '0px 8px', display: 'inline-block', }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.jss300} style={{ marginTop: '24px' }}>

                                <div className={classes.jss295} style={{ justifyContent: 'space-between', }}>
                                    <div className={classes.jss301}>{asstespage['0']['faq'][langchange]}   </div>
                                    <a className={classes.jss302}>
                                        <span style={{ marginRight: '4px' }}>View more <i className="fas fa-angle-right"></i></span>
                                    </a>
                                </div>
                                <div style={{ marginTop: '24px' }}>
                                    <div className={classes.jss303}><i className="fas fa-circle" style={{ paddingRight: '5px', color: 'rgb(40 205 149)' }}></i>{asstespage['0']['h1'][langchange]}</div>
                                    <div className={classes.jss304}>
                                        <span>{asstespage['0']['p1'][langchange]}</span>
                                    </div>
                                </div>
                                <div style={{ marginTop: '48px' }}>
                                    <div className={classes.jss303}><i className="fas fa-circle" style={{ paddingRight: '5px', color: 'rgb(40 205 149)' }}></i>{asstespage['0']['h2'][langchange]}</div>
                                    <div className={classes.jss304}>
                                        <span>{asstespage['0']['p2'][langchange]}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal open={open} onClose={onCloseModal} animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design', }} center>
                <div className='container'>
                    <div className='row deposit-modal'>
                        <div className='col-md-12'>
                            <h2>{first_wallet_name}</h2>
                            {/* <div className='p2p-pay-system'><p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p></div> */}


                            <div className='mt-3'>
                                <form onSubmit={handelSubmit} id='mainForm' method='post'>
                                    <div className='row'>
                                        <div className='col-md-6 ' style={{ position: 'relative' }}>
                                            <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                                <label>Direction:</label>

                                                <div class="input-group-prepend w-100">
                                                    <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                        aria-hidden="true"></i></span>

                                                    <input
                                                        type="text"
                                                        placeholder="Type to search list"
                                                        onChange={e => setsearch(e.target.value)}
                                                        onFocus={() => setdropdownVisible2(true)}
                                                        // onBlur={() => setdropdownVisible2(false)}
                                                        className='form-control my-0 py-1 w-100'
                                                        value={first_wallet_name}
                                                    />

                                                </div>
                                            </div>

                                            {dropdownVisible2 && (
                                                <div className="autocomplete-dropdown TEXT">
                                                    <ul className="autocomplete-search-results-list">
                                                        <li className="autocomplete-search-result" style={{ display: second_wallet == 'main_wallet' ? 'none' : 'block' }} onClick={() => sender_wallet('main_wallet', 'Main Account')}>{'Main Account'}</li>
                                                        <li className="autocomplete-search-result" style={{ display: second_wallet == 'funding_wallet' ? 'none' : 'block' }} onClick={() => sender_wallet('funding_wallet', 'Funding Account')}>{'Funding Account'}</li>
                                                        <li className="autocomplete-search-result" style={{ display: second_wallet == 'trading_wallet' ? 'none' : 'block' }} onClick={() => sender_wallet('trading_wallet', 'Trading Account')}>{'Trading Account'}</li>

                                                    </ul>
                                                </div>
                                            )}
                                            <img src={transferdata} className='transfer_img' onClick={() => flipValue(first_wallet, first_wallet_name, second_wallet, second_wallet_name)} />
                                        </div>
                                        <div className='col-md-6'>
                                            <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                                <label style={{ visibility: 'hidden' }}>cc</label>
                                                <div class="input-group-prepend w-100 ">
                                                    <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                        aria-hidden="true"></i></span>

                                                    <input
                                                        type="text"
                                                        placeholder="Type to search list"
                                                        onChange={e => setsearch(e.target.value)}
                                                        onFocus={() => setdropdownVisible1(true)}
                                                        // onBlur={() => setdropdownVisible1(false)}
                                                        className='form-control my-0 py-1 w-100'
                                                        value={second_wallet_name}
                                                    />

                                                </div>
                                            </div>

                                            {dropdownVisible1 && (
                                                <div className="autocomplete-dropdown TEXT">
                                                    <ul className="autocomplete-search-results-list">

                                                        <li className="autocomplete-search-result" style={{ display: first_wallet == 'main_wallet' ? 'none' : 'block' }} onClick={() => receiver_wallet('main_wallet', 'Main Account')}>{'Main Account'}</li>
                                                        <li className="autocomplete-search-result" style={{ display: first_wallet == 'funding_wallet' ? 'none' : 'block' }} onClick={() => receiver_wallet('funding_wallet', 'Funding Account')}>{'Funding Account'}</li>
                                                        <li className="autocomplete-search-result" style={{ display: first_wallet == 'trading_wallet' ? 'none' : 'block' }} onClick={() => receiver_wallet('trading_wallet', 'Trading Account')}>{'Trading Account'}</li>

                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                        <label>Coin</label>

                                        <div class="input-group-prepend w-100">
                                            <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                aria-hidden="true"></i></span>

                                            <input
                                                type="text"
                                                placeholder="Select Coin"
                                                onChange={e => setsearch(e.target.value)}
                                                onFocus={() => setdropdownVisible(true)}
                                                // onBlur={() => setdropdownVisible(false)}
                                                className='form-control my-0 py-1 w-100'
                                                value={trnsferIdname}
                                            />

                                        </div>
                                    </div>

                                    {dropdownVisible && (
                                        <div className="autocomplete-dropdown TEXT w-75">
                                            <ul className="autocomplete-search-results-list w-100" >
                                                {assetsData?.assets?.map((val) => {

                                                    return (
                                                        <li className="autocomplete-search-result" onClick={() => { settrnsferId(val?.token_id?.[0]); setdropdownVisible(false); settrnsferIdname(val?.tokenname);cal_bbalance(val?.tokenname) }}>{val?.tokenname}</li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}







                                    <div className="form-group">
                                        <label htmlFor="amount" >Amount: <span className='text-success'>{swap_balance?.balance}</span></label>
                                        <input type='text' className='form-control' onChange={(e) => settrensferAmount(e.target.value)} value={trensferAmount} placeholder='Amount' required />
                                    </div>
                                    {/* feild{?.template?.map((row,ii) => {
                                return  (
                                    <>
                                        
                                    </>
                                );
                            })} */}

                                    <div className="form-group text-center">
                                        <button type="submit" style={{ backgroundColor: 'rgb(40 205 149)', padding: '5px 18px', fontSize: '18px' }} className=' submit__btn btn text-white' name="submit" >Submit</button>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Assets