import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const VipPrevilege = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2 >   VIP PREVILEGE</h2>
            <h3 className='text-white mb-0'>{PROJECT_NAME} VIP Program</h3>
            <p className='mt-0'> Enjoy exclusive benefits all year round — from discounted trading fees to non-stop events!</p>
            <h3 className='text-white'>Apply for VIP</h3>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        <div class="about_story">
          <div className='row m-0 align-items-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner_story_top'>
                <img className='img-about-1' src={Ab} width='100%' alt='vipPrevilege/img' />
                <img src={abcd} width='100%' alt='vipPrevilege/img' />
              </div>
              <div className='banner_story_top-2'>
                <img className='img-about-1' src={abc} width='100%' alt='vipPrevilege/img' />
                <img src={abcde} width='100%' alt='vipPrevilege/img' />
              </div>
            </div>
            <div className='col-lg-7 col-md-6'>
              <div className='about-story-text about-page'>
                <h3 className='mb-0'> Welcome Gift</h3>
                 <ul className='ml-3'> 
                    <li><p>As a new member, we have a special gift prepared for you.</p></li>
                    <li><p>Claim Gift</p></li>
                    <li><p>VIP tiers are shared across Derivatives, Spot, USDC Perpetual and USDC Options Trading. Fulfill any one of our requirements to enjoy all fee discounts.</p></li>
                    <li><p>VIP Trading Benefits:</p></li>
                    <li><p>If you meet {PROJECT_NAME} VIP requirements, you can contact your account manager to obtain the following benefits If you meet the VIP conditions of others platform, provide its screenshots and related vouchers, you can share the benefits of our VIP benefits</p></li>

                 </ul>
                 <h3 className='mb-0'> Premier exclusive fees</h3>
                 <ul className='ml-3'> 
                    <li><p>First deposit above 20BTC or other equivalent valued assets, then you may get VIP rights. You can apply for exclusive trading fees Free of exemption for three months VIP with level 11 and above can apply for professional tradidng fee rates to gain more trading opportunities Consult your key account manager for details</p></li>
                    <li><p>Claim Gift</p></li>
                    <li><p>{PROJECT_NAME} new product premier experience right</p></li>
                    <li><p>Experience the new product, get {PROJECT_NAME} variety of newly products experience rights</p></li>

                 </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row'>
          <div className='col-md-6'>
              <div className='about-story-text about-page'>
                <h3 className='mb-0'> Exclusive Colo Service</h3>
                 <ul className='ml-3'> 
                    <li><p>Apply for Colocation (server hosting) customized service, more fast transaction response, sharing high-quality API limiting frequency</p></li>
                  </ul>
                 <h3 className='mb-0'> Exclusive key account manager & VIP line</h3>
                 <ul className='ml-3'> 
                    <li><p>{PROJECT_NAME} configures an exclusive key account manager and API technical services for each vip. One on one service helps you to solve your trading troubles.</p></li>
                 </ul>
                 <h3 className='mb-0'> C2C Loan preferential service</h3>
                 <ul className='ml-3'> 
                    <li><p>Efficient and convenient large-amount borrowing, covering multiple currencies, helping you to improve capital utilization</p></li>
                  </ul>
                 <h3 className='mb-0'> Exclusive withdrawal limits and channels</h3>
                 <ul className='ml-3'> 
                    <li><p>Higher withdrawal limit and faster deposit and withdrawal channels helps you to capture and obtain cross-market trading opportunities firstly</p></li>
                 </ul>
                 <h3 className='mb-0'> Synchronization of historical market data</h3>
                 <ul className='ml-3'> 
                    <li><p>You can apply for {PROJECT_NAME} historical market data synchronization to provide all-round support for your trading strategy development and market research</p></li>
                 </ul>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about1} width='100%' alt='vipPrevilege/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1 m-sm-5'>
          <div className='text-center m-4'><h2>VIP CAMPAIGNS DETAIL</h2><h4 className='mt-0'>VIP Privilege Added-Value Benefits</h4></div>
          <div className='row m-0 align-items-center First_row'>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>
                 <ol className='ml-3'> 
                    <li><p>Exclusive market/product updated weekly report</p></li>
                    <li><p>Regular {PROJECT_NAME} market/product update to help you get latest news</p></li>
                    <li><p>Exclusive Birthday Gift</p></li>
                    <li><p>{PROJECT_NAME} exclusive custom gift on the memorable days</p></li>
                    <li><p>Limited Customize gifts</p></li>
                    <li><p>Limited {PROJECT_NAME} customized tea sets, air purifiers... will give you different surprises on festivals</p></li>
                    <li><p>VIP Customer travel plan</p></li>

                  </ol>
                 
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>
                <ol start="8" className='ml-3'> 
                    <li><p>Get a relaxing trip with your family or other VIPs...</p></li>
                    <li><p>Private Banquet for VIP Clients</p></li>
                    <li><p>Exclusive dinners banquet from time to time</p></li>
                    <li><p>Variety of exclusive activities</p></li>
                    <li><p>Sea yacht party, Texas Hold’em game, wine tasting, gourmet buffet...</p></li>
                    <li><p>Industry Salon</p></li>
                    <li><p>Gather with {PROJECT_NAME} experts and industry leaders to analyze industry hot topics, exchange strategies and experience, and share special experiences</p></li>

                  </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default VipPrevilege