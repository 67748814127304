import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import miningbanner from '../../../../_assets/images/newImages/miningbanner.png';
import btc from '../../../../_assets/images/btc.png';
import mining5 from '../../../../_assets/images/mining5.png';
import mining6 from '../../../../_assets/images/mining6.png';
import mining7 from '../../../../_assets/images/mining7.png';
import { poolminingpage, elcMiningpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import axios from 'axios';
import { CryptoState } from '../../../../CryptoContext';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

const Miningpool = () => {
    const { langchange } = LangState();

    const [historicData, sethistoricalData] = useState([]);
    const [days, setDays] = useState(1);
    const { currency, symbol } = CryptoState();
    const currency2 = 'INR';

    const fatchHistoricalChart = async () => {
        const { data } = await axios.get(`https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=INR&days=${days}`);
        sethistoricalData(data.prices);
    }

    useEffect(() => {
        fatchHistoricalChart();
    }, [days]);

    const coin = 'bitcoin';

    const labels = historicData.map((coin) => {
        let date = new Date(coin[0]);
        let time =
            date.getHours() > 12
                ? `${date.getHours() - 12}:${date.getMinutes()} PM`
                : `${date.getHours()}:${date.getMinutes()} AM`;
        return days === 1 ? time : date.toLocaleDateString();
    })

    const data1 = {
        labels,
        datasets: [

            {
                data: historicData.map((coin) => coin[1]),
                label: `Price ( Past ${days} Days ) in ${currency}`,
                borderColor: "#EEBC1D",
            },

        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };



    const tablechart = (id) => {
        const navDash = document.getElementById(id).className;
        if (navDash === 'mining-sec-3-chart') {
            document.getElementById("tablechart").className = "mining-sec-3-chart active";
        }
        else {
            document.getElementById('tablechart').className = 'mining-sec-3-chart';
        }
    }
    return (
        <div>
            <div className="Fast-trade">
                <div className="container-fast">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/miningpool"> {poolminingpage['0']['nav']['btn1'][langchange]}  </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/MiningDashboard"> {poolminingpage['0']['nav']['btn2'][langchange]}  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/miningOverview">  {poolminingpage['0']['nav']['btn3'][langchange]} </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/miningSetting">  {poolminingpage['0']['nav']['btn4'][langchange]} </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div className='row m-0 '>
                    <div className='col-md-12'>
                        <div className='miningpool-sec'>
                            <img className='miningpool-banner_img' src={miningbanner} />
                        </div>
                    </div>
                </div>

                <div className='miningPool '>
                    <div className='row m-0'>
                        <div className='col-lg-12'>
                            <ol className='d-flex ' >
                                <li>Home /</li>
                                <li className='ml-2' style={{ color: ' rgb(40 205 149)' }}>Mining Pool</li>
                            </ol>
                        </div>

                        <div className='col-lg-12 d-flex align-items-center' >
                            <div className='row w-100  m-0'>
                                <div className='col-lg-4 p-0 '>
                                    <div className='h-100 w-100 d-flex align-items-center  '>
                                        <span ><i class='fas fa-volume-up'></i></span>
                                        <span className='border-right border-lightgray text-nowrap'><marquee className='mx-4' direction="up" scrollamount="1">{poolminingpage['0']['sec2']['p'][langchange]} </marquee></span>
                                    </div>
                                </div>
                                <div className='col-lg-8 button-inner p-0'>
                                    <div className='row p-0 m-0 w-100 mining-sec-2'>
                                        <div className='col-lg-1'>
                                            <span className='mining-sec-2   '><a className='mining-sec-2-a  h-100 w-100  text-center d-flex align-items-center justify-content-center ' href=''>{poolminingpage['0']['sec2']['all'][langchange]}</a></span>
                                        </div>
                                        <div className='col-lg-4 col-sm-4 '>
                                            <button className='mining-sec-2-button h-100 w-100'> <i class="fa fa-bolt mx-2" aria-hidden="true"></i>{poolminingpage['0']['sec2']['btn1'][langchange]}</button>
                                        </div>
                                        <div className='col-lg-3 col-sm-4 '>
                                            <button className='mining-sec-2-button  h-100 w-100' ><i class='fab fa-dyalog mx-2'></i> {poolminingpage['0']['sec2']['btn2'][langchange]}</button>
                                        </div>
                                        <div className='col-lg-4 col-sm-4 '>
                                            <button className='mining-sec-2-button  h-100 w-100'><i class="fa fa-cog mx-2" aria-hidden="true"></i> {poolminingpage['0']['sec2']['btn3'][langchange]}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0 mt-5 '>
                        <div className='col-md-12 text-center'>
                            <div className='mining-sec-3 table-responsive'>

                                <table className='table table-hover'  style={{ width: '1202px'}}>
                                    <thead>
                                        <tr className=''>
                                            <th className='text-left'>{poolminingpage['0']['sec2']['lable1'][langchange]}</th>
                                            <th>{poolminingpage['0']['sec2']['lable2'][langchange]}</th>
                                            <th>{poolminingpage['0']['sec2']['lable3'][langchange]}</th>
                                            <th>{poolminingpage['0']['sec2']['lable4'][langchange]}</th>
                                            <th>{poolminingpage['0']['sec2']['lable5'][langchange]}</th>
                                            <th className='text-left'> {poolminingpage['0']['sec2']['lable6'][langchange]}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-left'><img src={btc} width='35' className='mr-3' />BTC/BCH</td>
                                            <td>SH345G</td>
                                            <td>67.8<span>PH/s</span></td>
                                            <td>123.1<span>EH/s</span></td>
                                            <td>FPPS</td>
                                            <td className='text-left tutorial'><a href=''><i class="fa fa-book"></i> {poolminingpage['0']['sec2']['lable6'][langchange]} </a>
                                                <button onClick={() => { tablechart('tablechart') }}><i class="fa fa-angle-down "></i></button></td>
                                        </tr>
                                        <tr className='mining-sec-3-chart active' id='tablechart' >
                                            <td colspan='6'>
                                                <div className='row' style={{ background: '#f2f2f4', width: '100%' }}>
                                                    <div className='col-md-7'>
                                                        <div className='bg-white m-4'>
                                                            <Line options={{
                                                                elements: {
                                                                    point: {
                                                                        radius: 1,
                                                                    },
                                                                },
                                                            }} data={data1} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div className='chart'>
                                                            <div className='box'>
                                                                <p>Coin</p>
                                                                <span><span>BTC</span><i class="fa fa-angle-down "></i></span>
                                                            </div>
                                                            <div className='box'>
                                                                <p>Mining Fee</p>
                                                                <span>2 %</span>
                                                            </div>
                                                        </div>
                                                        <div className='chart'>
                                                            <div className='box'>
                                                                <p>Network Difficulty</p>
                                                                <span><span>27.55 T</span></span>
                                                            </div>
                                                            <div className='box'>
                                                                <p>24H Estimated Mining Income</p>
                                                                <span>0.00000461 BTC/TH</span>
                                                            </div>
                                                        </div>
                                                        <div className='chart'>
                                                            <div className='box'>
                                                                <p>Settlement Method</p>
                                                                <span><span>02:00 - 14:00 every day (UTC)</span></span>
                                                            </div>
                                                            <div className='box'>
                                                                <p>Settlement Period</p>
                                                                <span>00:00 T-1 day - 00:00 T day (UTC)</span>
                                                            </div>
                                                        </div>
                                                        <div className='chart'>
                                                            <div className='box'>
                                                                <p>Income Payment Time</p>
                                                                <span><span>02:00 - 14:00 every day (UTC)</span></span>
                                                            </div>
                                                            <div className='box'>
                                                                <p>Mine BTC</p>
                                                                <span>Mining to get VCASH </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                        <td className='text-left'><img src={btc} width='35' className='mx-2' />BTC/BCH</td>
                                        <td>SH345G</td>
                                        <td>67.8<span>PH/s</span></td>
                                        <td>123.1<span>EH/s</span></td>
                                        <td>FPPS</td>
                                        <td className='text-left tutorial'><a href=''><i class="fa fa-book"></i> Tutorial </a>
                                        <button><i class="fa fa-angle-down "></i></button></td>
                                    </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-md-12 text-center'>
                            <div className='mining-sec-4'>
                                <div className='fppf'>
                                    <h1>{poolminingpage['0']['sec3']['h1'][langchange]}</h1>
                                </div>
                                <div className='fppf'>
                                    <p>{poolminingpage['0']['sec3']['p1'][langchange]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='mining-sec-5'>
                                <div className='box'>
                                    <div className='box-img'>
                                        <img src={mining6} />
                                    </div>
                                    <h4>{poolminingpage['0']['sec3']['box1']['h1'][langchange]}</h4>
                                    <p>{poolminingpage['0']['sec3']['box1']['p'][langchange]}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mining-sec-5'>
                                <div className='box'>
                                    <div className='box-img' >
                                        <img src={mining6} />
                                    </div>
                                    <h4>{poolminingpage['0']['sec3']['box2']['h1'][langchange]}</h4>
                                    <p>{poolminingpage['0']['sec3']['box2']['p'][langchange]}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mining-sec-5'>
                                <div className='box'>
                                    <div className='box-img' >
                                        <img src={mining6} />
                                    </div>
                                    <h4>{poolminingpage['0']['sec3']['box3']['h1'][langchange]}</h4>
                                    <p>{poolminingpage['0']['sec3']['box3']['p'][langchange]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-md-12'>
                            <div className='mining-sec-6'>
                                <div className='text-center'>
                                    <h1 className='mining-sec-6-h1'>VIP Channel</h1>
                                </div>
                                <form >
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div class="form-group">
                                                <label for="PHONE">Phone *</label>
                                                <input type="numder" class="form-control" id="PHONE" />
                                            </div>
                                            <div class="form-group">
                                                <label for="pwd">Coin *</label>
                                                <input type="text" class="form-control" id="pwd" />
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div class="form-group">
                                                <label for="pwd">Email *</label>
                                                <input type="text" class="form-control" id="pwd" />                                        </div>
                                            <div class="form-group">
                                                <label for="pwd">Hashrate *</label>
                                                <input type="text" class="form-control" id="pwd" />                                        </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <textarea id="w3review" name="w3review" rows="4" ></textarea>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <button type='button' className='btn' > Submit </button>
                                            <button type='button' className='btn btn-2' > Clear </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-md-12 p-0'>
                            <div className="QAA" >
                                <div className="QAA-container">
                                    <h3> FAQ </h3>
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                                    1.{poolminingpage['0']['faq']['h1'][langchange]}
                                                </a>
                                                <h6> <i class='fas fa-angle-down'></i>  </h6>
                                            </div>
                                            <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    {poolminingpage['0']['faq']['p1'][langchange]}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                                    2.{poolminingpage['0']['faq']['h2'][langchange]}
                                                </a>
                                                <h6> <i class='fas fa-angle-down'></i>  </h6>

                                            </div>
                                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    {poolminingpage['0']['faq']['p2'][langchange]}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                                                    3.{poolminingpage['0']['faq']['h3'][langchange]}
                                                </a>
                                                <h6> <i class='fas fa-angle-down'></i>  </h6>
                                            </div>
                                            <div id="collapsefour" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    {poolminingpage['0']['faq']['p3'][langchange]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                                    4.{poolminingpage['0']['faq']['h4'][langchange]}
                                                </a>
                                                <h6> <i class='fas fa-angle-down'></i>  </h6>

                                            </div>
                                            <div id="collapsefive" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    {poolminingpage['0']['faq']['p4'][langchange]}</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                                                    5.{poolminingpage['0']['faq']['h5'][langchange]}
                                                </a>
                                                <h6> <i class='fas fa-angle-down'></i>  </h6>
                                            </div>
                                            <div id="collapsesix" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>{poolminingpage['0']['faq']['p5'][langchange]} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Miningpool