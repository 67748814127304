import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import madel from '../../../_assets/images/madel.svg';
import visa from '../../../_assets/images/visa2.png';
import { LangState } from '../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {buy_p2p} from '../../../redux/actions/p2p.action';
import { toast } from '../../../components/toast';
import { submitBuyP2p } from '../../../redux/actions/p2p.action';
const P2p = () => {
    const {langchange} = LangState();
    const dispatch = useDispatch();
    const [token ,settoken] = useState('USDT');
    const [open, setOpen] = useState(false);
    const [firstCurrency, setfirstCurrency] = useState();
    const [secondCurrency, setsecondCurrency] = useState();
    const [upiId, setupiId] = useState();

    const [dropdownVisible,setdropdownVisible] = useState(false);
    const onOpenModal = (val) => {
        setOpen(true);
        setBuyData(val)
    };
	const onCloseModal = () => setOpen(false);
    const buy_p2p_data = useSelector(state => state.p2p.buy_p2p);
    // console.log('buy_p2p',buy_p2p_data);
    const [BuyData,setBuyData] = useState();
    const handleSubmitForm = ( ) => {

    }
    useEffect(() => {
        dispatch(buy_p2p());
    },[])
    console.log('BuyData',BuyData);

    const showRecords = () => {
        if(buy_p2p_data?.postings != undefined){
          return  buy_p2p_data?.postings.map((val) => {
                return(
                    <tr>
                        <td className="name"> <p> {val?.user_id?.[0]?.firstname?.slice(0, val?.user_id?.[0]?.firstname?.length + 1 - val?.user_id?.[0]?.firstname?.length)} {val?.user_id?.[0]?.lastname?.slice(0, val?.user_id?.[0]?.lastname?.length + 1 - val?.user_id?.[0]?.lastname?.length)} </p> 
                         {/* Best Rate USDT <img src={madel} />  */}
                         {val?.token_id?.[0]?.coinName}
                        </td>
                        <td className="text-center"> {val?.UserPaymentMethodID?.map((resVal,key) => {
                            return  resVal.PaymentMethodFields?.[key]?.value ? resVal.PaymentMethodFields?.[key]?.value +' ' :' ';
                         })}
                        {/* <img src={visa} />   */}
                        </td>
                        <td>  { val?.sold_quantity? val?.quantity - val?.sold_quantity : val?.quantity}   </td>
                        <td>  {val?.min_limit} ~ {val?.sold_quantity? val?.quantity - val?.sold_quantity : val?.quantity}  </td>
                       
                        <td>   {val?.price} {val?.sell_currency}/{val?.token_id?.[0]?.coinName}	</td>
                        <td className="p-0 text-right"> <button type="button" className="btn"  onClick={() => {onOpenModal(val);}}> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                    </tr>
                );
            })
        }
    }

    const firstCalcu = (val) => {
        setfirstCurrency(val);
        // if(val < BuyData.min_limit){
        //     toast.error('Please Enter Minimum Amount '+BuyData.min_limit + ' '+BuyData?.token_id?.[0]?.coinName)
        // }
        // var maxVal = BuyData?.sold_quantity? BuyData?.quantity - BuyData?.sold_quantity : BuyData?.quantity
        // if(val > maxVal){
        //     toast.error('Please Enter Maximum Amount '+ maxVal +' '+BuyData?.token_id?.[0]?.coinName)
        // }
        var data = BuyData.price * val;
        setsecondCurrency(data)
    }

    const secCalcu = (val) => {
        var data =  val / BuyData.price ;
        var min =  BuyData.min_limit * BuyData.price;
        var maxVal = BuyData?.sold_quantity?( BuyData?.quantity - BuyData?.sold_quantity) * BuyData.price : BuyData?.quantity *BuyData.price;

       
            setfirstCurrency(data);
            setsecondCurrency(val)
        
    }

    const firstError = () => {
        if(firstCurrency < BuyData.min_limit){
            toast.error('Please Enter Minimum Amount '+BuyData.min_limit + ' '+BuyData?.token_id?.[0]?.coinName)
        }
        var maxVal = BuyData?.sold_quantity? BuyData?.quantity - BuyData?.sold_quantity : BuyData?.quantity
        if(firstCurrency > maxVal){
            toast.error('Please Enter Maximum Amount '+ maxVal +' '+BuyData?.token_id?.[0]?.coinName)
        }
        var data = BuyData.price * firstCurrency;
        setsecondCurrency(data)
    }

    const secError = () => {
        var data =  secondCurrency / BuyData.price ;
        var min =  BuyData.min_limit * BuyData.price;
        var maxVal = BuyData?.sold_quantity?( BuyData?.quantity - BuyData?.sold_quantity) * BuyData.price : BuyData?.quantity *BuyData.price;

        if(min > secondCurrency){
            toast.error('Please Enter Minimum Amount '+ min + ' '+ BuyData?.sell_currency);
            // setsecondCurrency(min);
            // setsecondCurrency(min/BuyData.price);

        }else if(secondCurrency > maxVal){

            toast.error('Please Enter Maximum Amount '+ maxVal +' '+ BuyData?.sell_currency);
            // setsecondCurrency(maxVal);
            // setfirstCurrency(maxVal/BuyData.price);
        }
    }


    console.log('BuyData',BuyData);

    const  onhandelsubmit = (e) =>{
        e.preventDefault();
        var submitdata = {
            posting_id : BuyData._id,
            quantity:firstCurrency,
            payment_method_id:upiId,
        }
        dispatch(submitBuyP2p(submitdata,setOpen));
        // console.log(submitdata);
    }
    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast.info('copied : ' + text);

    }

  return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-new-style">
            <div className="tab-container">
                <div className="left-sidebar" id="left-sidebar">
                    <ul className="nav flex-column"> 
                        <p> P2P </p>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange] } </Link>
                        </li>
                        <p> {p2ppage['0']['other'][langchange] } </p>
                        <li className="nav-item">
                            <a className="nav-link" href="#"> {p2ppage['0']['support'][langchange] } </a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange] }  </Link>
                        </li> 
                    </ul>
                </div>
                <div className="right-tab">
                    <div className="tab-header d-flex w-100 justify-content-between">
                        <div className=''>
                            <ul className="nav" role="tab">
                                <li className="nav-item" id="more" > 
                                    <a className={token == 'USDT'? "nav-link active" :'nav-link'} data-toggle="tab" onClick={() => {settoken('USDT')}} > USDT </a>
                                </li>
                                <li className="nav-item"> 
                                    <a className={token == 'BTC'? "nav-link active" :'nav-link'} data-toggle="tab" onClick={() => {settoken('BTC')}}>   STA </a>
                                </li>
                                {/* <li className="nav-item"> 
                                    <a className={token == 'ETH'? "nav-link active" :'nav-link'} data-toggle="tab" onClick={() => {settoken('ETH')}}>   ETH </a>
                                </li>
                                
                                <li className="nav-item"> 
                                    <a className={token == 'USDC'? "nav-link active" :'nav-link'} data-toggle="tab" onClick={() => {settoken('USDC')}}>  USDC </a>
                                </li> */}
                            </ul>
                        </div>
                        <div className='p2p-currncy-dropdown'>
                            <select style={{border:'none',fontSize:'16px' ,padding:'0px !importent',outline:'none'}}>
                                <option disabled>Currency</option>
                                <option>INR</option>
                                <option>USDT</option>
                            </select>
                        </div>
                    </div>
                    <div className="tab-content mt-0">
                    <div id="USDT" className="tab-pane active">
                        <div className="table-responsive"> 
                            <table className="table table-borderless" >
                                <thead>
                                <tr>
                                    <th className="w-25"> {p2ppage['0']['table']['label1'][langchange] }    </th>
                                    <th>  {p2ppage['0']['table']['label2'][langchange] }	  </th>
                                    <th>  {p2ppage['0']['table']['label3'][langchange] }  </th>
                                    <th>{p2ppage['0']['table']['label4'][langchange] }</th>
                                    <th>  {p2ppage['0']['table']['label5'][langchange] }  </th>
                                    <th className="text-right">  {p2ppage['0']['table']['label6'][langchange] } <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td className="name"> <p> MV </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa} />  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"  onClick={onOpenModal}> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr> */}
                                {showRecords()}

                              
                            </tbody>
                        </table>
                    </div>
                </div>                                        
                
                <div className='p2p-buy'>
                          
                        <Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                            <div className='container mt-5'>
                            <div className='row '>
                                <div className='col-md-7'>
                                    <div className='d-flex'>
                                        <div className='box'>
                                            <div className='modal-data-header d-flex align-items-center justify-content-around'>
                                                <div className='modal-p-h-1 d-flex'>
                                                    <div className='modal-user-profile '>
                                                        <span>{BuyData?.user_id?.[0]?.firstname?.slice(0, BuyData?.user_id?.[0]?.firstname?.length + 1 - BuyData?.user_id?.[0]?.firstname?.length)} {BuyData?.user_id?.[0]?.lastname?.slice(0, BuyData?.user_id?.[0]?.lastname?.length + 1 - BuyData?.user_id?.[0]?.lastname?.length)}</span>
                                                    </div>
                                                    <div className='name-p-1 ml-2'>
                                                        <h6 className='mb-0'>{BuyData?.token_id?.[0]?.coinName} <span className='check-images'></span>  </h6>
                                                        <h6 className='mb-0 d-flex text-nowrap'> <span className='mr-3'> 0 order(s)</span> 0.00% </h6>
                                                    </div>
                                                </div>
                                                <div className='modal-p-h-2 ml-lg-5'>
                                                    <p className='mb-0'>
                                                    Available <br />
                                                    <span> {BuyData?.sold_quantity? BuyData?.quantity - BuyData?.sold_quantity : BuyData?.quantity}</span>
                                                    </p>
                                                </div>
                                                <div className='modal-p-h-2 ml-lg-5'>
                                                    <p className='mb-0'>
                                                    Limit <br />
                                                    <span className='text-nowrap'> {BuyData?.min_limit} ~ {BuyData?.sold_quantity? BuyData?.quantity - BuyData?.sold_quantity : BuyData?.quantity}</span>
                                                    </p>
                                                </div>
                                                <div className='modal-p-h-2 ml-lg-5'>
                                                    <p className='mb-0'>
                                                    Price <br />
                                                    <span>{BuyData?.price} {BuyData?.sell_currency}/{BuyData?.token_id?.[0]?.coinName}</span>
                                                    </p>
                                                </div>
                                                <div className='modal-p-h-2 ml-lg-5'>
                                                    <p className='mb-0 text-nowrap'>
                                                    Payment Method </p><div className='link-profile' ></div>
                                                    <div className='' > {BuyData?.UserPaymentMethodID?.map((resVal,key) => {
                                                            return  resVal.PaymentMethodFields?.[key]?.value ? resVal.PaymentMethodFields?.[key]?.value +' ' :' ';
                                                        })} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='modal-data-body'>
                                                <div className='modal-body-1 mt-3 mb-3'>
                                                    <h5 className='mb-2'> Remarks</h5>
                                                    <p className='pr-5'> If you need more, just let me know! We buying and selling USDT and any crypto for cash AED in Dubai Person to person, no third party deals Please contact me in telegram before open deal @BronevoyBTC or text me</p>
                                                </div>
                                                <form onSubmit={onhandelsubmit}>
                                                    <div className='modal-form-q'>
                                                            {/* <h6 className='text-black'>Buy(Remaining Daily Limit:10,000AED)</h6> */}
                                                        <div className='form mt-4'>
                                                            <div class="form-group">
                                                                <label htmlFor="email" className='label-fisrt-1'>  | {BuyData?.token_id?.[0]?.coinName}    </label>
                                                                <input type="number" onChange={(e) => firstCalcu(e.target.value)}  onBlur={() => firstError()} value={firstCurrency} class="form-control" min={BuyData?.min_limit} max={BuyData?.quantity} placeholder={'min ' + BuyData?.min_limit} id=""  />
                                                            </div>
                                                            <div class="form-group ml-3">  
                                                                <label htmlFor="email" className='label-fisrt-1'>  | {BuyData?.sell_currency}  </label>                                                         
                                                                <input type="number" class="form-control" value={secondCurrency} onChange={(e) => secCalcu(e.target.value)} onBlur={() => secError()} placeholder="" id="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group ml-3">  
                                                        <div className='row '>
                                                            {BuyData?.UserPaymentMethodID.map((val) => {
                                                            return(<div className='col-md-6 d-flex align-items-center'>
                                                                <div className='card flex-row p-3 h-100 w-100 align-items-start'>
                                                                <input type="radio" className='mt-2' id={val._id} name="fav_language" value={val._id}  onChange={() => setupiId(val._id)} />
                                                                <label htmlFor={val._id} className='ml-2 mb-0 w-100'>
                                                                    { val.PaymentMethodFields.map((val2) => {
                                                                        // console.log(val2);
                                                                        return(
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <span className='mb-1'>
                                                                                    {val2.field?.replace('_',' ')?.toUpperCase() }: {val2.value +' '} <br />   
                                                                                </span>

                                                                                <p className='mb-1 copy__btn_1' onClick={() => copyToClipboard(val2.value)}> <i class='fas fa-copy'></i> </p>
                                                                            </div>
                                                                           
                                                                        )
                                                                    })}
                                                                </label><br/></div></div>);
                                                            })}
                                                        </div>
                                                        {/* <input type="radio" id="html" name="fav_language" value="HTML" />
                                                        <label for="html">HTML</label><br/> */}
                                                        
                                                    </div>
                                                    <div className='modal-button text-center mt-4'>
                                                        <h6 className='mb-2'>The trading password is not required</h6>
                                                        <input type='submit' value='Place Order' className='btn '/>
                                                        {/* <a href='#' className='btn '> Place Order</a> */}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Modal>
                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default P2p