import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import { useDispatch, useSelector } from 'react-redux';
import { get_deposit_tokens, get_deposit_transactions } from '../../../redux/actions/dashboard.action';

const DepositTable = (props) => {
	const { langchange } = LangState();
	const dispatch = useDispatch();
	const deposite = useSelector(state => state.token.deposit_token);

	const token_address = useSelector(state => state.token.token_address);
	const [coinaddress, setcoinaddress] = useState(token_address?.address?.walletAddress);
	const deposit_transaction = useSelector(state => state.token.deposit_transaction);
	console.log('props', props?.props?.[0]?.conAddress);
	const tokenaddress = props?.props?.[0]?.conAddress;
	const networkid = props?.props?.[1]?.networkid;
	const tokenid = props?.props?.[2]?.tokenid;
	useEffect(() => {
		// dispatch(get_deposit_tokens());
		// const  senData = {
		// 	contractAddress :'TD2Qvp4cLZWfSLmSSayQLifdcnmhZuGQsT',
		// 	networkID: "626b73db0da84ebb8e948b19",
		// 	tokenId :"628b4c026e60636426db3051",

		// }
		// dispatch(get_deposit_transactions(senData));
		const senData = {
			networkID: networkid,
			tokenId: tokenid,
			contractAddress: tokenaddress,

		}
		// console.log('senData',senData)
		const interval = setInterval(() => {
			if (tokenaddress != undefined) {
				dispatch(get_deposit_transactions(senData));
			}
		}, 5000);
		return () => clearInterval(interval);
	}, [props.props])

	const [search, setsearch] = useState();
	const handleSearch = () => {
		if (search != undefined) {
			return deposite.deposit_tokens?.filter(
				(iii) =>
					iii.coinName.toLowerCase().includes(search)
			)
		} else {
			return (deposite.deposit_tokens);
		}
	}

	function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr);
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	}


	return (
		<div>
			<div className='row mt-5 Deposit_Table_1'>
				<div className='col-md-12'>
					<h2>Deposit History</h2>
					<div className='table-responsive-xl'>
						<table className="table table-borderless">
							<thead className='thead-light'>
								<tr>
									<th className="w-25"> Token Name   </th>
									<th>Amount</th>

									<th className="">  Created At </th>
								</tr>
							</thead>
							<tbody>
								{deposit_transaction?.deposit_transactions?.map((row) => {
									return (
										<tr>
											<td>{row?.tokenName}</td>
											<td><span>{row?.amount}</span></td>

											<td>{secondsToHms(row?.createdAt)}</td>
										</tr>
									);
								})}
								{/* <tr>
							<td colspan="6" className='text-center'>
								<img src={emptyImg} className='mt-5' /><br/>
								<span>No data</span>
							</td>
						</tr> */}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DepositTable