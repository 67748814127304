import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import madel from '../../../../_assets/images/madel.svg';
import home from '../../../../_assets/images/home.svg';
import { LangState } from '../../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import { craete_p2p_post } from '../../../../redux/actions/p2p.action';
import { PROJECT_NAME } from '../../../../_constants';
import Modal from 'react-responsive-modal';
import { get_swap_balance_wallet_type, get_user_assets } from '../../../../redux/actions/assets.action';
import { P2PGetPaymetMethodes, P2PPaymetMethodes } from '../../../../redux/actions/payment.action';

const Create_posting = () => {
    const { langchange } = LangState();
    const dispatch = useDispatch();
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [open, setOpen] = useState(false);
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const [search, setsearch] = useState();
    const [trnsferId, settrnsferId] = useState();
    const [trnsferIdname, settrnsferIdname] = useState();
    const [price, setprice] = useState();
    const [sell_currency, setsell_currency] = useState('INR');
    const [quantity, setquantity] = useState();
    const [min_limit, setmin_limit] = useState();
    const [payment_methods, setpayment_methods] = useState();


    const my_posting = useSelector(state => state.p2p.my_posting);
    const assetsData = useSelector(state => state.assets.assetsData)
    const method = useSelector(state => state.payment.method);
    const get_method = useSelector(state => state.payment.get_method);

    useEffect(() => {
        dispatch(get_user_assets({ wallet_type: 'main_wallet' }))
        dispatch(P2PPaymetMethodes());
        dispatch(P2PGetPaymetMethodes());

        // dispatch(p2p_my_posting());
    }, []);

    const addMethod = (val) => {

        if (payment_methods == undefined) {
            setpayment_methods([val]);
        } else {
            var check = payment_methods.includes(val)
            if (check != true) {
                payment_methods.push(val);
                console.log(payment_methods)

            } else {
                var carIndex = payment_methods.indexOf(val);//get  "car" index
                //remove car from the colors array
                payment_methods.splice(carIndex, 1); // 

                console.log(payment_methods)
            }
            console.log('check', check);
            // setpayment_methods([...payment_methods,val])
        }
        console.log('payment_methods', payment_methods);


    }
    const handelSubmit = (e) => {
        e.preventDefault();
        const submitData = {
            token_id: trnsferId,
            price: price,
            sell_currency: sell_currency,
            quantity: quantity,
            min_limit: min_limit,
            payment_methods: payment_methods,
        }
        dispatch(craete_p2p_post(submitData, setOpen));

    }
    const handleSearch = () => {
        if (search != undefined) {
            return assetsData?.assets?.filter(
                (iii) =>
                    iii.tokenname.toLowerCase().includes(search)
            )
        } else {
            return (assetsData?.assets);
        }
    }
    console.log('sell_currency', sell_currency)
    const cal_bbalance = (val) => {

        dispatch(get_swap_balance_wallet_type({ tokenName: val, wallet_type: 'funding_wallet' }));
    }
    const swap_balance = useSelector(state => state.assets.balance_with_type);

    return (
        <div>
            <div className="Fast-trade">
                <div className="container-fast">
                    <ul className="nav">
                        {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                        </li>
                        {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                    </ul>
                </div>
            </div>
            <div className="tab-new-style">
                <div className="tab-container">
                    <div className="left-sidebar" id="left-sidebar">
                        <ul className="nav flex-column">
                            <p> P2P </p>
                            <li className="nav-item">
                                <Link className="nav-link" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange]}  </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange]}  </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange]} </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange]} </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange]} </Link>
                            </li>
                            <p> {p2ppage['0']['other'][langchange]} </p>
                            <li className="nav-item">
                                <a className="nav-link" href="#"> {p2ppage['0']['support'][langchange]} </a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange]}  </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="right-tab">
                        {/* <div className="tab-header">
                        <ul className="nav" role="tab">
                            <li className="nav-item" id="more" > 
                            <a className="nav-link active" data-toggle="tab" href="#USDT"> Posted</a>
                            </li>
                            <li className="nav-item"> 
                                <a className="nav-link " data-toggle="tab" href="#BTC">   Pending </a>
                            </li>
                        
                        </ul>
                    </div> */}
                        <div className="tab-content">
                            <div id="USDT" className="tab-pane active">
                                <div className="">

                                    <div className='d-flex justify-content-center align-items-center text-center' >
                                        <div>
                                            <img src={home}  alt='home icon'/>
                                            <p className='mt-3'>Only merchants on {PROJECT_NAME} can create and manage postings.</p>
                                            <button style={{ color: 'rgb(40 205 149)' }} onClick={onOpenModal} >Apply now</button>

                                        </div>

                                    </div>
                                    <Modal open={open} onClose={onCloseModal} animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design', }} center>
                                        <div className='container'>
                                            <div className='row deposit-modal'>
                                                <div className='col-md-12'>
                                                    <h2>Create post</h2>
                                                    <h4>Available Amount: {swap_balance?.balance}</h4>
                                                    {/* <div className='p2p-pay-system'><p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p></div> */}
                                                    <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                                        <label>Token</label>
                                                        <div class="input-group-prepend w-100">
                                                            <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                                aria-hidden="true"></i></span>

                                                            <input
                                                                type="text"
                                                                placeholder="Select Token"
                                                                onChange={e => setsearch(e.target.value)}
                                                                onFocus={() => setdropdownVisible(true)}
                                                                // onBlur={() => setdropdownVisible(false)}
                                                                className='form-control my-0 py-1 w-100'
                                                                value={trnsferIdname}
                                                            />

                                                        </div>
                                                    </div>
                                                    {dropdownVisible && (
                                                        <div className="autocomplete-dropdown TEXT w-75">
                                                            <ul className="autocomplete-search-results-list w-100" >
                                                                {handleSearch()?.map((val) => {

                                                                    return (
                                                                        <li className="autocomplete-search-result" onClick={() => { settrnsferId(val?.token_id?.[0]); setdropdownVisible(false); settrnsferIdname(val?.tokenname); cal_bbalance(val?.tokenname) }}>{val?.tokenname}</li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}

                                                    <div className='mt-3'>
                                                        <form onSubmit={handelSubmit} id='mainForm' method='post'>


                                                            <div className="form-group">
                                                                <label htmlFor="amount" >Sell Currency</label>
                                                                <select className='form-control select-post' onChange={(e) => setsell_currency(e.target.value)}>
                                                                    <option value='INR'>INR</option>
                                                                    <option value='USDT'>USDT </option>

                                                                </select>
                                                                {/* <input type='text'    className='form-control'  onChange={(e) => setsell_currency(e.target.value)}   placeholder='Sell Currency' required/> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="amount" >Price</label>
                                                                <input type='text' className='form-control' onChange={(e) => setprice(e.target.value)} placeholder='Price' required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="amount" >Quantity</label>
                                                                <input type='text' className='form-control' onChange={(e) => setquantity(e.target.value)} placeholder='Quantity' required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="amount" >Min Limit</label>
                                                                <input type='text' className='form-control' onChange={(e) => setmin_limit(e.target.value)} placeholder='Min Limit' required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="amount" >Payment Methods</label>
                                                                <div className='row m-0'>
                                                                    {get_method?.payment_methods?.map((val, key) => {
                                                                        // console.log('val?.PaymentMethodFields?.[1]?.upi_id ',val?._id)
                                                                        return (
                                                                            <div className='col-md-6 mb-2 pl-2 pr-2'>
                                                                                <div className='card p-3 h-100'>
                                                                                    <input type="checkbox" onClick={() => addMethod(val?._id)} id={val?._id} name="upi" value="UPI" />
                                                                                    <label for={val?._id}> {get_method.payment_methods?.[key]?.method?.[0]?.name + '  '}  </label>
                                                                                    {val?.PaymentMethodFields?.map((valfield) => {
                                                                                        return (<><span className='d-flex justify-content-between'>{valfield?.field?.replace("_", " ")?.toUpperCase()}:<span className='text-right' > {valfield?.value + ' '} </span>  </span> </>);
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                                {/* {method?.payment_methods?.map((val) => {
                                                            return(
                                                                <>
                                                                <input type="checkbox" onClick={() => addMethod(val?._id)} id={val?.name} name="upi" value="UPI"/>
                                                                <label for={val?.name}> {val?.name}</label>
                                                                </>
                                                            );
                                                        })} */}

                                                            </div>
                                                            <div className="form-group text-center">
                                                                <button type="submit" style={{ backgroundColor: 'rgb(40 205 149)', padding: '5px 18px', fontSize: '18px' }} className=' submit__btn btn text-white' name="submit" >Submit</button>
                                                            </div>

                                                        </form>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                    {/* <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th className="w-25"> Trade/ID   </th>
                                    <th>  Currency	  </th>
                                    <th>  Price/Premium  </th>
                                    <th>Remaining/Total</th>
                                    <th> Operation </th>
                                    <th className="text-right">  Time </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="name"> <p> MV </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa} />  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>

                                <tr>
                                    <td className="name"> <p> PM </p>  Best Rate USDT <img src={madel}/>  </td>
                                    <td className="text-center"> <img src={visa}/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>
                                <tr>
                                    <td className="name"> <p> SK </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa}/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>
                                <tr>
                                    <td className="name"> <p> SS </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa}/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>
                            </tbody>
                        </table> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Create_posting

