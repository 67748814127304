import { Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCoinPrice, get_2nd_swap_balance_wallet_type, get_swap_balance_wallet_type, get_wallet_details } from '../../../redux/actions/assets.action';
import SpotSectionMarkit from './SpotSectionMarkit';
import chartcorner from '../../../_assets/images/img/chartcorner.svg';
import { tradeCoinAction } from '../../../redux/actions/Chart.action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CryptoState } from '../../../CryptoContext';
import { toast } from '../../../components/toast';
const SpotSection = (props) => {
    var { id,pair } = useParams();
    const { cancelOrder, setcancelOrder} = CryptoState();

    const isSignedIn = useSelector(state => state.persist.isSignedIn);
    const isAuthenticated = isSignedIn;
    const wallet_details3 =  useSelector(state => state.assets.wallet_details);
    const token_prices = useSelector(state => state.assets.token_prices.data);
    const swap_balance  = useSelector(state => state.assets.balance_with_type);
    const swap_2nd_balance  = useSelector(state => state.assets.swap_2nd_balance);
    const coinPrice  = useSelector(state => state.assets.get_Price);

    const [activeTab,setactiveTab] = useState('limit')
    const [activeDrop,setactiveDrop] = useState('')
    const [coinName,setCoinName] = useState(props.props)
    const [usdt,setusdt] = useState(1)
    const [btc,setbtc] = useState(1) 
    const history = useHistory();
    const dispatch = useDispatch()
    const {pairName} = CryptoState();
    const [balanceBuy,setbalanceBuy] = useState(0) 
    const [balanceSell,setbalanceSell] = useState(0) 
    const [buyCalculation,setbuyCalculation] = useState(0) 
console.log('pairpairpairpair',pair);
   
    // const usdtPriceFun = (val) => {
    //     // console.log('val',val);
    //     setusdt(val);
    //     var newval  = val *   wallet_details3.prices.btc;
    //     setbtc(newval);

    // }
    const usdtWithPersent = (val) => {
        const setNewPrice = swap_balance.balance / 100 * val;
        // console.log('val',setNewPrice);

        const data = setNewPrice/usdt
        setbtc(data.toFixed(6));

        setbuyCalculation(setNewPrice.toFixed(6));

        // var newval  = setNewPrice *   wallet_details3.prices.btc;
        // setbtc(newval);
    }
    // const btcPriceFun = (val) => {
    //     setbtc(val);
    //     var newval  = (val / wallet_details3.prices.btc)*   wallet_details3.prices.usd;
    //     setusdt(newval);
    // }
    const addUsdt = () => {
        if(usdt >0){
        var calData = (parseFloat(usdt) + .1);
        setusdt(calData?.toFixed(6));
        const data = calData* btc;
        setbuyCalculation(data?.toFixed(6));
        }
    }
    const SubcUsdt = () => {
        if(usdt >0.1){

        var calData = (parseFloat(usdt) - .1);
        setusdt(calData?.toFixed(6));
        const data = calData* btc;
        setbuyCalculation(data.toFixed(6));
        }
        
    }
    const addBtc = () => {
        if(btc >0.0){
            var calData = (parseFloat(btc) + .1);
            setbtc(calData?.toFixed(6));
            const data = calData* usdt;
            setbuyCalculation(data?.toFixed(6));
        }
    }
    const SubcBtc = () => {
        if(btc >0.1){
            var calData = (parseFloat(btc) - .1);
            setbtc(calData.toFixed(6));
            var data = calData* usdt;
            setbuyCalculation(data.toFixed(6));
        }
    }
    const addCalculation = () => {
        if(buyCalculation >0.0){

        var calData = (parseFloat(buyCalculation) + .1);
        setbuyCalculation(calData.toFixed(6));
        const data = calData /usdt;
        setbtc(data);
        }
    }
    const SubcCalculation = () => {
        if(buyCalculation >0.1){

        var calData = (parseFloat(buyCalculation) - .1);
        setbuyCalculation(calData?.toFixed(6));
        const data = calData /usdt
        setbtc(data.toFixed(6));
        }
    }

    const MultiplyBuyFields = (val,data) => {
        var calData = val * data
         setbuyCalculation(calData?.toFixed(6));
        // setbuyCalculation(calData);
       
    }
    const DevideMultiplyBuyFields = (val) => {
        var calData =  val /usdt
        setbtc(calData?.toFixed(6));
        // setbuyCalculation(calData);
       
    }

    const fixDesit = (val,state) =>{
        // console.log('val % 1',val % 1);
        var data  = Math.floor(val*1000000)/1000000;
        state(data)
    }




    // sell coin fun///////////////////////////////////////////////////////////////////////////////////
    const [sellUsdt,setsellUsdt] = useState(1);
    const [sellBtc,setsellBtc] = useState(0);
    const [sellCalculation,setsellCalculation] = useState(0) 

    const addsellUsdt = () => {
        if(sellUsdt > 0){
            var calData = (parseFloat(sellUsdt) + .1);
            setsellUsdt(calData?.toFixed(6));
            const data = sellBtc * calData;
            const newData = data?.toFixed(6)
            setsellCalculation(newData);
        }
    }
    const SubcsellUsdt = () => {
        if(sellUsdt > 0.1){
        var calData = (parseFloat(sellUsdt) - .1);
        setsellUsdt(calData?.toFixed(6));
        const data = sellBtc * calData;
        const newData = data?.toFixed(6)
        setsellCalculation(newData);
        }
        
    }
    const addsellBtc = () => {
        if(sellBtc > 0) {
            var calData = (parseFloat(sellBtc) + .1);
            setsellBtc(calData?.toFixed(6));
            const data = sellBtc * calData;
            const newData = data?.toFixed(6)
            setsellCalculation(newData);
        }
    }
    const SubcsellBtc = () => {
        if(sellBtc > 0.1) {
            var calData = (parseFloat(sellBtc) - .1);
            setsellBtc(calData?.toFixed(6));
            // setsellCalculation(sellUsdt * calData);
            const data = sellBtc * calData;
            const newData = data?.toFixed(6)
            setsellCalculation(newData);
        }
    }
    const sellUsdtWithPersent = (val) => {
        const setNewPrice = swap_2nd_balance.balance / 100 * val;
        const newData = setNewPrice?.toFixed(6)

        const data =  newData/sellBtc;
        setsellUsdt(data?.toFixed(6));

        setsellCalculation(newData);

        // var newval  = setNewPrice *   wallet_details3.prices.btc;
        // setbtc(newval);
    }

    const addSellCalculation = () => {
        if(sellCalculation > 0) {
            var calData = (parseFloat(sellCalculation) + .1);
            setsellCalculation(calData?.toFixed(6));
            const newData = calData/sellUsdt;
            setsellUsdt(newData?.toFixed(6));
        }
    }
    const SubcSellCalculation = () => {
        if(sellCalculation > 0.1) {
            var calData = (parseFloat(sellCalculation) - .1);
            setsellCalculation(calData?.toFixed(6));
            const newData = calData/sellUsdt;
            setsellUsdt(newData?.toFixed(6));
        }
    }

    const MultiplySellFields = (val,data) => {
        var calData = val * data
         setsellCalculation(calData?.toFixed(6));
        // setbuyCalculation(calData);
       
    }
    const DevideMultiplySellFields = (val) => {
        var calData =  val /sellBtc
        setsellUsdt(calData?.toFixed(6));
        // setbuyCalculation(calData);
       
    }








    // action / function start buy sell etc///////////////////////////////////////////////////////////////////

    const buyLimitCoin = () => {
        if(btc > 9){
            var test = window.confirm('Are you sure you want to place this order!');
            if(test === true){
                var data = {
                    token_id: swap_balance?.token_id?.[0],
                    mode:'BUY',
                    tradingCurrency:props.props.toUpperCase() ,
                    amount:usdt ,
                    orderType:'LIMIT',
                    tradingCurrencyAmount:btc,
                }

                dispatch(tradeCoinAction(data,pairName,setbalanceSell,setbalanceBuy));
            }
        }else{
            toast.error('Minimum amount 10'+props.props);

        }
    }
    // console.log('swap_2nd_balance?.token_id?.[0]',coinPrice.tokenPrices);
    const sellLimitCoin = () => {
        if(sellUsdt > 9){

            var test = window.confirm('Are you sure you want to place this order!');
            if(test === true){
                var data = {
                    token_id: swap_2nd_balance?.token_id?.[0],
                    mode:'SELL',
                    tradingCurrency:props.props.toUpperCase() ,
                    amount:sellUsdt,
                    orderType:'LIMIT',
                    tradingCurrencyAmount:sellBtc,
                }
            
                dispatch(tradeCoinAction(data,pairName,setbalanceSell,setbalanceBuy));
            }
        }else{
            toast.error('Minimum amount 10'+props.props);

        }
    }
    
    useEffect(() => {
        dispatch(get_wallet_details())
        dispatch(getCoinPrice(props.props?.toUpperCase(),setusdt,setsellBtc))
        dispatch(get_swap_balance_wallet_type({tokenName: pair,wallet_type:'trading_wallet'},setbalanceBuy));
        dispatch(get_2nd_swap_balance_wallet_type({tokenName: props.props,wallet_type:'trading_wallet'},setbalanceSell))
        setCoinName(props.props);
    },[props.props,balanceBuy, balanceSell,cancelOrder])

   useEffect(() => {
    setbalanceBuy(swap_balance.balance);
    setbalanceSell(swap_2nd_balance.balance)
   })
  return (
      <>
        <div className="col-xl-5 col-lg-6 aside__price__box p-0">
                <div className="order__details">
                    <div className="order__details__inner">
                        <button className="aside__tabs spot__tab__btn active" id='spot1' value="spotBtn">
                            Spot
                        </button>
                        <button className="aside__tabs isolated__margin " id='cross1' value="crossBtn">
                            <span className="Isolated__margin__text">
                                Cross Margin
                            </span>
                            <span className="isolated__margin__number">10X</span>
                            <img className="isolate__margin__btn tabs__dropdown" src={"assets/svg/dropdown-icon.svg"} alt="" />
                            <div className="dropdown__content">
                                <option className="dropdown__option"  value="crossBtn" >
                                Isolated Margin 
                                </option>
                                <option className="dropdown__option" value="isolatedBtn">
                                    Isolated Margin
                                </option>
                            </div>
                        </button>
                        <button className=" isolated__margin ">
                            Futures
                            <span className="isolated__margin__number">100X</span>
                            Grid
                        </button>
                    </div>
                    <div className="notification__icon tranding__fee">
                        <img src={chartcorner} alt="" />
                    </div>
                    <div className="tranding__fee__popup">
                        <h3 className="popup__heading">
                            Trading Fee Rate
                        </h3>
                        <p className="popup__info">
                            Basic Trading Fee Rate of BTC/USDT: 0.1% for taker, 0.1% for maker
                        </p>
                        <p className="popup__info">
                            If you have FXC and enabled the 'FXC Pay Fees' feature, you'll get an extra 20% discount on
                            your
                            trading fees.
                        </p>
                    </div>
                </div>
                <div className="order__details">
                    <div className="order__details__inner">
                        <button className={activeTab == 'limit' ? "limit__tabs__button active": "limit__tabs__button" }  onClick={() => setactiveTab('limit')}>
                            Limit
                        </button>
                        <button className={activeTab == 'markit' ? "limit__tabs__button active": "limit__tabs__button" } onClick={() => setactiveTab('markit')} value="1">
                            Market
                        </button>
                    </div>
                    <div className=" aside__inner__dropdown">
                        <button className={activeDrop == 'stop-both' ? "pairs__button__aside active": 'pairs__button__aside'} onClick={() => activeDrop == 'stop-both' ? setactiveDrop('') : setactiveDrop('stop-both')} >
                            <span>Stop Limit</span>
                            <img src="assets/svg/dropdown-icon.svg" alt="" />
                        </button>
                        <div className="dropdown__content__aside">
                            <option className="dropdown__option__aside limit__tabs__button"  onClick={() => setactiveTab('spot-limit')}>
                                Stop Limit
                            </option>
                            <option className="dropdown__option__aside stop__market__btn limit__tabs__button" onClick={() => setactiveTab('spot-markit')}>
                                Stop Market
                            </option>
                        </div>

                    </div>
                    <div className="checkbox__icon">
                        <span className="auto__borrow">Auto-Borrow</span>
                        <span className="input__box active"></span>
                    </div>
                    <div className="notification__icon">
                        <svg width="13" height="13" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 30C10.9934 30 7.22654 28.4397 4.39336 25.6066C1.56029 22.7735 0 19.0066 0 15C0 10.9934 1.56029 7.22654 4.39336 4.39336C7.22654 1.56029 10.9934 0 15 0C19.0066 0 22.7735 1.56029 25.6066 4.39336C28.4397 7.22654 30 10.9934 30 15C30 19.0066 28.4397 22.7735 25.6066 25.6066C22.7735 28.4397 19.0066 30 15 30Z"
                                fill="#a1a4ac"></path>
                            <path
                                d="M17.4769 22.5031V12.5195H11.5538V14.5162H12.6308V22.5031H11.5V24.4998H18.5V22.5031H17.4769Z"
                                fill="#151C30"></path>
                            <path
                                d="M15.1367 10.7648C16.5906 10.7648 17.7734 9.58389 17.7734 8.13239C17.7734 6.68089 16.5906 5.5 15.1367 5.5C13.6828 5.5 12.5 6.68089 12.5 8.13239C12.5 9.58389 13.6828 10.7648 15.1367 10.7648Z"
                                fill="#151C30"></path>
                        </svg>
                    </div>
                </div>
                <div className={activeTab == 'limit' ? ' price__wrapper active' :'price__wrapper'} >
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Price" value={usdt} onChange={(e) => {fixDesit(e.target.value,setusdt);MultiplyBuyFields(e.target.value,btc)}}  />
                            <span>{pairName}</span>
                            <div className="increase__decrease__value">
                                <button onClick={() => addUsdt() }>+</button>
                                <button onClick={() => SubcUsdt()}>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" value={btc} onChange={(e) => { fixDesit(e.target.value,setbtc);MultiplyBuyFields(e.target.value,usdt)}} />
                            <span>{coinName.toUpperCase()}</span>
                            <div className="increase__decrease__value">
                                <button  onClick={() => addBtc()}>+</button>
                                <button onClick={() => SubcBtc()}>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" value={buyCalculation} onChange={(e) => { fixDesit(e.target.value,setbuyCalculation);DevideMultiplyBuyFields(e.target.value)}} />
                            <span>{pairName}</span>
                            <div className="increase__decrease__value">
                                <button  onClick={() => addCalculation()}>+</button>
                                <button onClick={() => SubcCalculation()}>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span onClick={() => usdtWithPersent(25)} style={{cursor:'pointer'}}>25%</span>
                            <span onClick={() => usdtWithPersent(50)} style={{cursor:'pointer'}}>50%</span>
                            <span onClick={() => usdtWithPersent(75)} style={{cursor:'pointer'}}>75%</span>
                            <span onClick={() => usdtWithPersent(100)} style={{cursor:'pointer'}}>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available :
                                </span>
                                <span>
                                    {balanceBuy?.toFixed(6)} {pairName}
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            {/* <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    {btc*usdt} USDT
                                </span>
                            </div> */}
                            {/* <div className="inner__item">
                                <span>
                                    Advanced: -
                                </span>
                                <span>
                                    Settings
                                </span>
                            </div> */}
                        </div>
                        <button className="Login__Sing__in__btn" onClick={() => buyLimitCoin()}>
                            <a className="login"   >Buy </a>
                            
                        </button>
                    </div>
                    <div className="price__input">
                    <div className="price__box">
                            <input type="number" placeholder="Amount" value={sellBtc} onChange={(e) => { fixDesit(e.target.value,setsellBtc);MultiplySellFields(e.target.value,sellUsdt)}} />
                            <span>{pairName}</span>
                            <div className="increase__decrease__value">
                                <button onClick={() => addsellBtc()}>+</button>
                                <button onClick={() => SubcsellBtc()}>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" value={sellUsdt}  onChange={(e) => {fixDesit(e.target.value,setsellUsdt);MultiplySellFields(e.target.value,sellBtc)}} />
                            <span>{coinName.toUpperCase()}</span>
                            <div className="increase__decrease__value">
                                <button onClick={() => addsellUsdt()}>+</button>
                                <button onClick={() => SubcsellUsdt()}>-</button>
                            </div>
                        </div>
                       
                        <div className="price__box">
                            <input type="number" placeholder="Amount" value={sellCalculation} onChange={(e) => {fixDesit(e.target.value,setsellCalculation);DevideMultiplySellFields(e.target.value)}} />
                            <span>{pairName}</span>
                            <div className="increase__decrease__value">
                                <button onClick={() => addSellCalculation()}>+</button>
                                <button onClick={() => SubcSellCalculation()}>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span onClick={() => sellUsdtWithPersent(25)} style={{cursor:'pointer'}}>25%</span>
                            <span onClick={() => sellUsdtWithPersent(50)} style={{cursor:'pointer'}}>50%</span>
                            <span onClick={() => sellUsdtWithPersent(75)} style={{cursor:'pointer'}}>75%</span>
                            <span onClick={() => sellUsdtWithPersent(100)} style={{cursor:'pointer'}}>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    {balanceSell?.toFixed(6) } {props.props}
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            {/* <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    {sellUsdt*sellBtc} USDT
                                </span>
                            </div> */}
                            {/* <div className="inner__item">
                                <span>
                                    Advanced: -

                                </span>
                                <span>
                                    Settings
                                </span>
                            </div> */}
                        </div>
                        
                        <button className="Login__Sing__in__btn"  onClick={() => sellLimitCoin()}>
                           
                            <a className="login" >Sell</a>
                        </button>

                    </div>
                </div>
                
                
                {activeTab == 'markit' ? <SpotSectionMarkit props={props.props.toUpperCase()} />: ' ' }


                
                <div className={activeTab == 'spot-limit' ? ' price__wrapper active' :'price__wrapper'} >
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>

                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>

                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Advanced: -

                                </span>
                                <span>
                                    Settings
                                </span>
                            </div>
                        </div>
                        <button className="Login__Sing__in__btn">
                            <Link className="login" to=''>Buy </Link>
                            <span>
                                Or
                            </span>
                            <Link to="">Sell</Link>
                        </button>

                    </div>
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Advanced: -

                                </span>
                                <span>
                                    Settings
                                </span>
                            </div>
                        </div>
                        <button className="Login__Sing__in__btn">
                            <a className="login">Log In</a>
                            <span>
                                Or
                            </span>
                            <a href="sign-up.html">Sign Up</a>
                        </button>
                    </div>
                </div>
                <div className={activeTab == 'spot-markit' ? ' price__wrapper active' :'price__wrapper'} >
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                        </div>
                        <button className="Login__Sing__in__btn">
                            <Link className="login" to=''>Buy </Link>
                            <span>
                                Or
                            </span>
                            <Link to="">Sell</Link>
                        </button>

                    </div>
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <Link className="login" to='/login'>Log In</Link>
                            <span>
                                Or
                            </span>
                            <Link to="/register">Sign Up</Link>
                        </button> */}
                        <button className="Login__Sing__in__btn">
                            <Link className="login" to=''>Buy </Link>
                            <span>
                                Or
                            </span>
                            <Link to="">Sell</Link>
                        </button>

                    </div>
                </div>

                
                <div className="tour__popup" id="BuyingSelling">
                    <h4>
                        Assets Overview
                    </h4>
                    <p>
                        The balance in your Main Account, Trading Account and Margin Account is shown here.
                    </p>
                    <div className="popup__footer">
                        <div className="pagination">
                            <span></span>
                            <span className="active"></span>
                            <span></span>
                        </div>
                        <div className="next__prev__btn">
                            <button className="next__button" onclick="assetsView()">
                                Prev
                            </button>
                            <button className="next__button" onclick="SpotMargin()">
                                Next
                            </button>
                        </div>
                    </div>
                    <button className="close__btn" onclick="closePopup()">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                           >
                        </svg>
                    </button>
                </div>
                <div className="tour__popup" id="SpotMargin">
                    <h4>
                        Assets Overview
                    </h4>
                    <p>
                        The balance in your Main Account, Trading Account and Margin Account is shown here.
                    </p>
                    <div className="popup__footer">
                        <div className="pagination">
                            <span></span>
                            <span></span>
                            <span className="active"></span>
                        </div>
                        <div className="next__prev__btn">
                            <button className="next__button" onclick="BuyingSelling()">
                                Prev
                            </button>
                            <button className="next__button" onclick="closePopup()">
                                Close
                            </button>
                        </div>
                    </div>
                    <button className="close__btn" onclick="closePopup()">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            >
                        </svg>
                    </button>
                </div>
            </div> 

      </>
  );
};

export default SpotSection;
