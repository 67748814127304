import react from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"; 
import { Link,NavLink, withRouter } from 'react-router-dom';
import { PROJECT_NAME } from "../../../_constants";
import TypeAnimation from 'react-type-animation';
import SideImg from '../../../_assets/images/slider-img.jpg'
const IndexBox = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const income_type_transactions = useSelector(state => state.income.income_type_transactions); 
    // console.log('table page',user);
    

    return(
        <>
             {/* <div className="box"> */}
                    <div className='col-md-6'>
                   <h2 className='' style={{marginTop:'17%'}}><TypeAnimation
        cursor={false}
        sequence={[
          'Find the Next Crypto Gem on SOLAR TECHNO ALLIANCE.',
          2000,
          
        ]}
        wrapper="a"
        repeat={1}
        className='TypeAnimation'
       />
          </h2> 
          <p className="sec__info">
                    1 Out of 4 Crypto Holders Worldwide Is with SOLAR TECHNO ALLIANCE
                </p>
                    </div>

                    <div className='col-md-6'>
                        <img src={SideImg} />
                    </div>
                {/* <div className="row pra">
                    <div>
                        <i className="fa fa-cube"></i>
                    </div>
                    <div className="pra-text">
                        <h4 className="mb-0"> Welcome to <span>{PROJECT_NAME}</span> </h4>
                    </div>
                </div> 

                <div className="row f-row mt-md-4">
                    <div className="col-md-3  col-sm-6 ">
                        <div className="card border-0" style={{backgroundColor: 'rgba(52, 152, 219, 0.9)'}}>
                            
                            <div className="row">
                                
                                <div className="col-12">
                                    
                                    <h3> Name </h3>
                                    <h6>{user.name}</h6>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-3  col-sm-6 ">
                        <div className="card border-0" style={{backgroundColor: 'rgba(175, 122, 197, 0.8)'}}>
                            
                            <div className="row">
                                
                                <div className="col-12">
                                   
                                    <h3> Sponser ID</h3>
                                    <h6>
                                        {user.sponser_id}
                                    </h6>
                                </div>
                            </div>
                            
                        </div>
                    </div> 
                    <div className="col-md-3  col-sm-6 ">
                        <div className="card border-0" style={{backgroundColor: 'rgba(255, 192, 203, 0.7)'}}>
                           
                            <div className="row">
                                
                                <div className="col-12">
                                    
                                    <h3> Email </h3>
                                    <h6>
                                        {user.email}
                                    </h6>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 ">
                        <div className="card border-0" style={{background:'darksalmon'}}>
                            <div className="row">
                                
                                <div className="col-12">
                                    
                                    <h3> Phone</h3>
                                    <h6>
                                        {user.phone}
                                    </h6>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="row f-row mt-md-4">
                    <div className="col-md-3  col-sm-6 ">
                        <div className="card border-0" style={{backgroundColor: 'darksalmon'}}>
                            
                            <div className="row">
                                <div className="col-3">
                                <i className="fas fa-hand-holding-usd"></i>
                                </div>
                                <div className="col-9">
                                    <Link to="/vendor/deposit"> <p className='text-white'> page visit </p></Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-3  col-sm-6 ">
                        <div className="card border-0" style={{backgroundColor: 'rgba(255, 192, 203, 0.7)'}}>
                            
                            <div className="row">
                                <div className="col-3">
                                    <i className='fas fa-chart-line'></i>
                                </div>
                                <div className="col-9">
                                   <Link to='/vendor/activation'><p className='text-white'> Buy Package</p></Link>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-3  col-sm-6 ">
                        <div className="card border-0" style={{backgroundColor: 'rgba(175, 122, 197, 0.8)'}}>
                            
                            <div className="row">
                                <div className="col-3">
                                    <i className="fas fa-money-check-alt" ></i>
                                </div>
                                <div className="col-9">
                                    <Link to="/vendor/withdraw"><p className='text-white'> Withdraw </p></Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 ">
                        <div className="card border-0" style={{background:'rgba(52, 152, 219, 0.9)'}}>
                            
                            <div className="row">
                                <div className="col-3">
                                    <i className="fas fa-file-invoice-dollar"></i>
                                </div>
                                <div className="col-9">
                                    
                                  <Link to="/vendor/income_transations"> <p className='text-white'> Total Earnings </p></Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        */}
                {/* </div> */}
        </>
    );
}

export default IndexBox;