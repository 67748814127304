import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 


const save_P2PPaymetMethodes = (data) => {
    return {
        type: types.SAVE_P2P_PAYMENT_METHOD,
        payload: data
    }
}
export const P2PPaymetMethodes = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.P2pPaymentMethodApi({}, options).then((res) => { 
         const  res1 = res.data;
         console.log(res1);
            dispatch(save_P2PPaymetMethodes(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
const save_P2PPaymetField = (data) => {
    return {
        type: types.SAVE_P2P_PAYMENT_FIELD,
        payload: data
    }
}
export const P2PPaymetField = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.P2pPAymentFieldApi(data, options).then((res) => { 
         const  res1 = res.data;
         console.log(res1);
            dispatch(save_P2PPaymetField(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const P2PPaymetFormSubmit = (data,setOpen) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.P2pPaymentFormSubmitApi(data, options).then((res) => { 
            const  res1 = res;
            console.log('res',res.data.success)
            if (res.data.success == 1) {
                toast.success(res.data.message);
                dispatch(P2PGetPaymetMethodes())
                setOpen(false);

                dispatch(stopLoader());

            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            toast.error('Try again later!');

            console.log('Failed', error);
        });
    } catch (error) {
        toast.error('Something worng!');

        console.log('Failed', error);
    }
}
const save_P2PGetPaymetMethodes = (data) => {
    return {
        type: types.SAVE_P2P_GET_PAYMENT_METHOD,
        payload: data
    }
}
export const P2PGetPaymetMethodes = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.P2pGetPaymentMethodApi({}, options).then((res) => { 
         const  res1 = res.data;
         console.log(res1);
            dispatch(save_P2PGetPaymetMethodes(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
