import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import about1 from '../../../_assets/images/img/About_12.png';
import posi1 from '../../../_assets/images/img/Position_1.svg';
import posi2 from '../../../_assets/images/img/Position_2.svg';
import posi3 from '../../../_assets/images/img/Position_3.svg';
import posi4 from '../../../_assets/images/img/Position_4.svg';
import about2 from '../../../_assets/images/img/About_13.png';
// import about3 from '../../../_assets/images/img/About_14.jpg';
import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { registerlang } from '../../../lang/lang';
import { PROJECT_NAME } from '../../../_constants';

const JoinUs = () => {
    function formBox() {
        var element = document.getElementById("join__in");
        element.classList.toggle("d-block");
      }
    return (
        <div id='about'>
            <Header></Header>
            {/* Banner  */}
            <div className='about_banner' style={{ marginTop: '80px' }}>
                <div className='about_banner_header'>
                    <ul className='nav w-100 justify-content-center'>
                        <li className='nav-item'>
                            <Link to='/vendor/about' className='nav-link '>  About US </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/JoinUs' className='nav-link active'>  Join US </Link>
                        </li>
                        {/* <li className='nav-item'>
                            <a href='#' className='nav-link'> Community  </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#' className='nav-link'>  Press </a>
                        </li> */}

                    </ul>
                </div>
                <div className='banner_body' id='Join_body' >
                    <div className='banner_content'>
                        <h2>JOIN US</h2>
                        <h5 className='text-white'>    {PROJECT_NAME} envisions to enable global financial inclusion of billions of people
                            around the planet with a crypto-based financial services. <br/>We aim to make
                            decentralized financial services accessible to the common man on their palms
                            and tips. Today, our first platform {PROJECT_NAME} invites everyone <br />to become a
                            part of the global crypto economy and trade a variety of crypto products using a
                            single account.</h5>
                        {/* <p> CASH FUDX believes that technological progress will revolutionize the way we create and distribute value. One day, everyone will become involved with crypto.</p> */}
                    </div>
                </div>
            </div>
            {/* Work To join-US */}
            <div className='about_container mt-5 pt-5' id='Join_container'>
                <div className='about_box-1'>
                    <div className='text-center mb-3'><h2>HOW YOU CAN JOIN US?</h2></div>
                    {/* <div className='join_position  mb-5 pb-5'>
                        <div className='text-center m-4'><h3>List of Openings (Job Tasks and Qualifications)</h3></div>
                        <div className='d-flex  align-items-center' style={{justifyContent:'space-evenly'}}>
                            <div className='position_box1_join'>
                                <img className='img-about-1' src={posi1} width='100%' />
                                <h4> Position -1 </h4> 
                            </div>
                            <div className='position_box1_join'>
                                <img className='img-about-1' src={posi2} width='100%' />
                                <h4> Position -1 </h4> 
                            </div>
                        </div>
                        <div className='d-flex  align-items-center mt-4' style={{justifyContent:'space-evenly'}}>
                            <div className='position_box1_join'>
                                <img className='img-about-1' src={posi3} width='100%' />
                                <h4> Position -1 </h4> 
                            </div>
                            <div className='position_box1_join'>
                                <img className='img-about-1' src={posi4} width='100%' />
                                <h4> Position -1 </h4> 
                            </div>
                        </div>
                    </div> */}
                    <div className='row mt-5'>
                        <div className='col-md-6'>
                            <img className='img-about-1' src={about2} width='100%' alt='about/img' />
                        </div>
                        <div className='col-md-6'>
                            <h3 className=''> Advantages Of Joining Us</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  The opportunity for professional growth and knowledge about crypto investment</li>
                                    <li>  An outstanding work environment and team that’s enthusiastic about crypto</li>
                                    <li> A chance to grow in a fast-paced industry and best</li>
                                    <li> A competitive salary and commission package.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5' onClick={() => formBox()} style={{cursor:'pointer'}}>
                        
                        <div className='col-md-12 text-center mb-5' ><h2>Jobs</h2></div>
                        <div className='col-md-3 border-bottom'>
                            <h3 className=''> JOB OPENINGS</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  Sales</li>
                                    <li>  Salaes Executive</li>
                                    <p className='m-0 mt-3'><b>Human Resource</b></p>
                                    <li >  HR Manager</li>
                                    <li>  HR Executive</li>
                                    <p className='m-0 mt-3'><b>Operations</b></p>

                                    <li className=''>  Operation Head</li>
                                    <li>  Operation Manager</li>
                                    <li> Operation Executive</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 border-bottom'>
                            <h3 className=''> BRAND & DESIGN</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  Brand Content Planning</li>
                                    <li>  Brand Copywriting Planning</li>
                                    <li> Global media public relations</li>
                                    <li>Motion Graphics animation</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 border-bottom'>
                            <h3 className=''> TECHNICAL SUPPORT</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  API Integration Engineer</li>
                                    <li>  API Integration Engineer-Technical Support</li>
                                    <li> API Technical Support</li>
                                    <li>GateChain Technical Support Engineer-Technical Support</li>
                                    <li>Technical Support Position</li>
                                    <li>Technical Support Specialist (API support)</li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-3 border-bottom'>
                            <h3 className=''> PRODUCT R&D</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>Android</li>
                                    <li>AWS</li>
                                    <li> Front End Development Engineer</li>
                                    <li>Golang</li>
                                    <li>iOS</li>
                                    <li>Linux C</li>
                                    <li>PHP</li>
                                    <li>Quantitative R&D Engineer</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 border-bottom mt-3'>
                            <h3 className=''> FINANCE</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  Analyst</li>
                                    <li>  API Integration Engineer-Technical Support</li>
                                    <li> Investment Associate</li>
                                    <li>Senior Investment Associate</li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 border-bottom mt-3'>
                            <h3 className=''> BUSINESS DEVELOPMENT</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  Assistant of Business Development</li>
                                    <li>  BD Manager</li>
                                    <li>Business Development Manager - Futures</li>
                                    <li>Business Development Manager (Ukraine)</li>
                                    <li>Global business development</li>
                                    <li>Institutional Development and Account Manager</li>
                                    <li>Institutional Growth Manager</li>
                                    <li>Key Account development</li>
                                    <li>Senior BD Strategy Manager</li>
                                    <li>Traders</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 border-bottom mt-3'>
                            <h3 className=''> MARKETING & OPERATION</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  Business Development Specialist-NFT</li>
                                    <li>  Content Managers-Marketing & Operation</li>
                                    <li> Creative Content Marketing</li>
                                    <li>Global Local Market leader</li>
                                    <li>KOL Manager</li>
                                    <li>Lending product marketing manager</li>
                                    <li>Marketing Project Senior Manager</li>
                                    <li>Official Youtuber-Marketing & Operation</li>
                                    <li>SEO Content Manager</li>
                                    <li>Technical SEO Manager</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 border-bottom mt-3'>
                            <h3 className=''> RISK CONTROL & INTERNAL AUDIT</h3>
                            <div className='about-story-text'>
                                <ul>
                                    <li>  Compliance Auditor</li>
                                    <li>  AML Manager (Monitoring & Investigation)</li>
                                    <li> AML Manager（ AML & CDD）</li>
                                    <li>Compliance manager（Review and Research）</li>
                                    <li>Risk Control Manager</li>
                                    <li>Risk Control Role</li>
                                </ul>
                            </div>
                        </div>
                    </div>






                    {/* <div className='row mt-5 border-bottom'>
                        <div className='col-md-12 '>
                            <div className='about-story-text '>
                                <div className='w-md-25 float-right'>
                                    <ul>
                                        <li>  DETAILS OF HR: </li>
                                        <li>Contact No.: +44 7405 456095,</li>

                                        <li>Mail:  contact@fudxcoin.com,</li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='row mt-5  align-items-center justify-content-center m-auto '  >
                        
                        <div className='col-md-6 card joinus mt-5 p-5 d-none' id='join__in'>
                            <div className='text-center mt-4 mb-3'>
                                <h3>Join Us</h3>
                            </div>
                            <div className=''>
                            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd-rjoWDKwo5APcbsWgt3mDnaVbAfRmRrS_-_QGIfbo15fF_A/viewform?embedded=true" width="640" height="677" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                                <form className='text-secondary'>
                                    <div className="form-group">
                                        <label for="name">Name</label>
                                        <input type="email" className="form-control" id="name"  placeholder="Enter  your name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Email address</label>
                                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter your email" />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Mobile</label>
                                        <input type="email" className="form-control" id="phone"  placeholder="Enter your mobile number" />
                                    </div>
                                    <div className="form-group">
                                        <label for="dis">Description</label>
                                        <textarea id="dis" className="form-control" name="description" rows="4" cols="50"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label for="attachment">Attachment  cv</label>
                                        <input type="file" style={{padding:'5px'}} className="form-control" id="attachment" placeholder="" />
                                    </div>
                                    <div className='text-center'>
                                        <button type="submit" style={{backgroundColor:'rgb(40 205 149)'}} className="btn btn-lg ">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            {/* <Footer /> */}
        </div>
    )
}
export default JoinUs;


