import * as types from '../actions/_types';

const initialState = {
  deposit_token: [],
  token_details:[],
  token_address:[],
  deposit_transaction:[],
};

export default function token(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_DEPOSIT_DETAILS:
      return { ...state, deposit_token: action.payload };
    case types.SAVE_DEPOSIT_TOKEN_DETAILS:
      return {...state, token_details: action.payload};  
    case types.SAVE_TOKEN_ADDRESS:
      return{...state,token_address: action.payload};
      case types.SAVE_DEPOSIT_TRANSACTION:
        return {...state, deposit_transaction: action.payload};
    default:
      return state;
  }
}

