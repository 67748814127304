import * as types from '../actions/_types';

const initialState = {
  
  trade_order : [],
  viewAllOrder:[],
};

export default function chart(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_TRADE_ORDER_DATA:
      return { ...state, trade_order: action.payload }; 
    case types.SAVE_ALL_ORDER_DETAILS:
      return {...state, viewAllOrder: action.payload};  
    default:
      return state;
  }
}

