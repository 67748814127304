import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const Spotlight = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          {/* <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul> */}
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Spotlight Application</h2>
            <h6>{PROJECT_NAME} Listing Application Form 2022</h6>
            <h6> Thank you for your interest in the {PROJECT_NAME} Exchange,<br /> please fill out the application form with valid information, we will review your project based on the information you provided, if anything is found<br /> to be invalid or false, your project will not be considered for listing on {PROJECT_NAME}</h6>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0 align-items-center First_row'>
            <div className='col-md-6' >
              <div style={{borderRadius:'20%',overflow:'hidden'}}>
                <img className='img-about-1' src={about1} width='100%' alt='spotlight/img' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h6>{PROJECT_NAME} Listing Application Form 2022<br />
                Thank you for your interest in the {PROJECT_NAME} Exchange, please fill out the application form with valid information, we will review your project based on the information you provided, if anything is found to be invalid or false, your project will not be considered for listing on {PROJECT_NAME}.                                                                                                                                                                                                                                        Please submit this application and your project will be sent for review by the Research Department. After you pass the initial review, we will share with you the listing workflow and tips, additionally, an account manager will be assigned to you, to walk you through the listing process.
                </h6>
              </div>
            </div>
            
          </div>
        </div>
       
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row vision'>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>

              <ol style={{color:'#000',marginLeft:'20px'}}>
                <h3 className='ml-4'>LISTING TERMS FOR APPLYING:</h3>
                  <li><h6>Please do not push us on giving the status of your project during the review process, the review for each project will take time and the research deep, there won’t be a date guaranteed for reviewing, integrating or testing your project, we will follow our workflow and rules strictly. The best thing to do is to provide clear concise information on your application and do not leave any detail out, no matter how small you think it may be.</h6>
                  </li>
                  <li><h6>Please follow our listing workflow and contact your account manager if you have anything urgent, but this won’t be considered to accelerate our review process.</h6>
                  </li>
                  <li><h6>All project reviews will be carried out in order, we do not give privilege to any project for an easier review.</h6>
                  </li>
                  <li><h6>Speaking directly with the decision-maker of your team is required, this will be considered as part of the project review. A minimum of 3 contacts at the executive level must be included in your application.<br />
                  No project shall use the {PROJECT_NAME} name in any publication whether Online or in print without the express written consent of {PROJECT_NAME} and any such announcements may only be made after the {PROJECT_NAME} official announcement if your token is listed. All prospective projects will have a Non-Disclosure Agreement to review, sign and return before any details about the project listing will be discussed.
                  </h6>
                  </li>
                  
                </ol>
                
              </div>
            </div>
            <div className='col-md-6 about-story-text about-page'>
              <img className='img-about-1' src={about3} width='100%' alt='spotlight/img' />
              <h6>5. Please do not pre-transfer any funds or tokens to us or any third parties, we won’t confirm any transfer beyond the requirements from our official listing contract. And our staff shall never ever ask you for any transfer via SNS, emails or chats.</h6> 

            </div>
          </div>
        </div>

        <div className='about_box-1 mt-5 border-top border-bottom'> 
          <div className='text-center mt-3'><h3>Email ID</h3></div>
          <div className='row m-0  First_row vision   '>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>
                <ol style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>Project Name/Token Ticker？*</h6>
                  </li>
                  <li><h6>Project Official Website*</h6>
                  </li>
                  <li><h6>What is the nature of the Project?*<br/> DeFi <br/>NFT <br />Stable Coin <br />Protocol<br />Dapp<br/>Currency<br/>Public Chain<br/>Other:</h6> </li>
                  <li><h6>What are the pain-points that the project is trying to solve?</h6>
                  </li>
                  <li><h6>What is the current number of users or nodes that are running?</h6>
                  </li>
                </ol>
                
              </div>
            </div>
            <div className='col-md-6'>
            <div className='about-story-text about-page'>
                <ol start="6" style={{color:'#000',marginLeft:'20px'}}>

                  <li><h6>Who are the target users? (e.g. to the business partner or to the customers etc.)</h6>
                  </li>
                  <li><h6>Please describe the main competitors of your project and provide us with your comparison with other products.</h6> </li>
                  <li><h6>Please briefly describe the technical characteristics of the project and its business model features.</h6>
                  </li>
                  <li><h6>Please describe the current and/or potential major partnership with your project</h6>
                  </li>
                  <li><h6>Please provide a detailed project roadmap (i.e., technology and commercial development)</h6> </li>
                  <li><h6>What level of development have you already achieved? (e.g., MVP/Alpha/Beta/Testnet/Mainnet)?</h6></li>
                  <li><h6>Please share with us a link to the demo or the product for us to try it.</h6></li>
                </ol>
                
              </div>
            </div>
           
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              {/* <div className='text-center mb-4'>
                <h2>Out of Scope Vulnerabilities</h2>
               
              </div> */}
              <div className='row mt-3'>
                <div className='col-md-6 about-page'>
                  <ol start="1"  >
                    <li><p>What's the token type? E.g. ERC20, TRC20 etc.</p>
                    </li>
                    <li><p>The total supply of tokens. If the total amount of tokens is not fixed, please provide how your token supply could change according to various conditions</p>
                    </li>
                    <li><p>What is the actual circulating amount of your tokens to be listed at the exchange?</p>
                    </li>
                    <li><p>What is the current market capitalization of the token/coin?</p>
                    </li>
                    <li><p>Please describe function and purpose of your project tokens*</p>
                      <h6>
                      Governance<br/>
                      Staking rewards <br/>
                      Airdrop rewards<br/>
                      Gas fee<br />
                      Payment for service/product<br/>
                      Liquidity<br/>
                      Other:
                      </h6>

                    </li>
                    <li><p> If you have checked any applicable features in the previous question, please describe each applicable feature and how it is associated with the token/coin.</p></li>
                    <li><p>Please describe the project token distribution plan and locked up plan in detailed</p></li>
                    
                  </ol>
                </div>
                <div className='col-md-6 about-page'>
                <img className='img-about-1' src={about4} width='100%' alt='spotlight/img' />
                <ol start="8"  >
                <li><p>Please provide the TokenVault/TokenLock smart contract address</p></li>
                    <li><p>Is the code of token smart contract open source or partially open source? If yes, please provide the project Github address or any other ways for us to view the open-sourced code</p></li>
                    
                  </ol>
                </div>
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>
        <div className='about_box-1 mt-5 pt-4 border-top'>
          <div className='row m-0  First_row vision'>
            <div className='col-md-6 border-right'>
              <div className='about-story-text about-page'>
                {/* <div className='text-center m-3'>
                  <h3>In-Scope Properties</h3>
                  </div> */}
                
                <ol style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>Was the token/coin offered through the Private Sale/Seed Round etc?*</h6>
                  <h4 className='m-0'>Yes/No</h4>
                  </li>
                  <li><h6>If Yes, please specify the institutions participating your seed/angel/cornerstone/presale rounds, and the amount they have committed in each round in fiat or tokens, respectively</h6></li>
                  <li><h6>Please provide the time of private sales for the project tokens</h6></li>
                  <li><h6>Please provide the price of tokens in each sales round and the percentage of private sales to the total amount of tokens issued</h6></li>
                  <li><h6>Was the token/coin offered through an ICO/IEO/Public Sale?</h6></li>
                  <li><h6>If Yes, What was the ICO/IEO/Public Sale price per token/coin?</h6></li>
                  <li><h6>Please provide the time of the project's public token offering</h6></li>
                  <li><h6>Please provide the ICO/IEO/Public Sale Upper limit and/or lower limit</h6></li>
                  <li><h6>Please tell us how many participants in your token sale</h6></li>

                 
                </ol>
                
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>
                <ol start="9" style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>How were the funds raised during the ICO/IEO/Public Sale used? What is the planned use for such funds going forward</h6></li>
                  <li><h6>Is there an escrow account for the ICO/IEO/Public Sale? If so please specify escrow method.</h6></li>
                  <li><h6>Please provide the latest valuation of the project (Millions of Dollars)</h6></li>
                  <li><h6>If there are any terms & Conditions or user agreements from the project that limit the token participants to specific groups  professionally and geographically</h6></li>
                  <li><h6>Are you cooperating with any liquidity provider right now? If yes, please leave the name</h6></li>
                  <li><h6>Which exchange(s) is the token/coin currently trading on?</h6></li>
                  <li><h6>In addition to the above-mentioned exchanges, are you negotiating with exchanges other than {PROJECT_NAME}? If so, which exchanges have you spoken to?</h6> </li>
                  
                </ol>
                
              </div>
            </div>
           
          </div>
        </div>
       
        <div className='about_box-1 mt-5'>

          <div className='row m-0  First_row about-page vision'>
            <div className='col-md-4 border-right'>
              <div className='about-story-text'>
                <ol style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>The total number of full-time employees working on this project. Please include the number of employees working in technology, marketing and other roles for this project
                      </h6>
                  </li>
                  <li><h6>The total number of full-time employees working on this project. Please include the number of employees working in technology, marketing and other roles for this project
                    </h6>
                  </li>
                  <li><h6>Please describe the project advisors profiles and how has each of them help to develop the project in the past.
                      </h6>
                  </li>
                 
                </ol>
                
              </div>
            </div>
            <div className='col-md-4 border-right'>
              <div className='about-story-text'>
                <ol style={{color:'#000',marginLeft:'20px'}}>
                  <li>
                    <h6>
                    Please list the number of people who follows your project at the social media platforms mentioned below
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please share with us any key stats of your Project, such as the total number of users, DAU, MAU, etc
                    </h6>
                  </li>
                  <li>
                    <h6>
                    Please specify numbers of media coverage of the project and please kindly include links of significant stories in the appendix of this form
                    </h6>
                  </li>
                 
                </ol>
                
              </div>
            </div>

            <div className='col-md-4'>
              <div className='about-story-text'>
                <ol style={{color:'#000',marginLeft:'20px'}}>
                  <li>
                    <h6>What's your budget of the listing fee (in USDT)</h6>
                  </li>
                  <li>
                    <h6>How much worth of tokens are you willing to reserve for post-listing marketing activities?</h6>
                  </li>
                  <li>
                    <h6>What is your timeline to get listed?</h6>
                  </li>
                  <li>
                    <h6>Referred by?</h6>
                  </li>
                  <li>
                    <h6> Please leave the contact of people who responsible for listing
                      Thank you for choosing {PROJECT_NAME}! If there is anything else you would like to add, please feel free to leave below.
                    </h6>
                  </li>
                  
                </ol>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Spotlight