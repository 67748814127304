import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 
import { get_2nd_swap_balance_wallet_type, get_swap_balance_wallet_type } from './assets.action';

const get_trade_order = (data) => {
    return {
        type: types.SAVE_TRADE_ORDER_DATA,
        payload: data
    }
}


export const getTredeOrder_data = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.get_trade_order_api(data, options).then((res) => { 
         const  res1 = res.data;
            dispatch(get_trade_order(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const tradeCoinAction = (data,pairName,setbalanceSell,setbalanceBuy, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.tradeCoinAction_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            // console.log('action',data.tradingCurrency)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                dispatch(getTredeOrder_data({status:'PENDING', tradingCurrency:  data.tradingCurrency}))
                dispatch(stopLoader());
                dispatch(get_swap_balance_wallet_type({tokenName: pairName,wallet_type:'trading_wallet'},setbalanceBuy));
                if(data.tradingCurrency != pairName){
                    dispatch(get_2nd_swap_balance_wallet_type({tokenName: data.tradingCurrency,wallet_type:'trading_wallet'},setbalanceSell));
                }
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}



const get_ViewAllOrder = (data) => {
    return {
        type: types.SAVE_ALL_ORDER_DETAILS,
        payload: data
    }
}


export const ViewAllOrders = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.get_ViewAllOrder_api(data, options).then((res) => { 
         const  res1 = res.data;
            dispatch(get_ViewAllOrder(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const cancelTradeOrder = (data,setcancelOrder,cancelOrder, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.cancelTradeOrder_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                dispatch(ViewAllOrders({status:'PENDING'}));
                dispatch(stopLoader());
                 if(cancelOrder === true ){
                    setcancelOrder(false) 
                 }else{ 
                     setcancelOrder(true)
                };
                
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}