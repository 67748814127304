import React from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col } from "react-bootstrap";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordFn } from '../../../redux/actions/user.action';
import { toast } from "../../../components/toast";
import { PROJECT_NAME } from "../../../_constants";
import logo from '../../../_assets/images/js_logo.png';
import { loginlang,registerlang } from "../../../lang/lang";
import { LangState } from "../../../LangugeContext";

const ForgotPassword = ({ history }) => {
  const {langchange} = LangState();

  const forgotPass = useSelector(state => state.persist.forgotPass);
  const dispatch = useDispatch();

  const handleForgotPass = (values) => {
    // dispatch(forgotPasswordFn({ ...values},history));
  }

  // const handleVerifyOtp = (values) => {
  //   if (parseInt(values['otpCode']) === parseInt(forgotPass['otpCode'])) {
  //     dispatch(verifyOtpFn(history));
  //   } else {
  //     toast.info('Invalid OTP code.');
  //   }
  // }

  return (
    <>
     <main>
        <section className="reset__password__sec">
            <div className="sec__content">
                <div className="sec__text">
                    <div className="sec__logo">
                    <Link to='/'>  
                    
                        <img src={logo} width='90' height='90' alt="logo" />
                        </Link>
                    </div>
                    <div className="sec__text__inner">
                        <h2 className="sec__heading">{loginlang['0']['h1'][langchange]}</h2>
                        <p className="sec__brief">{loginlang['0']['p'][langchange]}</p>
                    </div>
                </div>
                <div className="sec__form">
                    <div className="upper__text">
                        <span>{loginlang['0']['link'][langchange]} <Link to="/login">{registerlang['0']['linkbtn'][langchange]}</Link></span>
                       
                    </div>
                    <ForgotPasswordForm history={history} />

                </div>
            </div>
        </section>
    </main>                    
                      
    </>
  );
};

export default ForgotPassword;
