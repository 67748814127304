import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const PrivacyPolicy = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Privacy Policy</h2>
            <p>{PROJECT_NAME} Privacy Policy <br/>
            [Last revised: 27 January 2020]</p>
            <p>This Privacy Policy explains how {PROJECT_NAME} and its Affiliates <br/>(“{PROJECT_NAME} ”) collects, uses, shares,
and protects user information obtained through the {PROJECT_NAME} .com website.<br/> The terms “we,”
“us,” and “our” refer to {PROJECT_NAME} and its affiliates.</p>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        <div class="about_story">
          <div className='row m-0 align-items-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner_story_top'>
                <img className='img-about-1' src={Ab} width='100%' alt='privacyPolicy/img' />
                <img src={abcd} width='100%' alt='privacyPolicy/img' />
              </div>
              <div className='banner_story_top-2'>
                <img className='img-about-1' src={abc} width='100%' alt='privacyPolicy/img' />
                <img src={abcde} width='100%' alt='privacyPolicy/img' />
              </div>
            </div>
            <div className='col-lg-7 col-md-6'>
              <h3 className='about_title'> {PROJECT_NAME} Privacy Policy</h3>
              <div className='about-story-text'>
                <h6>
                This Privacy Policy explains how {PROJECT_NAME} and its Affiliates (“{PROJECT_NAME} ”) collects, uses, shares,
                and protects user information obtained through the {PROJECT_NAME} .com website. The terms “we,”
                “us,” and “our” refer to {PROJECT_NAME} and its affiliates. When we ask for certain personal
                information from users it is because we are required by law to collect this information or it is
                relevant for specified purposes. Any non-required information you provide to us is done so
                voluntarily. You decided whether to provide us with this non-required information; you may
                not be able to access or utilize all of our Services if you choose not to.
                </h6>
                <h6>By using the Site, you consent to the data practices described in this Privacy Policy. On
                    occasion, {PROJECT_NAME} may revise this Privacy Policy to reflect changes in law or our personal
                    data collection and use practices. If material changes are made to this Privacy Policy, the
                    changes will be announced by posting on the site. We will ask for your consent before using
                    your information for any purpose that is not covered in this Privacy Policy.
                </h6>
                <h6>
                The latest privacy policy has incorporated elements from the General Data Protection
                Regulation (GDPR) as we act in accordance to its personal information processing rules within
                the European Economic Area (EEA).
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='about_title text-center m-3'>
            <h3 className='mb-0'>INFORMATION {PROJECT_NAME} COLLECTS</h3>
            <h4 className='mt-0'>We want you to understand the types of information we
              collect when you register for and use {PROJECT_NAME} services
            </h4>
          </div>
          <div className='row m-0 align-items-center First_row'>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h6>
                Information you provide to us at registration
                </h6>
                <h6>When you create an {PROJECT_NAME} Account, you provide us with personal information that
                    includes your contact information (Email Address, name, and a password). You can also
                    choose to add a phone number for SMS or Google Authenticator account to be used for 2FA
                    verification for improved security.
                </h6>
                <h6>
                  Information we collect when authenticating user identity
                </h6>
                <h6>To comply with global industry regulatory standards including Anti-Money Laundering (AML),
                    Know-Your-Customer (KYC), and Counter Terrorist Financing (CTF), {PROJECT_NAME} requires user
                    accounts to undergo user identity authentication for both Personal & Enterprise-level
                    accounts. This entails collecting formal identification.
                    <br/>Information we collect as you use our services</h6>
              
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about1} width='100%' alt='privacyPolicy/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0 align-items-center'>
            <div className='col-md-6'>
              <img className='img-about-1' src={about2} width='100%' alt='privacyPolicy/img' />
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
              <h3 className='mb-0'>Service Usage Information</h3>
                <h6>
                  Through your use of the {PROJECT_NAME} platform, we also monitor and collect tracking information
                  related to usage such as access date & time, device identification, operating system, browser
                  type and IP address. This information may be directly obtained by {PROJECT_NAME} or through third
                  party services. This service usage data helps us our systems to ensure that our interface is
                  accessible for users across all platforms and can aid during criminal investigations.
                </h6>
                <h3 className='mb-0'>Transaction Information</h3>
                <h6>For all personal and enterprise user accounts, we collect transaction information including
                  deposit snapshots, account balances, trade history, withdrawals, and order activity and
                  distribution history. This transaction data is monitored for suspicious trading activity for user
                  fraud protection, and legal case resolution.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
        <div className='text-center'> <h2>WHY DOES {PROJECT_NAME} COLLECT THIS INFORMATION </h2></div>

          <div className='row m-0 align-items-center First_row vision'>
            <div className='col-md-6'>
              <div className='about-story-text'>

                <h3 className='mb-0'>To provide and maintain our services</h3>
                <h6>We use the information collected to deliver our services and verify user identity.
                  We use the IP address and unique identifiers stored in your device’s cookies to help us
                  authenticate your identity and provide our service. Given our legal obligations and system
                  requirements, we cannot provide you with services without data like identification, contact
                  information and transaction-related information.
                </h6>
                <h3 className='mb-0'>To protect our users</h3>
                <h6>We use the information collected to protect our platform, users’ accounts and archives.
                    We use IP addresses and cookie data to protect against automated abuse such as spam,
                    phishing and Distributed Denial of Service (DDoS) attacks.<br />
                    We analyze trading activity with the goal of detecting suspicious behavior early to prevent
                    potential fraud and loss of funds to bad actors.
                    </h6>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about3} width='100%' alt='privacyPolicy/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1 mt-5'>
         
          <div className='row m-0 values pt-5 about-page'>
            <div className='col-md-6'>
              <img className='img-about-1' src={about4} width='100%'alt='privacyPolicy/img' />
            </div>
            <div className='col-md-6 mt-5'>
              <div className='about-story-text text-primary'>
                <h3 className='mb-0'>To comply with legal and regulatory requirements</h3>
                <h6>Respect for the privacy and security of data you store with {PROJECT_NAME} informs our approach
                    to complying with regulations, governmental requests and user-generated inquiries. We will
                    not disclose or provide any personal information to third party sources without review from
                    our legal case team and/or prior consent from the user.
                </h6>
                <h3>To measure site performance</h3>
                <h6>We actively measure and analyze data to understand how our services are used. This review
                  activity is conducted by our operations team to continually improve our platform’s
                  performance and to resolve issues with the user experience.
                  We continuously monitor our systems’ activity information and communications with users
                  to look for and quickly fix problems.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>

          <div className='row m-0 align-items-center First_row vision'>
            <div className='col-md-6'>
              <div className='about-story-text'>

                <h3 className='mb-0'>To communicate with you</h3>
                <h6>We use personal information collected, like an email address to interact with users directly
                  when providing customer support on a ticket or to keep you informed on logins, transitions,
                  and security. Without processing your personal information for confirming each
                  communication, we will not be able to respond to your submitted requests, questions and
                  inquiries. All direct communications are kept confidential and reviewed internally for
                  accuracy.
                </h6>
                <h3 className='mb-0'>To enforce our Terms of Use and other agreements</h3>
                <h6>It is very important for us and our customers that we continually review, investigate and
                    prevent any potentially prohibited or illegal activities that violate our Terms of Service. For
                    the benefit of our entire user base, we carefully enforce our agreements with third parties
                    and actively investigate violations of our posted Terms of Use. {PROJECT_NAME} reserves the right to
                    terminate the provision of service to any user found engaging in activities that violate our
                    Terms of Use.
                </h6>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about3} width='100%' alt='privacyPolicy/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='text-center mb-4'>
            <h2>HOW DOES {PROJECT_NAME} PROTECT USER DATA</h2>
            
          </div>
          <div className='row m-0 align-items-center values'>
            
            <div className='col-md-12'>
              <div className='about-story-text text-primary'>
                <h6>{PROJECT_NAME} has implemented a number of security measures to ensure that your information
                    is not lost, abused, or altered. Our data security measures include, but are not limited to: PCI
                    Scanning, Secured Sockets Layered encryption technology, pseudonymisation, internal data
                    access restrictions, and strict physical access controls to buildings & files. Please note that it
                    is impossible to guarantee 100% secure transmission of data over the Internet nor method of
                    electronic storage. As such, we request that you understand the responsibility to
                    independently take safety precautions to protect your own personal information.
                  <br />
                  If you suspect that your personal information has been compromised, especially account
                  and/or password information, please lock your account and contact {PROJECT_NAME} customer
                  service immediately.
                </h6>
                
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default PrivacyPolicy