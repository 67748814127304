import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import appdownload from '../../../../_assets/images/qrcode.png';
import proofStyle1 from '../../../../_assets/images/proofStyle1.svg';
import proofStyle2 from '../../../../_assets/images/proofStyle2.svg';
import proofStyle3 from '../../../../_assets/images/proofStyle3.svg';
import proofStyle4 from '../../../../_assets/images/proofStyle4.svg';
import proofEx from '../../../../_assets/images/proofEx.svg';
import note from '../../../../_assets/images/note.svg';
import { getUserDetails,kycproofSubmit,kycSignatureProofSubmit } from '../../../../redux/actions/dashboard.action';
import { useDispatch, useSelector } from 'react-redux';
import { startLoader } from '../../../../redux/actions/loader.action';
import { LangState } from '../../../../LangugeContext';
import { PROJECT_NAME } from '../../../../_constants';
import { kycpage } from '../../../../lang/lang';
import { toast } from '../../../../components/toast';


const ProofUpload = ({history}) => {
    const dispatch = useDispatch();
    const [documentFront,SetdocumentFront] =  useState();
    const [documentBack,SetdocumentBack] =  useState();
    const [kyc_image,Setkyc_image] =  useState();
    const [error,Seterror] =  useState();

    const {langchange,screenLoader,setscreenLoader} = LangState();
    const user = useSelector(state => state.dashboard.user);

    const handleSubmitForm1 = (e) => { 
        e.preventDefault();
        console.log('ji');
        if(documentFront != undefined && documentBack != undefined){
            setscreenLoader('true');
            e.preventDefault(); 
            let formData = new FormData();
            formData.append('documentFront', documentFront); 
            formData.append('documentBack', documentBack); 
            dispatch(kycproofSubmit(formData,history,setscreenLoader,user?.country));
            dispatch(startLoader());

        }else{

            var empDataErr = (documentBack == undefined)? 'Documents Back':'Documents Front';
            Seterror(' Field Required  '+empDataErr);
            toast.error('Please select again '+empDataErr);

        }
      }

    const uploadImage = (e, input) => {
        let file = e.target.files[0];
        if(file.size < 2000000){

            let createFileUrl = URL.createObjectURL(file);
            SetdocumentFront(file);
        }else{
            toast.error('Max 2mb image upload');

        }
        // setShowImage(URL.createObjectURL(e.target.files[0]))
    }
    const uploadBackImage = (e, input) => {
        let file = e.target.files[0];
        console.log('asdad',file.size);
        if(file.size < 2000000){
            let createFileUrl = URL.createObjectURL(file);
            SetdocumentBack(file);
        }else{
            toast.error('Max 2mb image upload');
  
        }
        // setShowImage(URL.createObjectURL(e.target.files[0]))
    }
console.log('documentFront',documentFront);
    const handleSubmitForm2 = (e) => { 
        e.preventDefault();
        if(kyc_image != undefined){
            setscreenLoader('true');
            e.preventDefault(); 
            let formData1 = new FormData();
            formData1.append('kyc_image', kyc_image); 
            dispatch(kycSignatureProofSubmit(formData1,history,setscreenLoader));
            dispatch(startLoader());
        }else{
            Seterror(' Field Required  Signature Image');
        }    
      }

    const uploadSignatureImage = (e, input) => {
        let file = e.target.files[0];
        let createFileUrl = URL.createObjectURL(file);
        Setkyc_image(file)
        // setShowImage(URL.createObjectURL(e.target.files[0]))
    }
    useEffect(() => {
        // if(user)
        dispatch(getUserDetails())
        // dispatch()
        // if(user.documentBack != ' ' || user.documentBack != null || user.documentBack != undefined ){
        //     setscreenLoader('false');

        // }
    }, [screenLoader])

    const  button = () => {
        return( <button type="submit" className="btn btn-success"> Submit </button>)
    }

    const nextBtn = () =>{
        if(user.country == 'India'){
            return(
             <Link to="/vendor/PanProofUpload" className='btn btn-success'>Next</Link>
             )
        }else{
            return(
            <Link to="/vendor/SelfieProofUpload" className='btn btn-success'>Next</Link>
)

        }

    }
    

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      
      today = mm + '/' + dd + '/' + yyyy;

  return (
    <div>
         <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn4'][langchange]}   </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn5'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData">  {kycpage['0']['nav']['btn6'][langchange]}  </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="verification-2">
            <div className="container-xl">
                <div className="links-name">
                    <p> KYC</p>
                    <p> <a href="#">/ Individual Verification </a> </p>
                </div> 
                <div className="alert alert-success">
                    <i class='fas fa-info-circle'></i>
                    <div className="">
                        {/* <p> Ensure that the materials you provide are true, accurate, and clear. Failure to follow the requirements will result in a failed verification and affect your trading experience.</p> */}
                            <p style={{fontSize:'16px'}}>KYC Status : <span className='text-primary'>{user?.kycStatus }  </span> {user?.kycStatus == 'REJECTED'? 'beacuse': ' '}  <span className='text-danger'>{user?.kycStatus == 'REJECTED'? user?.remarks :' '} </span>.</p>
                    </div>
                    
                </div>
                {/* <h4>KYC Status : <span className='text-primary'>{user?.kycStatus}  </span>  because {user?.remarks}.</h4>                             */}

                <div className="form-box2">
                    <form onSubmit={handleSubmitForm1} encType="multipart/form-data">
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='uploadproof-app-download d-flex'>
                                    <div className='img-box'> <img  src={appdownload} alt='app download' wigth='100'/></div>
                                    <div>
                                        <h4>Verify via {PROJECT_NAME} App</h4>
                                        <p>If you cannot use the computer to upload photos, scan the QR code with {PROJECT_NAME} app to complete the verification. Haven't installed {PROJECT_NAME} app? Download Here</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mt-4'>
                                <h3>Your Basic Verification Information</h3>
                                <div className='uploadproof-user-info d-flex'>
                                    <div>
                                        
                                        <p><span>Name: </span> {user?.firstname +' '+user?.lastname}</p>
                                        <p><span>Country/Region: </span>  {user?.country }</p>
                                        <p><span>Identity Document Type: </span>  {user?.documentType }</p>
                                        <p><span>ID Number: </span> {user?.documentNumber } </p>
                                        <div className='edit text-right'><Link to='/vendor/kycform'>Edit</Link></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mt-4'>
                                <h3>Upload Identity Document Photo</h3>
                                <div className='uploadproof-proof-pattren'>
                                    <div className='img'>    
                                        <div><img src={proofStyle1} alt='Good' /><p>Good</p></div>
                                        <div><img src={proofStyle2} alt='Not cropped' /><p>Not cropped</p></div>
                                        <div><img src={proofStyle3} alt='Not blur' /><p>Not blur</p></div>
                                        <div><img src={proofStyle4}  alt='No reflection'/><p>No reflection</p></div>
                                    </div>
                                    <p>Ensure that the text on the photo is clearly visible, and that the <span className='text-danger'> ID Type/ID No. is consistent  with the Basic Verification.</span> Only JPG/PNG images of no more than 3 MB are supported.</p>
                                </div>
                            </div>
                            <div className='col-md-12 mt-4'>
                                <div className='uploadproof-proof-input'>
                                    <p className='text-danger'>{error}</p>
                                    <h3 className='mb-2'>Front of ID *</h3>
                                    <label for='documentFront'><div className='input-boxs'><span>+</span></div></label>

                                    <input type='file' id='documentFront'  style={{display:'none'}}   onChange={uploadImage} accept=".jpg, .jpeg, .png, .heic, .heif" data-bv-field="documentFront" name='documentFront' size={'5kb'} />
                                    <img src={documentFront != undefined ? URL.createObjectURL(documentFront) : user != undefined ? user.documentFront : ''} width='200' alt='Front proof' className='ml-5' />

                                </div>  
                                <div className='uploadproof-proof-input mt-4'>
                                    <h3 className='mb-2'>Back of ID *</h3>
                                    <label for='proof2'><div className='input-boxs'><span>+</span></div></label>

                                    <input type='file' id='proof2' style={{display:'none'}} onChange={uploadBackImage}  accept=".jpg, .jpeg, .png, .heic, .heif" />
                                    <img src={documentBack != undefined ? URL.createObjectURL(documentBack): user != undefined ? user.documentBack : ''}  width='200' className='ml-5' alt='back proof' />

                                </div>
                            </div>
                            <div className="col-12 mt-5">
                                { user.kycStatus == 'REJECTED' || user.kycStatus== 'PENDING' ?  button():nextBtn() }
                                {/* {user.kycStatus == 'REJECTED' ||user.kycStatus== 'PENDING' ? button() : ' ' } */}

                            </div>
                            {/* <div className='col-md-12 mt-4'>
                                <h3>Take Selfie Photo *</h3>
                                <div className='uploadproof-user-info d-flex'>
                                    <div>
                                        <p>Upload a clear photo of you holding<span className='text-danger'> your ID and a handwritten note.</span> The photo must<span className='text-danger'> include your face.</span></p>
                                        <p><span>The handwritten note must contain the following:</span> </p>

                                        <ul>
                                            <li>  <p>A handwritten kyc_image  “ <span className='text-success'>{user?.firstname +' '+user?.lastname}</span>“</p></li>
                                            <li>  <p>The current date  “ <span className='text-success'>{today}</span>“</p></li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mt-4'>
                                <div className='uploadproof-proof-pattren'>
                                    <div className='img'>    
                                        <div><p>Note Example:</p><img src={note} alt='explain  Icon' /><p className='mt-2'><span>(tap to expand)</span></p></div>
                                        <div><p>Photo Example: </p><img src={proofEx}  alt='explain  Icon '/><p className='mt-2'><span>(tap to expand)</span></p></div>
                                        <div><p>Video Tutorial:</p><img src={proofEx} alt='explain  Icon'/><p className='mt-2'><span>(tap to view)</span></p></div>
                                    </div>
                                    <p className='text-danger'>*The example note is for reference only. The text highlighted in green should be replaced in accordance with your current situation.</p>
                                </div>
                            </div> */}
                            
                        </div>
    
                    </form>
                    {/* <form className='mt-5' onSubmit={handleSubmitForm2} encType="multipart/form-data">
                        <div className='row'>
                            <div className='col-md-12 my-4'>
                            <p className='text-danger'>{error}</p>

                            <h3 className='mb-2'>Upload Selfie *</h3>

                                <div className='uploadproof-proof-input'>
                                    <label for='proof3'><div className='input-boxs'><span>+</span></div></label>

                                    <input type='file' id='proof3' style={{display:'none'}} onChange={uploadSignatureImage} accept=".jpg, .jpeg, .png,.HIEC,.HIEF" />
                                    <img src={kyc_image != undefined ? URL.createObjectURL(kyc_image) : user != undefined ? user.signatureImage : ''} width='200' className='ml-5' alt='Selfie image' />

                                </div>
                                
                            </div>
                            
                            <div className="col-12 text-center "x style={{display:(user?.signatureImage )? 'none' : 'block'}}>
                                <div className="">
                                <input type="checkbox"  value="" id='proof6' className='border border-primary'  />
                                    <label className="check-leble" for='proof6'>
                                    I have read and agree to the <a href="#" className='text-success'> the User Identity Verification Disclaimer. </a> 
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                {user?.signatureImage ? ' ' :button()}
                                {user.kycStatus == 'REJECTED' ? button() : ' ' }

                            </div>
                        </div>
                    </form> */}
                    <div className="loading" style={{display: screenLoader == 'false' ? 'none':'block' }} >Loading&#8230;</div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default ProofUpload