import React from 'react'
import coinimage from '../../../../_assets/images/newImages/coin-image.png';
import bitcoin from '../../../../_assets/images/bitcoin.png';
import icon from '../../../../_assets/images/icon.png';
import mobile_image from '../../../../_assets/images/newImages/mobile_image.png';
import trading_img from '../../../../_assets/images/trading_img.png';
import logo_img from '../../../../_assets/images/logo_img.png';
import circle_img from '../../../../_assets/images/newImages/circle_img.png';
import circle_img1 from '../../../../_assets/images/3circle_img.png';
import internet_img from '../../../../_assets/images/internet_img.png';
import section_4 from '../../../../_assets/images/newImages/section_4.png';
import appleicon  from '../../../../_assets/images/apple-icon.png';
import appstore from '../../../../_assets/images/app-store.png';
import android from '../../../../_assets/images/android.png';
import googleplay from '../../../../_assets/images/google-play.png';
import serviceimage from '../../../../_assets/images/service-image.svg';
import communityimage from '../../../../_assets/images/community-image.svg';
import newsimage from '../../../../_assets/images/news-image.svg';
import linkimage from '../../../../_assets/images/link-image.svg';
import { home_altcoin_exchange,home_altcoin_exchange_p, home_altcoin_exchange_price, home_people_exchange, home_platform_trade, home_section_coin, home_trade_sec } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import { Link, useHistory } from 'react-router-dom';






const Coinabout = () => {
    const {langchange} = LangState();
    const history = useHistory()
  return (
    <div>
        


        <section className="altcoin_exchange">
        <div className="container">
            <div className="sec_content">
                <div className="sec_heading">
                    <h2>{home_altcoin_exchange['0'][langchange]}</h2>
                    <span className="info_heading">{home_altcoin_exchange_p['0'][langchange]}</span>
                    <div className="sec_price">
                        <div className="price_detail">
                            <img src={bitcoin} alt="Bitcoin" />
                            <span className="coins">50+</span>
                            <span className="coins_detail">{home_altcoin_exchange_price[0]['coin'][langchange]}</span>
                        </div>
                        <div className="price_detail">
                            <img src={icon} alt="error" />
                            <span className="coins">6 Lakh</span>
                            <span className="coins_detail">{home_altcoin_exchange_price[0]['volume'][langchange]}</span>
                        </div>
                    </div>
                </div>
                <div className="sec_image">
                    <img src={coinimage} alt="Altcoin Exchange" />
                </div>
            </div>
        </div>
    </section>

    <section className="altcoin_exchange">
        <div className="container">
            <div className="sec_content sec_content_secound">
                <div className="sec_image">
                    <img src={mobile_image} alt="Social Trading Platform" />
                </div>
                <div className="sec_heading">
                    <h2>{home_platform_trade['0']['h1'][langchange]}</h2>
                    <span className="info_heading">{home_platform_trade['0']['p'][langchange]}</span>
                    <div className="sec_price">
                        <div className="price_detail">
                            <img src={trading_img} alt="error" />
                            <span className="coins">50 Thousand</span>
                            <span className="coins_detail">{home_platform_trade['0']['news'][langchange]}</span>
                        </div>
                        <div className="price_detail">
                            <img src={logo_img} alt="people" />
                            <span className="coins">1 Lakh</span>
                            <span className="coins_detail">{home_platform_trade['0']['global'][langchange]}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="altcoin_exchange">
        <div className="container">
            <div className="sec_content">
                <div className="sec_heading">
                    <h2>{home_people_exchange['0']['h1'][langchange]}</h2>
                    <span className="info_heading">{home_people_exchange['0']['p'][langchange]}</span>
                    <div className="sec_price">
                        <div className="price_detail">
                            <img src={internet_img} alt="error" />
                            <span className="coins">5+</span>
                            <span className="coins_detail">{home_people_exchange['0']['globle'][langchange]}</span>
                        </div>
                        <div className="price_detail">
                            <img src={circle_img1} alt="country" />
                            <span className="coins">8+</span>
                            <span className="coins_detail">{home_people_exchange['0']['countery'][langchange]}</span>
                        </div>
                    </div>
                </div>
                <div className="sec_image">
                    <img src={circle_img} alt="The People`s Exchange" />
                </div>
            </div>
        </div>
    </section>

    <section className="sec__trande altcoin_exchange">
        <div className="container">
            <div className="sec_content_app">
                <div className="sec_image_app">
                    <img src={section_4} alt="Trade Anytime" width={"100%"} />
                </div>
                <div className="sec_heading_app ">
                    <h2 className="info_content">{home_trade_sec['0']['h1'][langchange]}</h2>
                    <div className="info_heading_trade">{home_trade_sec['0']['p'][langchange]}
                    </div>


                    
                    <div className="download__app">
                        <div className="download__app__wrapper">
                            <div className="download__app__icon" onClick={() => history.push('/NoData')}>
                                <img src={appstore} alt="appstore/img" />
                                <span>App Store</span>
                                <div className="qr__code">
                                    <span>
                                        <img src="assets/img/app-qr.png" alt="App Store" />
                                    </span>
                                </div>
                            </div>
                            <div className="download__app__icon "  onClick={() => history.push('/NoData')}>
                                <img src={appleicon} alt="apple-icon/img" />
                                <span>iOS</span>
                                <div className="qr__code">
                                    <span> 
                                        <img src="assets/img/app-qr.png" alt="iOS" />
                                    </span>
                                </div>
                            </div>
                            <div className="download__app__icon "  onClick={() => history.push('/NoData')}>
                                <img src={googleplay} alt="googleplay/img" />
                                <span>Google Play</span>
                                <div className="qr__code">
                                    <span>
                                        <img src="assets/img/app-qr.png" alt="Google Play" />
                                    </span>
                                </div>
                            </div>
                            <div className="download__app__icon"  onClick={() => history.push('/NoData')}>
                                <img src={android} alt="android/img" />
                                <span>Android</span>
                                <div className="qr__code">
                                    <span>
                                        <img src="assets/img/app-qr.png" alt="Android" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="kucoin_section">
        <div className="sec_container_kucoin">
            <h2 className="sec_heading_kucoin">{home_section_coin[0]['h1'][langchange]}</h2>
            <div className="brief_heading">
                <div className="customer_service">
                    <img src={serviceimage} alt="Customer Service" />
                    <p className="service_text">{home_section_coin[0]['box1']['h1'][langchange]}</p>
                    <p className="text_dateil">{home_section_coin[0]['box1']['p'][langchange]}</p>
                    <Link to="/NoData">{home_section_coin[0]['btn'][langchange]}
                        <img src={linkimage} alt="arrow" />
                    </Link>
                </div>
                <div className="customer_service">
                    <img src={communityimage}  alt="Community"/>
                    <p className="service_text">{home_section_coin[0]['box2']['h1'][langchange]}</p>
                    <p className="text_dateil">{home_section_coin[0]['box2']['p'][langchange]}</p>
                    <Link to="/NoData">{home_section_coin[0]['btn'][langchange]}
                        <img src={linkimage} alt="arrow"/>
                    </Link>
                </div>
                <div className="customer_service">
                    <img src={newsimage} alt="News"/>
                    <p className="service_text">{home_section_coin[0]['box3']['h1'][langchange]}</p>
                    <p className="text_dateil">{home_section_coin[0]['box3']['p'][langchange]}</p>
                    <Link to="/NoData">{home_section_coin[0]['btn'][langchange]}
                        <img src={linkimage} alt="arrow"/>
                    </Link>
                </div>
            </div>
        </div>
    </section>

    

    </div>
  )
}

export default Coinabout