import React from 'react'
import { LangState } from '../../../LangugeContext';
import { leveragedpage } from '../../../lang/lang';
const leveraged = () => {
    const {langchange} = LangState();

    return (
    <div>
        <div className="lever-banner">
            <div className="container">
                <div className="text">
                    <h1> {leveragedpage['0']['h1'][langchange]}</h1>
                    <p> {leveragedpage['0']['p'][langchange]} </p>
                    <button type="button" className="btn"> {leveragedpage['0']['btn'][langchange]} </button>
                </div>
            </div>
        </div>
        <div className="box-l">
            <div className="l-container">
                <ul className="nav" role="tab">
                    <li className="nav-item"> 
                        <a className="nav-link active" data-toggle="tab" href="#l-1">  {leveragedpage['0']['tblbtn1'][langchange]}  </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " data-toggle="tab" href="#l-2"> {leveragedpage['0']['tblbtn2'][langchange]}  </a>
                    </li>
                    <li className="nav-item"> 
                        <a className="nav-link " data-toggle="tab" href="#l-3">   {leveragedpage['0']['tblbtn3'][langchange]} </a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div id="l-1" className="tab-pane active">
                        <div className="table-responsive-lg"> 
                            <table className="table table-borderless table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-left"> {leveragedpage['0']['table']['label1'][langchange]}    </th>
                                        <th className="text-center" > {leveragedpage['0']['table']['label2'][langchange]}  </th>
                                        <th className="text-center" > {leveragedpage['0']['table']['label3'][langchange]}	  </th>
                                        <th className="text-center" >  {leveragedpage['0']['table']['label4'][langchange]}	 </th>
                                        <th className="text-right">  {leveragedpage['0']['table']['label5'][langchange]} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="t-name"> 
                                            <div className="d-flex"> 
                                                <p>  <img width="32" height="35" style={{margin: '0 10px'}} src={'https://assets-currency.kucoin.com/6183a1cb8d6d9e00068e6dd2_ada3S.png'}  />   </p>
                                                <p>  ADA3S <br/> 
                                                    <span className="badge badge-danger"> 3x short ADA </span>
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-box">
                                                <div className="left-box text-right">
                                                    <span> +5%</span><br/>
                                                    ADA Change
                                                </div>
                                                <div className="right-box ">
                                                    <span>5% </span><br/>
                                                    ADA3S Change
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center" >
                                            <p> 1.235 USDT<br/>
                                                1.23 USD
                                            </p>
                                        </td>
                                        <td className="text-center">
                                            <p> 1.2325 USDT<br/>
                                                1.23USD </p>
                                        </td>
                                        <td className="text-right">
                                            <a className="btn " style={{backgroundColor: '#EBC75B',color:'#fff'}} href="#" > {leveragedpage['0']['table']['btn1'][langchange]}</a>
                                            <a className="btn text-dark " href="#" > {leveragedpage['0']['table']['btn2'][langchange]} </a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="t-name"> 
                                            <div className="d-flex"> 
                                                <p>  <img width="32" height="35" style={{margin: '0 10px'}} src={'https://assets-currency.kucoin.com/6183a1cb8d6d9e00068e6dd2_ada3S.png'}  />   </p>
                                                <p>  ADA3S <br/> 
                                                    <span className="badge badge-warning"> 3x short ADA </span>
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-box">
                                                <div className="left-box text-right">
                                                    <span> +5%</span><br/>
                                                    ADA Change
                                                </div>
                                                <div className="right-box ">
                                                    <span>5% </span><br/>
                                                    ADA3S Change
                                                </div>
                                            </div>
                                        </td>

                                        <td className="text-center" >
                                            <p> 1.235 USDT<br/>
                                                1.23 USD
                                            </p>
                                        </td>
                                        <td className="text-center">
                                            <p> 1.2325 USDT<br/>
                                                1.23USD </p>
                                        </td>

                                        <td className="text-right">
                                            <a className="btn " style={{backgroundColor:' #EBC75B',color:'#fff'}} href="#" > {leveragedpage['0']['table']['btn1'][langchange]}</a>
                                            <a className="btn text-dark " href="#" > {leveragedpage['0']['table']['btn2'][langchange]} </a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="t-name"> 
                                            <div className="d-flex"> 
                                                <p>  <img width="32" height="35" style={{margin: '0 10px'}} src={"https://assets-currency.kucoin.com/6183a1cb8d6d9e00068e6dd2_ada3S.png"}  />   </p>
                                                <p>  ADA3S <br/> 
                                                    <span className="badge badge-success"> 3x short ADA </span>
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-box">
                                                <div className="left-box text-right">
                                                    <span> +5%</span><br/>
                                                    ADA Change
                                                </div>
                                                <div className="right-box ">
                                                    <span>5% </span><br/>
                                                    ADA3S Change
                                                </div>
                                            </div>
                                        </td>

                                        <td className="text-center" >
                                            <p> 1.235 USDT<br/>
                                                1.23 USD
                                            </p>
                                        </td>
                                        <td className="text-center">
                                            <p> 1.2325 USDT<br/>
                                                1.23USD </p>
                                        </td>

                                        <td className="text-right">
                                            <a className="btn " style={{backgroundColor: '#EBC75B',color:'#fff'}} href="#" > {leveragedpage['0']['table']['btn1'][langchange]}</a>
                                            <a className="btn text-dark " href="#" > {leveragedpage['0']['table']['btn2'][langchange]} </a>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="QAA">
                            <div className="QAA-container">
                                <h3> Q&A </h3>
                                <div id="accordion">   
                                    <div className="card">
                                        <div className="card-header">
                                        <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                            {leveragedpage['0']['qa']['h1'][langchange]}
                                        </a>
                                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            {leveragedpage['0']['qa']['p1'][langchange]}
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <div className="card">
                                        <div className="card-header">
                                        <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                            {leveragedpage['0']['qa']['h2'][langchange]}
                                        </a>
                                        <h6> <i class='fas fa-angle-down'></i>  </h6>
                        
                                        </div>
                                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            {leveragedpage['0']['qa']['p2'][langchange]}
                                        </div>
                                        </div>
                                    </div>
                        
                                    <div className="card">
                                        <div className="card-header">
                                            <a className="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                                            {leveragedpage['0']['qa']['h3'][langchange]}
                                            </a>
                                    <h6> <i class='fas fa-angle-down'></i>  </h6>
                        
                                        </div>
                                        <div id="collapsefour" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                            {leveragedpage['0']['qa']['p3'][langchange]}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card">
                                        <div className="card-header">
                                            <a className="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                            {leveragedpage['0']['qa']['h4'][langchange]}
                                            </a>
                                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        
                                        </div>
                                        <div id="collapsefive" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                            {leveragedpage['0']['qa']['h4'][langchange]}
                                                <p> {leveragedpage['0']['qa']['p4']['p1'][langchange]}</p>
                                                <p> {leveragedpage['0']['qa']['p4']['p2'][langchange]}</p>

                                            </div>
                                        </div>
                                        </div>
                                        <div className="card">
                                        <div className="card-header">
                                            <a className="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                                            {leveragedpage['0']['qa']['h5'][langchange]}
                                            </a>
                                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        
                                        </div>
                                        <div id="collapsesix" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                                <ul>
                                                    <li> {leveragedpage['0']['qa']['p5'][langchange]} </li>
                                                </ul>
                                            
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card">
                                        <div className="card-header">
                                            <a className="collapsed card-link" data-toggle="collapse" href="#collapseseven">
                                            {leveragedpage['0']['qa']['h6'][langchange]}
                                            </a>
                                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        
                                        </div>
                                        <div id="collapseseven" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                            <ul>
                                                <li>{leveragedpage['0']['qa']['p6'][langchange]}</li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                        
                                    </div>          
                            </div>
                        </div>
                    </div>
                    <div id="l-2" className="tab-pane mt-5">
                        <form>
                            <div className="input-group ">
                                <div className="input-b">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="coin" />
                                    </div>
                                    <p className="" data-toggle="dropdown"> <i class='fas fa-caret-down'></i>  </p>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>

                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-b">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="status"/>
                                    </div>
                                    <p className="" data-toggle="dropdown"> <i class='fas fa-caret-down'></i>  </p>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-form-2" >
                                        <a href="#" className="dropdown-item"> Success  </a>
                                        <a href="#" className="dropdown-item"> Failed  </a>
                                        <a href="#" className="dropdown-item"> Pending  </a>

                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-b">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Last 7 days"/>
                                    </div>
                                    <p className="" data-toggle="dropdown"> <i class='fas fa-caret-down'></i>  </p>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-form-2">
                                        <a href="#" className="dropdown-item"> Last 7 days  </a>
                                        <a href="#" className="dropdown-item"> Last 30 days  </a>
                                        <a href="#" className="dropdown-item"> Last 90 days </a>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn"> Rest </button>
                        </form>

                        <div className="table-responsive "> 
                            <table className="table table-borderless table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-left"> Time   </th>
                                        <th className="text-center" > Leveraged Tokens	 </th>
                                        <th className="text-center" > Unit Share	  </th>
                                        <th className="text-center" >  Settled Net Worth		 </th>
                                        <th className="text-right">  Amount </th>
                                        <th className="text-right">  Settled At	 </th>
                                        <th className="text-right">  Status </th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="l-2" className="tab-pane mt-5">
                        <form>
                            <div className="input-group ">
                                <div className="input-b">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="coin"/>
                                    </div>
                                    <p className="" data-toggle="dropdown"> <i class='fas fa-caret-down'></i>  </p>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>
                                        <a href="#" className="dropdown-item"> ADA35  </a>

                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-b">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="status"/>
                                    </div>
                                    <p className="" data-toggle="dropdown"> <i class='fas fa-caret-down'></i>  </p>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-form-2" >
                                        <a href="#" className="dropdown-item"> Success  </a>
                                        <a href="#" className="dropdown-item"> Failed  </a>
                                        <a href="#" className="dropdown-item"> Pending  </a>

                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input-b">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Last 7 days"/>
                                    </div>
                                    <p className="" data-toggle="dropdown"> <i class='fas fa-caret-down'></i>  </p>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-form-2">
                                        <a href="#" className="dropdown-item"> Last 7 days  </a>
                                        <a href="#" className="dropdown-item"> Last 30 days  </a>
                                        <a href="#" className="dropdown-item"> Last 90 days </a>

                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn"> Rest </button>
                        </form>
                        <div className="table-responsive "> 
                            <table className="table table-borderless table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-left"> Time   </th>
                                        <th className="text-center" > Leveraged Tokens	 </th>
                                        <th className="text-center" > Unit Share	  </th>
                                        <th className="text-center" >  Settled Net Worth		 </th>
                                        <th className="text-right">  Amount </th>
                                        <th className="text-right">  Settled At	 </th>
                                        <th className="text-right">  Status </th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                                </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default leveraged