import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 

const saveUserProfile = (data) => {
    return {
        type: types.SAVE_USER_PROFILE,
        payload: data
    }
}
const savecoinAddress = (data) => {
    return {
        type: types.SAVE_COIN_ADDRESS,
        payload: data
    }
}
const savecointableAddress = (data) => {
    return {
        type: types.SAVE_COIN_TABLE_ADDRESS,
        payload: data
    }
}
const PackageData1 = (data) => {
    // console.log('PackageData',data);
    return {
        type: types.SAVE_PACKAGE,
        payload: data
        
    }
    
}
const saveBinanceBalance = (data) => {
    return {
        type: types.SAVE_BINANCE_BALANCE,
        payload: data
    }
}
const saveUsdtTransactions = (data) => {
    return {
        type : types.SAVE_USDT_TRANSACTIONS,
        payload:data
    }
}
const SaveBanners = (data) => {
    return {
        type : types.SAVE_BANNERS,
        payload:data
    }
}
export const getUserDetails = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.getUserDetails({}, options).then((res) => { 
         const  res1 = res.data.user;
            dispatch(saveUserProfile(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const kycFormSubmit = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.kycform(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success('Kyc updated  successfully.');
                history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const kycAadharSubmit = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.kycAadharform(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                history.push('/vendor/kycAadharVerify/'+res.data.client_id);

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const kycAadharVerifySubmit = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.kycAadharVerifyform(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const kycproofSubmit = (data, history,setscreenLoader,country_code) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeaderImage(token)
        return apiService.kycProof(data, options).then((res) => {

            console.log('outksdfbkjsd ', res)
            if (res.data.success == 1) {
                setscreenLoader('false');
                toast.success(res.data.message);
                if(country_code == 'India'){
                    history.push('/vendor/PanProofUpload');
                }else{
                    history.push('/vendor/SelfieProofUpload');
  
                }
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const kycPanproofSubmit = (data, history,setscreenLoader) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeaderImage(token)
        return apiService.kycPanProof(data, options).then((res) => {

            console.log('outksdfbkjsd ', res)
            if (res.data.success == 1) {
                setscreenLoader('false');
                toast.success(res.data.message);
                history.push('/vendor/SelfieProofUpload');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const kycSignatureProofSubmit = (data, history,setscreenLoader) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeaderImage(token)
        return apiService.kycSignatureProof(data, options).then((res) => {

            console.log('outksdfbkjsd ', res)
            if (res.data.success == 1) {
                setscreenLoader('false');
                toast.success('Proof updated  successfully.');
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const uploadPaymentProof = (data, history,setscreenLoader,id) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeaderImage(token)
        return apiService.uploadPaymentApi(data, options,id).then((res) => {

            if (res.data.success == 1) {
                setscreenLoader('false');
                toast.success(res.data.message);
                // history.push('/vendor/SelfieProofUpload');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
const save_deposit_tokens = (data) => {
    return {
        type: types.SAVE_DEPOSIT_DETAILS,
        payload: data
    }
}
export const get_deposit_tokens = () => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.depositToken({}, options).then((res) => { 
         const  res1 = res.data;
         console.log(res1);
            dispatch(save_deposit_tokens(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_deposit_tokens_details = (data) => {
    return {
        type: types.SAVE_DEPOSIT_TOKEN_DETAILS,
        payload: data
    }
}
export const get_deposit_tokens_details = (data) => (dispatch, getState) => {
    console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.depositTokenDetails(data, options).then((res) => { 
         const  res1 = res.data;
         console.log('hi its from function',res1);
            dispatch(save_deposit_tokens_details(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_token_Address = (data) => {
    return {
        type: types.SAVE_TOKEN_ADDRESS,
        payload: data
    }
}
export const get_token_Address = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.Token_address_api(data, options).then((res) => { 
         const  res1 = res.data;
         console.log(res1);
            dispatch(save_token_Address(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_deposit_transactions = (data) => {
    return {
        type: types.SAVE_DEPOSIT_TRANSACTION,
        payload: data
    }
}
export const get_deposit_transactions = (data) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.deposit_transactions_api(data, options).then((res) => { 
         const  res1 = res.data;
         console.log(res1);
            dispatch(save_deposit_transactions(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const acc_data_update = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.acc_data_update_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                history.push('/vendor/verify-email');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const update_acc_data_update = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.update_acc_data_update_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                history.push('/vendor/user-profile');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const trading_otp = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.trading_otp_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                history.push('/vendor/trading-change/'+data.user_type);

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const update_trading_password = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.update_trading_pass_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const check_trading_password = (data,setcheckPassword, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.check_trading_pass_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                setcheckPassword(true);
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}







export const getcoinAddress = (id) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.coinAddress({}, id).then((res) => { 
         const  res1 = res.data;
            console.log('coin address res',res1);
            dispatch(savecoinAddress(res1));
        }).catch((error) => {

            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getcoinAddresstable = (id,tokn) => (dispatch, getState) => {
    // console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.getcoinAddressdata({}, id,tokn).then((res) => { 
         const  res1 = res.data;
            console.log('coin address res',res1);
            dispatch(savecointableAddress(res1));
        }).catch((error) => {

            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}



 
//ApiBind

export const ApiBind = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.ApiBindApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const activativation = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.Activation(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
                window.location.reload();
            }else{
                toast.error(res.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const scaling = () => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.scalingApi({}, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const createTicket = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.createTicketApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}


const save_view_ticket = (data) => {
    return {
        type: types.SAVE_VIEW_TICKET,
        payload: data
    }
}
export const get_view_ticket = (data) => (dispatch, getState) => {
    console.log('hi its from function');
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.viewTicketApi(data, options).then((res) => { 
         const  res1 = res.data;
         console.log('hi its from function',res1);
            dispatch(save_view_ticket(res1));

        }).catch((error) => {
            
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const Newinvestment = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.NewInvestment(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const activateAccount = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.ActivateAccountApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success('Account Activated Successfully'); 
                dispatch(getUserDetails())
            }else{
                toast.error(res.data.status); 
                dispatch(getUserDetails())
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const OtpResent = (email) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.otp_resent({email : email}, options).then((res) => { 
            console.log(res.data)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
 

export const BinanceBalance = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.binanceBalance({}, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                dispatch(saveBinanceBalance(res.data.balance));
                // toast.success(res.data.balance);
            }else{
                dispatch(saveBinanceBalance(res.data.message));
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getBanners = (data) => (dispatch, getState) =>{
    try {   
        return apiService.bannersApi({}, {}).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                dispatch(SaveBanners(res.data.Banners));
                // toast.success(res.data.balance);
            }else{ 
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const SubmitWithdrawRequest = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.withdrawRequestApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.status);
            }else{
                toast.error(res.data.status);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const updateWalletAddress = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.updateWalletAddressApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const updateProfile = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.updateProfileApi(data, options).then((res) => { 
            console.log('res')
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getUsdtTransactions = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.usdtTransactionsApi({}, options).then((res) => { 
            console.log('usdt response' , res.data) 
            if(res.data.success == 1){
                dispatch(saveUsdtTransactions(res.data));
                // toast.success(res.data.balance);
            }else{
                // dispatch(saveBinanceBalance(res.data.message));
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getPackage = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.packageApi({}, options).then((res) => { 
            console.log('usdt response' , res.data) 
            if(res.data.success == 1){
                dispatch(PackageData1(res.data.pacakges));
                // toast.success(res.data.balance);
            }else{
                // dispatch(saveBinanceBalance(res.data.message));
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}