import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
const Fee = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Fee</h2>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
       
        <div className='about_box-1 mt-5'>
          <div className='text-center m-4'><h3>Fee Structure</h3></div>
          <div className='row m-0  First_row'>
          <div className='col-lg-7 about-page col-md-6'>
              <div className='about-story-text table-responsive'>
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th>PRICING TIER</th>
                      <th>TAKER FEE</th>
                      <th>MAKER FEE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Up to $10k</td>
                      <td>0.60%</td>
                      <td>0.40%</td>
                    </tr>
                    <tr>
                      <td>$10k - $50k</td>
                      <td>0.40%</td>
                      <td>0.25%</td>
                    </tr>
                    <tr>
                      <td>$50k - $100k</td>
                      <td>0.25%</td>
                      <td>0.15%</td>
                    </tr>
                    <tr>
                      <td>$100k - $1m</td>
                      <td>0.20%</td>
                      <td>0.10%</td>
                    </tr>
                    <tr>
                      <td>$1m - $20m</td>
                      <td>0.18%</td>
                      <td>0.08%</td>
                    </tr>
                    <tr>
                      <td>$20m - $100m</td>
                      <td>0.15%</td>
                      <td>0.05%</td>
                    </tr>
                    <tr>
                      <td>$100m - $300m</td>
                      <td>0.10%</td>
                      <td>0.02%</td>
                    </tr>
                    <tr>
                      <td>$300m - $500m</td>
                      <td>0.08%</td>
                      <td>0.00%</td>
                    </tr>
                    <tr>
                      <td>$500m+</td>
                      <td>0.05%</td>
                      <td>0.00%</td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-6 col-lg-5'>
              <img className='img-about-1' src={about1} width='100%' alt='fee/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='text-center m-4'><h3>Future</h3></div>
          <div className='row m-0 '>
            <div className='col-md-6'>
              <img className='img-about-1' src={about2} width='100%' alt='fee/img' />
            </div>
            <div className='col-md-6 about-page'>
            <div className='about-story-text table-responsive'>
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th>30-Day Volume USD</th>
                      <th>TAKER FEE</th>
                      <th>MAKER FEE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>$0 - $100,000</td>
                      <td>0.02%</td>
                      <td>0.05%</td>
                    </tr>
                    <tr>
                      <td>$100,001 - $1,000,000</td>
                      <td>0.02%</td>
                      <td>0.04%</td>
                    </tr>
                    <tr>
                      <td>$1,000,001 - $5,000,000</td>
                      <td>0.01%</td>
                      <td>0.03%</td>
                    </tr>
                    <tr>
                      <td>$5,000,001 - $10,000,000</td>
                      <td>0.01%</td>
                      <td>0.03%</td>
                    </tr>
                    <tr>
                      <td>$10,000,001 - $20,000,000</td>
                      <td>0.01%</td>
                      <td>0.02%</td>
                    </tr>
                    <tr>
                      <td>$20,000,001 - $50,000,000</td>
                      <td>0.01%</td>
                      <td>0.02%</td>
                    </tr>
                    <tr>
                      <td>$50,000,001 - $100,000,000</td>
                      <td>0.00%</td>
                      <td>0.01%</td>
                    </tr>
                    <tr>
                      <td>$100,000,001 +</td>
                      <td>0.00%</td>
                      <td>0.01%</td>
                    </tr>
                   
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Fee