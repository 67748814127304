import React, { useEffect, useState } from 'react';
import DOGE from '../../../_assets/images/DOGE.png';
import BNB from '../../../_assets/images/BNB.png';
import DASH from '../../../_assets/images/DASH.png';
import LTC from '../../../_assets/images/LTC.png';
import ETH from '../../../_assets/images/ETH.png';
import VLX from '../../../_assets/images/VLX.png';
// import DASH from '../../../_assets/images/DASH.png';
import { LangState } from '../../../LangugeContext';
import { home_table_th, marketpage } from '../../../lang/lang';
import { PROJECT_NAME } from '../../../_constants';
import { useDispatch, useSelector } from 'react-redux';
import { CoinList } from '../../../config/api';
import axios from 'axios';
import { Pagination } from '@material-ui/lab';
import { makeStyles, TextField } from '@material-ui/core';
import { CryptoState } from '../../../CryptoContext';
import down from '../../../_assets/images/price-down-icon.svg';
import up from '../../../_assets/images/up-icon.svg';
import { get_swap_tokens } from '../../../redux/actions/assets.action';
import { Link } from 'react-router-dom';
export function numberWithCommma(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const useStyles = makeStyles(() => ({
    row: {
        backgroundColor: '#16171a',
        cursor: 'ponter',
        '&:hover': {
            backgroundColor: '#131111',
        },
        fontFamily: 'Montserrat',
    },
    Pagination: {
        '& .MuiPaginationItem-root': {
            color: 'rgb(40 205 149)',
        },
        '& .MuiPagination-ul': {
            display: 'flex',

        }
    }
}));
const Market = ({history}) => {
    const {langchange} = LangState();
    const dispatch = useDispatch();
    const [coins, setcoins] = useState([]);
    const [loading, setloading] = useState(true);
    const [search, setsearch] = useState('');
    const [page, setpage] = useState(1);
    const [PairSetting, setPairSetting] = useState('USDT');

    const classes = useStyles();
    const { currency, symbol } = CryptoState();
    const swap_tokens = useSelector(state => state.assets.swap_tokens.swap_tokens);


    // console.log(('all_markit_coin',all_markit_coin));
    useEffect(() => {
        fatchcoins();
        dispatch(get_swap_tokens());
        // dispatch(get_all_market_coin());
    },[])
    
    const fatchcoins = async () => {
        const { data } = await axios.get(CoinList('USD'));
        setcoins(data);
        setloading(false);
    }
    const handleSearch = () => {
        return coins?.filter(
            (coin) =>
                coin.name.toLowerCase().includes(search) ||
                coin.symbol.toLowerCase().includes(search)
        );
    }

    const handleSearch2 = () => {
        return swap_tokens?.filter(
            (coin) =>
                coin.tokenName.toLowerCase().includes(search) ||
                coin.tokenName.toLowerCase().includes(search)
        );
    }

    
    const profit = ' ';
    return (
        <div>
            <div className="spot">
                <div className="container-spot">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link active" > {marketpage['0']['btn1'][langchange]} </a>
                        </li>
                        <li className="nav-item">
                            <Link to={'/vendor/soon'} className="nav-link " > {marketpage['0']['btn2'][langchange]}  </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/vendor/soon'} className="nav-link " >  {marketpage['0']['btn3'][langchange]} </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main-spot_box">
                <div className="container_spot">
                    {/* <div className="maltiple-link">
                        <p> <a href="#"> {PROJECT_NAME} SEPA and Trading Bot Newbie Welfare, 20,000 USDT To Be........ </a>    </p>
                        <p> <a href="#">  Won!{PROJECT_NAME} & 3Commas Trading Contest </a>    </p>
                        <p> <a href="#">  RANKER Listing Carnival: Run Your Trading Bot and Share a 3,000  </a>    </p>
                    </div> */}
                    <div className="spot-h">
                        <div className="spot-table-header">
                            <div className="table-header-left">
                                <a className="btn ml-0" > {marketpage['0']['Favorites'][langchange]}  </a>
                                <a className={PairSetting == 'USDT'? "btn active" :'btn'} onClick={() => setPairSetting('USDT') }>  USDT  </a>
                                {/* <a className={PairSetting == 'STA'? "btn active" :'btn'}  onClick={() => setPairSetting('STA') }> STA  </a> */}
                                {/* <a className="btn" href="#">ALTS</a>
                                <a className="btn" href="#">Defi</a>
                                <a className="btn" href="#">NFT</a>
                                <a className="btn" href="#">Metaverse</a>
                                <a className="btn" href="#">Polkadot</a>
                                <a className="btn" href="#">ETF</a>
                                <a className="btn" href="#">Spot Index</a> */}
                            </div>
                            <div className="table-header-right">
                                <a className="btn" href="#">   <i class='fab fa-algolia'></i>    </a>
                                <a className="btn" href="#">   <i class='fab fa-acquisitions-incorporated'></i>    </a>
                                <form>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder={marketpage['0']['search'][langchange]} onChange={(e) => setsearch(e.target.value)} /> 
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <div className="check-box">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="checkbox" className="form-check-input" value=""/>Support margin trading
                                </label>
                            </div>
                            <button type="button" className="btn"> {marketpage['0']['chrome'][langchange]}  </button>
                        </div> */}
                    </div>
                    <div className="table_work">
                        <div className="table-responsive"> 
                            <table className="table table-borderless table-hover">
                                <thead>
                                    <tr>
                                        <th style={{width: '5px', padding: '0'}}></th>
                                        <th  className="text-left"> {marketpage['0']['table']['label1'][langchange]} </th>
                                        <th></th>
                                        <th></th>
                                        <th className="text-center" > {marketpage['0']['table']['label2'][langchange]}	 </th>
                                        <th className="text-right" > {marketpage['0']['table']['label3'][langchange]} </th>
                                        <th className="text-right" >  {marketpage['0']['table']['label4'][langchange]} </th>
                                        <th className="text-center "> {marketpage['0']['table']['label5'][langchange]} </th>
                                        <th className="text-right" >   </th>

                                        {/* <th className="text-right" >  {marketpage['0']['table']['label6'][langchange]} </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr> 
                                        <td colspan="9" className="text-left"> {PROJECT_NAME} Plus <i class='far fa-question-circle'></i> </td>
                                    </tr> */}
                                    { handleSearch2()?.map((valCoin) =>{
                                        const profit1 = valCoin.sellPrice - valCoin.buyPrice;
                                            return(
                                                <tr>
                                                    <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                                    <td colspan="3"> 
                                                        <div className="d-flex">
                                                            <img src={valCoin.image} />  
                                                            <p> {valCoin.tokenName?.toUpperCase() } </p>
                                                        </div>
                                                    </td>
                                                    <td> {symbol}{" "}
                                                                    {numberWithCommma(valCoin?.sellPrice?.toFixed(2))}
                                                                    </td>
                                                    <td className={profit1 >0 ? 'up' : 'down'}>  <span className=' '>{profit1 > 0 ? '+'+profit1.toFixed(3) : profit1.toFixed(3)} <img src={profit1 > 0 ? up : down} alt="" /></span> </td>
                                                    <td className="text-center" > {symbol}{" "} {valCoin.sellPrice}
                                                                    {/* {numberWithCommma(valCoin.sellPrice} */}
                                                                      </td>
                                                    <td className="text-center" > {valCoin.dailysellQuantity}  </td>
                                                    <td className=" text-center" style={{width: '15%'}}> <button className='btn btn-md border' onClick={() => history.push(`/vendor/coin/enj/${PairSetting}`)} style={{color:'rgb(40 205 149)',border:'1px solid rgb(40 205 149)',cursor: 'pointer'}}>Trade</button>  </td>
        
                                                    {/* <td className=" text-center" style={{width: '15%'}}> XXXX-XX<small> <sub> XXXX-XX USD </sub> </small>   </td> */}
                                                </tr>
                    
                                            )
                                        } )}
                                    {handleSearch()
                                    .slice((page - 1) * 50, (page - 1) * 50 + 50)
                                    .map((row) => {
                                        const profits = row.price_change_percentage_24h > 0;
                                        if(row.symbol != 'usdt'){
                                            return(
                                            <tr>
                                                <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                                <td colspan="3"> 
                                                    <div className="d-flex">
                                                        <img src={row.image} />  
                                                        <p> {row.symbol?.toUpperCase() } </p>
                                                    </div>
                                                </td>
                                                <td> {symbol}{" "}
                                                                {numberWithCommma(row.current_price.toFixed(2))}
                                                                {/* <small> <sub> 93.71 USD </sub> </small> */}
                                                                </td>
                                                <td className={profit ? 'up' : 'down'}>  <span className=' '>{profit && '+'} {row.price_change_percentage_24h.toFixed(2)} <img src={profit ? up : down} alt="" /></span> </td>
                                                <td className="text-center" > {symbol}{" "}
                                                                {numberWithCommma(row.current_price.toFixed(2))}  </td>
                                                <td className="text-center" > {row.total_volume}  </td>
                                                <td className=" text-center" style={{width: '15%'}}> <button className='btn btn-md border' onClick={() => history.push(`/vendor/coin/${row.symbol}/${PairSetting}`)} style={{color:'rgb(40 205 149)',border:'1px solid rgb(40 205 149)',cursor: 'pointer'}}>Trade</button>  </td>

                                                {/* <td className=" text-center" style={{width: '15%'}}> XXXX-XX<small> <sub> XXXX-XX USD </sub> </small>   </td> */}
                                            </tr>
                                            )
                                        }
                                        })}
                                    {/* <tr>
                                        <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                        <td colspan="3"> 
                                            <div className="d-flex">
                                                <img src={BNB} />  
                                                <p> LUNA/BTC <span> 10X </span> </p>
                                            </div>
                                        </td>
                                        <td> 0.856940634<small> <sub> 93.71 USD </sub> </small>   </td>
                                        <td className="text-center">  +6.79%  </td>
                                        <td className="text-center" > 3197858  </td>
                                        <td className="text-center" > 75497834  </td>
                                        <td className=" text-center" style={{width: '15%'}}> 0.9873497<small> <sub> 33.71 USD </sub> </small>   </td>
                                    </tr>
                                    <tr>
                                        <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                        <td colspan="3"> 
                                            <div className="d-flex">
                                                <img src={DASH}/>  
                                                <p> LUNA/BTC <span> 10X </span> </p>
                                            </div>
                                        </td>
                                        <td> 0.856940634<small> <sub> 93.71 USD </sub> </small>   </td>
                                        <td className="text-center">  +6.79%  </td>
                                        <td className="text-center" > 3197858  </td>
                                        <td className="text-center" > 75497834  </td>
                                        <td className=" text-center" style={{width: '15%'}}> 0.9873497<small> <sub> 33.71 USD </sub> </small>   </td>
                                    </tr>
                                    <tr>
                                        <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                        <td colspan="3"> 
                                            <div className="d-flex">
                                                <img src={LTC}/>  
                                                <p> LUNA/BTC <span> 10X </span> </p>
                                            </div>
                                        </td>
                                        <td> 0.856940634<small> <sub> 93.71 USD </sub> </small>   </td>
                                        <td className="text-center">  +6.79%  </td>
                                        <td className="text-center" > 3197858  </td>
                                        <td className="text-center" > 75497834  </td>
                                        <td className=" text-center" style={{width: '15%'}}> 0.9873497<small> <sub> 33.71 USD </sub> </small>   </td>
                                    </tr>
                                    <tr>
                                        <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                        <td colspan="3"> 
                                            <div className="d-flex">
                                                <img src={ETH}/>  
                                                <p> LUNA/BTC <span> 10X </span> </p>
                                            </div>
                                        </td>
                                        <td> 0.856940634<small> <sub> 93.71 USD </sub> </small>   </td>
                                        <td className="text-center">  +6.79%  </td>
                                        <td className="text-center" > 3197858  </td>
                                        <td className="text-center" > 75497834  </td>
                                        <td className=" text-center" style={{width: '15%'}}> 0.9873497<small> <sub> 33.71 USD </sub> </small>   </td>
                                    </tr>
                                    <tr>
                                        <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                        <td colspan="3"> 
                                            <div className="d-flex">
                                                <img src={VLX}/>  
                                                <p> LUNA/BTC <span> 10X </span> </p>
                                            </div>
                                        </td>
                                        <td> 0.856940634<small> <sub> 93.71 USD </sub> </small>   </td>
                                        <td className="text-center">  +6.79%  </td>
                                        <td className="text-center" > 3197858  </td>
                                        <td className="text-center" > 75497834  </td>
                                        <td className=" text-center" style={{width: '15%'}}> 0.9873497<small> <sub> 33.71 USD </sub> </small>   </td>
                                    </tr>
                                    <tr>
                                        <td className="time_clock"> <i class='far fa-clock'></i> </td>
                                        <td colspan="3"> 
                                            <div className="d-flex">
                                                <img src={DASH}/>  
                                                <p> LUNA/BTC <span> 10X </span> </p>
                                            </div>
                                        </td>
                                        <td> 0.856940634<small> <sub> 93.71 USD </sub> </small>   </td>
                                        <td className="text-center">  +6.79%  </td>
                                        <td className="text-center" > 3197858  </td>
                                        <td className="text-center" > 75497834  </td>
                                        <td className=" text-center" style={{width: '15%'}}> 0.9873497<small> <sub> 33.71 USD </sub> </small>   </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <Pagination style={{ padding: 20, width: '100%', display: 'flex', justifyContent: 'center' }} classes={{ ul: classes.Pagination }} count={(handleSearch()?.length / 50).toFixed(0)} onChange={(_, value) => {
                                setpage(value);
                                window.scroll(0, 450);
                            }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Market