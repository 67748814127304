import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TrendingCoins} from '../../../../config/api';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CryptoState } from '../../../../CryptoContext';
const useStyles = makeStyles({
    Carousel: {
      height: '50%',
      display:'flex',
      alignItems:'center',
    },
    CarouselItem:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        cursor:'poninter',
        textTransform:'uppercase',
        color:'white',
    }
  });
export function   numberWithCommma(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const Carousel = () => {
    const dispach = useDispatch();
    const [tarnding,setTranding]= useState([]);
    const { currency, symbol } = CryptoState();

    const classes = useStyles();

    const fatchTrandingCoins = async () => {
        const { data } = await axios.get(TrendingCoins(currency));
        // console.log('data acoin api',data);
        setTranding(data);
    }
    // console.log('tarnding',tarnding);
    // useEffect(() => {
    //     fatchTrandingCoins();

    // },[]);

    useEffect(() => {
        fatchTrandingCoins();
      },[currency]);
    // window.onload = function() {
    //     fatchTrandingCoins();
    //     console.log('window load')
    //   };

    const items = tarnding.map((coin) => {
        let profit = coin.price_change_percentage_24h >= 0;
        return(
            <Link className={classes.CarouselItem} to='/'>
                <img src={coin.image}  alt={coin.name} height='80' style={{marginBottom:'10'}}/>
                <span>
                    {coin.symbol}
                    &nbsp;
                    <span style={{color: profit > 0 ? 'rgb(14,203,129' : 'red'}}>{profit && '+'}{coin.price_change_percentage_24h?.toFixed(2)}%</span>
                </span>
                <span style={{fontSize:22,fontWeight:500}}>{symbol} {numberWithCommma(coin?.current_price.toFixed(2))}</span>
            </Link>

        );
    })

    const responsive = {
        0:{
            items:2,
        },
        512:{
            items:4,
        },
    }
  return <div className={classes.Carousel}>
      <AliceCarousel
        mouseTracking
        infinite
        autoPlayInterval={1000}
        animationDuration={1500}
        disableDotsControls
        disableButtonsControls
        responsive={responsive}
        autoPlay
        items={items}
      />
  </div>;
};

export default Carousel;
