import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'

import RegisterForm from "./RegisterForm";
import { register , checkSponser } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";
import {  PROJECT_NAME } from "../../../_constants";
import "../../../_assets/css/login.css";
import { Link } from "react-router-dom";
import { LangState } from "../../../LangugeContext";
import { loginlang, registerlang } from "../../../lang/lang";
import logo from '../../../_assets/images/js_logo.png';
import log from "../../../_assets/logo/1sign_i.png"

const Register = ({ history }) => {
  const {langchange} = LangState();

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    values = { ...values, deviceType: 'WEB', deviceToken: 'WEB' };

    console.log(values);
    // dispatch(register(values, history));
  }

  
  const validateSponser = (sponserId) => {
    console.log('this is outer sponser ' + sponserId);
    dispatch(checkSponser(sponserId , history))
  }
  return (
    <>
    

    <main>
        <section className="reset__password__sec">
            <div className="sec__content">
                <div className="sec__text">
                    <div className="sec__logo">
                      <Link to='/'>  
                        {/* <img src={logo} width='90' height='90' alt="logo"/> */}
                        </Link>
                    </div>
                    <div className="sec__text__inner">
                        <img src={log} className="log sign_i"  alt="log" />
                        <h2 className="sec__heading">{loginlang['0']['h1'][langchange]}</h2>
                        <p className="sec__brief">{loginlang['0']['p'][langchange]}</p>
                    </div>
                </div>
                <div className="sec__form">
                   
                    <RegisterForm history={history} />
                    <div className="upper__text">
                        <span>{registerlang['0']['link'][langchange]}<Link to="/login">{registerlang['0']['linkbtn'][langchange]}</Link></span>
                    </div>
                </div>
            </div>
        </section>
    </main> 
    </>
  );
};

export default Register;