import React from 'react'
import img1 from '../../../_assets/images/1.svg'; 
import visa from '../../../_assets/images/visa.svg'; 
import pay from '../../../_assets/images/pay.svg'; 
import sepa from '../../../_assets/images/sepa.svg'; 
import { Link } from 'react-router-dom';
import { LangState } from '../../../LangugeContext';
import { fastBuypage } from '../../../lang/lang';
const fastBuy = () => {
    const {langchange} = LangState();
    
    return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" To="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] }</Link>
                    </li>
                </ul>
            </div>
        </div>

        <div className="Fast-banner">
            <div className="left-bg">

            </div>
            <div className="container-banner">
                <div className="card border-0 banner-card-1">
                    <h1> {fastBuypage['0']['sec1']['h1'][langchange]}  </h1>
                    <h2>  {fastBuypage['0']['sec1']['h2'][langchange]}  </h2>
                    <p>{fastBuypage['0']['sec1']['p1'][langchange]} </p>
                    <div className="img-group">
                        <img src={img1} alt="icon"/>
                        <img src={visa} alt="icon"/>
                        <img src={pay} alt="icon"/>
                        <img src={sepa} alt="icon"/>
                    </div>
                </div>
                <div className="card border-0 img-fast"> 
                    <ul className="nav" role="tab">
                        <li className="nav-item" id="more" > 
                            <a className="nav-link active" data-toggle="tab" href="#buy" id="buy"> {fastBuypage['0']['form']['btn1'][langchange]}  </a>
                        </li>
                        <li className="nav-item"> 
                            <a className="nav-link " data-toggle="tab" href="#sell" id="sell">  {fastBuypage['0']['form']['btn2'][langchange]} </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="javascript" className=" tab-pane active">
                            <form>
                                <div className="form-group">
                                    <label for="spend"> {fastBuypage['0']['form']['label1'][langchange]}</label><br/>
                                    <input type="text" placeholder="limit: 50 - 1,000" id="spend"/>
                                </div>
                                <div className="form-group">
                                    <label for="spend"> {fastBuypage['0']['form']['label2'][langchange]}</label><br/>
                                    <input type="text" placeholder="limit: 50 - 1,000" id="spend"/>
                                </div>
                                <div className="form-group">
                                    <label for="sel1">{fastBuypage['0']['form']['label3'][langchange]}</label>
                                    <select className="form-control" id="sel1">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn"> {fastBuypage['0']['form']['submit'][langchange]} </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="direction">
            <div className="d-container">
                <h1> {fastBuypage['0']['sec2']['h1'][langchange]} </h1>
                <p className="title" >  {fastBuypage['0']['sec2']['p'][langchange]}: </p>
                <div className="d-side">
                    <div className="user">
                        <p><i class='fas fa-user'></i>  </p>
                        <p>  {fastBuypage['0']['sec2']['box1'][langchange]} </p>
                    </div>
                    <div className="user">
                        <p><i class='fas fa-user'></i>  </p>
                        <p>  {fastBuypage['0']['sec2']['box2'][langchange]} </p>
                    </div>
                    <div className="user">
                        <p><i class='fas fa-copy'></i>  </p>
                        <p>  {fastBuypage['0']['sec2']['box3'][langchange]}</p>
                    </div>
                </div>
                <div className="broder-line"></div>
            </div>
        </div>
        <div className="FAQ">
            <div className="FAQ-container">
                <h1>FAQ</h1>
                <div className="FAQ-header">
                    <p>User Guide: Buy Crypto with Bank Card (Web version)  </p>
                    <h6> <a href="#"> View all </a> </h6>
                </div>
                <div id="accordion">   
                    <div className="card">
                        <div className="card-header">
                            <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                How can I qualify to buy crypto with a bank card?
                            </a>
                            <h6> <i class='fas fa-angle-down'></i>  </h6>

                        </div>
                        <div id="collapseTwo" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                Lorem ipsum..
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                How do I check if my bank card qualifies?
                            </a>
                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        </div>
                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                            Lorem ipsum..
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <a className="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                                Are there any transaction limits?
                            </a>
                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        </div>
                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                Lorem ipsum..
                            </div> 
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <a className="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                Will I be charged a transaction fee?
                            </a>
                            <h6> <i class='fas fa-angle-down'></i>  </h6>
                        </div>
                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                Lorem ipsum..
                            </div>
                        </div>
                    </div>
                </div>          
            </div>
        </div>
    </div>
    )
}

export default fastBuy