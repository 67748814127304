import * as types from '../actions/_types';

const initialState = {
  method: [],
  field: [],
  
};

export default function payment(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_P2P_PAYMENT_METHOD:
      return { ...state, method: action.payload };
    case types.SAVE_P2P_PAYMENT_FIELD:
      return {...state,field: action.payload};
    case types.SAVE_P2P_GET_PAYMENT_METHOD:
      return {...state,get_method: action.payload};  
   
    default:
      return state;
  }
}

