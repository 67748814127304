import React from 'react'
import { Link } from 'react-router-dom'
import { cloudMiningpage, orderMiningpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';

const orderMining = () => {
    const {langchange} = LangState();

    return (
    <div>
        <div className="order">
            <div className="container-order">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/cloudMining"> {cloudMiningpage['0']['nav']['btn1'][langchange]} </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/orderMining"> {cloudMiningpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/elecMining">  {cloudMiningpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                </ul>
            </div>
        </div>

        <div className="container-table">
            <div className="table-responsive-lg"> 
                <table className="table table-borderless" style={{width: '1200px'}}>
                    <thead>
                        <tr>
                            <th className="text-left"> {orderMiningpage['0']['lable1'][langchange]}  </th>
                            <th className="text-center" > {orderMiningpage['0']['lable2'][langchange]}	 </th>
                            <th className="text-center" > {orderMiningpage['0']['lable3'][langchange]} </th>
                            <th className="text-center" >  {orderMiningpage['0']['lable4'][langchange]}  </th>
                            <th className="text-right">  {orderMiningpage['0']['lable5'][langchange]} </th>
                            <th className="text-right">  {orderMiningpage['0']['lable6'][langchange]} </th>
                            <th className="text-right">  {orderMiningpage['0']['lable7'][langchange]} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="9" className="text-center mt-4">
                                <img src="img/no_history-removebg-preview.png" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export default orderMining