import React from 'react'
import visa from '../../../_assets/images/visa2.png';
import simplex from '../../../_assets/images/simplex.svg';
import apple from '../../../_assets/images/apple.png';
import banxa from '../../../_assets/images/banxa.png';
import right from '../../../_assets/images/right.png';
import { Link } from 'react-router-dom';
import { LangState } from '../../../LangugeContext';
import { fastBuypage, thirdpartypage } from '../../../lang/lang';
import { PROJECT_NAME } from '../../../_constants';
const thirdParty = () => {
    const {langchange} = LangState();
    return (
        <div>
            <div className="Fast-trade">
                <div className="container-fast">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]}  </Link>
                        </li>

                        <li className="nav-item">
                        <Link className="nav-link" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}   </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/thirdParty">  {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="thired-form">
                <div className="form-container">
                    <h3>  {fastBuypage['0']['nav']['btn3'][langchange] } </h3>
                    <form>
                        <div className="row f-row">
                            <div className="col-md-6 pl-0">
                                <label for="text-1"> {thirdpartypage['0']['label1'][langchange] }  </label> <br/>
                                    <input type="text" placeholder={thirdpartypage['0']['inputPlace'][langchange] }/>
                            </div>
                            <div className="col-md-6 pr-0">
                                <div className="form-group">
                                    <label for="sell1">  {thirdpartypage['0']['label2'][langchange] }</label>
                                    <select className="form-control" id="sel1">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row s-row">
                            <div className="col-md-6 pl-0">
                                <p> {thirdpartypage['0']['label3'][langchange] } </p>
                                <div className="btn">
                                        <img src={visa} alt="visa"/>
                                        Visa/Mastercard
                                        <img src={right} className="check-it"/>
                                </div>
                                <div className="btn">
                                    <img src={apple} alt="visa"/>
                                    Apple Pay
                                    <img src={right} className="check-it"/>
                                </div>
                            </div>
                            <div className="col-md-6 pr-0">
                                <p> {thirdpartypage['0']['label4'][langchange] }</p>
                                <div className="btn">
                                        <img src={simplex} alt="visa"/>
                                        Simplex
                                        <img src={right} className="check-it"/>
                                </div>

                                <div className="btn">
                                    <img src={banxa} alt="visa"/>
                                    Banxa
                                    <img src={right} className="check-it"/>
                                </div>
                            </div>
                        </div>
                        <div className="row th-row">
                            <div className="col-12 p-0">
                                <p> {thirdpartypage['0']['spanLine1'][langchange] }:- <span> USD  </span></p> 
                                <p> {thirdpartypage['0']['spanLine2'][langchange] }:- <span> BTC  </span></p> 
                                <p> {thirdpartypage['0']['spanLine3'][langchange] }<span> -  </span></p> 
                                <p> {thirdpartypage['0']['spanLine4'][langchange] }: <span> Value  </span>
                                </p> 
                            </div>
                        </div>
                        <div className="row border-0">
                            <div className="col-12 p-0">
                                <div className="column-header">
                                    <h5>  {thirdpartypage['0']['h1'][langchange] } </h5>
                                    <a href="#">  Powered by: <img src={simplex} alt="visa"/>  Simplex </a>
                                    </div>
                                    <div className="text">
                                        You are going to leave {PROJECT_NAME}.com and be taken to Simplex.com. Services related to credit card payments are provided by Simplex, a separate third party platform. Please read and agree to Simplex's Terms of Use before using their services. For any questions related to the services, please contact Simplex : support@simplex.com.
                                    </div>
                            </div>
                            <div className="col-12 p-0">
                                <label for="check-2" className="check-box">
                                    <input type="checkbox" id="check-2"/> {thirdpartypage['0']['info'][langchange] }
                                </label>
                            </div>

                            <div className="col-12 btn-column p-0">
                                <button type="submit" className="btn"> {thirdpartypage['0']['btn'][langchange] } </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default thirdParty