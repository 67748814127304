import React from 'react'
import { Link } from 'react-router-dom'
import img from '../../../../_assets/images/no_history-removebg-preview.png';
import { pooldashboardpage, poolminingpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
const MiningDashboard = () => {
    const {langchange} = LangState();

    return (
        <div>
            <div className="Fast-trade">
                <div className="container-fast">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/miningpool"> {poolminingpage['0']['nav']['btn1'][langchange]}  </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/MiningDashboard"> {poolminingpage['0']['nav']['btn2'][langchange]}  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/miningOverview">  {poolminingpage['0']['nav']['btn3'][langchange]} </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/miningSetting">  {poolminingpage['0']['nav']['btn4'][langchange]} </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="dash-account">
                <div className="container-account">
                    <div className="left-account">
                        <h1> {pooldashboardpage['0']['mining'][langchange]} </h1>
                            <div className="nav-area">
                                <form>
                                    <div className="input-group" style={{width: '100%', margin: '0'}}>
                                        <input type="text" className="form-control" placeholder={pooldashboardpage['0']['mining'][langchange]} data-toggle="dropdown"/> 
                                            <i class='fas fa-caret-down'></i> 
                                        <div className="dropdown-menu dropdown-menu-left" >
                                            <a href="#" className="dropdown-item"> No Option  </a>
                                        </div>
                                    </div>
                                </form>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#"> <i class='fas fa-wallet'></i> {pooldashboardpage['0']['income'][langchange]}  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">  <i className="fa fa-chain"></i>  {pooldashboardpage['0']['Marchines'][langchange]}  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#"> <i class='fas fa-cog'></i> {poolminingpage['0']['nav']['btn4'][langchange]}  </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="right-account">
                            <div className="right-account-header">
                                <form>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Mining algorithm" data-toggle="dropdown"/> 
                                            <i class='fas fa-caret-down'></i> 
                                        <div className="dropdown-menu dropdown-menu-left" >
                                            <a href="#" className="dropdown-item"> SA565 A </a>
                                            <a href="#" className="dropdown-item"> SSIH 908 </a>
                                        </div>
                                    </div>
                                </form>

                                <div className="right-ac-btn">
                                    <button type="button" className="btn">  {pooldashboardpage['0']['Connect'][langchange]} </button>
                                    <button type="button" className="btn">  {pooldashboardpage['0']['Create'][langchange]} </button>
                                </div>
                            </div>

                            <img className="img/fluid text-center mt-5" src={img} alt="no data History"/>
                        </div> 
                </div>
            </div>
        </div>
    )
}

export default MiningDashboard