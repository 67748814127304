import { Select } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SpotSection = () => {
    const isSignedIn = useSelector(state => state.persist.isSignedIn);
    const isAuthenticated = isSignedIn;
    const loginButton = () => {
        
            return(<button className="Login__Sing__in__btn">
            <Link className="login" to='/login'>Log In </Link>
            <span>
                Or
            </span>
            <Link to="/register">Sign Up</Link>
        </button>)
    }
    const sellBtn = () => {
            return(<button className="Login__Sing__in__btn">
            <Link className="login" to=''>Buy </Link>
            <span>
                Or
            </span>
            <Link to="">Sell</Link>
        </button>)
        
    }
  return (
      <>
        <div className="aside__price__box">
                <div className="order__details">
                    <div className="order__details__inner">
                        <button className="aside__tabs spot__tab__btn active" id='spot1' value="spotBtn">
                            Spot
                        </button>
                        <button className="aside__tabs isolated__margin" id='cross1' value="crossBtn">
                            <span className="Isolated__margin__text">
                                Cross Margin
                            </span>
                            <span className="isolated__margin__number">10X</span>
                            <img className="isolate__margin__btn tabs__dropdown" src="assets/svg/dropdown-icon.svg" alt="dropdown-icon" />
                            <div className="dropdown__content">
                                <option className="dropdown__option"  value="crossBtn" >
                                Isolated Margin 
                                </option>
                                <option className="dropdown__option" value="isolatedBtn">
                                    Isolated Margin
                                </option>
                            </div>
                        </button>
                        <button className=" isolated__margin">
                            Futures
                            <span className="isolated__margin__number">100X</span>
                            Grid
                        </button>
                    </div>
                    <div className="notification__icon tranding__fee">
                        <img src="assets/svg/trading-fee-icon.svg" alt="trading-icon" />
                    </div>
                    <div className="tranding__fee__popup">
                        <h3 className="popup__heading">
                            Trading Fee Rate
                        </h3>
                        <p className="popup__info">
                            Basic Trading Fee Rate of BTC/USDT: 0.1% for taker, 0.1% for maker
                        </p>
                        <p className="popup__info">
                            If you have FXC and enabled the 'FXC Pay Fees' feature, you'll get an extra 20% discount on
                            your
                            trading fees.
                        </p>
                    </div>
                </div>
                <div className="order__details">
                    <div className="order__details__inner">
                        <button className="limit__tabs__button active">
                            Limit
                        </button>
                        <button className="limit__tabs__button" value="1">
                            Market
                        </button>
                    </div>
                    <div className=" aside__inner__dropdown">
                        <button className="pairs__button__aside">
                            <span>Stop Limit</span>
                            <img src="assets/svg/dropdown-icon.svg" alt="dropdown-icon" />
                        </button>
                        <div className="dropdown__content__aside">
                            <option className="dropdown__option__aside limit__tabs__button">
                                Stop Limit
                            </option>
                            <option className="dropdown__option__aside stop__market__btn limit__tabs__button">
                                Stop Market
                            </option>
                        </div>

                    </div>
                    <div className="checkbox__icon">
                        <span className="auto__borrow">Auto-Borrow</span>
                        <span className="input__box active"></span>
                    </div>
                    <div className="notification__icon">
                        <svg width="13" height="13" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 30C10.9934 30 7.22654 28.4397 4.39336 25.6066C1.56029 22.7735 0 19.0066 0 15C0 10.9934 1.56029 7.22654 4.39336 4.39336C7.22654 1.56029 10.9934 0 15 0C19.0066 0 22.7735 1.56029 25.6066 4.39336C28.4397 7.22654 30 10.9934 30 15C30 19.0066 28.4397 22.7735 25.6066 25.6066C22.7735 28.4397 19.0066 30 15 30Z"
                                fill="#a1a4ac"></path>
                            <path
                                d="M17.4769 22.5031V12.5195H11.5538V14.5162H12.6308V22.5031H11.5V24.4998H18.5V22.5031H17.4769Z"
                                fill="#151C30"></path>
                            <path
                                d="M15.1367 10.7648C16.5906 10.7648 17.7734 9.58389 17.7734 8.13239C17.7734 6.68089 16.5906 5.5 15.1367 5.5C13.6828 5.5 12.5 6.68089 12.5 8.13239C12.5 9.58389 13.6828 10.7648 15.1367 10.7648Z"
                                fill="#151C30"></path>
                        </svg>
                    </div>
                </div>
                <div className="price__wrapper active">
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available :
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Advanced: -
                                </span>
                                <span>
                                    Settings
                                </span>
                            </div>
                        </div>
                        {isAuthenticated === true ? sellBtn(): loginButton()}
                    </div>
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Advanced: -

                                </span>
                                <span>
                                    Settings
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <Link className="login" to='/login'>Log In</Link>
                            <span>
                                Or
                            </span>
                            <Link to="/register">Sign Up</Link>
                        </button> */}
                                                {isAuthenticated === true ? sellBtn(): loginButton()}

                    </div>
                </div>
                <div className="price__wrapper">
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" readonly placeholder="Best Market Price" />
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <a className="login">Log In</a>
                            <span>
                                Or
                            </span>
                            <a href="sign-up.html">Sign Up</a>
                        </button> */}
                                                {isAuthenticated === true ? sellBtn(): loginButton()}

                    </div>
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" readonly placeholder="Best Market Price" />
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <a className="login">Log In</a>
                            <span>
                                Or
                            </span>
                            <a href="sign-up.html">Sign Up</a>
                        </button> */}
                                                {isAuthenticated === true ? sellBtn(): loginButton()}

                    </div>
                </div>
                <div className="price__wrapper ">
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>

                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>

                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Advanced: -

                                </span>
                                <span>
                                    Settings
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <a className="login">Log In</a>
                            <span>
                                Or
                            </span>
                            <a href="sign-up.html">Sign Up</a>
                        </button> */}
                                                {isAuthenticated === true ? sellBtn(): loginButton()}

                    </div>
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>

                            </div>
                            <div className="inner__item">
                                <span>
                                    Advanced: -

                                </span>
                                <span>
                                    Settings
                                </span>
                            </div>
                        </div>
                        <button className="Login__Sing__in__btn">
                            <a className="login">Log In</a>
                            <span>
                                Or
                            </span>
                            <a href="sign-up.html">Sign Up</a>
                        </button>
                    </div>
                </div>
                <div className="price__wrapper ">
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <a className="login">Log In</a>
                            <span>
                                Or
                            </span>
                            <a href="sign-up.html">Sign Up</a>
                        </button> */}
                                                {isAuthenticated === true ? sellBtn(): loginButton()}

                    </div>
                    <div className="price__input">
                        <div className="price__box">
                            <input type="number" placeholder="Stop Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Price" />
                            <span>USDT</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="price__box">
                            <input type="number" placeholder="Amount" />
                            <span>BTC</span>
                            <div className="increase__decrease__value">
                                <button>+</button>
                                <button>-</button>
                            </div>
                        </div>
                        <div className="percentage">
                            <span>25%</span>
                            <span>50%</span>
                            <span>75%</span>
                            <span>100%</span>
                        </div>
                        <div className="availabe">
                            <div className="inner__item">
                                <span>
                                    Available：
                                </span>
                                <span>
                                    0 USDT
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                                fill="#2DBD96" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div className="inner__item">
                                <span>
                                    Volume:
                                </span>
                                <span>
                                    0 USDT
                                </span>
                            </div>
                        </div>
                        {/* <button className="Login__Sing__in__btn">
                            <Link className="login" to='/login'>Log In</Link>
                            <span>
                                Or
                            </span>
                            <Link to="/register">Sign Up</Link>
                        </button> */}
                                                {isAuthenticated === true ? sellBtn(): loginButton()}

                    </div>
                </div>
                <div className="tour__popup" id="BuyingSelling">
                    <h4>
                        Assets Overview
                    </h4>
                    <p>
                        The balance in your Main Account, Trading Account and Margin Account is shown here.
                    </p>
                    <div className="popup__footer">
                        <div className="pagination">
                            <span></span>
                            <span className="active"></span>
                            <span></span>
                        </div>
                        <div className="next__prev__btn">
                            <button className="next__button" onclick="assetsView()">
                                Prev
                            </button>
                            <button className="next__button" onclick="SpotMargin()">
                                Next
                            </button>
                        </div>
                    </div>
                    <button className="close__btn" onclick="closePopup()">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                           >
                        </svg>
                    </button>
                </div>
                <div className="tour__popup" id="SpotMargin">
                    <h4>
                        Assets Overview
                    </h4>
                    <p>
                        The balance in your Main Account, Trading Account and Margin Account is shown here.
                    </p>
                    <div className="popup__footer">
                        <div className="pagination">
                            <span></span>
                            <span></span>
                            <span className="active"></span>
                        </div>
                        <div className="next__prev__btn">
                            <button className="next__button" onclick="BuyingSelling()">
                                Prev
                            </button>
                            <button className="next__button" onclick="closePopup()">
                                Close
                            </button>
                        </div>
                    </div>
                    <button className="close__btn" onclick="closePopup()">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            >
                        </svg>
                    </button>
                </div>
            </div> 

      </>
  );
};

export default SpotSection;
