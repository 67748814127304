import React from 'react';
import Header from '../dashboard/Header';
import CommingSoon from '../../../_assets/images/Coming-soon.png';


const NoData = () => {
  console.log('NoDataNoDataNoDataNoData')
  return (
    <div>
      < Header />
      <section className="Soon_page_2A Soon_page_2A_Public">
        <div className="private-empty_soon">
          <div className="private-data-empty">
            <h1 className='Soon-text-secondary Soon-text-secondary-2 '>
              <img className='comming_soon_no_data' alt='Comming Soon' src={CommingSoon} />
            </h1>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NoData