import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import { useDispatch, useSelector } from 'react-redux';
import { get_deposit_tokens, get_deposit_transactions, get_view_ticket } from '../../../redux/actions/dashboard.action';

const Ticket = (props) => {
	const { langchange } = LangState();
	const dispatch = useDispatch();
	const viewTocket = useSelector(state => state.dashboard.viewTocket);

	
	useEffect(() => {
		dispatch(get_view_ticket())
	}, [])
	console.log(viewTocket);
	const [search, setsearch] = useState();
	// const handleSearch = () => {
	// 	if (search != undefined) {
	// 		return deposite.deposit_tokens?.filter(
	// 			(iii) =>
	// 				iii.coinName.toLowerCase().includes(search)
	// 		)
	// 	} else {
	// 		return (deposite.deposit_tokens);
	// 	}
	// }

	function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr);
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	}


	return (
		<div>
			<div className="header-2">
				<div className="container-s">
					<ul className="nav">
						
						<li className="nav-item">
							<Link className="nav-link " to="/vendor/CreateTicket">  Create Ticket </Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link active" to="/vendor/Ticket"> View Ticket   </Link>
						</li>
					
					</ul>
				</div>
			</div>
			<div className='container'>

				<div className='row mt-5 Deposit_Table_1'>
					<div className='col-md-12'>
						<h2>Ticket</h2>
						<div className='table-responsive-xl'>
							<table className="table table-borderless">
								<thead className='thead-light'>
									<tr>
										<th className="">#</th>
										<th > Title   </th>
										<th>Message</th>
										<th>Remark</th>
										<th className="">  Created At </th>
									</tr>
								</thead>
								<tbody>
									{viewTocket?.record?.map((row,i) => {
										return (
											<tr>
												<td>{i+1}</td>
												<td>{row?.title}</td>
												<td><span>{row?.message}</span></td>
												<td></td>
												<td>{secondsToHms(row?.createdAt)}</td>
											</tr>
										);
									})}
									{/* <tr>
								<td colspan="6" className='text-center'>
									<img src={emptyImg} className='mt-5' /><br/>
									<span>No data</span>
								</td>
							</tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Ticket