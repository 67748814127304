import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const FudxEarn = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  {PROJECT_NAME} Earn</h2>
            <h6>{PROJECT_NAME} Earn is our asset management platform that helps you grow your crypto holdings easily.</h6>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        <div class="about_story">
          <div className='row m-0 align-items-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner_story_top'>
                <img className='img-about-1' src={Ab} width='100%' alt='FudxEran/img' />
                <img src={abcd} width='100%' alt='FudxEran/img' />
              </div>
              <div className='banner_story_top-2'>
                <img className='img-about-1' src={abc} width='100%' alt='FudxEran/img'/>
                <img src={abcde} width='100%' alt='FudxEran/img'/>
              </div>
            </div>
            <div className='col-lg-7 about-page col-md-6'>
              <h3 className='about_title'> {PROJECT_NAME} Earn </h3>
              <div className='about-story-text'>
                <h3 className='mt-5 mb-0'>What is {PROJECT_NAME} Earn?</h3>
                <h6 className='m-0'>
                {PROJECT_NAME} Earn is our asset management platform that helps you grow your crypto holdings easily. We offer competitive APYs to ensure higher yields, and best-in-class security to safeguard your funds. Whether you're a regular crypto Joe or a cryptonet, invest with us today to start earning!
                </h6>
                <h3>{PROJECT_NAME} Saving </h3>
                <p>{PROJECT_NAME} Savings provides you a customized savings experience, allowing you to earn interest on your favourite coins. You can choose from flexible- and fixed-term products that offer competitive and guaranteed APYs.</p>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6 className='m-0'>Principal Guaranteed</h6>
                  </li>
                  <li><h6 className='m-0'>Flexible & Fixed Terms Supported.</h6>
                  </li>
                  <li><h6 className='m-0'>High APYs for Your Favorite Coins.</h6> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row'>
          <div className='col-lg-7 about-page col-md-6'>
              <div className='about-story-text'>
                <h3 className='mt-5 mb-0'>Dual Asset</h3>
                <h6 className='m-0'>
                Dual Asset is a short-term trading tool that gives you the opportunity to earn higher returns in low-volatility markets. All you have to do is predict the direction of a given crypto asset, such as BTC, ETH, LINK, BIT, and many more, within a pre-set timeframe and enjoy higher returns on your capital. Upon maturity, you will receive your proceeds in the form of USDT or your chosen crypto asset, depending on the outcome of your trade.                </h6>
                <h3>{PROJECT_NAME} Saving </h3>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6 className='m-0'>Earn in USDT when prices rise, earn in alternate crypto assets when prices fall</h6>
                  </li>
                  <li><h6 className='m-0'>Earn attractive yield while accumulating your preferred crypto asset.</h6>
                  </li>
                  <li><h6 className='m-0'>Enjoy short lock-up periods to manage your assets as you desire.</h6> </li>
                </ul>
              </div>
            </div>
            <div className='col-md-6 col-lg-5'>
              <img className='img-about-1' src={about1} width='100%' alt='FudxEran/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0 align-items-center'>
            <div className='col-md-6'>
              <img className='img-about-1' src={about2} width='100%' alt='FudxEran/img' />
            </div>
            <div className='col-md-6 about-page'>
              <div className='about-story-text'>
                <h3 className='mb-0'>Liquidity Mining </h3>
                <h6>
                {PROJECT_NAME}'s Liquidity Mining refers to liquidity pools that are based on a revamped automated market maker (AMM) model. You can add liquidity to earn yield derived from trading fees. You can also add leverage to increase your share of the pool and maximize your yield.
                </h6>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6 className='m-0'>Add liquidity to the pool</h6>
                  </li>
                  <li><h6 className='m-0'>Claim daily yield via liquidity provision</h6>
                  </li>
                  <li><h6 className='m-0'>Add or remove liquidity anytime</h6> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row vision'>
            <div className='col-md-12'>
              <div className='about-story-text'>
               <div className='text-center'> <h2>Launchpool </h2></div>

                <h5 className='mb-0'>What is {PROJECT_NAME} Launchpool?</h5>
                <h6>{PROJECT_NAME} Launchpool allows you to stake and earn tokens for free. The tokens you've staked can be unstaked anytime.</h6>
                <h5 className='mb-0'>How is my daily yield calculated?</h5>
                <h6>Your daily yield depends on the number of tokens you've staked to each pool in proportion to the total number of tokens staked to each pool by the respective participants. Once staked, your daily yield will be automatically calculated.
                  <br />Let’s assume that you’ve staked token α to earn a new token β: <br />Your daily yield for β = (the amount of α you’ve staked to the α pool / total number of α staked to the α pool by the all participants) * daily β prize pool
                  <br />Calculations for your yield will start on the next day (T+1) after you’ve staked tokens, and will be deposited daily to your Earn Account starting one day after the calculation period (T+2). No yield will be generated on the day you unstake your staked tokens. Your yield will be refreshed daily at 00:00AM UTC.
                  <br />Your last daily yield and staked tokens will be automatically credited to your Earn Account two (2) days after the event ends. Please note that there may be delays in the depositing of yields earned. You will only receive a yield if it’s greater than or equal to $0.01 equivalent.
                </h6>
                <h5 className='mb-0'>When can I unstake tokens I've staked?</h5>
                <h6>Your staked tokens can be unstacked anytime. They will be automatically credited to your Earn Account immediately once unstaked. To unstake, please head to “Launch pool” in “Earn Account” under “Orders & Trades”.</h6> 

                <h5 className='mb-0'>Can I compound my yield?</h5>
                <h6>Currently, compounding is not supported. Your daily yield will not be reinvested automatically to generate additional yield. Instead, you must make a new deposit manually on the {PROJECT_NAME} Launch pool.</h6> 
                <h5 className='mb-0'>How is the estimated APY calculated?</h5>
                <h6>The estimated annualized percentage yield (APY) is the sum of the estimated APY from each pool, which is derived from the total number of tokens staked to the pool by all respective participants. Please note that the higher the total number of tokens staked to a pool, the lower its estimated APY will be.</h6> 
                <h5 className='mb-0'>What is the effective staking amount?</h5>
                <h6>The effective staking amount refers to the number of tokens you’ve staked that is currently being calculated for yield. Assuming that you’ve staked 100 BIT at 10:00AM UTC on Nov. 10, 2021, your effective staking amount will be updated to 100 BIT at 00:00AM UTC on Nov. 11, 2021.</h6> 
                <h5 className='mb-0'>Who is eligible for {PROJECT_NAME} Launchpool?</h5>
                <h6>You'll need to complete KYC Level 1 verification first to stake BIT and/or the new token on the {PROJECT_NAME} Launchpool. KYC verification is not required for "Study to Earn" and "Share to Earn". Please note that enterprises are not eligible for the event. You can log in to your {PROJECT_NAME} account and click here to complete the individual KYC verification</h6> 

              </div>
            </div>
            <div className='col-md-12 border p-5'>
              <div className='about-story-text text-center'>
              <h3 className='mb-0'>Cash Fudx</h3>
                <p>That why {PROJECT_NAME} is Best Crypto Exchange in
                  India. So, start investing in digital currency. Shiba
                  INU is good in each and every way. Crypto Prices
                  are really getting higher in market. Enter in Web
                  3.0Crypto, to trade with ease.</p>
               </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default FudxEarn