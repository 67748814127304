import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
// import madel from '../../../../_assets/images/madel.svg';
// import visa from '../../../../_assets/images/visa2.png';
import { LangState } from '../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../lang/lang';
// import noDataImg from '../../../../_assets/images/no_history-removebg-preview.png';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
// import { P2PGetPaymetMethodes, P2PPaymetField, P2PPaymetFormSubmit, P2PPaymetMethodes } from '../../../../redux/actions/payment.action';
import { array } from 'prop-types';
import { action_sell_status, view_user_order_p2p } from '../../../redux/actions/p2p.action';
import SellChat from './SellChat';
import { getUserDetails, uploadPaymentProof } from '../../../redux/actions/dashboard.action';
import { toast } from '../../../components/toast';
import { startLoader } from '../../../redux/actions/loader.action';

const SellOrders_Chat = ({history}) => {
    const { id } = useParams();

    const {langchange} = LangState();
    const [open, setOpen] = useState(false);
    const [dropdownVisible,setdropdownVisible] = useState(false);
    const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
    const [search, setsearch] = useState();
    const [methodName, setmethodName] = useState();
    const [methodId, setmethodId] = useState();
    const [remark, setremark] = useState();
    const [inputType, setinputType] = useState('trx');
    const [documentFront,SetdocumentFront] =  useState();
    const {screenLoader,setscreenLoader} = LangState();
    const [error,Seterror] =  useState();
    const [utr,Setutr] =  useState();

    const dispatch = useDispatch();
    const view_user_sell = useSelector(state => state.p2p.view_user_sell);
    const user = useSelector(state => state.dashboard.user);
    useEffect(() => {
        dispatch(getUserDetails())        
        dispatch(view_user_order_p2p({order_id:id}));

    },[])
    const handleSubmitForm = () => {
    }

    const approve = () => {
        var data = {
            order_id : id,
            status :'APPROVED',
            remarks: 'APPROVED',
        }
        dispatch(action_sell_status(data,setOpen))
    }

    const  reject = () => {
        var data = {
            order_id : id,
            status :'REJECTED',
            remarks: remark,
        }
        dispatch(action_sell_status(data,setOpen))
    }
    console.log('view_user_sell',view_user_sell?.order?.orderId?.[0]?._id);
    const showBtn = () =>{
       if(view_user_sell?.order?.status == 'PENDING' ){
         return (
            <div>
                <a  className='btn approved-btn' onClick={() => approve()}> Approve </a>
                <a  className='btn approved-btn bg-danger ml-3' onClick={onOpenModal}> Dispute </a>
            </div>
            );
        }
    }
    function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr);
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	}



    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast.info('copied : ' + text);

    }

    const handleSubmitForm1 = (e) => { 
        e.preventDefault();
        if(inputType == 'trx'){
            const  data = {
               proof_type:"utr",
               utr:utr,
            }
            dispatch(uploadPaymentProof(data,history,setscreenLoader,id));
            dispatch(startLoader());
        }else{
            if(documentFront != undefined  ){
                setscreenLoader('true');
                e.preventDefault(); 
                let formData = new FormData();
                formData.append('payment_proof', documentFront); 
                formData.append('proof_type', 'image'); 
    
                dispatch(uploadPaymentProof(formData,history,setscreenLoader,id));
                dispatch(startLoader());
            }else{
                Seterror(' Field Required Proof Upload');
            }
        }
        
      }

    const uploadImage = (e, input) => {
        let file = e.target.files[0];
        let createFileUrl = URL.createObjectURL(file);
        SetdocumentFront(file)
        // setShowImage(URL.createObjectURL(e.target.files[0]))
    }





    const  inputField = () => {
        if(inputType == 'trx'){
            var  input = <div class="form-group">
                            <label for="">Transaction ID</label>
                            <input type="text" class="form-control" id="" aria-describedby="" placeholder="Enter Transaction ID" onChange={(e) => Setutr(e.target.value)} />
                        </div>;
        }else{
            var input = <div class="form-group">
                            <label for="">Upload Proof Image</label>
                            <input type="file" class="form-control" onChange={uploadImage} aria-describedby="" placeholder="Enter Proof Image" />
                        </div>
        }

        return(
            <>
                 {input}
            </>
            );
    }

    console.log('inputType',inputType);
    return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-container">  
            <div class="chat_Sws">
                <div class="chat_contaienr1">
                    <div className='back_pages' onClick={() => history.push('/vendor/p2p/sell')}>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </div>
                    <div class="row m-0 h-100">
                        <div class="col-xl-8 col-lg-6 chat_column">
                            <div class="card" id="PayMent_box">
                                <div class="chat_payment_status">
                                    <div class="Payment_container active">
                                        <div class="number number_1 ">
                                            <p class=""> 1 </p>
                                        </div>
                                        <p class="pay_name"> Pending Payment </p>
                                    </div>
                                    <div class="Payment_container">
                                        <div class="number number_1">
                                            <p> 2 </p>
                                        </div>
                                        <p class="pay_name"> Release Crypto to the buyer? </p>
                                    </div>
                                    <div class="Payment_container">
                                        <div class="number">
                                            <p class="pay_name"> 3 </p>
                                        </div>
                                        <p class="pay_name"> Completed </p>
                                    </div>
                                </div>
                                <div class="chat_order_info">
                                    <div class="row m-0">
                                        <div class="col-12 p-0 mb-3">
                                            <h5 class="chat_title">Order Info</h5>
                                        </div>
                                        <div class="col-lg-2 col-md-6 p-0">
                                            <h6> Amount </h6>
                                            <h4 class="text-danger"><i class="fa fa-inr"></i> {view_user_sell?.order?.quantity * view_user_sell?.order?.orderId?.[0]?.price} {view_user_sell?.order?.orderId?.[0]?.sell_currency} </h4>
                                        </div>
                                        <div class="col-lg-2 col-md-6 p-0">
                                            <h6> Price</h6>
                                            <h4 class="rs"><i class="fa fa-inr"></i> {view_user_sell?.order?.orderId?.[0]?.price} {view_user_sell?.order?.orderId?.[0]?.sell_currency} </h4>
                                        </div>
                                        <div class="col-lg-4 col-md-6 p-0">
                                            <h6> Quantity</h6>
                                            <h4 class="rs">{view_user_sell?.order?.orderId?.[0]?.sold_quantity} <span> {view_user_sell?.order?.token_id?.[0]?.coinName}</span></h4>
                                        </div>
                                        <div class="col-lg-4 col-md-6 p-0">
                                            <h6> Time <i class="fa fa-info-circle"></i></h6>
                                            <h4 class="rs">{secondsToHms(view_user_sell?.order?.createdAt)}<span> </span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="Payment_method_a">
                                    <div class="Payment_method_header d-flex justify-content-between align-item-center">
                                        <h5 class="chat_title">Paymet Method</h5>
                                        <a href="#"> View all <i class="fa fa-angle-right ml-2"></i> </a>
                                    </div>
                                    <div class="card bg-transparent">
                                        <div class="method_payment w-50">
                                            <a href="#">
                                                <div class="id_area">
                                                    <span> User Payment Method  </span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="method_address w-100">
                                        {view_user_sell?.order?.UserPaymentMethodID?.[0]?.PaymentMethodFields?.map((val) => {
                                            return(
                                                <div className='method_address_1 mb-3'>
                                                    <p>{val?.field.replace('_',' ')}:</p>
                                                    <h5> {val?.value} <span onClick={() => copyToClipboard(val?.value)}><i class="fa fa-clone"></i></span></h5> 
                                                </div>
                                            );
                                        })} 
                                            {/* <div class="method_address_1 mb-3">
                                                <p> Name </p>
                                                <h5> User Name <i class="fa fa-clone"></i></h5>
                                            </div>
                                            <div class="method_address_1">
                                                <p> UPI ID </p>
                                                <h5> UPIID@ybl <i class="fa fa-clone"></i></h5>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-xl-6 col-lg-6">
                                        <div class="Payment_FAQ">
                                            <h5 class="chat_title">FAQ</h5>
                                            <div id="accordion">
                                                <div class="card">
                                                    <div class="card-header bg-transparent p-0 border-0">
                                                        <a class="card-link" data-toggle="collapse" href="#collapseOne">
                                                            <i class="fa fa-plus-circle"></i>
                                                            <span> What Should I do After I Received The Payment? </span>
                                                        </a>
                                                    </div>
                                                    <div id="collapseOne" class="collapse" data-parent="#accordion">
                                                        <div class="card-body">
                                                            Lorem ipsum..
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card">
                                                    <div class="card-header bg-transparent p-0 border-0">
                                                        <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                                            <i class="fa fa-plus-circle"></i>
                                                            <span> What Should I do If the Other Party did not make Payment? </span>
                                                        </a>
                                                    </div>
                                                    <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                                        <div class="card-body">
                                                            Lorem ipsum..
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {view_user_sell?.order?.user_id?.[0]?._id == user._id ? showBtn():' '}
                                                
                                        </div>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 pt-3'>
                                        <h4>Upload Payment Proof</h4>
                                    <form onSubmit={handleSubmitForm1}>
                                        <div class="form-group">
                                            <select class="form-control form-select" aria-label="Default select example" onChange={(e) => setinputType(e.target.value)}>
                                                <option selected>Select Proof type</option>
                                                <option value="screenshot" onClick={() => setinputType('screenshot')}>Screenshot</option>
                                                <option value="trx" onClick={() => setinputType('trx')}>Transaction ID</option>
                                            </select>
                                        </div>
                                            {inputField()}
                                            
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                            </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 chat_column">
                            <div class="card" id="Phone_chat">
                                <div class="card-header border-0">
                                    <div class="User_first_letter">
                                        <p> {view_user_sell?.order?.user_id?.[0]?._id == user._id ? view_user_sell?.order?.buyer_id?.[0]?.firstname?.slice(0, view_user_sell?.order?.buyer_id?.[0]?.firstname?.length + 1 - view_user_sell?.order?.buyer_id?.[0]?.firstname?.length) :  view_user_sell?.order?.user_id?.[0]?.firstname?.slice(0, view_user_sell?.order?.user_id?.[0]?.firstname?.length + 1 - view_user_sell?.order?.user_id?.[0]?.firstname?.length)}</p>
                                    </div>
                                    <div class="User_name w-100">
                                        <p class="user_fullName"> {view_user_sell?.order?.user_id?.[0]?._id == user._id ? view_user_sell?.order?.buyer_id?.[0]?.firstname +' ' + view_user_sell?.order?.buyer_id?.[0]?.lastname:  view_user_sell?.order?.user_id?.[0]?.firstname +' ' + view_user_sell?.order?.user_id?.[0]?.lastname} </p>
                                        <p class="user_details"> Normal User <span> <a href="#"
                                                    class="text-decoration-none">Report</a> </span> </p>

                                        <div class="User_activity d-flex justify-content-between align-item-center">
                                            <div class="">
                                                <h6> 30d Trades</h6>
                                                <h5> 26</h5>
                                            </div>
                                            <div class="">
                                                <h6> 30d Completion Rate </h6>
                                                <h5> 65.00 %</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                              

                            {/* <SellChat /> */}
                        {view_user_sell?.order?.buyer_id?.[0]?._id == user._id  || view_user_sell?.order?.user_id?.[0]?._id == user._id ? <SellChat props={ {id : view_user_sell?.order?._id,buyer_id : view_user_sell?.order?.buyer_id?.[0]}} /> :' '}
                        



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
            <div className='container'>
                <div className='row deposit-modal'>
                    <div className='col-md-12 '>
                        <h3>Reject Order</h3>
                        <div className='card border-0 card__box_modal'>
                        <div className='p2p-pay-system bg-transparent'>
                            {/* <p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p> */}
                        </div>
                        <div class="input-group md-form form-sm form-1 pl-0 mt-5 d-block  ">
                            <label className=''>Remark:</label>

                            <div class="input-group-prepend "> 
                                <textarea id="w3review" className='w-100' name="w3review" rows="4" cols="50" onChange={(e) => setremark(e.target.value)}></textarea>
                            </div>
                            <div class="inpmodal-button text-center mt-4"> 
                                <button  className='btn btn-danger' onClick={() => reject()} >Reject Order</button>
                            </div>
                        </div>
                        </div>
                    
                
                    </div>
                </div>
            </div>
        </Modal>
    </div>
            
    
  )
}

export default SellOrders_Chat