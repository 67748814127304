import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage,mainAccountpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { PROJECT_NAME } from '../../../_constants';
import { buy_swap_token, get_calculate_swap_buy_price, get_calculate_swap_sell_price, get_swap_tokens, get_swap_transactions, sell_swap_token } from '../../../redux/actions/assets.action';
import tronImage from '../../../_assets/images/tron.png';
import bnbImage from '../../../_assets/images/busd1.png';
import { logDOM } from '@testing-library/react';
import { Pagination, Icon } from 'semantic-ui-react';

const UseStyles = makeStyles({
  assetsBtn: {
      marginTop:'80px',
      display: 'flex',
      background: 'rgba(125, 135, 146, 0.07840000000000003)',
      alignItems: 'center'
  },
  btnDesign:{
      width: '1200px',
      margin:  '0 auto',
      display: 'flex',
      marginBottom: '-16px',
      justifyContent: 'space-between',
      overflowX: 'scroll',
      // width: '100%',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
          height: '0px',
        },

  },
  btnUl:{
      display: 'flex',
      marginBottom: '1em',
  },
  btnTag:{
      height: '64px!important',
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '.1px',
      borderRadius: '0!important',
      color: 'hsla(0,0%,100%,.6)',
      padding: '0 12px',
  },
  MuiButtonlabel:{
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
  },
  MuiTouchRippleroot:  {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: 'hidden',
      position: 'absolute',
      borderRadius: 'inherit',
      pointerEvents: 'none',
      },
  
});
const SwapTable = () => {
	const classes = UseStyles();
	const {langchange} = LangState();
    const [search , setsearch] = useState('');
	const dispatch = useDispatch();
    const swap_trans = useSelector(state => state.assets.swap_trans);
    const [page,setpage] = useState(1);
	const [listno , setlistno] = useState(0);
	useEffect(() => {
		dispatch(get_swap_transactions({skip:0,limit:5}));
		
	}, [page])
	const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 5);
        dispatch(get_swap_transactions({ skip: pageNo, limit: 5 })); 
		setlistno(pageNo)
    }   




	const handleSearch = () => {	
		if(search != undefined){
			return swap_trans.swap_trans?.filter(
				(iii) => 
				iii.coinName.toLowerCase().includes(search)
			)
		}else{
			return(swap_trans.swap_trans);
		}
    }
	function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr); 
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
	}
	return (
	<div>
		<div className={classes.assetsBtn}>
			<div className={classes.btnDesign}>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
				</ul>

				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							{/* <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link> */}
                            <Link to='/vendor/swap-currency' style={{color:'rgb(40 205 149)'}}> Swap Currency</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/depositassest' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/withdrawAssets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/bonus' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
				</ul>
			</div>
			
		</div>
		<div className="box___3B-ao ">
            <div className="wrapper___1F-PJ">
               
                <section style={{marginTop: '22px'}}>
                    <div className="markets___3kLEb">
						<div className='table-responsive'>
							<table class="table">
								<thead class="thead-light">
									<tr>
									<th scope="col">#</th>
									<th scope="col">Swap Token</th>
									<th scope="col">Currency</th>
									<th scope="col">Amount</th>
									<th scope="col">Currency Amount</th>
									<th scope="col">Usd Amount</th>
									<th scope="col">Mode</th>
									<th scope="col">createdAt</th>
									
									</tr>
								</thead>
								<tbody>
									{swap_trans?.records?.map((val,key) => {
										return(
											<tr className='borderless'>
												<th >{listno+key+1}</th>
												<td>{val?.swaptoken}</td>
												<td>{val?.currency}</td>
												<td>{val?.amount}</td>
												<th >{val?.currency_amount}</th>
												<td>{val?.usd_amount}</td>
												<td>{val?.mode}</td>
												<td>{secondsToHms(val?.createdAt)}</td>
												
											</tr>
										);
									})}
									
									
								</tbody>
							</table>
							<div className='pagination'>
							<Pagination className="mx-auto"
								defaultActivePage={1}
								onPageChange={pageChange}
								ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
								firstItem={{ content: <Icon name='angle double left' />, icon: true }}
								lastItem={{ content: <Icon name='angle double right' />, icon: true }}
								prevItem={{ content: <Icon name='angle left' />, icon: true }}
								nextItem={{ content: <Icon name='angle right' />, icon: true }}
								totalPages={Math.ceil(swap_trans?.total / 5)}
						/>  
							</div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
		
	</div>
	)
}

export default SwapTable