import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "../history.reducer";
import { reducer as formReducer } from "redux-form";
import loader from "./loader.reducer";
import persist from "./persist.reducer";
import menu from "./menu.reducer";
import dashboard from "./dashboard.reducer";
import token from "./token.reducer.";
import payment from "./payment.reducer";
import assets from "./assets.reducer";
import p2p from "./p2p.reducer";
import user from './user.reducer';
import chart from './chart.reducer'; 
import google2fa from "./google2fa.reducer";
const appReducer = combineReducers({
    loader: loader,
    form: formReducer,
    persist: persist,
    dashboard: dashboard,
    router: connectRouter(history),
    menu:menu,
    token:token,
    payment:payment,
    assets:assets,
    p2p:p2p,
    user:user,
    chart:chart,
    google2fa:google2fa
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
