import { makeStyles, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { CoinList } from '../../../../config/api';
import { CryptoState } from '../../../../CryptoContext';
import { home_table_box_heading, home_table_search, home_table_th } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import down from '../../../../_assets/images/price-down-icon.svg';
import up from '../../../../_assets/images/up-icon.svg';
import graph from '../../../../_assets/images/graph.png';
import { Link } from 'react-router-dom';
import { get_swap_tokens } from '../../../../redux/actions/assets.action';
import { useDispatch, useSelector } from 'react-redux';

export function numberWithCommma(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const Cointable = () => {
    const  dispatch = useDispatch();
    const swap_tokens = useSelector(state => state.assets.swap_tokens.swap_tokens);
    const history = useHistory();
    const { currency, symbol } = CryptoState();
    const { langchange } = LangState();
    const [coins, setcoins] = useState([]);
    const [loading, setloading] = useState(true);
    const [search, setsearch] = useState('');
    const [page, setpage] = useState(1);
    const fatchcoins = async () => {
        const { data } = await axios.get(CoinList(currency));
        setcoins(data);
        setloading(false);
    }
    useEffect(() => {
        fatchcoins();
        dispatch(get_swap_tokens());

    }, [currency])

    // const darkTheme = createTheme({
    //     palette:{
    //         primary:{
    //             main:'#fff',
    //         },
    //         type:'dark',
    //     },
    // });

    const handleSearch = () => {
        return coins.filter(
            (coin) =>
                coin.name.toLowerCase().includes(search) ||
                coin.symbol.toLowerCase().includes(search)
        );
    }

    const handleSearch2 = () => {
        return swap_tokens?.filter(
            (swap) =>
            swap.tokenName?.toLowerCase().includes(search) 
            // ||
            // swap.symbol.toLowerCase().includes(search)
        );
    }
    // console.log('search',search== '' ?'empty search ': 'fxc'.indexOf(search) > -1? 'fxc':' not foung');
    
    const useStyles = makeStyles(() => ({
        row: {
            backgroundColor: '#16171a',
            cursor: 'ponter',
            '&:hover': {
                backgroundColor: '#131111',
            },
            fontFamily: 'Montserrat',
        },
        Pagination: {
            '& .MuiPaginationItem-root': {
                color: 'rgb(40 205 149)',
            },
            '& .MuiPagination-ul': {
                display: 'flex',

            }
        }
    }));
    const classes = useStyles();

    const exchangeCoin = () => {
        return(

            handleSearch2()?.map((valCoin) =>{
                return(
                    <li onClick={() => history.push(`/login`)}>
                <ul key={valCoin?._id}   >

                    <li className='d-flex first_li'>
                        <img className="coin__icon mx-2" src={valCoin?.image} alt={valCoin?.tokenName} />
                        <span className='mx-2'>{valCoin?.tokenName?.toUpperCase()}</span><br />
                        <span className='mx-2 mt-1 overflow-hidden'>{valCoin?.tokenName}</span>
                    </li>

                    <li>
                        <span>
                            {/* {symbol} */}$
                        {" "}{valCoin?.sellPrice}
                            {/* {numberWithCommma(row.current_price.toFixed(2))} */}
                            </span>

                    </li>
                    <li className={up}>
                        <img src={up} alt="up/img" />

                    </li>
                    <li>
                        <a><img src={graph} width='120' alt='img/graph' /></a>
                    </li>
                    <li className="buy__trade__button">
                        <Link to="">{home_table_th['4'][langchange]}</Link>
                        <Link to="">{home_table_th['5'][langchange]}</Link>
                    </li>

                </ul>
            </li>
                );
            })
            
        )
    }
    return (
        <div> <div className="update__trending__coin__tabs">
            <div className="tabs__button">
                <button className="tab__btn active">
                    {home_table_box_heading['0'][langchange]}
                </button>
                {/* <button className="tab__btn">
            New Coins
        </button>
        <button className="tab__btn">
            Top Gainers
            <span>
                62.98%
            </span>
        </button> */}
                <span className="tabs__bottom__line"></span>
            </div>
            {/* <input  type='text' label='Search For a Crypto Currency..'   onChange={(e) => setsearch(e.target.value)}/>  */}
            <div className="form__control my-2 mb-5">
                <input className="search home__search" type='text' label={home_table_search['0'][langchange]} placeholder={home_table_search['0'][langchange]} onChange={(e) => setsearch(e.target.value)} />
            </div>
            <div class="table-responsive-xl">
                <div className="tabs__content active">
                    <div className="content__heading w-100">
                        <ul className='outer-table'>

                            <li className=''>
                                {home_table_th['0'][langchange]}
                            </li>
                            <li>
                                {home_table_th['1'][langchange]}
                            </li>
                            <li>
                                {home_table_th['2'][langchange]}
                            </li>

                            <li>
                                {home_table_th['3'][langchange]}
                            </li>
                            <li className='d-sm__data-none'>
                                
                                
                            </li>
                        </ul>
                    </div>

                    <div className="content__inner__data ">
                        <ul className="coins__price">
                            {/* {search== '' ? exchangeCoin(): 'fxc'.indexOf(search) > -1? exchangeCoin():''} */}
                       {exchangeCoin()}
                            {handleSearch()
                                .slice((page - 1) * 5, (page - 1) * 5 + 5)
                                .map((row) => {
                                    const profit = row.price_change_percentage_24h > 0;
                                    return (
                                        <>
                                            <li>
                                                <ul onClick={() => history.push(`/coin/${row.symbol}`)} key={row.name}   >

                                                    <li className='d-flex first_li '>
                                                        <img className="coin__icon mx-2" src={row.image} alt={row?.name} />
                                                        <span className='mx-2'>{row.symbol?.toUpperCase()}</span><br />
                                                        <span className='mx-2 mt-1'>{row?.name}</span>
                                                    </li>

                                                    <li>
                                                        <span>{symbol}{" "}
                                                            {numberWithCommma(row.current_price.toFixed(2))}</span>

                                                    </li>
                                                    <li className={profit ? 'up' : 'down'}>
                                                        <span className=' '>{profit && '+'} {row.price_change_percentage_24h.toFixed(2)}</span>
                                                        {/* <span>-565641.454%</span> */}
                                                        <img src={profit ? up : down} alt="P/img-a" />

                                                    </li>
                                                    <li>
                                                        {/* <span>{symbol}{" "} {numberWithCommma(row.market_cap.toString().slice(0,-6))} M </span>   */}
                                                        <a><img src={graph} width='120' alt='Graph/img' /></a>
                                                    </li>
                                                    <li className="buy__trade__button">
                                                        <Link to="">{home_table_th['4'][langchange]}</Link>
                                                        <Link to="">{home_table_th['5'][langchange]}</Link>
                                                    </li>

                                                </ul>
                                            </li>

                                        </>

                                    );
                                })}

                        </ul>
                        <div className='d-flex first_li' style={{flexWrap: 'nowrap'}}>
                            <Pagination style={{ padding: 20, width: '100%', display: 'flex', justifyContent: 'center' }} classes={{ ul: classes.Pagination }} count={(handleSearch()?.length / 5).toFixed(0)} onChange={(_, value) => {
                                setpage(value);
                                window.scroll(0, 450);
                            }} /></div>
                        <div className="inner__link">

                            <Link to="/NoData">{home_table_th['6'][langchange]}</Link>
                            <Link to="/NoData" className="view__more">
                                <span>
                                    {home_table_th['7'][langchange]}
                                </span>
                                <img src="assets/svg/more-view-icon.svg" alt="view/img" />
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            <div className="tabs__content ">
                <div className="content__heading">
                    <ul>
                        <li>
                            Coin
                        </li>
                        <li>
                            Last Price
                        </li>
                        <li>
                            24H Change
                        </li>

                        <li> M
                            arkets
                        </li>
                    </ul>
                </div>

                <div className="content__inner__data">
                    <ul className="coins__price">
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/ltc-icon.png" alt="ltc-icon/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="down/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/ltc-icon.png" alt="coin__icon/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="down__app/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/ltc-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="down-icon/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/xrp-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="up">
                                    <a href="#">
                                        <span>565641.454%</span>
                                        <img src="assets/svg/up-icon.svg" alt="profit-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/kcs-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="loss-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div className="inner__link">
                        <a href="#">Pin to Chrome</a>
                        <a href="kucoin-market.html" className="view__more">
                            <span>
                                View More
                            </span>
                            <img src="assets/svg/more-view-icon.svg" alt="more-data/img" />
                        </a>
                    </div>
                </div>

            </div>
            <div className="tabs__content">
                <div className="content__heading">
                    <ul>
                        <li>
                            Coin
                        </li>
                        <li>
                            Last Price
                        </li>
                        <li>
                            24H Change
                        </li>

                        <li> M
                            arkets
                        </li>
                    </ul>

                </div>

                <div className="content__inner__data">
                    <ul className="coins__price">

                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/ltc-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="down-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/ltc-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="loss-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/ltc-icon.png" alt="coin" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="loss-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/xrp-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="up">
                                    <a href="#">
                                        <span>565641.454%</span>
                                        <img src="assets/svg/up-icon.svg" alt="profit-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a href="#">
                                        <img className="coin__icon" src="assets/img/kcs-icon.png" alt="coin/img" />
                                        <span>BTC</span>
                                        <span>Bitcoin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>38300.05</span>
                                        <span> 38,300.15</span>
                                        <span>USD</span>
                                    </a>
                                </li>
                                <li className="down">
                                    <a href="#">
                                        <span>-565641.454%</span>
                                        <img src="assets/svg/price-down-icon.svg" alt="profit-trade/img" />
                                    </a>

                                </li>
                                <li>
                                    <a href="#">
                                        <img src="assets/svg/graph.svg" alt="graph/img" />
                                    </a>

                                </li>
                                <li className="buy__trade__button">
                                    <a href="#">Trade</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="inner__link">
                    <Link to="/NoData">Pin to Chrome</Link>
                    <Link to="/NoData" className="view__more">
                        <span>
                            View More
                        </span>
                        <img src="assets/svg/more-view-icon.svg" alt="view-more/img" />
                    </Link>
                </div>

            </div>
        </div>
        </div>
    )
}

export default Cointable