import React from 'react'
import spotlight from '../../../../_assets/images/newImages/spotlight.png';
import spotlight1 from '../../../../_assets/images/newImages/spotlight1.png'
import spotlight2 from '../../../../_assets/images/newImages/spotlight2.png'
import spotlight3 from '../../../../_assets/images/newImages/spotlight3.png'
import { PROJECT_NAME } from '../../../../_constants';

const spotlightCenter = () => {
    return (
        <div className='container spotlightCenter_container ' style={{ background: 'rgba(233, 233, 233)', marginTop: '80px' }}>

            <div className='row m-0'>
                <div className='col-md-12 m-0 p-0'>
                    <div className='spotlightCenterImg'>
                        <img src={spotlight} width="100%" height="100%" />
                    </div>
                </div>
            </div>
            <div className='row m-0 Main_row'>
                <div className='col-md-12 m-0 p-0 '>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0 '>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight (South Asia Exclusive) - Gari Network (GARI) </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Gari Network (GARI) </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - ClearDAO (CLH)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='spotlightCenterImg'  >
                        {/* <img src={spotlight} /> */}
                    </div>
                </div>
                <div className='col-md-12 m-0 p-0'>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0 '>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Chumbi Valley (CHMB)</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Victoria VR (VR)</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight -Cryowar (CWAR)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='spotlightCenterImg'  >
                        {/* <img src={spotlight} /> */}
                    </div>
                </div>
                <div className='col-md-12 m-0 p-0'>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight1})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0 '>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight -IX Swap (IXS)</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight1})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Lithium (LITH)</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight1})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - CoinBurp (BURP)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='spotlightCenterImg'  >
                        {/* <img src={spotlight} /> */}
                    </div>
                </div>
                <div className='col-md-12 m-0 p-0'>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight2})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0 '>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - MEME.COM (MEM)</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight2})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Hot Cross (HOTCROSS) </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight2})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Polkadex (PDEX)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='spotlightCenterImg'  >
                        <img src={spotlight} />
                    </div> */}
                </div>
                <div className='col-md-12 m-0 p-0'>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight3})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0 '>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Velo</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight3})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Bitbns (Flash Sale)</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight3})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - Bitbns</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='spotlightCenterImg'  >
                        {/* <img src={spotlight} /> */}
                    </div>
                </div>
                <div className='col-md-12 m-0 p-0'>
                    <div className='row m-0'>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0 '>
                                    <button className='spotlightCenterImg-box-button-e'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>{PROJECT_NAME} Spotlight - LUKSO</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>Spotlight - Tokoin（Second Round） </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border-0 shadow spotlightCenterImg-main-box'>
                                <div className='spotlightCenterImg-box text-white p-0 card-header' style={{ backgroundImage: `url(${spotlight})`, marginTop: '0px' }}>
                                </div>
                                <div className='card-body m-0'>
                                    <button className='spotlightCenterImg-box-button'>Ended</button>
                                    <p className='spotlightCenterImg-box-p'>Spotlight - Tokoin（First Round） </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='spotlightCenterImg'  >
                        {/* <img src={spotlight} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default spotlightCenter