import React, { useEffect, useState } from 'react'

import firebase from 'firebase/app';
import { auth } from '../../../firebaseData';
import { getAuth, signInWithPopup, GoogleAuthProvider,signOut } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
const ChatLogin = () => {
    const [userData,setuserData] = useState();
    const [loginToken,setloginToken] = useState();
    const [user] = useAuthState(auth)
    function siginInChat() {
        const provider = new GoogleAuthProvider();

        // provider.setCustomParameters({
        //     'login_hint': 'kuldeep.chandora2016@gmail.com'
        //   });
          
        // const provider =  new firebase.auth.GoogleAuthProvider();
        // auth.signInWithPopup(provider);
        signInWithPopup(auth, provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            setloginToken(token)
            const user = result.user;
            setuserData(user)
            // console.log(token,user);
            // ...
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
          });    

    }
    function signOutChat() {
        signOut(auth).then(() => {
            console.log('auth',auth);
            setuserData('');
        }).catch((error) => {
            // An error happened.
          });

    } 

    useEffect(() => {

    },[userData])
    console.log('userData',user);
    console.log(userData?.providerData);
    
    const logOutButton =() => {
        return(<button onClick={signOutChat}>logout</button>)
    }
    const logInButton = () => {
        return( <button onClick={siginInChat}>Chat Login</button>
        )
    }
  return (
    <div style={{marginTop:'200px'}}>
        {user ? logOutButton() : logInButton() }
    </div>
  )
}

export default ChatLogin