import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const SupportCentre = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Support Centre/FAQ</h2>
            {/* <h6>{PROJECT_NAME} Earn is our asset management platform that helps you grow your crypto holdings easily.</h6> */}
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        <div class="about_story">
          <div className='row m-0 align-items-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner_story_top'>
                <img className='img-about-1' src={Ab} width='100%' alt='SupportCentre/img' />
                <img src={abcd} width='100%' alt='SupportCentre/img' />
              </div>
              <div className='banner_story_top-2'>
                <img className='img-about-1' src={abc} width='100%' alt='SupportCentre/img' />
                <img src={abcde} width='100%' alt='SupportCentre/img' />
              </div>
            </div>
            <div className='col-lg-7 about-page col-md-6'>
              <h3 className='about_title'> Knowledge base</h3>
              <div className='about-story-text'>
                <h3 className='mt-5 mb-0'>Crypto fundamentals</h3>
                <h6 className='m-0'>
                  A beginner’s guide to cryptocurrency trading and basic questions  
                </h6>
                <h3 className='mb-0'>Account and security </h3>
                <p>Tips and issues: how to create, protect and operate a {PROJECT_NAME} account, what is 2fa and how it works, privacy policy</p>
                <h3 className='mb-0'>Trading</h3>
                <p>This section is all about trading, orders, futures. Manage your orders by following given advices.</p>
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h3 className='m-0'>Fees and levels</h3>
                  </li>
                  <li><h3 className='m-0'>Questions regarding fees</h3>
                  </li>
                  <li><h3 className='m-0'>{PROJECT_NAME} Token</h3> </li>
                  <li><h3 className='m-0'>What is a {PROJECT_NAME} Token</h3> </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0  First_row'>
          <div className='col-lg-7 about-page col-md-6'>
              <div className='about-story-text'>
                <h3 className='mt-5 mb-0'>Wallet App  </h3>
                <h6 className='m-0'>
                Blockchain wallet by the {PROJECT_NAME} team ensures a simple and safe way of buying, selling, sending, swapping, receiving, and storing your digital assets. App provides all the necessary features for handling your favourite cryptocurrencies. You can be a seasoned trader, a long-term holder, or an enthusiastic beginner investor, and use the wallet for buying and managing Bitcoin and altcoins as effortlessly as you handle your fiat money.               
                
                </h6>
              </div>
            </div>
            <div className='col-md-6 col-lg-5'>
              <img className='img-about-1' src={about1} width='100%' alt='SupportCentre/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0  align-items-center'>
            <div className='col-md-6'>
              <img className='img-about-1' src={about2} width='100%' alt='SupportCentre/img' />
            </div>
            <div className='col-md-6 about-page'>
              <div className='about-story-text'>
                <h3 className='mb-0'>Deposits/Withdrawals </h3>
                <h6> Instructions and tips about how to initiate and track the status of your deposits and withdrawals                </h6>
                <h3 className='mb-0'>Advanced Functionality </h3>
                <h6>Master and sub-account, API keys and markets overview</h6>
                <h3 className='mb-0'>Other</h3>
                <h6>Forks and swaps, Toolbox and other general information <br/> <br />All these are doubt area, so you need to clear them </h6>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default SupportCentre