import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const TechnicalSupport = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Technical Support</h2>
            <h6>Advice and answers from the {PROJECT_NAME} Team</h6>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        
        <div className='about_box-1 mt-sm-5'>
          <div className='row m-0 align-items-center First_row vision'>
            <div className='col-md-6'>
              <div className='about-story-text about-page'>
               <div className='text-center'> <h2>Technical Support </h2></div>
                  <div className='ml-md-5'>
                      <ul style={{color:'#000'}}>
                        <li><h3 className='m-2'>TRADING </h3>
                        </li>
                        <li><h3 className='m-2'>COINS AND MARKETS</h3>
                        </li>
                        <li> <h3 className='m-2'>ACCOUNT AND SECURITY</h3> </li>
                        <li><h3 className='m-2'>DEPOSITS/TRANFER/WITHDRAWALS</h3></li>
                        <li><h3 className='m-2'>LEGAL KYC</h3></li>
                        <li><h3 className='m-2'>OTHERS</h3></li>
                      </ul>
                  </div>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about3} width='100%' alt='Technical/img' />
            </div>
          </div>
        </div>
        {/* <div className='about_box-1 mt-5'>
          <div className='text-center mb-3'>
            <h4>(PUT THE NORMAL FORM OR AUTOMATED ONE)</h4>
            <p>Team will get in touch with you within 24 hours.</p>
            <h4>FREQUENTLY ASKED QUESTIONS (WANT TO PUT OR NOT)</h4>
          </div>
          
        </div> */}
        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default TechnicalSupport