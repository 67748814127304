import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import madel from '../../../../_assets/images/madel.svg';
import visa from '../../../../_assets/images/visa2.png';
import { LangState } from '../../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../../lang/lang';
import noDataImg from '../../../../_assets/images/no_history-removebg-preview.png';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { P2PGetPaymetMethodes, P2PPaymetField, P2PPaymetFormSubmit, P2PPaymetMethodes } from '../../../../redux/actions/payment.action';
import { array } from 'prop-types';
import { action_sell_status, view_user_order_p2p } from '../../../../redux/actions/p2p.action';

const SellOrders = () => {
    const { id } = useParams();

    const {langchange} = LangState();
    const [open, setOpen] = useState(false);
    const [dropdownVisible,setdropdownVisible] = useState(false);
    const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
    const [search, setsearch] = useState();
    const [methodName, setmethodName] = useState();
    const [methodId, setmethodId] = useState();
    const [remark, setremark] = useState();

    const dispatch = useDispatch();
    const view_user_sell = useSelector(state => state.p2p.view_user_sell);
    useEffect(() => {
        dispatch(view_user_order_p2p({order_id:id}));

    },[])
    const handleSubmitForm = () => {
    }

    const approve = () => {
        var data = {
            order_id : id,
            status :'APPROVED',
            remarks: 'APPROVED',
        }
        dispatch(action_sell_status(data,setOpen))
    }

    const  reject = () => {
        var data = {
            order_id : id,
            status :'REJECTED',
            remarks: remark,
        }
        dispatch(action_sell_status(data,setOpen))
    }
    console.log('view_user_sell',view_user_sell?.order);
    const showBtn = () =>{
       if(view_user_sell?.order?.status == 'PENDING'){
         return (
            <div>
                <a  className='btn approved-btn' onClick={() => approve()}> Approve </a>
                <a  className='btn approved-btn bg-danger ml-3' onClick={onOpenModal}> Dispute </a>
            </div>
            );
        }
    }
    function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr);
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	}
     return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-new-style">
            <div className="tab-container">
                <div className="left-sidebar" id="left-sidebar">
                    <ul className="nav flex-column"> 
                        <p> P2P </p>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange] } </Link>
                        </li>
                        <p> {p2ppage['0']['other'][langchange] } </p>
                        <li className="nav-item">
                            <a className="nav-link" href=""> {p2ppage['0']['support'][langchange] } </a>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange] }  </Link>
                        </li> 
                    </ul>
                </div>
                <div className="right-tab">
                   
                <div className="tab-content">
                    
                    <div id="USDT" className="tab-pane active">
                    <h3>Sell P2P Details </h3> 
                    <div className='p2p-pay-system'><p className=''>View User Sell P2P Details</p></div>

                        <div className="table-responsive mt-5"> 
                        
                        <div className='card border-0 text-center card__box'>
                        <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Token Name </p>
                                <p> {view_user_sell?.order?.token_id?.[0]?.coinName}</p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> User Payment Method ID </p>
                                <p> {view_user_sell?.order?.UserPaymentMethodID?.[0]?.PaymentMethodFields?.map((val) => {
                                    return(<div>{val?.field.replace('_',' ')}: {val?.value} </div>);})} </p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Purchase Quantity </p>
                                <p> {view_user_sell?.order?.quantity}</p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Purchase Quantity  Price</p>
                                <p> {view_user_sell?.order?.quantity * view_user_sell?.order?.orderId?.[0]?.price} {view_user_sell?.order?.orderId?.[0]?.sell_currency}</p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Status </p>
                                <p> {view_user_sell?.order?.status}</p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Time </p>
                                <p> {secondsToHms(view_user_sell?.order?.createdAt)}</p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <h4> About Sell Token </h4>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Price </p>
                                <p> {view_user_sell?.order?.orderId?.[0]?.price} {view_user_sell?.order?.orderId?.[0]?.sell_currency}</p>
                            </div>
                            <div className='data-1 d-flex justify-content-between align-items-center'>
                                <p> Sold Quantity </p>
                                <p> {view_user_sell?.order?.orderId?.[0]?.sold_quantity}</p>
                            </div>
                            
                            

                            <div className='btn_area'>
                                {showBtn()}
                            </div>
                            <div className='btn_area'>
                            <Link  className='btn approved-btn' to={'/vendor/Chat/' + view_user_sell?.order?._id + '/' + view_user_sell?.order?.buyer_id?.[0]} > Chat </Link>

                            </div>
                            
                        </div>
                        <Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                            <div className='container'>
                                <div className='row deposit-modal'>
                                    <div className='col-md-12 '>
                                        <h3>Reject Order</h3>
                                        <div className='card border-0 card__box_modal'>
                                        <div className='p2p-pay-system bg-transparent'>
                                            {/* <p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p> */}
                                        </div>
                                        <div class="input-group md-form form-sm form-1 pl-0 mt-5 d-block  ">
                                            <label className=''>Remark:</label>

                                            <div class="input-group-prepend "> 
                                                <textarea id="w3review" className='w-100' name="w3review" rows="4" cols="50" onChange={(e) => setremark(e.target.value)}></textarea>
                                            </div>
                                            <div class="inpmodal-button text-center mt-4"> 
                                                <button  className='btn btn-danger' onClick={() => reject()} >Reject Order</button>
                                            </div>
                                        </div>
                                        </div>
                                    
                                
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>                                        
                
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default SellOrders