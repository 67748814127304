export const CountryData1 =[


    {
        "code":"CA",
        "en":"Canada",
        "cn":"加拿大",
        "mobileCode":"1",
        "ico":"https://assets.staticimg.com/ucenter/flag/canada.svg",
        "weight":10
        },
        {
        "code":"US",
        "en":"United States",
        "cn":"美国",
        "mobileCode":"1",
        "ico":"https://assets.staticimg.com/ucenter/flag/united-states.svg",
        "weight":10
        },
        {
        "code":"IN",
        "en":"India",
        "cn":"印度",
        "mobileCode":"91",
        "ico":"https://assets.staticimg.com/ucenter/flag/india.svg",
        "weight":10
        },
        {
            "code":"GH",
            "en":"Ghana",
            "cn":"英国",
            "mobileCode":"233",
            "ico":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQGedvgGLaACFZcOTzWvnLFtkJpnUmVpEJ9g&usqp=CAU",
            "weight":10
        },
        {
        "code":"ID",
        "en":"Indonesia",
        "cn":"印尼",
        "mobileCode":"62",
        "ico":"https://assets.staticimg.com/ucenter/flag/indonesia.svg",
        "weight":10
        },
        {
        "code":"TR",
        "en":"Turkey",
        "cn":"土耳其",
        "mobileCode":"90",
        "ico":"https://assets.staticimg.com/ucenter/flag/turkey.svg",
        "weight":10
        },
        {
        "code":"GB",
        "en":"United Kingdom",
        "cn":"英国",
        "mobileCode":"44",
        "ico":"https://assets.staticimg.com/ucenter/flag/united-kingdom.svg",
        "weight":10
        },
        {
        "code":"PH",
        "en":"The Philippines",
        "cn":"菲律宾",
        "mobileCode":"63",
        "ico":"https://assets.staticimg.com/ucenter/flag/philippines.svg",
        "weight":10
        },
        {
        "code":"TH",
        "en":"Thailand",
        "cn":"泰国",
        "mobileCode":"66",
        "ico":"https://assets.staticimg.com/ucenter/flag/thailand.svg",
        "weight":10
        },
        {
        "code":"VN",
        "en":"Vietnam",
        "cn":"越南",
        "mobileCode":"84",
        "ico":"https://assets.staticimg.com/ucenter/flag/nvietnam.svg",
        "weight":10
        },
        {
        "code":"BR",
        "en":"Brazil",
        "cn":"巴西",
        "mobileCode":"55",
        "ico":"https://assets.staticimg.com/ucenter/flag/brazilc.svg",
        "weight":10
        },
        {
        "code":"RU",
        "en":"Russia",
        "cn":"俄罗斯",
        "mobileCode":"7",
        "ico":"https://assets.staticimg.com/ucenter/flag/russia.svg",
        "weight":10
        },
        {
        "code":"FR",
        "en":"France",
        "cn":"法国",
        "mobileCode":"33",
        "ico":"https://assets.staticimg.com/ucenter/flag/france.svg",
        "weight":10
        },
        {
        "code":"UA",
        "en":"Ukraine",
        "cn":"乌克兰",
        "mobileCode":"380",
        "ico":"https://assets.staticimg.com/ucenter/flag/ukrainef.svg",
        "weight":10
        },
        {
        "code":"ES",
        "en":"Spain",
        "cn":"西班牙",
        "mobileCode":"34",
        "ico":"https://assets.staticimg.com/ucenter/flag/spain.svg",
        "weight":10
        },
        {
        "code":"PL",
        "en":"Poland",
        "cn":"波兰",
        "mobileCode":"48",
        "ico":"https://assets.staticimg.com/ucenter/flag/poland.svg",
        "weight":10
        },
        {
        "code":"NL",
        "en":"Netherlands",
        "cn":"荷兰",
        "mobileCode":"31",
        "ico":"https://assets.staticimg.com/ucenter/flag/netherlands.svg",
        "weight":10
        },
        {
        "code":"JP",
        "en":"Japan",
        "cn":"日本",
        "mobileCode":"81",
        "ico":"https://assets.staticimg.com/ucenter/flag/japan.svg",
        "weight":10
        },
        {
        "code":"NG",
        "en":"Nigeria",
        "cn":"尼日利亚",
        "mobileCode":"234",
        "ico":"https://assets.staticimg.com/ucenter/flag/nigeria.svg",
        "weight":10
        },
        {
        "code":"DE",
        "en":"Germany",
        "cn":"德国",
        "mobileCode":"49",
        "ico":"https://assets.staticimg.com/ucenter/flag/germany.svg",
        "weight":10
        },
        {
        "code":"RO",
        "en":"Romania",
        "cn":"罗马尼亚",
        "mobileCode":"40",
        "ico":"https://assets.staticimg.com/ucenter/flag/Romania.svg",
        "weight":10
        },
        {
        "code":"AU",
        "en":"Australia",
        "cn":"澳大利亚",
        "mobileCode":"61",
        "ico":"https://assets.staticimg.com/ucenter/flag/australia.svg",
        "weight":10
        },
        {
        "code":"KR",
        "en":"Korea",
        "cn":"韩国",
        "mobileCode":"82",
        "ico":"https://assets.staticimg.com/ucenter/flag/korea.svg",
        "weight":10
        },
        {
        "code":"IT",
        "en":"Italy",
        "cn":"意大利",
        "mobileCode":"39",
        "ico":"https://assets.staticimg.com/ucenter/flag/Italy.svg",
        "weight":10
        },
        {
        "code":"FI",
        "en":"Finland",
        "cn":"芬兰",
        "mobileCode":"358",
        "ico":"https://assets.staticimg.com/ucenter/flag/Finland.svg",
        "weight":10
        },
        {
        "code":"IE",
        "en":"Ireland",
        "cn":"爱尔兰",
        "mobileCode":"353",
        "ico":"https://assets.staticimg.com/ucenter/flag/Ireland.svg",
        "weight":10
        },
        {
        "code":"NO",
        "en":"Norway",
        "cn":"挪威",
        "mobileCode":"47",
        "ico":"https://assets.staticimg.com/ucenter/flag/Norway.svg",
        "weight":10
        },
        {
        "code":"SE",
        "en":"Sweden",
        "cn":"瑞典",
        "mobileCode":"46",
        "ico":"https://assets.staticimg.com/ucenter/flag/Sweden.svg",
        "weight":10
        },
        {
        "code":"BE",
        "en":"Belgium",
        "cn":"比利时",
        "mobileCode":"32",
        "ico":"https://assets.staticimg.com/ucenter/flag/Belgium.svg",
        "weight":10
        },
        {
        "code":"CH",
        "en":"Switzerland",
        "cn":"瑞士",
        "mobileCode":"41",
        "ico":"https://assets.staticimg.com/ucenter/flag/Switzerland.svg",
        "weight":10
        },
        {
        "code":"HU",
        "en":"Hungary",
        "cn":"匈牙利",
        "mobileCode":"36",
        "ico":"https://assets.staticimg.com/ucenter/flag/Hungary.svg",
        "weight":10
        },
        {
        "code":"CZ",
        "en":"Czechia",
        "cn":"捷克",
        "mobileCode":"420",
        "ico":"https://assets.staticimg.com/ucenter/flag/Czech_Republic.svg",
        "weight":10
        },
        {
        "code":"HK",
        "en":"Hong Kong China",
        "cn":"中国香港",
        "mobileCode":"852",
        "ico":"https://assets.staticimg.com/ucenter/flag/peoples-republic-of-china.svg",
        "weight":10
        },
        {
        "code":"MO",
        "en":"Macao China",
        "cn":"中国澳门",
        "mobileCode":"853",
        "ico":"https://assets.staticimg.com/ucenter/flag/peoples-republic-of-china.svg",
        "weight":10
        },
        {
        "code":"TW",
        "en":"Taiwan China",
        "cn":"中国台湾",
        "mobileCode":"886",
        "ico":"https://assets.staticimg.com/ucenter/flag/peoples-republic-of-china.svg",
        "weight":10
        },
        {
        "code":"MY",
        "en":"Malaysia",
        "cn":"马来西亚",
        "mobileCode":"60",
        "ico":"https://assets.staticimg.com/ucenter/flag/Malaysia.svg",
        "weight":10
        },
        {
        "code":"SG",
        "en":"Singapore",
        "cn":"新加坡",
        "mobileCode":"65",
        "ico":"https://assets.staticimg.com/ucenter/flag/Singapore.svg",
        "weight":10
        },
        {
        "code":"AE",
        "en":"United Arab Emirates",
        "cn":"阿联酋",
        "mobileCode":"971",
        "ico":"https://assets.staticimg.com/ucenter/flag/United_Arab_Emirates.svg",
        "weight":10
        },
        {
        "code":"IL",
        "en":"Israel",
        "cn":"以色列",
        "mobileCode":"972",
        "ico":"https://assets.staticimg.com/ucenter/flag/Israel.svg",
        "weight":10
        },
        {
        "code":"QA",
        "en":"Qatar",
        "cn":"卡塔尔",
        "mobileCode":"974",
        "ico":"https://assets.staticimg.com/ucenter/flag/Qatar.svg",
        "weight":10
        },
        {
        "code":"KZ",
        "en":"Kazakhstan",
        "cn":"哈萨克斯坦",
        "mobileCode":"7",
        "ico":"https://assets.staticimg.com/ucenter/flag/Kazakhstan.svg",
        "weight":10
        },
        {
        "code":"MX",
        "en":"Mexico",
        "cn":"墨西哥",
        "mobileCode":"52",
        "ico":"https://assets.staticimg.com/ucenter/flag/Mexico.svg",
        "weight":10
        },
        {
        "code":"AR",
        "en":"Argentina",
        "cn":"阿根廷",
        "mobileCode":"54",
        "ico":"https://assets.staticimg.com/ucenter/flag/Argentina.svg",
        "weight":10
        },
        {
        "code":"CL",
        "en":"Chile",
        "cn":"智利",
        "mobileCode":"56",
        "ico":"https://assets.staticimg.com/ucenter/flag/Chile.svg",
        "weight":10
        },
        {
        "code":"CO",
        "en":"Colombia",
        "cn":"哥伦比亚",
        "mobileCode":"57",
        "ico":"https://assets.staticimg.com/ucenter/flag/Colombia.svg",
        "weight":10
        },
        {
        "code":"PE",
        "en":"Peru",
        "cn":"秘鲁",
        "mobileCode":"51",
        "ico":"https://assets.staticimg.com/ucenter/flag/Peru.svg",
        "weight":10
        },
        {
        "code":"CR",
        "en":"Costa Rica",
        "cn":"哥斯达黎加",
        "mobileCode":"506",
        "ico":"https://assets.staticimg.com/ucenter/flag/Costa_Rica.svg",
        "weight":10
        },
        {
        "code":"DO",
        "en":"Dominican Republic",
        "cn":"多米尼加",
        "mobileCode":"1",
        "ico":"https://assets.staticimg.com/ucenter/flag/Dominican_Republic.svg",
        "weight":10
        },
        {
        "code":"UY",
        "en":"Uruguay",
        "cn":"乌拉圭",
        "mobileCode":"598",
        "ico":"https://assets.staticimg.com/ucenter/flag/Uruguay.svg",
        "weight":10
        },
        {
        "code":"NZ",
        "en":"New Zealand",
        "cn":"新西兰",
        "mobileCode":"64",
        "ico":"https://assets.staticimg.com/ucenter/flag/New_Zealand.svg",
        "weight":10
        },
        {
        "code":"ZA",
        "en":"South Africa",
        "cn":"南非",
        "mobileCode":"27",
        "ico":"https://assets.staticimg.com/ucenter/flag/South_Africa.svg",
        "weight":10
        },
        {
        "code":"PT",
        "en":"Portugal",
        "cn":"葡萄牙",
        "mobileCode":"351",
        "ico":"https://assets.staticimg.com/ucenter/flag/Portugal.svg",
        "weight":10
        },
        {
        "code":"SI",
        "en":"Slovenia",
        "cn":"斯洛文尼亚",
        "mobileCode":"386",
        "ico":"https://assets.staticimg.com/ucenter/flag/Slovenia.svg",
        "weight":10
        },
        {
        "code":"BG",
        "en":"Bangladesh",
        "cn":"孟加拉国",
        "mobileCode":"880",
        "ico":"https://assets.staticimg.com/ucenter/flag/Bangladesh.svg",
        "weight":10
        },
        {
        "code":"LKA",
        "en":"Sri Lanka",
        "cn":"斯里兰卡",
        "mobileCode":"94",
        "ico":"https://assets.staticimg.com/ucenter/flag/SriLanka.svg",
        "weight":10
        },
        {
        "code":"VEN",
        "en":"Venezuela",
        "cn":"委内瑞拉",
        "mobileCode":"58",
        "ico":"https://assets.staticimg.com/ucenter/flag/Venezuela.svg",
        "weight":10
        },
        {
        "code":"AZ",
        "en":"Azerbaijan",
        "cn":"阿塞拜疆",
        "mobileCode":"994",
        "ico":"https://assets.staticimg.com/ucenter/flag/Azerbaijan.svg",
        "weight":10
        },
        {
        "code":"AM",
        "en":"Armenia",
        "cn":"亚美尼亚",
        "mobileCode":"374",
        "ico":"https://assets.staticimg.com/ucenter/flag/Armenia.svg",
        "weight":10
        },
        {
        "code":"BY",
        "en":"Belarus",
        "cn":"白俄罗斯",
        "mobileCode":"375",
        "ico":"https://assets.staticimg.com/ucenter/flag/Belarus.svg",
        "weight":10
        },
        {
        "code":"GE",
        "en":"Georgia",
        "cn":"格鲁吉亚",
        "mobileCode":"995",
        "ico":"https://assets.staticimg.com/ucenter/flag/Georgia.svg",
        "weight":10
        },
        {
        "code":"KG",
        "en":"Kyrgyzstan",
        "cn":"吉尔吉斯共和国",
        "mobileCode":"996",
        "ico":"https://assets.staticimg.com/ucenter/flag/Kyrgyzstan.svg",
        "weight":10
        },
        {
        "code":"MD",
        "en":"Moldova",
        "cn":"摩尔多瓦",
        "mobileCode":"373",
        "ico":"https://assets.staticimg.com/ucenter/flag/Moldova.svg",
        "weight":10
        },
        {
        "code":"UZ",
        "en":"Uzbekistan",
        "cn":"乌兹别克斯坦",
        "mobileCode":"998",
        "ico":"https://assets.staticimg.com/ucenter/flag/Uzbekistan.svg",
        "weight":10
        },
        {
        "code":"TJ",
        "en":"Tajikistan",
        "cn":"塔吉克斯坦",
        "mobileCode":"992",
        "ico":"https://assets.staticimg.com/ucenter/flag/Tajikistan.svg",
        "weight":10
        },
        {
        "code":"TM",
        "en":"Turkmenistan",
        "cn":"土库曼斯坦",
        "mobileCode":"993",
        "ico":"https://assets.staticimg.com/ucenter/flag/Turkmenistan.svg",
        "weight":10
        },
        {
        "code":"EST",
        "en":"Estonia",
        "cn":"爱沙尼亚",
        "mobileCode":"372",
        "ico":"https://assets.staticimg.com/cms/media/3scu94Z2Rdz0QkYbnzXYa1if0jimgx1ehNINYJ1Je.svg",
        "weight":10
        },
        {
        "code":"SVK",
        "en":"Slovakia",
        "cn":"斯洛伐克",
        "mobileCode":"421",
        "ico":"https://assets.staticimg.com/cms/media/1IeL7zNIg5dqjbO1DCZ2bMzDmZDU5WyJ03WtDnwSC.svg",
        "weight":10
        },
        {
        "code":"BH",
        "en":"Bahrain",
        "cn":"巴林",
        "mobileCode":"973",
        "ico":"https://assets.staticimg.com/ucenter/flag/Bahrain.svg",
        "weight":10
        },
        {
        "code":"EG",
        "en":"Egypt",
        "cn":"埃及",
        "mobileCode":"20",
        "ico":"https://assets.staticimg.com/ucenter/flag/Egypt.svg",
        "weight":10
        },
        {
        "code":"JO",
        "en":"Jordan",
        "cn":"约旦",
        "mobileCode":"962",
        "ico":"https://assets.staticimg.com/ucenter/flag/Jordan.svg",
        "weight":10
        },
        {
        "code":"KW",
        "en":"Kuwait",
        "cn":"科威特",
        "mobileCode":"965",
        "ico":"https://assets.staticimg.com/ucenter/flag/Kuwait.svg",
        "weight":10
        },
        {
        "code":"PS",
        "en":"Palestinian Territories",
        "cn":"巴勒斯坦",
        "mobileCode":"970",
        "ico":"https://assets.staticimg.com/ucenter/flag/Palestinian_Territories.svg",
        "weight":10
        },
        {
        "code":"OM",
        "en":"Oman",
        "cn":"阿曼",
        "mobileCode":"968",
        "ico":"https://assets.staticimg.com/ucenter/flag/Oman.svg",
        "weight":10
        },
        {
        "code":"SA",
        "en":"Saudi Arabia",
        "cn":"沙特阿拉伯",
        "mobileCode":"966",
        "ico":"https://assets.staticimg.com/ucenter/flag/Saudi_Arabia.svg",
        "weight":10
        },
        {
        "code":"DZ",
        "en":"Algeria",
        "cn":"阿尔及利亚",
        "mobileCode":"213",
        "ico":"https://assets.staticimg.com/ucenter/flag/Algeria.svg",
        "weight":10
        },
        {
        "code":"TN",
        "en":"Tunisia",
        "cn":"突尼斯",
        "mobileCode":"216",
        "ico":"https://assets.staticimg.com/ucenter/flag/Tunisia.svg",
        "weight":10
        },
        {
        "code":"BS",
        "en":"Bahamas",
        "cn":"巴哈马",
        "mobileCode":"1242",
        "ico":"https://assets.staticimg.com/ucenter/flag/Bahamas.svg",
        "weight":10
        },
        {
        "code":"ER",
        "en":"Eritrea",
        "cn":"厄立特里亚",
        "mobileCode":"291",
        "ico":"https://assets.staticimg.com/ucenter/flag/Eritrea.svg",
        "weight":10
        },
        {
        "code":"LY",
        "en":"Libya",
        "cn":"利比亚",
        "mobileCode":"218",
        "ico":"https://assets.staticimg.com/ucenter/flag/Libya.svg",
        "weight":10
        },
        {
        "code":"CF",
        "en":"Central African Republic",
        "cn":"中非",
        "mobileCode":"236",
        "ico":"https://assets.staticimg.com/ucenter/flag/Central_African_Republic.svg",
        "weight":10
        },
        {
        "code":"BA",
        "en":"Bosnia and Herzegovina",
        "cn":"波黑",
        "mobileCode":"387",
        "ico":"https://assets.staticimg.com/ucenter/flag/Bosnia_and_Herzegovina.svg",
        "weight":10
        },
        {
        "code":"MN",
        "en":"Mongolia",
        "cn":"蒙古",
        "mobileCode":"976",
        "ico":"https://assets.staticimg.com/ucenter/flag/Mongolia.svg",
        "weight":10
        },
        {
        "code":"IS",
        "en":"Iceland",
        "cn":"冰岛",
        "mobileCode":"354",
        "ico":"https://assets.staticimg.com/ucenter/flag/Iceland.svg",
        "weight":10
        },
        {
        "code":"LB",
        "en":"Lebanon",
        "cn":"黎巴嫩",
        "mobileCode":"961",
        "ico":"https://assets.staticimg.com/ucenter/flag/Lebanon.svg",
        "weight":10
        },
        {
        "code":"KE",
        "en":"Kenyan",
        "cn":"肯尼亚",
        "mobileCode":"254",
        "ico":"https://assets.staticimg.com/ucenter/flag/kenyan.svg",
        "weight":10
        },
        {
        "code":"HR",
        "en":"Croatia",
        "cn":"克罗地亚",
        "mobileCode":"385",
        "ico":"https://assets.staticimg.com/ucenter/flag/Croatia.svg",
        "weight":10
        },
        {
        "code":"LA",
        "en":"Laos",
        "cn":"老挝",
        "mobileCode":"856",
        "ico":"https://assets.staticimg.com/ucenter/flag/Laos.svg",
        "weight":10
        },
        {
        "code":"KH",
        "en":"Cambodia",
        "cn":"柬埔寨",
        "mobileCode":"855",
        "ico":"https://assets.staticimg.com/ucenter/flag/Cambodia.svg",
        "weight":10
        },
        {
        "code":"BN",
        "en":"Brunei",
        "cn":"文莱",
        "mobileCode":"673",
        "ico":"https://assets.staticimg.com/ucenter/flag/Brunei.svg",
        "weight":10
        },
        {
        "code":"TL",
        "en":"East Timor",
        "cn":"东帝汶",
        "mobileCode":"670",
        "ico":"https://assets.staticimg.com/ucenter/flag/East_Timor.svg",
        "weight":10
        },
        {
        "code":"MV",
        "en":"Maldives",
        "cn":"马尔代夫",
        "mobileCode":"960",
        "ico":"https://assets.staticimg.com/ucenter/flag/Maldives.svg",
        "weight":10
        },
        {
        "code":"PK",
        "en":"Pakistan",
        "cn":"巴基斯坦",
        "mobileCode":"92",
        "ico":"https://assets.staticimg.com/ucenter/flag/Pakistan.svg",
        "weight":10
        },
        {
        "code":"NP",
        "en":"Nepal",
        "cn":"尼泊尔",
        "mobileCode":"977",
        "ico":"https://assets.staticimg.com/ucenter/flag/Nepal.svg",
        "weight":10
        },
        {
        "code":"BT",
        "en":"Bhutan",
        "cn":"不丹",
        "mobileCode":"975",
        "ico":"https://assets.staticimg.com/ucenter/flag/Bhutan.svg",
        "weight":10
        },
        {
        "code":"CY",
        "en":"Cyprus",
        "cn":"塞浦路斯",
        "mobileCode":"357",
        "ico":"https://assets.staticimg.com/ucenter/flag/Cyprus.svg",
        "weight":10
        },
        {
        "code":"CN",
        "en":"China(Mainland)",
        "cn":"中国(大陆地区)",
        "mobileCode":"86",
        "ico":"https://assets.staticimg.com/ucenter/flag/peoples-republic-of-china.svg",
        "weight":9
        }
        
]