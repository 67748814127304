import React from 'react'
import { Link } from 'react-router-dom'
import { cloudMiningpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import cloud_banner from '../../../../_assets/images/newImages/cloud-banner.png'; 
import mining5 from '../../../../_assets/images/newImages/mining5.png';
import mining6 from '../../../../_assets/images/newImages/mining6.png';
import mining7 from '../../../../_assets/images/newImages/mining7.png';
import icon1 from '../../../../_assets/images/newImages/icon1.png';
import icon2 from '../../../../_assets/images/newImages/icon2.png';
import icon3 from '../../../../_assets/images/newImages/icon3.png';
const cloudMining = () => {
    const {langchange} = LangState();
    
    return (
        <div>
            <div className="cloud-eran">
                <div className="container-cloud">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/cloudMining"> {cloudMiningpage['0']['nav']['btn1'][langchange]} </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/orderMining"> {cloudMiningpage['0']['nav']['btn2'][langchange]}  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/elecMining">  {cloudMiningpage['0']['nav']['btn3'][langchange]} </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="cloud-banner">
                <img className='cloud-banner_img' src={cloud_banner} />
            </div>


            <div className="alert-sms">
                <div className="alert-container">
                    <a className="link" href="#"> <i class='fas fa-volume-up pr-2'></i> <marquee direction = "left"> {cloudMiningpage['0']['info'][langchange]} </marquee> </a>
                </div>
            </div>
                    
            <div className="cloud-form">
                <div className="check-box">
                    <div className="check-area mb-3">
                        <div className="form-check-inline">
                            <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" value=""/> Products on sale Only
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" value=""/> Purchased Products Only
                        </label>
                    </div>
                    <span> Filter
                        <ul className="drop-filter">
                            <a className="dropdown-item" href="#"> All  </a>
                            <a className="dropdown-item" href="#"> Upcoming </a>
                            <a className="dropdown-item" href="#"> On Sale </a>
                            <a className="dropdown-item" href="#"> Sold Out </a>
                            </ul>
                        </span>
                    </div>

                    <div className="time-zone">
                        <div className="left-time">
                            <input placeholder="Start date" for="date" value="" /> 
                            <div className="drop-date">
                                <input type="datetime-local"  id="date" /> 
                            </div>
                        </div> 
                        <i class='fas fa-long-arrow-alt-right'></i>
                        <div className="left-time">
                            <input placeholder="End date" value="" />
                        </div>
                    </div>

                </div>
            </div>

            <div className="card-box">
                <div className="row m-0 p-0">
                    <p className="card-title"> <i className='card-box-title-span'>Cloud Mining </i>  <span> Steady Earnings </span> <span> Mining </span> <span> Time Limited  </span>  
                    </p>
                    <div className="col-12 p-0">
                        <div className="card">
                            <span className="title"> Sold Out </span>
                            <div className="card-header">
                                <div className="left-header">
                                    <h4> Cloud Mining </h4>   <span className="bg-light">S19j Pro-180 Days</span>
                                    <h6 className='text-nowrap '> <span className='mb-3 d-inline-block'> Antminer S19J  </span> <span className='mb-3 d-inline-block'> Pro180 Days5T </span> <span className='mb-3 d-inline-block'>  Threshold     </span> </h6>
                                </div>
                                <div className="right-header">
                                    <h1> 22.94<span> USDT/T </span>
                                    </h1>
                                </div>
                            </div>

                            <div className="line"> <span>0%</span> </div>

                            <div className="card-body">
                                <div className="data-1">
                                    <h5> Tenor </h5>
                                    <p> 180 D </p>
                                </div>
                                <div className="data-1">
                                    <h5> APR</h5>
                                    <p> 212.53% </p>
                                </div>
                                <div className="data-1">
                                    <h5> Electricity Fees </h5>
                                    <p> 0.06USDT/T/D </p>
                                </div>
                                <div className="data-1">
                                    <h5> Start Mining </h5>
                                    <p> 2022-03-09 05:30:00 </p>
                                </div>
                                <div className="">
                                    <button type="button" className="btn"> Buy </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 p-0">
                        <div className="card">
                            <span className="title"> Sold Out </span>
                            <div className="card-header">
                                <div className="left-header">
                                    <h4> Cloud Mining </h4>   <span className="bg-light">S19j Pro-365 Days</span>
                                    <h6 className='text-nowrap '> <span className='mb-3 d-inline-block'> Antminer S19J  </span> <span className='mb-3 d-inline-block'> Pro180 Days5T </span> <span className='mb-3 d-inline-block'>  Threshold     </span> </h6>
                                </div>
                                <div className="right-header">
                                    <h1> 46.52<span> USDT/T </span>
                                    </h1>
                                </div>
                            </div>

                            <div className="line"> <span>0%</span> </div>

                            <div className="card-body">
                                <div className="data-1">
                                    <h5> Tenor </h5>
                                    <p> 365 D </p>
                                </div>
                                <div className="data-1">
                                    <h5> APR</h5>
                                    <p> 104. 80% </p>
                                </div>
                                <div className="data-1">
                                    <h5> Electricity Fees </h5>
                                    <p> 0.06 <span> USDT/T/D</span> </p>
                                </div>
                                <div className="data-1">
                                    <h5> Start Mining </h5>
                                    <p> 2022-03-09 05:30:00 </p>
                                </div>
                                <div className="">
                                    <button type="button" className="btn"> Buy </button>
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <a className="text-dark text-decoration-none" href="#"> You've reached the end. </a>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 p-0">
                        <div className="service-box">
                            <div className="service-header">
                                <h3> {cloudMiningpage['0']['sec']['h1'][langchange]} </h3>
                                <p> {cloudMiningpage['0']['sec']['p1'][langchange]} </p>
                            </div>

                            <div className="row m-0">
                                <div className="col-12 eraning-card p-0">
                                    <div className="row p-0">
                                        <div className="col-md-4 ">
                                            <div className='mining-icon'>
                                                <img src={mining5} alt="icon image" />
                                            </div>
                                            <div className="text-s">
                                                <p> {cloudMiningpage['0']['sec']['label1'][langchange]} </p>
                                                <p> {cloudMiningpage['0']['sec']['p1'][langchange]} </p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className='mining-icon'>
                                                <img src={mining6} alt="icon image" />
                                            </div>    
                                            <div className="text-s">
                                                <p> {cloudMiningpage['0']['sec']['label2'][langchange]} </p>
                                                <p> {cloudMiningpage['0']['sec']['p2'][langchange]} </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='mining-icon'>
                                                <img src={mining7} alt="icon image" />
                                            </div>
                                            <div className="text-s">
                                                <p> {cloudMiningpage['0']['sec']['label3'][langchange]} </p>
                                                <p> {cloudMiningpage['0']['sec']['p3'][langchange]} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 extra-card p-0">
                                    <div className="row p-0">
                                        <div className="col-md-4">
                                                <img src={icon3} alt="icon image"/>
                                            <div className="text-s">
                                                <p> {cloudMiningpage['0']['sec']['label4'][langchange]} </p>
                                                <p> {cloudMiningpage['0']['sec']['p4'][langchange]} </p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <img src={icon2} alt="icon image"/>
                                            <div className="text-s">
                                                <p> {cloudMiningpage['0']['sec']['label5'][langchange]}</p>
                                                <p> {cloudMiningpage['0']['sec']['p5'][langchange]} </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <img src={icon1} alt="icon image"/>
                                            <div className="text-s">
                                                <p> {cloudMiningpage['0']['sec']['label6'][langchange]} </p>
                                                <p> {cloudMiningpage['0']['sec']['p6'][langchange]} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="FAQ" style={{backgroundColor: 'transparent', margin: '100px 0'}}>
                <h1 className="text-center"> FAQ </h1>
                <div className="FAQ-container">
                    <div id="accordion">   
                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                    1. {cloudMiningpage['0']['faq']['h1'][langchange]}
                                </a>
                                <h6> <i class='fas fa-angle-down'></i>  </h6>
                            </div>
                            <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    {cloudMiningpage['0']['faq']['p1'][langchange]}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    2. {cloudMiningpage['0']['faq']['h2'][langchange]}
                                </a>
                            <h6> <i class='fas fa-angle-down'></i>  </h6>

                            </div>
                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    {cloudMiningpage['0']['faq']['p2'][langchange]}
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                                    3. {cloudMiningpage['0']['faq']['h3'][langchange]}
                                </a>
                                 <h6> <i class='fas fa-angle-down'></i>  </h6>

                            </div>
                            <div id="collapsefour" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    {cloudMiningpage['0']['faq']['p3'][langchange]}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                    4. {cloudMiningpage['0']['faq']['h4'][langchange]}
                                </a>
                                <h6> <i class='fas fa-angle-down'></i>  </h6>

                            </div>
                            <div id="collapsefive" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    {cloudMiningpage['0']['faq']['p4'][langchange]}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                                    5. {cloudMiningpage['0']['faq']['h5'][langchange]}
                                </a>
                                <h6> <i class='fas fa-angle-down'></i>  </h6>

                            </div>
                            <div id="collapsesix" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    {cloudMiningpage['0']['faq']['p5'][langchange]}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" href="#collapsesaven">
                                    6. {cloudMiningpage['0']['faq']['h6'][langchange]}
                                </a>
                                <h6> <i class='fas fa-angle-down'></i>  </h6>
                            </div>
                            <div id="collapsesaven" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    {cloudMiningpage['0']['faq']['p6'][langchange]}
                                </div>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
        </div>
    )
}

export default cloudMining