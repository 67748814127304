import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';
import posi1 from '../../../_assets/images/img/Position_1.svg';
import posi2 from '../../../_assets/images/img/Position_2.svg';
import posi3 from '../../../_assets/images/img/Position_3.svg';
import posi4 from '../../../_assets/images/img/Position_4.svg';
import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { PROJECT_NAME } from '../../../_constants';

const TradingBot = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' id="about" style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <a href='#' className='nav-link active'>  About US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Join US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  TRADING BOT</h2>
            <h6>{PROJECT_NAME} Grid Bots</h6>
            
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container' id='Join_container'>
        
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-4'>
                <h2>Smart Trades Made Easy</h2>
                
              </div>
              <div className='row mt-3 align-items-center'>
                <div className='col-md-6  about-page'>
                 <h3>What Are Grid Bots?</h3>
                 <p>Support Spot Trading Profit From Market Volatility Buy Low and Sell High</p>
                 <p>Grid bots are automated trading strategies. They're designed to place buy and sell orders at regular intervals within a predefined price range. Grid bots seek to capitalize on price fluctuations and perform best in volatile markets</p>

                 <h3>Create your first grid bot</h3>
                 <p>Trade 24/7 to make the best of market fluctuations<br/>
                    5028 active bots & up to 12,221% APR
                  </p>
                  <h3 className='my-1'>Create Now</h3>
                  <h3 className='my-1'>Leaderboard</h3>
                  <h3 className='my-1'>Ranking     7-day ranking</h3>
                </div>
                <div className='col-md-6'>
                <img className='img-about-1' src={about4} width='100%' alt='TradingBot/img' />
                </div>
              </div>
                  
              </div>
            </div>
            
          </div>
        </div> 
        <div className='about_box-1'>
          <div className='text-center m-5'><h2>Why Choose {PROJECT_NAME} Grid Bots?</h2></div>
          <div className='row m-0 '>
            <div className='col-md-6'>
              <img className='img-about-1' src={about2} width='100%'  alt='TradingBot/img' />
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h3 className='mb-0'>Hassle Free</h3>
                <p>Trade 24/7 to make the best of market fluctuations</p>
                <h3 className='mb-0'>Smart Trading Strategy</h3>
                <p>Buy low and sell high automatically to maximize profit</p>
                <h3 className='mb-0'>No Extra Fees</h3>
                <p>No management fees will be charged</p>
              </div>
            </div>
          </div>
        </div>  
          
        <div className='about_box-1'>
          <div className='row m-0  First_row vision '>
            <div className='col-md-12 mt-3 Paddind_____p-0'>
              <div className='about-story-text'>
                <div className='text-center mb-2'>
                  <h2>FAQ</h2>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-12 about-page '>
                    <ol start="1"  >
                      <li><h3 className='mb-0'>What are grid bots?</h3>
                        <p>Grid bots are automated trading strategies. They're designed to place buy and sell orders at regular intervals within a predefined price range. Grid bots seek to capitalize on price fluctuations and perform the best in volatile markets. Currently, {PROJECT_NAME} grid bots support Spot trading.
                        </p>
                      </li>
                      <li><h3 className='mb-0'>Are there any fees associated with grid bots?</h3>
                      <p>{PROJECT_NAME} grid bots incur no extra fees. Trading fees will be charged when your Spot orders are successfully filled. The fee structure is the same as the rate incurred when you trade on {PROJECT_NAME}'s Spot platform.</p>
                      </li>
                      <li><h3 className='mb-0'>Do I need to terminate the grid bot if the market price falls outside my predefined price range?</h3>
                      <p>The grid bot will only operate in the upper and lower price bounds you've configured. If the market price falls outside of this range, no new order will be placed by the grid bot until the price returns to the range. Currently, you're not allowed to modify the grid parameters when the grid bot is active. We recommend that you terminate the bot based on your trading preferences to minimize losses.</p>
                      </li>
                    
                    </ol>
                  </div>
                  
                </div>
                  
              </div>
            </div>
            <div className='col-md-12 border p-5'>
              <div className='about-story-text text-center'>
              <h3 className='mb-0'>Cash Fudx</h3>
                <p>That why {PROJECT_NAME} is Best Crypto Exchange in
                  India. So, start investing in digital currency. Shiba
                  INU is good in each and every way. Crypto Prices
                  are really getting higher in market. Enter in Web
                  3.0Crypto, to trade with ease.</p>
               </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default TradingBot