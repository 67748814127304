import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CryptoContext from './CryptoContext';
import * as serviceWorker from './serviceWorker';
import LangugeContext from './LangugeContext';
ReactDOM.render(
  // <React.StrictMode>
  <CryptoContext >
    <LangugeContext>
    <App />,
    </LangugeContext>,
    </CryptoContext>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
