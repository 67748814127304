import { Accordion } from 'semantic-ui-react';
import { Activation } from '../../Services/api/api.service';
import * as types from '../actions/_types';

const initialState = {
 
    news : [],
    
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_NEWS:
      return { ...state, news: action.payload }; 
       
    default:
      return state;
  }
}

