import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import madel from '../../../../_assets/images/madel.svg';
import visa from '../../../../_assets/images/visa2.png';
import { LangState } from '../../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../../lang/lang';
import { order_p2p } from '../../../../redux/actions/p2p.action';
import { useDispatch, useSelector } from 'react-redux';

const P2pOrder = ({history}) => {
    const [changeTab,setchangeTab] = useState('progress');
    const dispatch = useDispatch();
    const order_p2p_data = useSelector(state => state.p2p.order_p2p);
    const {langchange} = LangState();
    useEffect(() =>{
        dispatch(order_p2p());
    },[]);
    console.log('order_p2p_data',order_p2p_data);
    const tableData = () => {
        return order_p2p_data?.postings?.map((val) => {
            return(
                <tr>
                    <td className="name"> <p> {val?.user_id?.[0]?.firstname?.slice(0, val?.user_id?.[0]?.firstname?.length + 1 - val?.user_id?.[0]?.firstname?.length)} {val?.user_id?.[0]?.lastname?.slice(0, val?.user_id?.[0]?.lastname?.length + 1 - val?.user_id?.[0]?.lastname?.length)}  </p>  {val?.token_id?.[0]?.coinName}
                        {/* <img src={madel}/>  */}
                    </td>
                    <td className="text-center">
                        {val?.UserPaymentMethodID?.[0]?.PaymentMethodFields?.map((method) => {
                            return(<div>{method?.field?.replace('_',' ') }: {method.value} </div>)

                        })}
                        {/* <img src={visa}/>   */}
                    </td>
                    <td>  {val?.orderId?.[0]?.price} {val?.orderId?.[0]?.sell_currency}  </td>
                    <td>  {val?.quantity} </td>
                    <td>   {val?.orderId?.[0]?.price * val?.quantity} 	</td>
                    <td>   {val?.status} 	</td>
                    <td className="p-0 text-right" onClick={() => history.push('/vendor/order-chat/'+ val?._id)}> <button type="button" className="btn"> View </button>   </td>

                    {/* <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td> */}
                </tr>
            );
        })
    }
  return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-new-style">
            <div className="tab-container">
                <div className="left-sidebar" id="left-sidebar">
                    <ul className="nav flex-column"> 
                        <p> P2P </p>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange] } </Link>
                        </li>
                        <p> {p2ppage['0']['other'][langchange] } </p>
                        <li className="nav-item">
                            <a className="nav-link" href="#"> {p2ppage['0']['support'][langchange] } </a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange] }  </Link>
                        </li> 
                    </ul>
                </div>
                <div className="right-tab">
                    <div className="tab-header">
                        <ul className="nav" role="tab">
                            <li className="nav-item" id="more" > 
                            <a className={changeTab == 'progress' ? "nav-link active" : "nav-link" }    data-toggle="tab" onClick={() => setchangeTab('progress')} > In Progress </a>
                        </li>
                        <li className="nav-item"> 
                            <a className={changeTab == 'complete' ? "nav-link active" : "nav-link" } data-toggle="tab" onClick={() => setchangeTab('complete')} >   Completed </a>
                        </li>
                        <li className="nav-item"> 
                            <a className={changeTab == 'cancel' ? "nav-link active" : "nav-link" } data-toggle="tab"  onClick={() => setchangeTab('cancel')}>   Canceled </a>
                        </li>
                        
                        <li className="nav-item"> 
                            <a className={changeTab == 'all' ? "nav-link active" : "nav-link" } data-toggle="tab" onClick={() => setchangeTab('all')}>  All </a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    <div id="USDT" className="tab-pane active">
                        <div className="table-responsive-xl"> 
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th className="w-25"> {p2ppage['0']['table']['label1'][langchange] }    </th>
                                    <th>  {p2ppage['0']['table']['label2'][langchange] }	  </th>
                                    <th>  Price  </th>
                                    <th>Quantity</th>
                                    <th>  Total </th>
                                    <th>Status</th>
                                    <th></th>
                                    {/* <th className="text-right">  {p2ppage['0']['table']['label6'][langchange] } <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData()}
                                {/* <tr>
                                    <td className="name"> <p> MV </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa} />  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>

                                <tr>
                                    <td className="name"> <p> PM </p>  Best Rate USDT <img src={madel}/>  </td>
                                    <td className="text-center"> <img src={visa}/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>
                                <tr>
                                    <td className="name"> <p> SK </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa}/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>
                                <tr>
                                    <td className="name"> <p> SS </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa}/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>                                        
                <div id="BTC" className="tab-pane">
                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th className="w-25"> Posted by    </th>
                                    <th>  Payment Method	  </th>
                                    <th>  Available  </th>
                                    <th>Limit</th>
                                    <th>  Price  </th>
                                    <th className="text-right">  Trade <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="name"> <p> TC </p>  Best Rate USDT <img src="img/tableche.svg" />  </td>
                                    <td className="text-center"> <img src="img/pay.svg"/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> BUY </button>   </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
                <div id="ETH" className="tab-pane">
                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                <th className="w-25"> Posted by    </th>
                                <th>  Payment Method	  </th>
                                <th>  Available  </th>
                                <th>Limit</th>
                                <th>  Price  </th>
                                <th className="text-right">  Trade <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="name"> <p> TC </p>  Best Rate USDT <img src="img/tableche.svg" />  </td>
                                    <td className="text-center"> <img src="img/visa.svg"/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> BUY </button>   </td>
                                </tr>
                                <tr>
                                    <td className="name"> <p> KK </p>  Best Rate USDT <img src="img/tableche.svg" />  </td>
                                    <td className="text-center"> <img src="img/sepa.svg"/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> BUY </button>   </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
                <div id="KCS" className="tab-pane">
                    <div className="table-responsive">

                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th className="w-25"> Posted by    </th>
                                    <th>  Payment Method	  </th>
                                    <th>  Available  </th>
                                    <th>Limit</th>
                                    <th>  Price  </th>
                                    <th className="text-right">  Trade <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="name"> <p> TC </p>  Best Rate USDT <img src="img/tableche.svg" />  </td>
                                    <td className="text-center"> <img src="img/pay.svg"/>  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> BUY </button>   </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  

                        <div id="USDC" className="tab-pane">
                            <div className="table-responsive">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="w-25"> Posted by    </th>
                                            <th>  Payment Method	  </th>
                                            <th>  Available  </th>
                                            <th>Limit</th>
                                            <th>  Price  </th>
                                            <th className="text-right">  Trade <span className="badge" style={{background:'#EBC75B'}}>0 free </span> </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default P2pOrder