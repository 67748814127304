import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { assetsnav, depositpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import usdt from '../../../_assets/images/usdt.png'
import emptyImg from '../../../_assets/images/deposit/empty.svg'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { get_deposit_tokens, get_deposit_tokens_details,get_token_Address } from '../../../redux/actions/dashboard.action';
import { toast } from '../../../components/toast';
import qrImg from '../../../_assets/images/qrcode.b2b57e3a.svg';
import testQr from '../../../_assets/images/appDownload.png';
import DepositTable from './DepositTable';
import { PROJECT_NAME } from '../../../_constants';
const UseStyles = makeStyles({
  assetsBtn: {
      marginTop:'77px',
      display: 'flex',
      background: 'rgba(125, 135, 146, 0.07840000000000003)',
      alignItems: 'center'
  },
  btnDesign:{
      width: '1200px',
      margin:  '0 auto',
      display: 'flex',
      marginBottom: '-16px',
      justifyContent: 'space-between',
      overflowX: 'scroll',
      // width: '100%',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
          height: '0px',
        },

  },
  btnUl:{
      display: 'flex',
      marginBottom: '1em',
  },
  btnTag:{
      height: '64px!important',
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '.1px',
      borderRadius: '0!important',
      color: 'hsla(0,0%,100%,.6)',
      padding: '0 12px',
  },
  MuiButtonlabel:{
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
  },
  MuiTouchRippleroot:  {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: 'hidden',
      position: 'absolute',
      borderRadius: 'inherit',
      pointerEvents: 'none',
      },

  
});
const Deposit = () => {
	const classes = UseStyles();
	const {langchange} = LangState();
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
    const [inputdata , setinputdata] = useState('');

	const [coinName, setcoinName] = useState('Select Coin');
	const [coinImage, setcoinImage] = useState('');
	const [networkid, setnetworkid] = useState();
	const [tokenid, settokenid] = useState();

	const [detailcoinName, setdetailcoinName] = useState('Select Network');


	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const onOpenModal2 = () => setOpen2(true);
	const onCloseModal2 = () => setOpen2(false);
	const dispatch = useDispatch();
    const deposite = useSelector(state => state.token.deposit_token);
    const token_details = useSelector(state => state.token.token_details.tokenDetails);
	const token_address = useSelector(state => state.token.token_address);
	const [coinaddress, setcoinaddress] = useState(token_address?.address?.walletAddress);

// console.log('token_address',token_address?.address?.walletAddress)
	// console.log('token_details',token_details)
	useEffect(() => {
		dispatch(get_deposit_tokens());
		// handleSearch();
		// if(deposite.deposit_tokens != undefined){
		// 	setcoinName(deposite?.deposit_tokens[0].coinName);
		// 	setcoinImage(deposite?.deposit_tokens[0].image);
		// }
	}, [])

	const [search, setsearch] = useState();
	const handleSearch = () => {	
		if(search != undefined){
			return deposite.deposit_tokens?.filter(
				(iii) => 
				iii.coinName.toLowerCase().includes(search)
			)
		}else{
			return(deposite.deposit_tokens);
		}
    }

	const selectCoin = (data) => {
		setcoinName(data.coinName);
		setcoinImage(data.image);
		setOpen(false);
		setdetailcoinName('Select Network');
		settokenid(data._id)
		const id = {
			tokenID:data._id,
		}
		dispatch(get_deposit_tokens_details(id))
	}
	const selectNetwork = (id,name) =>{
		setdetailcoinName(name);
		setnetworkid(id);

		const data1 = {
			networkID: id,
		}
		
		dispatch(get_token_Address(data1))
		setOpen2(false);
	}
	const copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText = token_address?.address?.walletAddress
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast.info('copied : ' + token_address?.address?.walletAddress);

    }

	const passProps = [{conAddress:token_address?.address?.walletAddress},{networkid:networkid},{tokenid:tokenid}];
	return (
	<div>
		<div className={classes.assetsBtn}>
			<div className={classes.btnDesign}>
				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
				</ul>

				<ul className={classes.btnUl}>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/swap-currency' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['swap'][langchange]}</Link>
							<Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/depositassest' style={{color:'rgb(40 205 149)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/withdrawAssets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					<button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button>
					{/* <button className={classes.btnTag}>
						<span className={classes.MuiButtonlabel}>
							<li>
							<Link to='/vendor/bonus' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
							</li>
						</span>
						<span className={classes.MuiTouchRippleroot}></span>
					</button> */}
				</ul>
			</div>
			
		</div>
		<div className='container'>
			<div className='row'>
				<div  className='col-md-7'>
					<div className='row'>
						<div className='col-md-12 mt-5'>
							<h1>Deposit Crypto</h1>
						</div>
					</div>
					<div className='mt-5 margin_zero_add' style={{marginLeft:'9%'}}>
						<div className='new_depost'>
							<div className='select-field'>
								<span className='lable'>Coin</span>
								<div className='row'>
									<div className='col-md-3'>
										<p className='select-coin'>Select a Coin</p>
									</div>
									<div className='col-md-9'>
										<div className='select-box-div d-flex justify-content-between' onClick={onOpenModal}> 
											<p className='d-flex m-0'><span className='img'><img src={coinImage} width='30' /></span><h5>{coinName}</h5><span className='spam-label'> </span></p><p className='p-1'><i class="fas fa-chevron-down"></i></p>
										</div>
										<Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
											<div className='container'>
											<div className='row deposit-modal m-0'>
												<div className='col-md-12'>
												<h2>Coin</h2>
												<div class="input-group md-form form-sm form-1 pl-0">
													<div class="input-group-prepend">
													<span class="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i class="fas fa-search text-white"
														aria-hidden="true"></i></span>
													</div>
													<input class="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e) => {setsearch(e.target.value)}} />
												</div>
												{/* <div className='deposit-his mt-3'>
													<p>History</p>
													<div className='deposit-his-btn'>
													<button className='btn'>TRX</button>
													<button className='btn'>USDT</button>
													<button className='btn'>BTC</button>

													</div>
												</div>
												<div className='deposit-his mt-3'>
													<p>Top</p>
													<div className='deposit-his-btn'>
													<button className='btn'>BNB</button>
													<button className='btn'>TRX</button>
													<button className='btn'>USDT</button>
													<button className='btn'>BTC</button>

													</div>
												</div>  */}
												<div className='deposit-coin-list'>
													<ul>  
													{handleSearch()?.map((i,ii) => {
														return  (
															<>
																<li onClick={() => {selectCoin(i)}}><div className='img'><img src={i.image} alt={i.coinName}  width='40'/></div><div className='text'><p className='symbole'>{i.coinName}</p><span className='name'>{i.coinName}</span></div></li>
															</>
														);
													})}

													</ul>
												</div> 
												</div>
											</div>
											</div>
										</Modal>
									</div>
								</div>
							</div>
							<div className='select-field mt-3'>
								<span className='lable'>Network</span>
								<div className='row'>
									<div className='col-md-3'>
										<p className='select-coin'>Select Network</p>
									</div>
									<div className='col-md-9'>
										<div  className='select-box-div d-flex justify-content-between' onClick={onOpenModal2} > 
												<h5 className='h4  my-2'>{detailcoinName}</h5> <p className='p-1'><i class="fas fa-chevron-down"></i></p>
										</div>
										<Modal open={open2} onClose={onCloseModal2}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
											<div className='container'>
											<div className='row deposit-modal m-0'>
												<div className='col-md-12'>
												<h2>Select Network</h2>
												<div className='info'><p>Ensure that the selected deposit network is the same as the withdrawal network. Otherwise, your assets may be lost.</p></div>
												<div className='deposit-coin-list' >
													<ul> 

														{token_details?.[0]?.networkDetails?.map((iiii,iiiii) =>{
															return(
																<li  onClick={() => {selectNetwork(iiii?._id,iiii?.Network)}}><div className='text'><p className='symbole'>{iiii?.Network}</p><span className='name'>{token_details?.[0]?.networkDetails?.[iiiii]?.Network}</span></div></li>

															);	
														})} 
													
													</ul>
												</div> 
												</div>
											</div>
											</div>
										</Modal>
									</div>
								</div>
							</div>





							<div className="option_data row  m-0">
								<div className="col-12">
									<div className=" card bg-transparent border-0 padding_zero_add" style={{paddingLeft:'22%'}}>
										
										<div className="card-body">
										<p className='mb-0'> Address : </p>
											<div className="d-flex justify-content-between ">
												<h4 style={{width: "65%"}}>  {token_address?.address?.walletAddress}</h4>
												<div className="right-c d-flex justify-content-between">
													<div className='mr-2 open-qr-img' ><img src={qrImg} />
														<div className="deposite-qr">
															{/* <p> <i class='fas fa-qrcode'></i> </p> */}
															<div className="qr_show shadow-lg">
																<div  >
																	{/* <img src={testQr} /> */}
																	<img src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + token_address?.address?.walletAddress } className='' alt="website qrcode"/>
																	<div className='text-left' style={{textAlign:'left', margin:'15px'}}>
																		<ul >
																			<li >This address can only receive {coinName}({detailcoinName})</li>
																			<li>Deposit to: Main Account</li>
																			<li>Confirm that your network is ({PROJECT_NAME} Community Chain).</li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<p className='mt-1' onClick={copyToClipboard}> <i class='fas fa-copy'></i> </p>
													
												</div>
											</div>

											<h5 id="myInput"> {inputdata} </h5>

										</div>
										<div className="col-12 tip">
											<div className='row'>
												<div className='col-md-6'>
													<p>Recipient Account </p>
													<p>Main Account <span className='text-success'>Edit</span></p>
												</div>
												<div className='col-md-6'>
													<p>Deposit Confirmation</p>
													<p>20 block(s)</p>
												</div>
												<div>
													<p className='pl-3 mt-3'>Confirm that your network is <span className='text-success'>({PROJECT_NAME} Community Chain). </span> </p>	
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
				</div>
				<div  className='col-md-5'>
					<div className='row'>
						<div className='col-md-12 mt-5'>
							<Link to={'/vendor/depositfiat'} className='faq-Deposit-btn'>Deposit Fiat <span><i class="fas fa-arrow-right"></i></span></Link>
						</div>
						<div className='depost-faq mt-4'>
							<h2>FAQ</h2>
							<div className='faq-con'>
								<ul>
									<li className='d-flex'><i class="fas fa-arrow-right"></i><p className='ml-2'><Link> How do I deposit crypto onto my {PROJECT_NAME} account?</Link></p></li>
									<li className='d-flex'><i class="fas fa-arrow-right"></i><p className='ml-2'><Link>What should I do if I didn't receive my deposits or I deposit to an incorrect address? </Link></p></li>
									<li className='d-flex'><i class="fas fa-arrow-right"></i><p className='ml-2'><Link>What should I do if I deposit the wrong crypto?</Link></p></li>
									<li className='d-flex'><i class="fas fa-arrow-right"></i><p className='ml-2'><Link>What should I do if I forgot to specify the Memo, Tag, or Message for my deposit?</Link></p></li>
									<li className='d-flex'><i class="fas fa-arrow-right"></i><p className='ml-2'><Link>What should I do if I mistakenly deposit through the BSC or BEP20 network and did not receive the deposit?</Link></p></li>
									<li className='d-flex'><i class="fas fa-arrow-right"></i><p className='ml-2'><Link>What are the common deposit networks?</Link></p></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DepositTable props={passProps} />
			{/* <div className='row'>
				<div className='col-md-12'>
					<h2>Deposit History</h2>
					<table className="table table-borderless">
						<thead className='thead-light'>
							<tr>
								<th className="w-25"> Amount   </th>
								<th>Network</th>
								<th>Blockchain Record </th>
								<th>Status</th>
								<th> Remarks </th>
								<th className="text-right">  Time </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="6" className='text-center'>
									<img src={emptyImg} className='mt-5'  /><br/>
									<span>No data</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div> */}
		</div>
	</div>
	)
}

export default Deposit