import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import assetsSide from '../../../_assets/images/assetsSide.svg';
import transferdata from '../../../_assets/images/transferdata.svg';

import { LangState } from '../../../LangugeContext';
import { assetsnav, asstespage, mainAccountpage, marginAccountpage } from '../../../lang/lang';
import Modal from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_assets, get_wallet_details, transfer_user_assets } from '../../../redux/actions/assets.action';
const UseStyles = makeStyles({
    assetsBtn: {
        marginTop: '77px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
        },

    },
    btnUl: {
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag: {
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
    },

});

const TradeAccount = (history) => {
    const dispatch = useDispatch()
    const classes = UseStyles();
    const wallet_details =  useSelector(state => state.assets.wallet_details);
    const { langchange } = LangState();
    const [open, setOpen] = useState(false);
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const [dropdownVisible1, setdropdownVisible1] = useState(false);
    const [dropdownVisible2, setdropdownVisible2] = useState(false);
    const [first_wallet, setfirst_wallet] = useState('main_wallet');
    const [first_wallet_name, setfirst_wallet_name] = useState('Main Account');

    const [second_wallet, setsecond_wallet] = useState('trading_wallet');
    const [second_wallet_name, setsecond_wallet_name] = useState('Trading Account');
    const [trnsferId, settrnsferId] = useState();
    const [trnsferIdname, settrnsferIdname] = useState();
    const [trensferAmount, settrensferAmount] = useState();

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [methodName, setmethodName] = useState();
    const [methodId, setmethodId] = useState();
    const [search, setsearch] = useState();

    useEffect(() => {
        dispatch(get_user_assets({ wallet_type: 'trading_wallet' }));
        dispatch(get_wallet_details())

    }, [second_wallet_name]);
    const assetsData = useSelector(state => state.assets.assetsData)
    const selectCoin = (data) => {
        // setdropdownVisible(false)
        // setmethodName(data.name);
        // setmethodId(data._id);
        // const id = {
        // 	payment_method_id:data._id,
        // }
        // dispatch(P2PPaymetField(id))
    }
    const sender_wallet = (id, name) => {
        setfirst_wallet(id);
        setfirst_wallet_name(name);
        setdropdownVisible2(false)
    }
    const receiver_wallet = (id, name) => {
        setsecond_wallet(id);
        setsecond_wallet_name(name);
        setdropdownVisible1(false)
    }

    const flipValue = (val1, name1, val2, name2) => {
        setfirst_wallet(val2);
        setsecond_wallet(val1);
        setfirst_wallet_name(name2);
        setsecond_wallet_name(name1);

    }


    const handelSubmit = (e) => {
        e.preventDefault();

        const trn = {
            sender_wallet: first_wallet,
            receiver_wallet: second_wallet,
            amount: trensferAmount,
            token_id: trnsferId,
        }
        dispatch(transfer_user_assets(trn));
        onCloseModal();
    }
    const [checkBoxCondition, setcheckBoxCondition] = useState(0);

    const handleSearch = () => {
        if (search != undefined) {
            return assetsData?.assets?.filter(
                (iii) =>
                    iii.tokenname.toLowerCase().includes(search)
            )
        } else {
            return (assetsData?.assets);
        }
    }
    return (
        <div>
            <div className={classes.assetsBtn}>
                <div className={classes.btnDesign}>
                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/assets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Assets'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/mainAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Main'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/tradeAccount' style={{ color: 'rgb(40 205 149)' }}> {assetsnav['0']['Trading'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/marginAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Margin'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                    </ul>
                    <ul className={classes.btnUl}>
                        {/* <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
                                    <Link to='/vendor/swap-currency' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['swap'][langchange]}</Link>

                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button> */}
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/depositassest' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/withdrawAssets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Withdraw'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/record' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        {/* <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/bonus' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Bonus'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button> */}
                    </ul>
                </div>
            </div>
            <div className="box___3B-ao">
                <div className="wrapper___1F-PJ">
                    <section className="flexBetween___32pmZ" style={{ marginBottom: '24px' }}>
                        <div className="flexBetween___32pmZ titleBox___3WLBl">
                            <div className="title___3r00x">{assetsnav['0']['Trading'][langchange]}</div>
                        </div>
                        <a>
                            <button className="MuiButtonBase-root MuiButton-root jss57 MuiButton-outlined jss63 jss177 MuiButton-outlinedPrimary jss64 jss178" tabindex="0" type="button" onClick={() => history.history.push('/vendor/user-profile')}>
                                <span className="MuiButton-label"> {mainAccountpage['0']['Account'][langchange]} </span>
                                <span className="MuiTouchRipple-root"></span>
                            </button>
                        </a>
                    </section>
                    <section className='section_header_BTC' style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <div className="coinBox___2P5W8">
                            <span className="coin___3iu2v">{wallet_details?.wallets?.trading_wallet?.balance?.toFixed(2)}</span>USDT <span className="legal___2mgq8">
                                {/* <span>≈ 0</span>
                                <span style={{ marginLeft: '4px' }}>AUD</span> */}
                            </span>
                            {/* <span className="supplement___3ICm-">(Loans: <span className="" style={{ borderBottom: '1px dotted rgb(155, 155, 155)' }}>0 BTC</span>) </span> */}
                        </div>
                        <div className="flex section_header_BTC_item">
                            <span style={{ marginLeft: '0px' }}>
                                <div className="MuiFormControl-root MuiTextField-root jss186 jss187  input_186_div">
                                    <div className="MuiInputBase-root MuiInput-root jss197 MuiInput-underline jss189 jss193 MuiInputBase-formControl MuiInput-formControl">
                                        <input placeholder={mainAccountpage['0']['Search'][langchange]} type="text" className=" MuiInputBase-input MuiInput-input jss198 jss199 search-input" onChange={(e) => setsearch(e.target.value)} />
                                    </div>
                                </div>
                            </span>
                            <div className="span_BTC_div ">
                            <span style={{ marginLeft: '8px' }}>
                                    <label className="MuiFormControlLabel-root formControlLabel___13wEE mt-2" for='hi'>
                                        <span className="MuiButtonBase-root MuiIconButton-root jss204 MuiCheckbox-root jss200 jss202 MuiCheckbox-colorPrimary MuiIconButton-colorPrimary d-flex align-items-center" aria-disabled="false">
                                            <span className="MuiIconButton-label">
                                                <input className="jss207 d-block mr-3"  type="checkbox" id='hi' onClick={ () => checkBoxCondition == 0 ? setcheckBoxCondition(1) : setcheckBoxCondition(0) } />
                                                {/* <svg className="MuiSvgIcon-root svg-main" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                                </svg> */}
                                            </span>
                                            <span className="MuiTouchRipple-root"></span>
                                              <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                                            <span className="">{mainAccountpage['0']['thickbtn1'][langchange]}</span>
                                        </span>
                                        </span>
                                      
                                    </label>
                                </span>
                                <span className='d-none' style={{ marginLeft: '8px' }}>
                                    <label className="MuiFormControlLabel-root formControlLabel___13wEE ">
                                        <span className="MuiButtonBase-root MuiIconButton-root jss204 MuiCheckbox-root jss200 jss210 MuiCheckbox-colorPrimary MuiIconButton-colorPrimary" aria-disabled="false">
                                            <span className="MuiIconButton-label">
                                                <input className="jss207" type="checkbox" data-indeterminate="false" value="" />
                                                <svg className="MuiSvgIcon-root svg-main" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                                </svg>
                                            </span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </span>
                                        <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"> {mainAccountpage['0']['thickbtn2'][langchange]}</span>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </section>
                    <section style={{ marginTop: '22px' }}>
                        <div className="markets___3kLEb">
                            <div className="Mui-alert-root jss9288 Mui-alert-primary jss9289 bg-rgba(235, 199, 91, 0.192)" style={{ background: 'rgba(235, 199, 91, 0.192)', padding: '14px 4px' }}>
                                <div className="Mui-alert-icon jss9292">
                                    <i className="fa fa-info-circle mx-2" aria-hidden="true"></i>

                                </div>
                                <div className="Mui-alert-content jss9293">
                                    {/* <p className="Mui-alert-message jss9294"></p> */}
                                    <p className="Mui-alert-description jss9295">{marginAccountpage['0']['info'][langchange]}</p>
                                </div>
                            </div>
                            <div className='table-responsive-lg'>
                                <div style={{ width: '1200px', height: '40px' }}>
                                    <div className="ant-affix" style={{
                                        top: '80px', left: ' 90.5455px', width: '1200px'
                                    }}>
                                        <table class="MuiTable-root tableHeader___23wMl" data="" favonsymbol="[object Object]" style={{ width: '100%', tableLayout: 'fixed' }}>
                                            <thead class="MuiTableHead-root">
                                                <tr class="MuiTableRow-root MuiTableRow-head">
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss9297" scope="col" style={{ width: '15%', overflowWrap: 'break-word', padding: '9px 16px' }}>{mainAccountpage['0']['Coin'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss9299" scope="col" style={{ width: '20%', overflowWrap: 'break-word', padding: '9px 16px' }}>{mainAccountpage['0']['Total'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss9301" scope="col" style={{ width: '20%', overflowWrap: 'break-word', padding: '9px 16px' }}>{mainAccountpage['0']['Available'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss9303" scope="col" style={{ width: '20%', overflowWrap: 'break-word', padding: '9px 16px' }}>{mainAccountpage['0']['orders'][langchange]}</th>
                                                    <th class="MuiTableCell-root MuiTableCell-head MuiTableCell-head jss9305 actionCol___2onsg" scope="col" style={{ width: '20%', overflowWrap: 'break-word', padding: '9px 16px' }}>{mainAccountpage['0']['Operation'][langchange]}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div class="tableBodyContent___2JYio">
                                        <div style={{ height: 'auto', position: 'relative' }}>
                                            <div style={{height: 'auto', inset: '0px', width: '1200px' }}>
                                                <table class="MuiTable-root" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                    <tbody class="MuiTableBody-root">
                                                        {handleSearch()?.map((val) => {
                                                            if(val.balance > checkBoxCondition){
                                                                return (
                                                                    <tr className="MuiTableRow-root">
                                                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-body jss409" style={{ width: '15%', height: '80px' }}>
                                                                            {/* <div style={{maxHeight: '80px', overflow: 'hidden', overflowWrap: 'break-word'}}>
                                                                        <div>
                                                                            <div className="inlineCoin___2rs3q" style={{lineHeight: '40px', display: 'inline-flex', alignItems: 'center'}}> */}
                                                                            {/* <img src="https://assets-currency..com/6196181fb26db300061312bd_Logo%20color.png" style={{ width: '40px', height: '40px', float: 'left', marginRight: '4px' }} /> */}
                                                                            <span className="inlineCoinName___16eUB ml-3">
                                                                                <span className="font-size-14" style={{ fontWeight: '500' }}>{val?.tokenname}</span>&nbsp;
                                                                                {/* <span className="color-gray" style={{fontSize: '13px', fontWeight: 'normal'}}>EarthFund</span> */}
                                                                            </span>
                                                                            {/* </div>
                                                                        </div>
                                                                    </div> */}
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-body jss411" style={{ width: '20%', height: '80px' }}>
                                                                            {/* <div style={{maxHeight: '80px', overflow: 'hidden', overflowWrap: 'break-word'}}> */}
                                                                            <span className="amountTpl___11Pq4">{val?.balance} <span>
                                                                                <br />
                                                                                {/* <span className="color-gray smallFontSize___10abT">
                                                                                    <span>≈ 0</span>
                                                                                    <span style={{marginLeft: '4px'}}>AUD</span>
                                                                                </span> */}
                                                                            </span>
                                                                            </span>
                                                                            {/* </div> */}
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-body jss413" style={{ width: '20%', height: '80px' }}>
                                                                            <div style={{ maxHeight: '80px', overflow: 'hidden', overflowWrap: 'break-word' }}>
                                                                                <span className="amountTpl___11Pq4">{val?.balance} <span>
                                                                                    <br />
                                                                                    {/* <span className="color-gray smallFontSize___10abT">
                                                                                    <span>≈ 0</span>
                                                                                    <span style={{marginLeft: '4px'}}>AUD</span>
                                                                                </span> */}
                                                                                </span>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-body jss415" style={{ width: '20%', height: '80px' }}>
                                                                            {/* <div style={{maxHeight: '80px', overflow: 'hidden', overflowWrap: 'break-word'}}> */}
                                                                            <span className="amountTpl___11Pq4">0 <span>
                                                                                <br />
                                                                                <span className="color-gray smallFontSize___10abT">
                                                                                    <span>≈ 0</span>
                                                                                    <span style={{ marginLeft: '4px' }}>AUD</span>
                                                                                </span>
                                                                            </span>
                                                                            </span>
                                                                            {/* </div> */}
                                                                        </td>
                                                                        {/* <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-body jss417 operatorCol" style={{ width: '20%', height: '80px' }}>
                                                                            <div style={{ maxHeight: '80px', overflow: 'hidden', overflowWrap: 'break-word' }}>
                                                                                <div className="actionCol___2onsg">
                                                                                                                                                                        <Link to="/vendor/depositassest" className="actionLink___1_b7r btn btn-sm border" style={{marginRight: '5px',padding: '7px', fontSize: '13px'}}><i class="fas fa-sort-amount-down"></i>{mainAccountpage['0']['Deposit'][langchange]}</Link>

                                                                                    <Link to="/vendor/withdrawAssets" className="actionLink___1_b7r btn btn-sm border" style={{marginRight: '5px',padding: '7px', fontSize: '13px'}}><i class="fas fa-sort-amount-up"></i>{mainAccountpage['0']['Withdraw'][langchange]}</Link>
                                                                                    <a className="actionLink___1_b7r btn btn-sm border" onClick={onOpenModal} style={{marginRight: '5px',padding: '7px', fontSize: '13px'}}><i class="fas fa-exchange-alt"></i>{mainAccountpage['0']['Transfer'][langchange]}</a>
                                                                                </div>
                                                                            </div>
                                                                        </td> */}
                                                                         <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-body jss417 operatorCol" style={{ width: '20%', height: '80px',padding:'0px' }}>
                                                                            <div style={{ maxHeight: '80px', overflow: 'hidden', overflowWrap: 'break-word' }}>
                                                                                <div className="actionCol___2onsg">
                                                                                    <Link to={`/vendor/coin/${val?.tokenname == 'STA'? 'enj':val?.tokenname}`} className="actionLink___1_b7r btn btn-sm border" style={{marginRight: '5px',padding: '7px', fontSize: '13px'}}><i class="fas fa-sort-amount-up"></i>Trade</Link>
                                                                                    <Link to="/vendor/withdrawAssets" className="actionLink___1_b7r btn btn-sm border" style={{marginRight: '5px',padding: '7px', fontSize: '13px'}}><i class="fas fa-sort-amount-up"></i>{mainAccountpage['0']['Withdraw'][langchange]}</Link>
                                                                                    <a className="actionLink___1_b7r btn btn-sm border" onClick={onOpenModal} style={{marginRight: '5px',padding: '7px', fontSize: '13px'}}><i class="fas fa-exchange-alt"></i>{mainAccountpage['0']['Transfer'][langchange]}</a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                );
                                                            }
                                                        })}

                                                    </tbody>
                                                    {/* <a className="actionLink___1_b7r" onClick={onOpenModal} >{mainAccountpage['0']['Transfer'][langchange]}</a> */}

                                                </table>
                                                <Modal open={open} onClose={onCloseModal} animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design', }} center>
                                                    <div className='container'>
                                                        <div className='row deposit-modal'>
                                                            <div className='col-md-12'>
                                                                <h2>Transfer</h2>
                                                                {/* <div className='p2p-pay-system'><p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p></div> */}


                                                                <div className='mt-3'>
                                                                    <form onSubmit={handelSubmit} id='mainForm' method='post'>
                                                                        <div className='row'>
                                                                            <div className='col-md-6 ' style={{ position: 'relative' }}>
                                                                                <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                                                                    <label>Direction:</label>

                                                                                    <div class="input-group-prepend w-100">
                                                                                        <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                                                            aria-hidden="true"></i></span>

                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Type to search list"
                                                                                            onChange={e => setsearch(e.target.value)}
                                                                                            onFocus={() => setdropdownVisible2(true)}
                                                                                            // onBlur={() => setdropdownVisible2(false)}
                                                                                            className='form-control my-0 py-1 w-100'
                                                                                            value={first_wallet_name}
                                                                                        />

                                                                                    </div>
                                                                                </div>

                                                                                {dropdownVisible2 && (
                                                                                    <div className="autocomplete-dropdown TEXT">
                                                                                        <ul className="autocomplete-search-results-list">
                                                                                            <li className="autocomplete-search-result" style={{ display: second_wallet == 'main_wallet' ? 'none' : 'block' }} onClick={() => sender_wallet('main_wallet', 'Main Account')}>{'Main Account'}</li>
                                                                                            <li className="autocomplete-search-result" style={{ display: second_wallet == 'funding_wallet' ? 'none' : 'block' }} onClick={() => sender_wallet('funding_wallet', 'Funding Account')}>{'Funding Account'}</li>
                                                                                            <li className="autocomplete-search-result" style={{ display: second_wallet == 'trading_wallet' ? 'none' : 'block' }} onClick={() => sender_wallet('trading_wallet', 'Trading Account')}>{'Trading Account'}</li>

                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                                <img src={transferdata} className='transfer_img' onClick={() => flipValue(first_wallet, first_wallet_name, second_wallet, second_wallet_name)} />
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                                                                    <label style={{ visibility: 'hidden' }}>cc</label>
                                                                                    <div class="input-group-prepend w-100 ">
                                                                                        <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                                                            aria-hidden="true"></i></span>

                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Type to search list"
                                                                                            onChange={e => setsearch(e.target.value)}
                                                                                            onFocus={() => setdropdownVisible1(true)}
                                                                                            // onBlur={() => setdropdownVisible1(false)}
                                                                                            className='form-control my-0 py-1 w-100'
                                                                                            value={second_wallet_name}
                                                                                        />

                                                                                    </div>
                                                                                </div>

                                                                                {dropdownVisible1 && (
                                                                                    <div className="autocomplete-dropdown TEXT">
                                                                                        <ul className="autocomplete-search-results-list">

                                                                                            <li className="autocomplete-search-result" style={{ display: first_wallet == 'main_wallet' ? 'none' : 'block' }} onClick={() => receiver_wallet('main_wallet', 'Main Account')}>{'Main Account'}</li>
                                                                                            <li className="autocomplete-search-result" style={{ display: first_wallet == 'funding_wallet' ? 'none' : 'block' }} onClick={() => receiver_wallet('funding_wallet', 'Funding Account')}>{'Funding Account'}</li>
                                                                                            <li className="autocomplete-search-result" style={{ display: first_wallet == 'trading_wallet' ? 'none' : 'block' }} onClick={() => receiver_wallet('trading_wallet', 'Trading Account')}>{'Trading Account'}</li>

                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                                                            <label>Coin</label>

                                                                            <div class="input-group-prepend w-100">
                                                                                <span class="input-group-text lighten-3" style={{ background: ' #dcdcdc80', border: 'none' }} id="basic-text1"><i class="fas fa-search text-white"
                                                                                    aria-hidden="true"></i></span>

                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Select Coin"
                                                                                    onChange={e => setsearch(e.target.value)}
                                                                                    onFocus={() => setdropdownVisible(true)}
                                                                                    // onBlur={() => setdropdownVisible(false)}
                                                                                    className='form-control my-0 py-1 w-100'
                                                                                    value={trnsferIdname}
                                                                                />

                                                                            </div>
                                                                        </div>

                                                                        {dropdownVisible && (
                                                                            <div className="autocomplete-dropdown TEXT w-75">
                                                                                <ul className="autocomplete-search-results-list w-100" >
                                                                                    {assetsData?.assets?.map((val) => {

                                                                                        return (
                                                                                            <li className="autocomplete-search-result" onClick={() => { settrnsferId(val?.token_id?.[0]); setdropdownVisible(false); settrnsferIdname(val?.tokenname) }}>{val?.tokenname}</li>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        )}







                                                                        <div className="form-group">
                                                                            <label htmlFor="amount" >Amount</label>
                                                                            <input type='text' className='form-control' onChange={(e) => settrensferAmount(e.target.value)} value={trensferAmount} placeholder='Amount' required />
                                                                        </div>
                                                                        {/* feild{?.template?.map((row,ii) => {
                                                                    return  (
                                                                        <>
                                                                            
                                                                        </>
                                                                    );
                                                                })} */}

                                                                        <div className="form-group text-center">
                                                                            <button type="submit" style={{ backgroundColor: 'rgb(40 205 149)', padding: '5px 18px', fontSize: '18px' }} className=' submit__btn btn text-white' name="submit" >Submit</button>
                                                                        </div>

                                                                    </form>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default TradeAccount

