import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const TokenListing = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          {/* <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul> */}
        </div>
        <div className='banner_body' >
          <div className='banner_content about-page'>
            <h2>LISTING TOKENS </h2>
           <h3 className='text-white'>BUSINESS TOKEN Listing</h3>
            
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0 align-items-center First_row'>
            
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h3>LISTING TOKENS ON {PROJECT_NAME}</h3>
                <h6>
                In order to list your token on {PROJECT_NAME} and {PROJECT_NAME} Pro and make your token accessible to the massive crypto community in India, all you have to do is click on the link to the form below
                </h6>
              </div>
            </div>
            <div className='col-md-6' >
              <div style={{borderRadius:'20%',overflow:'hidden'}}>
                <img className='img-about-1' src={about1} width='100%' alt='TokenListing/img' />
              </div>
            </div>
          </div>
        </div>
       
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row vision'>
             <div className='col-md-6'>
              <img className='img-about-1' src={about3} width='100%' alt='TokenListing/img' />
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h4 >Fill the Listing Application Form</h4>
                <h3 className='mt-0 mb-0'>{PROJECT_NAME}  MODEL FRAMEWORK</h3>
                <p>Building on our promise to provide the safest crypto space for our users, it is imperative for us to make sure we are vetting all coins available on our platform. A strong foundation of awareness, education, and investor-friendly structure is what {PROJECT_NAME}’s Model Framework ensures from the very first day.</p>
                <h3 className='mb-0'>FOR CRYPTO COIN DEVELOPERS</h3>
                <p>{PROJECT_NAME} has been working towards their promise to make crypto accessible. One of the priority initiatives for that is to make sure the platform is providing traders and investors access to valid and high-quality tokens. Thus, the tokens listed on {PROJECT_NAME}’s platform go through rigorous checks before they are made available.</p>
              </div>
            </div>
           
          </div>
        </div>

        <div className='about_box-1 mt-5'>
          <div className='text-center m-3'><h3>How to get your Coin Listed on {PROJECT_NAME} and {PROJECT_NAME} Pro</h3></div>
          <div className='row m-0  First_row vision'>
            <div className='col-md-4 border-right'>
              <div className='about-story-text about-page'>
                <h3>Step 1</h3>
                <p>Fill the online Listing Application form as the 1st step to listing your coin on {PROJECT_NAME}. Once the token goes through an algorithm check & passes all of {PROJECT_NAME}’s Framework criteria our Crypto Analysts would then analyse your inputs from the form</p>
                <p>Note: Make sure to include all the information you wish to communicate with us in the form. It is advisable that the Project Founder or CEO fill out the application, as we would need to speak to the key person of the project if we encounter any issues with the listing.</p>
              </div>
            </div>
            <div className='col-md-4 border-right'>
            <div className='about-story-text about-page'>
                <h3>Step 2</h3>
                <p>Once the application is submitted, Our Crypto Analyst Team will conduct due diligence on the token. This process is completed within 5-7 days.</p>
                <p>Our Crypto Analysts would do the analysis on the token with the help of  Framework analysing all the aspects of the token.</p>
              </div>
            </div>
            <div className='col-md-4'>
            <div className='about-story-text about-page'>
                <h3>Step 3</h3>
                <p>Once the token gets approved by the Quality Control team, technical checks are done for the token. Thereafter, decisions regarding the listing of the coin on both {PROJECT_NAME} & {PROJECT_NAME} Pro platforms are conveyed to the registered email id given in the Listing Application form</p>
                <p>Following the decision a draft agreement is made for approval, following which the final​​ agreement and execution of its terms are communicated to you.</p>
              </div>
            </div>
           
          </div>
        </div>
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-4'>
\               
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 about-page'>
                  <h3 className='mb-0'>FILL THE APPLICATION FORM HERE</h3>
                  <p>If you wish to be a market-making partner please write to us at mailid of {PROJECT_NAME}.</p>
                  <h3 className='mb-0'>FREQUENTLY ASKED QUESTIONS</h3>
                  <ul   className='ml-4'>
                    <li><p>How to get your coin listed on {PROJECT_NAME}?</p>
                    </li>
                    <li><p>What are the requirements to list your crypto project on {PROJECT_NAME}?</p>
                    </li>
                    <li><p>Who will contact you after applying for a coin listing?</p>
                    </li>
                    <li><p>What are the token listing fees on {PROJECT_NAME}?
                        </p>
                    </li>
                    <li><p>How to become a crypto market maker at {PROJECT_NAME}?</p></li>
                    
                  </ul>
                  <h3>Build your crypto portfolio on {PROJECT_NAME} -a safe, simple and a compliant crypto investment app.</h3>
                </div>
                <div className='col-md-6 about-page'>
                <img className='img-about-1' src={about4} width='100%' alt='TokenListing/img' />
              
                </div>
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>
        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default TokenListing