import React, { useEffect } from 'react'
import slider1 from '../../../../_assets/images/banner/Banner1.jpg';
import slider2 from '../../../../_assets/images/banner/Banner2.jpg';
import slider3 from '../../../../_assets/images/banner/Banner3.jpg';
// import slider4 from '../../../../_assets/images/banner/Banner_4.png';
// import slider5 from '../../../../_assets/images/banner/Banner_5.png';
// import mamta from '../../../../_assets/images/banner/Banner_1.png'
// import Banner1 from '../../../../_assets/images/banner/1Banner_1.png';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useDispatch, useSelector } from 'react-redux';
import { get_news } from '../../../../redux/actions/user.action';

const CoinSlider = () => {

   const dispatch = useDispatch();
   const news = useSelector(state => state.user.news);

    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
    };
    
    const items = [
        <div className="item" data-value="1"><img src={slider1} alt="Join the protocal listing party" width='300' /></div>,
        <div className="item" data-value="2"><img src={slider2} alt="Social " width='300' /></div>,
        <div className="item" data-value="3"><img src={slider3} alt="Trading App and website" width='300'/></div>,
        <div className="item" data-value="4"><img src={slider1} alt="Trade anytime" width='300'  /></div>,
        <div className="item" data-value="5"><img src={slider2} alt="Safe and secure" width='300'  /></div>,
    ];
    useEffect(() => {
        dispatch(get_news())
    },[])
console.log('news',news);
    // const itemsLength = Array.from({ length: 5 });

    // const items = itemsLength.map((item, index) => {
    //     const style = { width: 150 + index * 100 };
    //     return (<div className="item" style={style}>{index + 1}                 <img src={slider} alt="" />
    //     </div>);
    // });

  return (
    <div>
    <div className="sec__links">
    <marquee  direction = "left" className="">
        
        <div className='d-flex justify-content-between align-items-center'>
            {news?.news?.map((val) => {
                return(<p className='inline-block'> {val?.news}  <span className='ml-2'> |</span></p>)
            })}
        
        </div>
    
    </marquee>
    <ul className='d-none'>
   

        <li>
            <a href="">
                The Official Statement on Fraud Regarding the Issue that Criminals Pretending to be SOLAR TECHNO ALLIANCE
                Business Team
            </a>
            <span>02-02</span>
        </li>
        <li>
            <a href="">
                The Official Statement on Fraud Regarding the Issue that Criminals Pretending to be SOLAR TECHNO ALLIANCE
                Business Team
            </a>
            <span>02-02</span>
        </li>
        <li>
            <a href="">
                The Official Statement on Fraud Regarding the Issue that Criminals Pretending to be SOLAR TECHNO ALLIANCE
                Business Team
            </a>
            <span>02-02</span>
        </li>
        <li className="arrow__icon">
            <a href="">
                <img src="assets/svg/arrow-icon.png" alt="arrow-icon" />
            </a>

        </li>
    </ul>
</div>
<div className="update__trending__slider">
    <div className="swiper mySwiper trending__coin__slider">
        <div className="swiper-wrapper">
            {/* <div className="swiper-slide">
                <img src={slider} alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div>
            <div className="swiper-slide">
                <img src="assets/img/slider-img.jpg" alt="" />
            </div> */}
            
<AliceCarousel
        mouseTracking
        // infinite
        autoPlayInterval={1000}
        animationDuration={1500}
        disableDotsControls
        // disableButtonsControls
        responsive={responsive}
        autoPlay
        items={items}
      />
        </div>
        {/* <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div> */}
    </div>

    {/* <AliceCarousel
          
        infinite
        mouseTracking
        items={items}
    /> */}
{/* 
<AliceCarousel
        mouseTracking
        items={items}
        paddingLeft={50}
        paddingRight={50}
        responsive={responsive}
    /> */}

    {/* <Carousel /> */}
</div>
    </div>
  )
}

export default CoinSlider