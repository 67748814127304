// import { makeStyles, Typography,LinearProgress } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CoinList, SingleCoin } from '../../../config/api';
import { CryptoState } from '../../../CryptoContext';
// import CoinInfo from './CoinInfo';
// import ReactHtmlParser from "react-html-parser";
// import { numberWithCommma } from './CoinTable';
import Header from './Header';
// import ChartComponent from './ChartComponent';
import OrderSection from './OrderSection';
// import TradingViewWidget from './Chart';

import TradingViewWidget, {
	BarStyles,
	IntervalTypes,
	RangeTypes,
	Themes,
	Chart
  } from './Chart';
import Trade from '../trade/Trade';
import OrderSectionLogined from '../../private/chart/OrderSection';
import { useDispatch, useSelector } from 'react-redux';
import CoinInfo from '../../private/market/CoinInfo';
import { getCoinPrice, get_2nd_swap_balance_wallet_type, get_swap_balance_wallet_type, get_swap_tokens } from '../../../redux/actions/assets.action';

export function numberWithCommma(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}






const CoinPage = () => {
  var { id,pair } = useParams();
  const [coin,setCoin] = useState()
  const { currency,symbol,currencyId,pairName, setpairName} = CryptoState();
  const {setCurrencyId} = CryptoState();
  const [navcoin,setnavCoin] = useState()
  const [sockitCoin,setsockitCoin] = useState()
  // setpairName(pair)
  const dispatch = useDispatch();
  const fatchCoin = async () =>{
    const { data } = await axios.get(SingleCoin('sta-token'));
    setCoin(data);
  }
  // console.log(pair,pairName);

  //  useEffect(() => {
    
  //    fatchCoin();
  //  },[id])


   const swap_tokens = useSelector(state => state.assets.swap_tokens.swap_tokens);
   const history = useHistory();
   useEffect(() => {
    fatchCoin();

      //  fatchcoins();
       dispatch(get_swap_tokens());
       setpairName(pair);
      //  dispatch(get_swap_balance_wallet_type({tokenName: pairName,wallet_type:'trading_wallet'}));
      //  var newId = id != 'enj' ? id :'STA';
      //  dispatch(get_2nd_swap_balance_wallet_type({tokenName: newId?.toUpperCase(),wallet_type:'trading_wallet'}))
      //  dispatch(getCoinPrice(newId?.toUpperCase()))

      //  var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
      //  var segment_array = segment_str.split( '/' );
      //  var last_segment = segment_array.pop();
      //  console.log('last_segment',last_segment);

       // dispatch(get_all_market_coin());

       
      // return () =>{
        if(history.location?.pathname == '/vendor/coin/'+id+'/'+pair){

        var binanceSocket = new WebSocket("wss://stream.binance.com:9443/ws/"+id+"usdt@ticker_1h")
        binanceSocket.onmessage = function(event){
            var messageObject = JSON.parse(event.data)
            if(history.location?.pathname == '/vendor/coin/'+id+'/'+pair){
            // document.getElementById("price").innerHTML = messageObject.w;
            document.getElementById("vol-price").innerHTML = messageObject.v;
            document.getElementById("high-price").innerHTML = messageObject.h;
            document.getElementById("last-price").innerHTML = messageObject.p;
            }
        }

        var binanceSocketPrice = new WebSocket("wss://stream.binance.com:9443/ws/"+id+"usdt@bookTicker")
        binanceSocketPrice.onmessage = function(eventPrice){
            var messageObjectPrice = JSON.parse(eventPrice.data)
            if(history.location?.pathname == '/vendor/coin/'+id+'/'+pair){
            document.getElementById("price").innerHTML = messageObjectPrice.b;
            }
        }
        // } 
      }
   },[id,pairName])

   
   
  //  const fatchcoins = async () => {
  //      const { data } = await axios.get(CoinList('USD'));
  //      setnavCoin(data);
  //     //  setloading(false);
  //  }

   const isSignedIn = useSelector(state => state.persist.isSignedIn);
   const isAuthenticated = isSignedIn;

   

// const navlist = () => {

  
//   return navcoin?.map((row) => {
//     const profit = row.price_change_percentage_24h > 0;
//     // console.log('asdsad',row);
//     // if(id == 'enj'){
//       if(id == row.symbol ){
//       return(
//           <div className='trading__sub__header graph-m-top'>
//             <div className='row m-0 pt-2 pb-2'>
//               <div className='col-lg-1 col-12 p-0'>
//                 <Link to={'/vendor/market'}>
//                   <div className='border__card'>
//                     <i class="fa fa-arrow-left" aria-hidden="true"></i>
//                   </div>
//                 </Link>
//               </div>
//               <div className='col-lg-2 col-4  text-white p-0'>
//                 <div className=''>
//                     <div className='border__card'>
//                     <h4 className='text-white'> <span>{id != 'enj' ? id?.toUpperCase() :'STA'}/{pairName}</span></h4>
//                     {/* <p> Aave</p> */}
//                   </div>
//                 </div>
//               </div>
//               <div className='col-lg-2 col-4  text-white p-0'>
//                 <div className=''>
//                     <div className='border__card'>
//                     <h4 className='text-white'> <span id='price'>{sockitCoin}</span></h4>
//                     {/* <p> Aave</p> */}
//                   </div>
//                 </div>
//               </div>
//               <div className='col-lg-2 col-4 text-white p-0'>
//                 <div className=''>
//                     <div className='border__card'>
//                     <h4 className='text-white mb-0'> <span>24h change</span></h4>
//                       <p className='text-white'> {numberWithCommma(row?.price_change_24h?.toFixed(2))}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className='col-lg-2 col-4 text-white p-0'>
//                 <div className=''>
//                     <div className='border__card'>
//                     <h4 className='text-white mb-0'> <span>24h High</span></h4>
//                       <p className='text-white'> {row?.high_24h?.toFixed(2)}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className='col-lg-2 col-4 text-white p-0'>
//                 <div className=''>
//                     <div className='border__card'>
//                     <h4 className='text-white mb-0'> <span>24h Low</span></h4>
//                       <p className='text-white'> {row?.low_24h?.toFixed(2)}</p>
//                   </div>
//                 </div>
//               </div>
//               {/* <div className='col-lg-2 col-4 text-white p-0'>
//                 <div className=''>
//                     <div className='border__card'>
//                     <h4 className='text-white mb-0'> <span>75.9</span></h4>
//                       <p className='text-white'> 6,167</p>
//                   </div>
//                 </div>
//               </div> */}
//             </div>

//           </div>


//       )}})


    
// }


const StaTocken = () =>{
  return(
    <>
    <iframe
                  src="https://teams.bogged.finance/embeds/chart?address=0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C&chain=bsc&charttype=candles&theme=dark&defaultinterval=15m&showchartbutton=false&showbuybutton=false"
                  frameborder="0"
                  height="450px"
                  width="100%"
                ></iframe>
    </>
    // <div className="nomics-ticker-widget " data-name="The Graph" data-base={id != 'enj' ? id?.toUpperCase()  :"STA5"}></div>

  )
}
  return (
    <div className='treding__graph__body'>
    <Header />
    <main className='p-sm-3'>

            {/* {navlist()} */}
        <div className="graph__grid row m-0">
          <div className="col-lg-7 col-md-12 tradingview-widget-container p-0">
          <div className='trading__sub__header graph-m-top'>
              <div className='row m-0 pt-2 pb-2'>
                <div className='col-1 p-0'>
                  <Link to={'/vendor/market'}>
                    <div className='border__card'>
                      <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </div>
                  </Link>

                </div>
                <div className='col-md-2 col-4  text-white p-0'>
                  <div className=''>
                      <div className='border__card'>
                      <h4 className='text-white'> <span>{id != 'enj' ? id?.toUpperCase() :'STA'}/{pairName}</span></h4>
                    </div>
                  </div>
                </div>
                <div className='col-md-2 col-4  text-white p-0'>
                <div className=''>
                    <div className='border__card'>
                    <h4 className='text-white'> <span id='price'></span></h4>
                  </div>
                </div>
              </div>
                <div className='col-md-2 col-4 text-white p-0'>
                  <div className=''>
                      <div className='border__card'>
                      <h4 className='text-white mb-0'> <span>Volumn</span></h4>
                        <p className='text-white' id='vol-price'> 00</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-2 col-4 text-white p-0'>
                  <div className=''>
                      <div className='border__card'>
                      <h4 className='text-white mb-0'> <span>24h High</span></h4>
                        <p className='text-white' id='high-price'> 00</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-2 col-4 text-white p-0'>
                  <div className=''>
                      <div className='border__card'>
                      <h4 className='text-white mb-0' > <span>24h Low</span></h4>
                        <p className='text-white' id='last-price'> 00</p>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
{/* <coingecko-coin-ticker-widget  coin-id="sta-token" currency="usd" locale="en"></coingecko-coin-ticker-widget> */}
{/* <CoinInfo coin={coin} /> */}
              { id != 'enj' ? <TradingViewWidget   symbol={id + currency} />  : StaTocken() }
              {/* <iframe
                  src="https://teams.bogged.finance/embeds/chart?address=0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C&chain=bsc&charttype=candles&theme=dark&defaultinterval=15m&showchartbutton=false&showbuybutton=false"
                  frameborder="0"
                  height="100%"
                  width="1000px"
                ></iframe> */}
                
                {/* <iframe id="tradingview_7dff2" name="tradingview_7dff2" src="https://charts.bogged.finance/charting_library/en-tv-chart.9df149da.html#symbol=0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C&amp;interval=1s&amp;toolbarbg=101722&amp;widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&amp;timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&amp;locale=en&amp;uid=tradingview_7dff2&amp;clientId=0&amp;userId=0&amp;chartsStorageVer=1.0&amp;customCSS=%2Fcharting_library%2Fchart.css&amp;debug=false&amp;timezone=Asia%2FCalcutta&amp;theme=Dark" title="Financial Chart" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen="" style={{display: 'block', width: '100%', height: '100%',}}></iframe> */}
          </div>
          <div className="col-lg-5 col-12  aside__box h-100 p-0">
              <div className="aside__order__book h-100 graph-m-top lg___margin___d">

              <Trade currencyId={id + 'usdt'}/>

              </div>

          </div>
        {isAuthenticated === true ?  <OrderSectionLogined props={id} /> : <OrderSection />}
      </div>
  </main>
  <script src="https://widget.nomics.com/embed.js"></script>
  

      </div>
  );
};

export default CoinPage;

