import React from 'react';
import Trade from '../trade/Trade';
import SpotSection from './SpotSection';
import { CryptoState } from '../../../CryptoContext';

const OrderTable = () => {

    const {currencyId,currency} = CryptoState();

    console.log('setCurrencyId(id)',currency,'setCurrencyId',currencyId )

  return (
    <div className="order__wrapper">
    <div className="order__data active" id='myElement'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
                <div className="dropdown__bg__layer">

                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled
                </h4>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Unfilled
                </h4>
            </div>
            <div className="order__tabs__inner__Data cancer__order">
                <button className="cancel__btn">
                    <span>
                        Cancel All
                    </span>
                    <span className="cancel__icon">
                        <img className="cancel-order-icon" src="assets/svg/cancel-order-icon.svg" alt="camcel-icon" />
                    </span>
                    <div className="cancel__Popup">
                        <p>
                            Click here to all the open oders as specified (included order note display
                            here)
                        </p>
                    </div>
                </button>
            </div>
        </div>
        <ul className="inner__data__order">
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
        </ul>

        {/* <Trade currencyId='ETHBTC'/> */}
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginLeft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    >
                </svg>
            </span>
        </div>
    </div>
     <div className="order__data stop__orders__data" id='myElement1'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Stop Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data cancer__order">
                <button className="cancel__btn">
                    <span>
                        Cancel All
                    </span>
                    <span className="cancel__icon">
                        {/* <img className="cancel-order-icon" src="assets/svg/cancel-order-icon.svg" alt="" /> */}
                    </span>
                    <div className="cancel__Popup">
                        <p>
                            Click here to all the open oders as specified (included order note display
                            here)
                        </p>
                    </div>
                </button>
            </div>
        </div>
        <ul className="inner__data__order">
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>

            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
        </ul>
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginleft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                   >
                </svg>

            </span>
        </div>
    </div>
    
    <div className="order__data stop__orders__data" id='myElement2'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                    <option className="dropdown__option">
                        Stop Market
                    </option>
                    <option className="dropdown__option">
                        Stop Limit
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Buy/Sell
                    </option>
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Avg. Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data cancer__order">
                <div className="order__tabs__inner__Data tabs__dropdown">
                    <button className="pairs__button">
                        <span>All</span>
                        <i className="fas fa-caret-down pl-1"></i>
                    </button>
                    <div className="dropdown__content">
                        <option className="dropdown__option">
                            All
                        </option>
                        <option className="dropdown__option">
                            Completed
                        </option>
                        <option className="dropdown__option">
                            Cancelled
                        </option>
                    </div>
                </div>
            </div>
        </div>
        <ul className="inner__data__order">
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>

            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
        </ul>
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginLeft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    >
                </svg>
            </span>
        </div>
    </div>
    <div className="order__data trade__history__Data" id='myElement3'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                    <option className="dropdown__option">
                        Stop Market
                    </option>
                    <option className="dropdown__option">
                        Stop Limit
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                    <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled Price
                </h4>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Volume
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Fee
                </h4>
            </div>
        </div>
        <ul className="inner__data__order">
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
            <li className="data">
                <span>Empty Data</span>
                <span> </span>
                <span></span>
                <span></span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
                <span>Empty Data</span>
            </li>
        </ul>
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginLeft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    >
                </svg>
            </span>
        </div>
    </div>
     {/* <div className="no-data">
        <img src="assets/svg/no-data.svg" alt="" />
        <span>No Data</span>
    </div> */}
    <div className="history__popup">
        <span>
            For more order details, please check <a href="">Order History</a>.
        </span>
    </div>
</div>
  );
};

export default OrderTable;
