import React from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../../../_assets/images/no_history-removebg-preview.png';
import {  poolminingpage, pooloverviewpage, poolsettingpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
const miningSetting = () => {
    const {langchange} = LangState();

    return (
        <div>
            <div className="Fast-trade">
                <div className="container-fast">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/miningpool"> {poolminingpage['0']['nav']['btn1'][langchange]}  </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/MiningDashboard"> {poolminingpage['0']['nav']['btn2'][langchange]}  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/miningOverview">  {poolminingpage['0']['nav']['btn3'][langchange]} </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/miningSetting">  {poolminingpage['0']['nav']['btn4'][langchange]} </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="setting-account">
                <div className="container-setting">
                    <div className="left-setting">
                        <h2>{poolminingpage['0']['nav']['btn4'][langchange]} </h2>
                        <div className="card setting-card">
                            <ul className="nav flex-column" role="tab">
                                <li className="nav-item"> 
                                    <a className="nav-link active" data-toggle="tab" href="#user"> <i class='fas fa-user'></i>  {poolsettingpage['0']['user'][langchange]} </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab" href="#s-account">  <i className="fa fa-chain"></i> {poolsettingpage['0']['Account'][langchange]} </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab" href="#s-address">  <i class='fas fa-map-marker-alt'></i> {poolsettingpage['0']['IncomeAddress'][langchange]} </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab" href="#s-transfer">  <i className="fa fa-chain"></i> {poolsettingpage['0']['Transfer'][langchange]} </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab" href="#s-fee">  <i className="fa fa-chain"></i> {poolsettingpage['0']['Kickback'][langchange]}  </a>
                                </li>
                            </ul>
                        </div>
                        <form>
                            <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="switch1" />
                            <label className="custom-control-label" for="switch1"> {poolsettingpage['0']['Alert'][langchange]} </label>
                            </div>
                        </form>
                    </div>
                    <div className="right-setting">
                        <div className="tab-content">
                            <div id="user" className="tab-pane active">
                                <h3> {poolsettingpage['0']['Profile'][langchange]}  </h3>
                                <div className="card user-id-card">
                                    <i class='fas fa-user'></i> 
                                    <div className="name-area">
                                        <h5> UID </h5>
                                        <p> 1200009034 <i class='fas fa-copy'></i> </p>
                                    </div>
                                </div>
                            </div>
                            <div id="s-account" className="tab-pane">
                                <div className="table-header">   
                                    <h5> Account Settings </h5>
                                    <div className="d-flex">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search Account"/> 
                                            </div>
                                        </form>
                                        <button type="button" className="btn"> <i class='fas fa-plus'></i> Create Account </button>
                                    </div>
                                </div>
                                <div className="table-responsive"> 
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th> Mining Account </th>
                                                <th>  status </th>
                                                <th>  	Operation </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colspan="9" className="text-center mt-4">
                                                    <img src={img1}/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div id="s-address" className="tab-pane">
                                <h3> Income Address </h3>
                                <div className="noti ">
                                <p>   Mine BTC to earn Vcash and Namecoin. As these assets are not listed on KuCoin, you must provide valid deposit addresses as below To avoid losses from high service fees,gains will only be issued after 100 USDT worth of income. </p>
                                </div>
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#"> VCASH  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">  NAMECOIN </a>
                                    </li>
                                </ul>
                                <form action="">
                                    <div className="form-group">
                                        <label for="text">Income Address</label>
                                        <input type="text" className="form-control" placeholder="Please enter Income Address " id="income_address"/>
                                    </div>
                                    <div className="form-group">
                                        <label for="Remarks">Remarks</label>
                                        <input type="text" className="form-control" placeholder="Please enter Remarks" id="Remarks"/>
                                    </div>
                                        <button type="submit" className="btn">Save</button>
                                </form>
                            </div>
                            <div id="s-transfer" className="tab-pane">
                                <div className="transfer-header">
                                    <ul className="nav" role="tab">
                                        <li className="nav-item"> 
                                            <a className="nav-link active" data-toggle="tab" href="#table_1"> Transfer </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link " data-toggle="tab" href="#table_2"> Recevie </a>
                                        </li>
                                    </ul>
                                    <button type="button" className="btn"> <i class='fas fa-cog'></i> Transfer Setting </button>
                                </div>

                                <div className="tab-content">
                                    <div id="table_1" className="tab-pane active"> 
                                        <div className="table-responsive mt-3"> 
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left"> Mining Account </th>
                                                        <th className="text-center" > Coin	 </th>
                                                        <th className="text-center" > Date </th>
                                                        <th className="text-center" >  Quantity </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="9" className="text-center mt-4">
                                                            <img src={img1}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div id="table_2" className="tab-pane"> 
                                        <div className="table-responsive mt-3"> 
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left"> UID </th>
                                                        <th className="text-center" > Mining Account	 </th>
                                                        <th className="text-center" > Coin	 </th>
                                                        <th className="text-center" > Date </th>
                                                        <th className="text-center" >  Quantity </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="9" className="text-center mt-4">
                                                            <img src={img1}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="s-fee" className="tab-pane">
                                <div className="transfer-header">
                                    <ul className="nav" role="tab">
                                        <li className="nav-item"> 
                                            <a className="nav-link active" data-toggle="tab" href="#sent"> Sent </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link " data-toggle="tab" href="#received"> Recevied </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-content">
                                    <div id="sent" className="tab-pane"> 
                                        <div className="table-responsive mt-3"> 
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left"> Mining Account </th>
                                                        <th className="text-center" > Coin	 </th>
                                                        <th className="text-center" > Date </th>
                                                        <th className="text-center" >  Quantity </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="9" className="text-center mt-4">
                                                            <img src={img1}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div id="received" className="tab-pane active"> 
                                        <div className="table-responsive mt-3"> 
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left"> Mining Account </th>
                                                        <th className="text-center" > Coin	 </th>
                                                        <th className="text-center" > Date </th>
                                                        <th className="text-center" >  Quantity </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="9" className="text-center mt-4">
                                                            <img src={img1}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default miningSetting