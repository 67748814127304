import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import madel from '../../../../_assets/images/madel.svg';
import visa from '../../../../_assets/images/visa2.png';
import { LangState } from '../../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../../lang/lang';
import noDataImg from '../../../../_assets/images/no_history-removebg-preview.png';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { P2PGetPaymetMethodes, P2PPaymetField, P2PPaymetFormSubmit, P2PPaymetMethodes } from '../../../../redux/actions/payment.action';
import { array } from 'prop-types';
import { getUserDetails } from '../../../../redux/actions/dashboard.action';

const PaymentSetting = () => {
    const {langchange} = LangState();
    const [open, setOpen] = useState(false);
    const [dropdownVisible,setdropdownVisible] = useState(false);
    const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
    const [search, setsearch] = useState();
    const [methodName, setmethodName] = useState();
    const [methodId, setmethodId] = useState();
    const dispatch = useDispatch();
    const method = useSelector(state => state.payment.method);
    const feild = useSelector(state => state.payment.field);
    const get_method = useSelector(state => state.payment.get_method);
    const user = useSelector(state => state.dashboard.user);

	const handleSearch = () => {	
		if(search != undefined){
			return method.payment_methods?.filter(
				(iii) => 
				iii.name.toLowerCase().includes(search)
			)
		}else{
			return(method.payment_methods);
		}
    }

    const selectCoin = (data) => {
        setdropdownVisible(false)
		setmethodName(data.name);
		setmethodId(data._id);
		const id = {
			payment_method_id:data._id,
		}
		dispatch(P2PPaymetField(id))
	}

    useEffect(() => {
		dispatch(P2PPaymetMethodes());
		dispatch(P2PGetPaymetMethodes());
        dispatch(getUserDetails())

	}, [])



    const [updateFormData, setupdateFormData] = useState();
    var formData1 = [' '];
    const handleSubmitForm2 = (e) => {
        e.preventDefault();
    
        var form = document.getElementById('mainForm');
        form.addEventListener("submit", function(event) {
            event.preventDefault();
            var url = form.getAttribute('action');
            // console.log(url);
            const formData = new FormData(this);
            
            for (const formElement of formData) {
                console.log('formElement',formElement);
            }
            const entries = formData.entries();
            // console.log('entries',entries);
            var data = Object.fromEntries(entries);
            // console.log('data',data);
            var i = 0;
            for (var [key, value] of Object.entries(data)) {
                // console.log(`${key}: ${value}`);
                formData1[i] = {[key]: value}
               
                i++
              }
              var newArr = Object.values(formData1);
              setupdateFormData(newArr);

              var data = {
                fieldsData:newArr, 
                payment_method_id:methodId,
             }
             dispatch(P2PPaymetFormSubmit(data,setOpen));
            //   console.log('formData',newArr)
        });


      };

    //   const handleSubmitForm = (e) => {
    //     e.preventDefault();
    
    //     var form = document.getElementById('mainForm');
    //     form.addEventListener("submit",
    const handleSubmitForm = (event) => {
            event.preventDefault();
            // var url = form.getAttribute('action');
            var form = document.getElementById('mainForm');
            // console.log('url',url);
            const formData = new FormData(form);
            console.log('formData',formData);
            for (const formElement of formData) {
                console.log('formElement',formElement);
            }
            const entries = formData.entries();
            // console.log('entries',entries);
            var data = Object.fromEntries(entries);
            console.log('data',data);
            var i = 0;
            for (var [key, value] of Object.entries(data)) {
                // console.log(`${key}: ${value}`);
                formData1[i] = {[key]: value}
               
                i++
              }
              var newArr = Object.values(formData1);
              setupdateFormData(newArr);

              var data = {
                fieldsData:newArr, 
                payment_method_id:methodId,
             }
             dispatch(P2PPaymetFormSubmit(data,setOpen));
              console.log('formData',data)
        }
    //     );


    //   };
      console.log('get_method',get_method?.payment_methods);
      const handleChange = (e) => {
        // var eventNAme = e.target.name;
        // var eventValue = e.target.value.trim();
        // console.log(e.target);
        // formData.push({ [e.target.name]: e.target.value.trim()});
        // // formData({ ...formData,          [e.target.name]: e.target.value.trim()  });
        //     // updateFormData([{[eventNAme] : eventValue}])
        //     // updateFormData({...formData,: {[eventNAme] : eventValue}})

        
           
      };
     
      const addBtn = () => {

        if(user?.kycStatus == 'APPLIED'){
            var btn = <button className='btn border-solid p2p-pay-system-add-btn' onClick={onOpenModal}><i class="fa fa-plus" aria-hidden="true"></i> Add</button>;
        }else{
            var btn = <h4 className='text-danger'>KYC compulsory for Payment Settings </h4>
            
        }

        return(
            <>
                {btn}
            </>
        )
      }

     return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-new-style">
            <div className="tab-container">
                <div className="left-sidebar" id="left-sidebar">
                    <ul className="nav flex-column"> 
                        <p> P2P </p>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange] } </Link>
                        </li>
                        <p> {p2ppage['0']['other'][langchange] } </p>
                        <li className="nav-item">
                            <a className="nav-link" href="#"> {p2ppage['0']['support'][langchange] } </a>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link active" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange] }  </Link>
                        </li> 
                    </ul>
                </div>
                <div className="right-tab">
                   
                <div className="tab-content">
                    
                    <div id="USDT" className="tab-pane active">
                    <h3>Payment Settings (Maximum: 10) </h3> 
                    <div className='p2p-pay-system'><p className=''>Select at least one payment method</p></div>

                        <div className="table-responsive d-flex justify-content-center align-items-center mt-5"> 
                        <table className="table table-borderless w-100">
                                <thead>
                                {get_method?.payment_methods?.map((val,key) => {
                                    console.log('get_method?.method?.[key]?.name')
                                    return(
                                        <tr>
                                            
                                            <th>  { get_method.payment_methods?.[key]?.method?.[0]?.name}	  </th>
                                            <th className="w-25"> 
                                                {val?.PaymentMethodFields?.map((valfield) => {
                                                    return(<>{valfield?.value + ' '}</>);
                                                })  }    
                                            </th>
                                            <th className="text-right">  <Link to=''><span className='btn bg-success '>Edit</span> / <span className='btn bg-danger'>Delete</span></Link> </th>
                                        </tr>
                                    );
                                })}
                                
                            </thead>
                            {/* <tbody>
                                <tr>
                                    <td className="name"> <p> MV </p>  Best Rate USDT <img src={madel} />  </td>
                                    <td className="text-center"> <img src={visa} />  </td>
                                    <td>  1,006.126154  </td>
                                    <td>  333 ~ 1,471.37 USD </td>
                                    <td>   1.04 USD/USDT	</td>
                                    <td className="p-0 text-right"> <button type="button" className="btn"> {p2ppage['0']['table']['submit'][langchange] } </button>   </td>
                                </tr>

                            </tbody> */}
                            <div className=' text-center mt-5'>
                            {/* <img src={noDataImg} /><br/> */}

                            {addBtn()}










                        </div>
                        </table>
                        
                           
                        <Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                            <div className='container' style={{overflow:'visible'}}>
                            <div className='row deposit-modal' style={{overflow:'visible'}}>
                                <div className='col-md-12'>
                                <h3>Payment Settings</h3>
                                <div className='p2p-pay-system'><p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p></div>

                                <div class="input-group md-form form-sm form-1 pl-0 mt-5">
                                <label>Payment Methods:</label>

                                    <div class="input-group-prepend w-100">
                                    <span class="input-group-text lighten-3" style={{background:' #dcdcdc80',border:'none'}} id="basic-text1"><i class="fas fa-search text-white"
                                        aria-hidden="true"></i></span>
                                    
                                        <input 
                                        type="text"
                                        placeholder="Type to search list" 
                                        onChange={e => setsearch(e.target.value)}
                                        onFocus={() => setdropdownVisible(true)}
                                        // onBlur={() => setdropdownVisible(false)}
                                        className='form-control my-0 py-1 w-100'
                                        value={methodName}
                                        />
                                       
                                    </div>
                                </div>
                                
                                {dropdownVisible && (
                                    <div className="autocomplete-dropdown TEXT">
                                        <ul className="autocomplete-search-results-list">
                                        {/* {results.map(result => ( */}
                                        {handleSearch()?.map((row,ii) => {
                                            return  (
                                                <>
                                                    <li className="autocomplete-search-result"  onClick={() => selectCoin(row)}>{row?.name}</li>
                                                </>
                                            );
                                        })}
                                           
                                        {/* ))} */}
                                        </ul>
                                    </div>
                                    )}
                                    <div className='mt-3'>
                                        <form onSubmit={handleSubmitForm} id='mainForm' method='post'>

                                            {feild?.template?.map((row,ii) => {
                                                return  (
                                                    <>
                                                        <div className="form-group">
                                                            <label htmlFor="Email" key={ii}>{row?.title}</label>
                                                            <input type={row?.type}  key={row?._id}  className='form-control' onChange={handleChange}  placeholder={row?.placeholder} name={row?.fieldName} required={row?.isRequired}/>
                                                        </div>  
                                                    </>
                                                );
                                            })}
                                            
                                            <div className="form-group text-center">
                                                <input type='submit' name='Add' value={'Submit'} style={{backgroundColor:'rgb(40 205 149)',padding:'5px 18px',fontSize:'18px'}}  className=' submit__btn btn text-white'  />
                                                {/* <button type="submit"  style={{backgroundColor:'rgb(40 205 149)',padding:'5px 18px',fontSize:'18px'}}  className=' submit__btn btn text-white' name="submit" >Submit</button> */}
                                            </div>
                                            
                                        </form>
                                    </div>
                                
                                </div>
                            </div>
                            </div>
                        </Modal>
                    </div>
                </div>                                        
                
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default PaymentSetting