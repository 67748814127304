import React, { useState } from "react";
import { Form } from 'semantic-ui-react';
import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { HiMail} from 'react-icons/hi'
import { BiKey } from 'react-icons/bi'
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom'
import qrimg from './../../../_assets/images/qr-code.png'
import { CountryData1 } from "../register/countrycode";
import { login, loginsentMobileOtp, loginVerify, sentMobileOtp } from "../../../redux/actions/user.action";
import { useDispatch } from "react-redux";
import { LangState } from "../../../LangugeContext";
import { loginlang, registerlang } from "../../../lang/lang";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "../../../components/toast";

const LoginForm = (props) => {
    const [ passwordType, setPasswordType ] = useState("password");
    const [country, setcountry] = useState(1)
    const [phone,setphone] = useState();
    const [password,setpassword] = useState();
    const [email,setemail] = useState();
    const [search,setsearch] = useState('');
    const [recaptcha,setrecaptcha] = useState();
    const [otp,setotp] = useState();

    const {langchange} = LangState();
    function dropdownq() {
        //  document.getElementById("withdraw-box-1").style.display = 'none';
		document.getElementById("withdraw-box-2").classList.toggle("show");
		document.getElementById("withdraw-box-1").classList.toggle("hide");
    }
    function dropdownq2() {
		document.getElementById("withdraw-box-4").classList.toggle("show");
		document.getElementById("withdraw-box-3").classList.toggle("hide");
    }
    // const handleSubmitForm = () => {
    //     handleSubmit();
    // }
    // const data3 = {
    //         phone: phone,
    //         password:password,
    //         country_code :'+'+country,
    //         register_type:'phone',
    //    }
       const data4 = {
            email: email,
            password:password,
            register_type:'email',
       }
    // const data5 = (email == '') ? {data3} :{data4} ;

    const dispatch = useDispatch();

  const handleSubmitForm1 = (values) => {
    // if(recaptcha != undefined){
        values = { ...data4, deviceType: 'WEB', deviceToken: 'WEB' };
        dispatch(login(values, props.history,dropdownq2));
        
    // }else{
    //     toast.error('Recaptcha required');

    // }
    
  }
  const data3 = {
    phone: phone,
    password:password,
    register_type:'phone',
    country_code:'+'+country,
}
  const handleSubmitForm = (values) => {
    values = { ...data3, deviceType: 'WEB', deviceToken: 'WEB' };
    dispatch(login(values, props.history, dropdownq));
  }

  const handleSubmitFormveiyfy = (values) => {
    var veifyData = {
        phone:phone,
        password:password,
        register_type:'phone',
        otp:otp,
        country_code:'+'+country,

    }
    dispatch(loginVerify(veifyData, props.history));
  }

  const handleSubmitEmailveiyfy = (values) => {
    var veifyData = {
        email:email,
        password:password,
        register_type:'email',
        otp:otp,

    }
    dispatch(loginVerify(veifyData, props.history));
  }

    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";

    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";

    }

    const passwordTypeFn = () => {
        passwordType === "password"? setPasswordType("text"):setPasswordType("password"); 
    
    }

    function loginFun(myElement) {
        const newId = myElement + 'form';
        document.getElementById(myElement).className = "form__tab__btn  line active";
        document.getElementById(newId).className = "form__tabs active";
        if(myElement != 'email'){
            document.getElementById('email').className = "form__tab__btn  line";
            document.getElementById('emailform').className = "form__tabs d-none";
        }
        if(myElement != 'phone'){
            document.getElementById('phone').className = "form__tab__btn  line";
            document.getElementById('phoneform').className = "form__tabs d-none";
        } 
        // if(myElement != 'qr'){
        //     document.getElementById('qr').className = "form__tab__btn  line";
        //     document.getElementById('qrform').className = "form__tabs d-none";
        // }
        // if(myElement != 'myElement3'){
        //     document.getElementById('myElement3').className = "form__tab__btn  line";
        //     document.getElementById('btn3').className = "order__button";
        // }
        console.log(myElement)

    }
    function onChange(value) {
        setrecaptcha(value);
      }


      const phonedata3 = {
        phone: phone,
        country_code :'+'+country,
        register_type:'phone',
    }
    const SendOTP = () => {
        console.log('phone OTP');
        dispatch(loginsentMobileOtp(phonedata3));
    }

    const emaildata = {
        email: email,
        register_type:'email',
    }
    const emialOTP = () => {
        console.log('emialOTP');
        dispatch(loginsentMobileOtp(emaildata));
    }

    return (
        <>
            
            
                        <h2 className="form__heading">Log In</h2>
                        <div className="form__tabs__wrapper">
                            <div className="form__tabs__btn">
                                <button className="form__tab__btn  line active" id="phone" onClick={() => {loginFun('phone')}}>{loginlang['0']['mobilebtn'][langchange]} </button>
                                <button className="form__tab__btn Email line" id="email" onClick={() => {loginFun('email')}}>{loginlang['0']['emailbtn'][langchange]}</button>
                                {/* <button className="form__tab__btn " id="qr" onClick={() => {loginFun('qr')}}>{loginlang['0']['qrbtn'][langchange]}</button> */}
                            </div>
                            <div className="form__tabs active" id="phoneform">
                                <div className='withdrawal-input-box-1' id="withdraw-box-1">
                                    <Form onSubmit={handleSubmitForm}>
                                        <div className="form__tabs active" id="">
                                            <div className="tab__content">
                                                <div className="form__control">
                                                    <label for="phone login__number">{loginlang['0']['mobilelable1'][langchange]}</label>
                                                    <div className="input__dropdown__wrapper">
                                                        <div className="input__dropdown login__number__input">
                                                        <div className="input__dropdown login__number__input">
                                                            <div className="form-group">

                                                                <button type="button" className="dropdown__btn" onClick={dropdown}> <span>+{country}</span>
                                                                    <svg className="down_svg__icon" width="24" height="24"
                                                                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                                        fill="currentColor">
                                                                        <path
                                                                            d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                                        </path>
                                                                    </svg>
                                                                </button>
                                                                <div className="number__dropdown " id='countryDrop'>
                                                                    <div className="search__input">
                                                                        <label for="search">
                                                                            <svg className="search_svg__icon" viewBox="0 0 1024 1024"
                                                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                                                height="24" fill="currentColor">
                                                                                <defs>
                                                                                    <style></style>
                                                                                </defs>
                                                                                <path
                                                                                    d="M719.104 688.896l134.23 134.272a21.333 21.333 0 01-30.166 30.165L688.896 719.104l30.208-30.208zm-249.77-518.23a298.667 298.667 0 110 597.334 298.667 298.667 0 010-597.333zm0 42.667a256 256 0 100 512 256 256 0 000-512z">
                                                                                </path>
                                                                            </svg>
                                                                        </label>
                                                                        <input type="text" id="search"  onChange={(e) => {setsearch(e.target.value)} }  className="search__input" />
                                                                    </div>
                                                                    <div className="select__countery">
                                                                    
                                                                    {CountryData1.filter((key) => {
                                                                        if(search == ""){
                                                                            return key;
                                                                        }else if(key.en.toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                                                            return key
                                                                        }
                                                                    }).map(key =>(

                                                                        <div className="countery"  onClick={ () => {setcountry(key.mobileCode);dropdownClose()}}>
                                                                            <div className="flag" >
                                                                                <img src={key.ico} width='30' />
                                                                                <p className="countery__code" >{key.en}</p>
                                                                            </div>
                                                                            <div className="number">+{key.mobileCode}</div>
                                                                        </div>
                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input type="number"   placeholder="phone" name="phone"
                                                                required  onChange={(e) => {setphone(e.target.value)}} />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form__control">
                                                    <label for="verification">{loginlang['0']['mobilelable2'][langchange]}</label>
                                                    <input className="password__input" type={passwordType} onChange={(e) => {setpassword(e.target.value)}} 
                                                        name="verification" required />
                                                    <div className="show__password">
                                                        <span className="text-dark" onClick={() => passwordTypeFn()}>
                                                        <AiFillEye />
                                                        </span>

                                                    </div>

                                                </div>
                                                {/* <div className="form__control">
                                                    <ReCAPTCHA
                                                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                        onChange={onChange}
                                                    />
                                                </div> */}
                                                <div className="submit__btn__wrapper">
                                                    <button type="submit" className="submit__btn">{loginlang['0']['loginbtn'][langchange]}</button>
                                                    <Link to="/forgot_password" className="not__recive forget__password">{loginlang['0']['forget'][langchange]}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Form> 
                                </div>
                                <div className='withdrawal-input-box' id="withdraw-box-2">
                                    <Form onSubmit={handleSubmitFormveiyfy}>
                                        <div className="form__tabs active" id="">
                                            <div className="tab__content">
                                                <div className="form__control">
                                                    <label for="otp">Enter your Mobile Otp</label>
                                                    <div className="email__verification">
                                                        <input className="reset__input" type="text" id="otp" placeholder="Enter your Otp"
                                                        name="otp" onChange={(e) => {setotp(e.target.value)}} required />
                                                        <a  onClick={SendOTP} className="send__code btn-focus" >{registerlang['0']['otp'][langchange]}</a>
                                                    </div>
                                                </div>
                                                <div className="submit__btn__wrapper">
                                                    <button type="submit" className="submit__btn">{loginlang['0']['loginbtn'][langchange]}</button>
                                                    <Link to="/forgot_password" className="not__recive forget__password">{loginlang['0']['forget'][langchange]}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>   
                                </div>
                            </div>
                            <div className="form__tabs " id="emailform">
                                <div className='withdrawal-input-box-1' id="withdraw-box-3">               
                                    <Form onSubmit={handleSubmitForm1}>
                                        <div className="form__tabs active" id="emailform">
                                            <div className="tab__content">
                                                <div className="form__control">
                                                    <label for="Email">{loginlang['0']['emaillable1'][langchange]}</label>
                                                    <input type="Email"   onChange={(e) => {setemail(e.target.value)}} name="Email" />
                                                </div>
                                                <div className="form__control">
                                                    <label for="verification">{loginlang['0']['mobilelable2'][langchange]}</label>
                                                    <input className="password__input" type={passwordType}  onChange={(e) => {setpassword(e.target.value)}} id="verification"
                                                        name="emial" />
                                                    <div className="show__password">
                                                    <span className="text-dark" onClick={() => passwordTypeFn()}>
                                                        <AiFillEye />
                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="form__control">
                                                    {/* <ReCAPTCHA
                                                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                        onChange={onChange}
                                                    /> */}
                                                </div>
                                                <div className="submit__btn__wrapper">
                                                    <button type="submit" className="submit__btn">{loginlang['0']['loginbtn'][langchange]}</button>
                                                    <a href="reset-password.html" className="not__recive forget__password">{loginlang['0']['forget'][langchange]}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className='withdrawal-input-box' id="withdraw-box-4">
                                    <Form onSubmit={handleSubmitEmailveiyfy}>
                                        <div className="form__tabs active" id="emailform">
                                            <div className="tab__content">
                                            <div className="form__control">
                                                        <label for="otp">Enter your Email Otp</label>
                                                        <div className="email__verification">
                                                            <input className="reset__input" type="text" id="otp" placeholder="Enter your Otp"
                                                            name="otp" onChange={(e) => {setotp(e.target.value)}} required />
                                                            <a  onClick={emialOTP} className="send__code">{registerlang['0']['otp'][langchange]}</a>
                                                        </div>
                                                    </div>
                                                <div className="submit__btn__wrapper">
                                                    <button type="submit" className="submit__btn">{loginlang['0']['loginbtn'][langchange]}</button>
                                                    <a href="reset-password.html" className="not__recive forget__password">{loginlang['0']['forget'][langchange]}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>                    
                            </div>
                            <Form >
                                <div className="form__tabs " id="qrform">
                                    <div className="tab__content">
                                        <img src={qrimg} alt="" />
                                        <div className="qr__description">
                                            <img src="assets/svg/scan.svg" alt="" />
                                            <p>
                                            {loginlang['0']['qr'][langchange]}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="submit__btn__wrapper">
                                        <Link to='/forgot_password' className="not__recive forget__password">{loginlang['0']['forget'][langchange]}</Link>
                                    </div>
                                </div>
                                
                            </Form>
                        </div>
                        
                    



        </>
    );
};

export default reduxForm({ form: 'LoginForm', enableReinitialize: true })(LoginForm);