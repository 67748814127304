import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';
import posi1 from '../../../_assets/images/img/Position_1.svg';
import posi2 from '../../../_assets/images/img/Position_2.svg';
import posi3 from '../../../_assets/images/img/Position_3.svg';
import posi4 from '../../../_assets/images/img/Position_4.svg';
import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { PROJECT_NAME } from '../../../_constants';
const RiskDisclosure = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <a href='#' className='nav-link active'>  About US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Join US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Risk Disclosure Statement</h2>
            
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container' id='Join_container'>
      <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-4'>
                <h2>AML/CFT</h2>
                
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 about-page'>
                <h6>We strive to protect our Users from fraudulent and scam activities in the crypto assets sphere. It is possible that certain crypto assets are used for scam or any other criminal activity, as defined by law. In order to prevent this, it is necessary to take measures to ensure customer verification and security of financial transactions. One of the best measures is AML/KYC procedure, which allows us to confirm that you are a law-abiding individual or corporation.</h6>
                <h6>{PROJECT_NAME} has a risk scoring system that is able to spot suspicious activity. If a transaction is flagged by the system, it is put on hold and the User is asked to pass AML & KYС verification. For the reasons of security, we are not able to disclose specific criteria of our system, however, it has proven to be very effective against money launderers, scammers etc.</h6>
                
                </div>
                <div className='col-md-6'>
                <img className='img-about-1' src={about4} width='100%' alt='RiskDisclosure/img' />
                </div>
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision'>
          <div className='col-md-12 about-page border-right'>
              <div className='about-story-text'>
                <h3 className='mb-0'>AML/CFT </h3>
                <div className='ml-3'>
                  <h6>{PROJECT_NAME} reserves the right to apply the AML/KYC procedure to certain Users, addresses and particular transactions of crypto assets. Application of the AML/KYC procedure is based on {PROJECT_NAME} internal policies and aimed at preventing and mitigating possible risks of {PROJECT_NAME} being involved in money laundering as well as any other illegal activities.</h6>
                  <h6>Please be informed that {PROJECT_NAME} reserves the right to appoint a Third-party service provider in order to fulfil AML/KYC procedure on behalf of {PROJECT_NAME}. While processing your information, such service provider completely complies with our Privacy Policy in respect of your personal information.</h6>
                  <h6>{PROJECT_NAME} can provide the information collected during AML/KYC procedure to the competent authorities upon legitimate request.</h6>
                  <h6>{PROJECT_NAME} will not enter a business arrangement with an individual or entity suspected of or directly involved in money laundering, or in which the funds have been the source of illegal activity.</h6>
                  <h6>AML and KYC Procedure</h6>
                  <h6>AML/KYC procedure might be applied in respect to those crypto assets payouts, which are determined by our automated risk prevention system as suspicious. In these cases, {PROJECT_NAME} will contact the User by email registered with the account.</h6>
                  <h6>AML/KYC procedure includes confirming the identity of Users by means of:</h6>
                  <ol style={{color:'#000'}}>
                    <li><h6>A high-quality photo of their ID (ID card, passport or driving license) valid in their country (A selfie together with proof of identity will be requested);</h6>
                    </li>
                    <li><h6>Proof of address (to confirm country of residence);</h6>
                    </li>
                    <li><h6>Any other applicable documentation (will be requested such as source of funds in case of suspected serious violations).</h6> </li>
                  </ol>
                  <h6>Upon successful completion of the AML & KYC verification, the transaction that was put on hold will be processed.</h6>
                  <h6>In response to {PROJECT_NAME}’s request for a user’s documentation, {PROJECT_NAME} takes all possible measures to recognize the deceptive documentation or other false information and reserves the right to investigate certain Users or transactions which have been detected to be risky or suspicious.</h6>
                  <h6>If there is any reasonable doubt that any information provided by User is wrong, untruthful, outdated or incomplete, {PROJECT_NAME} has the right to send User a notice to demand corrections or terminate all of the Services to the User. </h6>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 border-right mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-4'><h2>{PROJECT_NAME} Commission   Scheme for Affiliates</h2></div>
              <div className='row'>
                <div className='col-md-6'>
                  <h3 className='mb-0'>1. Direct Commission:   </h3>
                  <h6>Your   direct commission is generated by   the   completed trades from   the invitees, and
                  you will receive your commission on a weekly basis. There are two affiliate levels
                  which affect the percentage of your commission. Your initial affiliate level is 1 once
                  you have been approved to become an affiliate. 
                  Your direct commission is generated by the completed trades from the invitees, and
                  you will receive your commission on a weekly basis. There are two affiliate levels
                  which affect the percentage of your commission. Your initial affiliate level is 1 once
                  you have been approved to become an affiliate.    
                    </h6>
                </div>
                <div className='col-md-6 '>
                  <div className='w-100 d-flex align-items-center'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th rowSpan={2}>Affiliate Level</th>
                          <th rowSpan={2}> Commission Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Level 1</td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td>Level 2</td>
                          <td>45%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <h3 className='mb-0'>2. Sub-Affiliate Commission:   </h3>
                  <h6 className='mb-0'>completed trades from   the invitees, and   you   will receive your   commission on   a
                    weekly  An affiliate can   invite   a   sub-affiliate,   and   all completed trades done   by
                    the clients referred by   the   sub-affiliate will   contribute   5%   of   the
                    commission to the main affiliate. Please be advised that this 5% of commission does
                    not affect the commission scheme for the sub-affiliate (40% or 45%) and the main
                    affiliate can only receive the sub-affiliate commission while the sub-affiliate is a
                    valid affiliate.   
                  </h6>
                  <h6 className='mt-0'>
                    (E.g., if A invites B to be an affiliate, B invites C to be a trading client, then A is the
                    main affiliate, and B is a sub-affiliate, A can get a 5% commission from C’s trading
                    while B receives his 40% or 45% commission from C’s trading depends on B’s
                    affiliate level) 
                  </h6>
                </div>
                
              </div>
                  
              </div>
            </div>
            
          </div>
        </div> 
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-4'>
                <h2>Affiliate Level Adjusting Mechanism</h2>
                <p>After   becoming   an   official {PROJECT_NAME} affiliate,   you will receive   an
                  assessment every   quarter. And {PROJECT_NAME} will   adjust   your   affiliate   level
                  based on the performance of last quarter. The assessment operates as below: 
                  </p>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 about-page'>
                  <ol start="1"  >
                    <li><p>The Affiliates need to invite at least 100 new registered users every quarter,
                        of which at least 20 new users make trades, to become or maintain Level 2.</p>
                    </li>
                    <li><p>Trades refers   to   the   completion   of   at   least   one   trade on
                      the {PROJECT_NAME} platform including Spot, Margin, and Futures Trading.</p>
                    </li>
                    <li><p>If a Level 1 Affiliates can meet the criteria of (1), your level will be
                      promoted to Level 2 in the following quarter. If a Level 2 Affiliate fails
                      the criteria of (1), your level will be downgraded to Level 1 in the
                      following quarter.</p>
                    </li>
                    <li><p>Quarterly assessment starts on 1st January, 1st April, 1st July, and 1st
                      October in a calendar year.</p>
                    </li>
                  </ol>
                </div>
                <div className='col-md-6'>
                <img className='img-about-1' src={about4} width='100%' alt='RiskDisclosure/img' />
                </div>
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>   
        <div className='about_box-1'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='text-center mb-2'>
                <h2>Terms and Conditions</h2>
              </div>
              <div className='row mt-3'>
                <div className='col-md-12 about-page'>
                  <ol start="1"  >
                    <li><p>The commission is paid on a weekly basis which is distributed on the
                      following Wednesday (UTC+8).</p>
                    </li>
                    <li><p>The invitees an affiliate can invite is uncapped as well as the total
                      amount of the commission.</p>
                    </li>
                    <li><p>The transaction fees of the inviter’s sub-account are merged into the main 
                        account to calculate the commission amount.</p>
                    </li>
                    <li><p>Invite yourself to get commissions is strictly prohibited in this program. 
                      {PROJECT_NAME} will disqualify any affiliate who practices this actions and 
                      reserve right to revoke the commissions they have received.</p>
                    </li>
                    <li><p>Hijacking official traffic or jeopardizing {PROJECT_NAME} clients’ account security 
                        will lead to cancellation of the affiliate membership immediately and any 
                        commission receivables will be revoked. (Including but not limited to, using 
                        content that is highly similar to {PROJECT_NAME}'s official social media accounts,
                        website content, or website domain names for redirects, or placing 
                        {PROJECT_NAME} ads through search engines, etc.).</p>
                    </li>
                    <li><p>{PROJECT_NAME} reserves all rights of final interpretation of this program could 
                      make amendment without prior notification. </p>
                    </li>
                  </ol>
                </div>
                
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0  First_row vision '>
          <div className='col-md-12 mt-3'>
              <div className='about-story-text'>
              <div className='row mt-3'>
                <div className='col-md-12 about-page '>
                  <div className='border p-3'>
                    <div className='text-center mb-3'><h2>Additional Notes</h2></div>
                    <ol start="1"  >
                      <li><p>Allformer events orprograms will upgrade automatically to this plan.</p>
                      </li>
                      <li><p>If you have any questions or concerns, please don’t hesitate to <br/>
                          contact@{PROJECT_NAME}.com, or view the help center.
                          <br/>The {PROJECT_NAME} Team</p>
                      </li>
                      
                    </ol>
                    <p className='pl-4'>You’ll be immediately disqualified when asking for payment in exchange for
                        vulnerability details. Please make sure that any report submitted includes a clear
                        and   detailed   description   of   the   vulnerability.   Please   also   include   detailed
                        instructions on how to reproduce your findings.
                      </p>
                  </div>
                </div>
                
              </div>
                  
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default RiskDisclosure