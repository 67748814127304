import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from '../../../components/toast';
import { assetsnav, bonusAccountpage } from '../../../lang/lang';
import { LangState } from '../../../LangugeContext';
import { view_Referral } from '../../../redux/actions/p2p.action';
import box1 from '../../../_assets/images/img/ref-box1.svg';
import box2 from '../../../_assets/images/img/ref-box2.svg';
import box3 from '../../../_assets/images/img/ref-box3.svg';

import { PROJECT_NAME } from '../../../_constants';
const UseStyles = makeStyles({
    assetsBtn: {
        marginTop: '80px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
        },

    },
    btnUl: {
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag: {
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
    },

});
const Referral = () => {
    const classes = UseStyles();
    const { langchange } = LangState();
    const dispatch = useDispatch()
    const referral = useSelector(state => state.p2p.referral);

    // console.log('referral',referral.base_url);
    useEffect(() => {
        dispatch(view_Referral())
    },[]);
    const copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText =  referral?.base_url+'#/register/'+referral?.user?.username;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast.success('Referral Link copied');
    }
    const copyToClipboardId = () => {
        var textField = document.createElement('textarea')
        textField.innerText =  referral?.user?.username;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast.success('Referral Code copied');
    }
    return (
        <div>
            {/* <div className={classes.assetsBtn}>
                <div className={classes.btnDesign}>
                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/assets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Assets'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/mainAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Main'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/tradeAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Trading'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/marginAccount' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Margin'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                    </ul>

                    <ul className={classes.btnUl}>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/swap-currency' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['swap'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/depositassest' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/withdrawAssets' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Withdraw'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/record' style={{ color: 'rgba(0, 20, 42, 0.6)' }}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                        <button className={classes.btnTag}>
                            <span className={classes.MuiButtonlabel}>
                                <li>
                                    <Link to='/vendor/bonus' style={{ color: 'rgb(40 205 149)' }}> {assetsnav['0']['Bonus'][langchange]}</Link>
                                </li>
                            </span>
                            <span className={classes.MuiTouchRippleroot}></span>
                        </button>
                    </ul>
                </div>
            </div> */}
            <div className="box___3B-ao" style={{ background: '#fff', marginTop: '60px' }}>
                <div className="wrapper___1F-PJ referral ">
                    <div className='row section'>
                        <div className='col-md-12'>
                            <h3>       
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                               <span> Return <div> | </div> Referral Bonus </span>
                            </h3>
                        </div>
                       
                    </div>
                    <div className='section-2'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <h3 className='p-0'>Total Invitees</h3>
                                <p>{referral?.user?.referrals?.length ? referral?.user?.referrals?.length : '0'} Person</p>
                            </div>
                            <div className='col-md-3'>
                                <h3>Referral Code <i class="fa fa-question-circle" aria-hidden="true"></i></h3>
                                <p>{referral?.user?.username} <i class="fa fa-clone" aria-hidden="true" onClick={copyToClipboardId}></i></p>
                            </div>
                            <div className='col-md-6'>
                                <h3>Referral Link <i class="fa fa-question-circle" aria-hidden="true"></i></h3>
                                <p>{referral?.base_url+'#/register/'+referral?.user?.username} <i class="fa fa-clone" aria-hidden="true"  onClick={copyToClipboard}></i> </p>
                            </div>

                        </div>
                    </div>
                    <div className='section-3'>
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <h2>Invite New Users to Earn Rewards</h2>
                                <p>Up to 1 STA in rewards!</p>
                                <button className='btn btn-md pl-3 py-1 btn-primary'>Join on App</button>
                            </div>
                            <div className='col-md-12'>
                                <div className='main-box'> 
                                    <div className='box border-right'>
                                        <img src={box1} />
                                        <p>More bonuses</p>
                                    </div>
                                    <div className='box border-right'>
                                        <img src={box2} />
                                        <p>More fun</p>
                                    </div>
                                    <div className='box'>
                                        <img src={box3} />
                                        <p>More excitement</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mining-sec-3 table-responsive-xl">
                        <h3>Referrals</h3>
                        <table class="table table-borderless text-center">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">User</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Refferal bonus</th>
                                <th scope="col">Trading Income </th>

                                {/* <th scope="col">createdAt</th> */}
                            </tr>
                            </thead>
                            <tbody>
                                {referral?.refferals?.map((val,key) => {
                                    return(
                                        <tr>
                                            <td>{key+1}</td>
                                            <td>{val.username}</td>
                                            <td className='w-25'>{val.email}</td>
                                            <td>{val.phone}</td>
                                            <td className={val?.kycStatus == 'APPROVED' ? 'text-success':'text-danger'}>{val?.kycStatus == 'APPROVED' ? <i class="fas fa-check-circle"></i>:<i class="far fa-times-circle"></i>  }</td>
                                            <td className='text-danger'><i class="far fa-times-circle"></i></td>

                                        </tr>
                                    )
                                }) }
                            </tbody>
                        </table>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Referral