import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const Contract = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content about-page'>
            <h2>  Contract (Futures) </h2>
            <h6>Trade in Crypto Futures Today</h6>
            <h6>Maximise your trading potential with leverage up to 15X with crypto futures.</h6>
           </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container '>
       
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row'>
            <div className='col-md-6 border p-5'>
              <div className='about-story-text'>
                <h3 className='mb-0'>LIQUIDITY OPTIONS</h3>
                <h6 className='mb-3'>
                Experience trading with various liquidity options from the world’s leading crypto future exchanges wherein users get profitable arbitrage opportunities</h6>                  
                  <h3 className='mb-0'>QUICK ORDER MATCHING</h3>
                  <h6>
                  We provide a fast trade matching engine that helps traders get their orders through in real-time even during adverse market conditions.                   </h6>
              </div>
            </div>
            <div className='col-md-6 border p-5'>
              <div className='about-story-text'>
                <h3 className='mb-0'>RISK MITIGATION</h3>
                <h6 className='mb-3'>
                Risk control mechanisms that provide a robust trading environment and insurance coverage to safeguard traders’ funds.
                </h6>
                <h3 className='mb-0'>WIDE ARRAY OF CONTRACTS</h3>
                  <h6>
                  Variety of contracts to choose from – Vanilla, Inverse, and Perpetuals. Traders can customize a portfolio that suits their needs.                  </h6>
              </div>
            </div>
            <div className='col-md-12 border p-5'>
              <div className='about-story-text text-center'>
              <h3 className='mb-0'>Cash Fudx</h3>
                <p>That why {PROJECT_NAME} is Best Crypto Exchange in
                  India. So, start investing in digital currency. Shiba
                  INU is good in each and every way. Crypto Prices
                  are really getting higher in market. Enter in Web
                  3.0Crypto, to trade with ease.</p>
               </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Contract