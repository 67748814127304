import React, { createContext, useContext, useEffect, useState } from "react";

const Crypto = createContext();

const CryptoContext = ({ children }) => {
  const [currency, setCurrency] = useState("USD");
  const [symbol, setSymbol] = useState("$");
  const [currencyId, setCurrencyId] = useState('' );
  const [checkTRXPass, setcheckTRXPass] = useState(false);
  const [livePrice, setlivePrice] = useState(false);
  const [pairName, setpairName] = useState(false);
  const [cancelOrder, setcancelOrder] = useState(false);

  useEffect(() => {
    if (currency === "INR") setSymbol("₹");
    else if (currency === "USD") setSymbol("$");
    else if (currency === "EUR") setSymbol("€");
  }, [currency]);

  return (
    <Crypto.Provider value={{ currency, setCurrency, symbol,currencyId, setCurrencyId,checkTRXPass, setcheckTRXPass ,livePrice, setlivePrice,pairName, setpairName,cancelOrder, setcancelOrder}}>
      {children}
    </Crypto.Provider>
  );
};

export default CryptoContext;

export const CryptoState = () => {
  return useContext(Crypto);
};