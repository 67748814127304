import logoImg from '../_assets/logo/logo.png'
export const API_HOST = process.env.REACT_APP_API_URL;
export const HOME_ROUTE = process.env.REACT_APP_HOME_ROUTE;
export const IMAGE_BASE_URL = '';
export const PAGE_LIMIT = 10;
export const AVAILIBILITY = [
    { key: true, title: 'Available' },
    { key: false, title: 'Not Available' }
]

export const QUANTITY = () => {
    let arr = [];

    for (let i = 1; i <= 20; i++) {
       arr.push({ key: i, title: i }); 
    }

    return arr;
}

export const CALLSLOTS = () => {
    let arr = [];
    let slots = 4;
    let totalMins = 60;

    for (let i = 0; i < 12; i++) {
        let slotTime = 0;
        arr.push({ 
            key: `${i < 10 ? `${i}` : i}:${totalMins - 1}`, 
            title: `${i < 10 ? `0${i}` : i}:00` 
        }); 

        for (let j = 0; j < 3; j++) {
            slotTime += totalMins / slots;
            arr.push({ 
                key: `${i < 10 ? `${i}` : i}:${slotTime}`, 
                title: `${i < 10 ? `0${i}` : i}:${slotTime}` 
            }); 
        }
    }

    for (let i = 12; i < 24; i++) {
        let slotTime = 0;
        arr.push({ 
            key: `${i < 10 ? `${i}` : i}:${totalMins - 1}`, 
            title: `${i < 10 ? `0${i}` : i}:00` 
        }); 
    
        for (let j = 0; j < 3; j++) {
            slotTime += totalMins / slots;
            arr.push({ 
                key: `${i < 10 ? `${i}` : i}:${slotTime}`, 
                title: `${i < 10 ? `0${i}` : i}:${slotTime}` 
            }); 
        }
    }

    return arr;
}

export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const PROJECT_NAME = 'JS Tech';
export const TOKEN_PROJECT_NAME = 'JS';
export const APP_URL = 'https://exchange.jstechservices.us/';
export const currency = '$';
export const logo = logoImg;
// export const HELLO_WORLD = ['Hello World' , 'नमस्ते दुनिया'];