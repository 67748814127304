import React ,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CountryData1 } from '../../public/register/countrycode';
import { kycpage ,registerlang} from '../../../lang/lang';
import { trading_otp, getUserDetails, update_trading_password } from '../../../redux/actions/dashboard.action';
import { LangState } from '../../../LangugeContext';
import { PROJECT_NAME } from '../../../_constants';
const ChangeTrading = ({history}) => {
    const { id } = useParams();
// console.log('ifff',id);
    const {langchange} = LangState();
    const [ activeclass, setactiveclass ] = useState('icon' );
    const [email,setemail] = useState();
    const [country, setcountry] = useState(91)
    const [search,setsearch] = useState('');
    const [phone,setphone] = useState(1);
    const [otp,setotp] = useState();
    const [password,setpassword] = useState();
    const handleSubmotForm = () => {
        // handleSubmit();
    }
    const user = useSelector(state => state.dashboard.user);
    console.log('user',user);
    const dispatch = useDispatch();
    const handleSubmitForm1 = () => {
        var data = {
            user_id:email,
            otp:otp,
            user_type:'email',
            trading_password:password,
        }
        dispatch(update_trading_password(data,history));
        // console.log('hiii');
        
        // handleSubmit();
    }
    const handleSubmitForm = () => {
        var data = {
            user_id:phone,
            user_type:'phone',
            otp:otp,
            trading_password:password,
        }
        dispatch(update_trading_password(data,history));
        // console.log('hiii');
        
        // handleSubmit();
    }
    
    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";
    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";     
    }
    const SendOTP = () => {
        console.log('phone OTP');
        var data ={
            phone:phone,
            country_code:'+'+country,
            user_type:'phone',
        }
        // console.log('phone OTP',data);

        dispatch(trading_otp(data,history));
    }
    const emialOTP = () => {
        console.log('emialOTP');
        var data = {
            email:email,
            user_type:'email',
        }
        dispatch(trading_otp(data,history));
    }

    useEffect(() => {
        dispatch(getUserDetails())        

    },[]);


    const phoneForm = () => {
        return(
            <Form onSubmit={handleSubmitForm} >
                <div className="form__tabs active" id="phoneform">
                    <div className="tab__content">
                        

                        <div className="form__control">
                            <label for="phone login__number">{registerlang['0']['mobilebtn'][langchange]}</label>
                            <div className="input__dropdown__wrapper">
                                <div className="input__dropdown new-input-verify-data login__number__input d-flex align-items-center">
                                    <div className="form-group  d-flex align-items-center mb-0">

                                        <button type="button" className="dropdown__btn text-nowrap " onClick={dropdown}> <span>+{country}</span>
                                            <svg className="down_svg__icon" width="24" height="24"
                                                viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor">
                                                <path
                                                    d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                </path>
                                            </svg>
                                        </button>
                                        <div className="number__dropdown " id='countryDrop'>
                                            <div className="search__input">
                                                <label for="search">
                                                    <svg className="search_svg__icon" viewBox="0 0 1024 1024"
                                                        xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" fill="currentColor">
                                                        <defs>
                                                            <style></style>
                                                        </defs>
                                                        <path
                                                            d="M719.104 688.896l134.23 134.272a21.333 21.333 0 01-30.166 30.165L688.896 719.104l30.208-30.208zm-249.77-518.23a298.667 298.667 0 110 597.334 298.667 298.667 0 010-597.333zm0 42.667a256 256 0 100 512 256 256 0 000-512z">
                                                        </path>
                                                    </svg>
                                                </label>
                                                <input type="text" id="search"  onChange={(e) => {setsearch(e.target.value)} }  className="search__input" />
                                            </div>
                                            <div className="select__countery">
                                            
                                            {CountryData1.filter((key) => {
                                                if(search == ""){
                                                    return key;
                                                }else if(key.en.toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                                    return key
                                                }
                                            }).map(key =>(

                                                <div className="countery"  onClick={ () => {setcountry(key.mobileCode);dropdownClose()}}>
                                                    <div className="flag" >
                                                        <img src={key.ico} width='30' />
                                                        <p className="countery__code" >{key.en}</p>
                                                    </div>
                                                    <div className="number">+{key.mobileCode}</div>
                                                </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                    <input type="number"  placeholder="phone" className='w-100%'   onChange={(e) => {setphone(e.target.value)}}
                                        required  />
                                    <a  className="send__code code_send_button" onClick={SendOTP}>{registerlang['0']['otp'][langchange]}</a>
                                </div>
                            </div>
                        </div>
                    
                        
                        <div className="form__control ">
                        <label for="Email">OTP</label>
                        <div className="email__verification new-input-verify-data">
                            <input className="reset__input" type="number" id="otp" placeholder="Enter OTP"
                            name="Email" onChange={(e) => {setotp(e.target.value)}} required />
                        </div>
                    </div>
                    <div className="form__control ">
                        <label for="Password">Password</label>
                        <div className="email__verification new-input-verify-data">
                            <input className="reset__input" type="text" id="Password" placeholder="Password"
                            name="Password" onChange={(e) => {setpassword(e.target.value)}} required />
                        </div>
                    </div>
                    <div className="form__control ">
                        <div className="text-right">
                            <input className="reset__input btn" type="submit" style={{background:'#24ae8f',padding:'10px 20px',marginTop:'10px'}}  />
                        </div>
                    </div> 
                        
                    </div>
                
                </div>
            </Form>
        );
    }

    const emailForm = () => {
        return(
            <Form onSubmit={handleSubmitForm1} >
            <div className="form__tabs active" id="emailform">
                <div className="tab__content">
                    <div className="form__control ">
                        <label for="Email">{registerlang['0']['emailbtn'][langchange]}</label>
                        <div className="email__verification new-input-verify-data">
                            <input className="reset__input" type="Email" id="Email" placeholder="Enter your Email"
                            name="Email" onChange={(e) => {setemail(e.target.value)}} required />
                            <a  onClick={emialOTP} className="send__code code_send_button">{registerlang['0']['otp'][langchange]}</a>
                        </div>
                    </div>
                    <div className="form__control ">
                        <label for="Email">OTP</label>
                        <div className="email__verification new-input-verify-data">
                            <input className="reset__input" type="number" id="otp" placeholder="Enter OTP"
                            name="Email" onChange={(e) => {setotp(e.target.value)}} required />
                        </div>
                    </div>
                    <div className="form__control ">
                        <label for="Email">Password</label>
                        <div className="email__verification new-input-verify-data">
                            <input className="reset__input" type="text" id="Email" placeholder="Enter your Password"
                            name="Email" onChange={(e) => {setpassword(e.target.value)}} required />
                        </div>
                    </div>
                    <div className="form__control ">
                        <div className="text-right">
                            <input className="reset__input btn" type="submit" style={{background:'#24ae8f',padding:'10px 20px',marginTop:'10px'}}  />
                        </div>
                    </div>       
                    
                

                </div>
        
            
            </div>
            
            </Form>
        );
    }
  return (
    <div>
        <div className="header-2">
       <div className="container-s">
           <ul className="nav">
               <li className="nav-item">
                   <Link className="nav-link " to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
               </li>

               <li className="nav-item">
                   <Link className="nav-link active" to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
               </li>
               <li className="nav-item">
                   <Link className="nav-link " to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
               </li>
               <li className="nav-item">
                   <a className="nav-link" href="#"> {kycpage['0']['nav']['btn4'][langchange]}   </a>
               </li>
               <li className="nav-item">
                   <a className="nav-link" href="#"> {kycpage['0']['nav']['btn5'][langchange]}  </a>
               </li>
               <li className="nav-item">
                   <a className="nav-link" href="#">  {kycpage['0']['nav']['btn6'][langchange]}  </a>
               </li>
           </ul>
       </div>
   </div>
     <div className='container  mt-5'>
        <div className='trading-auto'>
            <div className='text-center'>
                <h1>Security Verification</h1>
                <div className='info'>
                    <p >To ensure your account security, the withdrawal services on {PROJECT_NAME} and Futures will be 
                        temporarily closed for 24 hours after the setting is approved.</p>
                </div>
            </div>
       
        </div>
        <div className='trading-auto'>

            `<div className='row '>
                <div className='trading-auto col-md-5'>
               
                <div className="form__tabs__wrapper">
                            {id == 'phone' ? phoneForm() : ' '}

                            {id == 'email' ? emailForm() : ' ' }

                          
                        </div>
                </div>
            </div>`
        </div>
    </div>
    </div>
  )
}

export default ChangeTrading