import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const MarketProgram = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  MarketProgram US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li> */}
            {/* <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li> */}

          </ul>
        </div>
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Market Makers Incentive Program</h2>
            <p>  Market-making on {PROJECT_NAME} is an advantageous offer for those who enjoy ruling the markets and know the mechanics of exchange.</p>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
        <div class="about_story">
          <div className='row m-0 align-items-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner_story_top'>
                <img className='img-about-1' src={Ab} width='100%' alt='MarketProgram/img' />
                <img src={abcd} width='100%' alt='MarketProgram/img' />
              </div>
              <div className='banner_story_top-2'>
                <img className='img-about-1' src={abc} width='100%' alt='MarketProgram/img' />
                <img src={abcde} width='100%' />
              </div>
            </div>
            <div className='col-lg-7 col-md-6'>
              <div className='about-story-text'>
                <h6>
                Market-making on {PROJECT_NAME} is an advantageous offer for those who enjoy ruling the markets and know the mechanics of exchange.
                We are now offering monthly market-maker contracts with attractive conditions: cash incentives and rebate bonuses. Moreover, we provide the best APIs to our market makers. 
                Contracts listed in the table below are valid for BTC, BCH, USDT, ETH, DASH, XRP and other coins. We add a new coin to the exchange if two individuals take MM contracts to support this coin on our exchange. A contract is valid only for one pair, and a market maker is eligible to take only one contract of each category.
                We monitor all market-making activity and review the existing contracts every month. To help our market makers build the most efficient trading strategy, we provide them with detailed daily performance reports

                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0  First_row'>
            <div className='col-md-6'>
              <div className='about-story-text'>
              <div><h3>How do MM Contracts Work?</h3></div>

                <h6>
                The mathematics behind fulfilling an MM contract is quite simple. Let us take the example of an XS contract.
The contract stipulates that you will hold a volume of 1 BTC on the buy side, a volume of 1 BTC on the sell side,
and a spread of 0.3%.
First, we will take a snapshot of your standing orders at 00:00:00.000 UTC, which we consider the starting point of your daily MM contract, and we are assuming your standing orders had the following distribution (this is just an example):  
                </h6>
                <h6><span className='h4'><b>Details: </b></span>If a limit order is executed immediately, then such order will be considered a market order and will be charged the standard 0.1% market order execution fee. Rebates are valid for all limit orders placed while acting within a contract.</h6>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about1} width='100%' alt='MarketProgram/img' />
            </div>
            <div className='row'>
              <div className='col-md-12 about-story-text'>
                <h6><span className='h4 '><b>NOTE</b></span>
                In order to receive the Rebate Bonus, a market maker must stay in the spread for at least 80% of one (1) trading day (00:00:00.000 UTC - 23:59:59.999 UTC). To receive the cash incentive, it is required to stay in the spread 90% of a one (1) month period (grace period: 48 hours of downtime).
                The contracts are such that upon fulfilling a condition, your benefits from rebates and cash incentives are cumulative. This implies that you can receive a maximum rebate of 0.1% and 21,000 USDT per month if your market-making activity is successful.  <br />
                Rebate bonuses earned during a trading day are applied on the next day. The benefit is paid-out upon the contact's successful completion and only if the size, spread and time conditions of the contract are satisfied.

                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className='about_box-1'>
          <div className='row m-0 align-items-center'>
            <div className='col-md-6'>
              <img className='img-about-1' src={about2} width='100%' alt='spotlight/img' />
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h3 className='mb-1'>Calculating the Spread condition</h3>
                <h6>
                Your spread is defined by the following formula:
                  In the case of the order book shown above, we would have the spread equal to
                  Therefore, this order book fails to meet the market-making conditions.
                  Every time you place a new order, the existing order is changed or removed, and the MM conditions are recalculated.
                </h6>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default MarketProgram