import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'

import VerifyAccountForm from "./verifyAccountForm";
import logo from "../../../_assets/images/js_logo.png";
import { verifyEmail } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";
import { PROJECT_NAME } from "../../../_constants";
import "../../../_assets/css/login.css";
import { Link } from "react-router-dom";
import { LangState } from "../../../LangugeContext";
import { loginlang } from "../../../lang/lang";
const VerifyAccount = ({ history }) => {
  const dispatch = useDispatch();
  const [email,setemail] = useState();

  const handleSubmit = (values) => {
        var data = {

        }
    //  let data = {email : email , otp :values.otp};
    //  values = { ...values, deviceType: 'WEB', deviceToken: 'WEB' };
  }
  const {langchange} = LangState();

  return (
    <>
      <main>
          <section className="reset__password__sec">
              <div className="sec__content">
                  <div className="sec__text">
                      <div className="sec__logo">
                      <Link to='/'>  
                      
                          <img src={logo} width='60' height='60' alt="logo" />
                          </Link>
                      </div>
                      <div className="sec__text__inner">
                          <h2 className="sec__heading">{loginlang['0']['h1'][langchange]}</h2>
                          <p className="sec__brief">{loginlang['0']['p'][langchange]}</p>
                      </div>
                  </div>
                  <div className="sec__form">
                      <div className="upper__text">
                          <span>{loginlang['0']['link'][langchange]} <Link to="/register">{loginlang['0']['linkbtn'][langchange]}</Link></span>
                        
                      </div>
                      <VerifyAccountForm history={history} />

                  </div>
              </div>
          </section>
      </main> 
                   
    </>
  );
};

export default VerifyAccount;