import React, { useState , useEffect } from "react";
import { Form } from 'semantic-ui-react';
import { useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { HiMail} from 'react-icons/hi'
import { BiKey } from 'react-icons/bi'
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom'
import { forgetOtp, forgotPasswordFn, resentOtp } from "../../../redux/actions/user.action";
import { LangState } from "../../../LangugeContext";
import { registerlang } from "../../../lang/lang";
import { CountryData1 } from "../register/countrycode";

const VerifyAccountForm = ({ history }) => {
    const dispatch = useDispatch();
    const [ passwordType, setPasswordType ] = useState("password");

    const [ userEmail, setuserEmail ] = useState();
  
    const resendOtp = () => {
        dispatch(resentOtp(userEmail));
    } 

    const {langchange} = LangState();
    const [ activeclass, setactiveclass ] = useState('icon' );
    const [email,setemail] = useState();
    const [country, setcountry] = useState(91)
    const [search,setsearch] = useState('');
    const [phone,setphone] = useState(1);
    const [password,setpassword] = useState();

    const [otp,setotp] = useState();
    const handleSubmitForm = (values) => {
        var data = {
            email:email,
            otp:otp,
            password:password,
            user_type:'email',
        }
        dispatch(forgotPasswordFn(data, history));
    }
    const handleSubmitForm1 = () => {
        var data = {
            phone:phone,
            otp:otp,
            password:password,
            country_code:'+'+country,
            user_type:'phone',
        }
        dispatch(forgotPasswordFn(data, history));
    }

    const SendOTP = () => {
        console.log('phone OTP');
        var data ={
            phone:phone,
            country_code:'+'+country,
            user_type:'phone',
        }
        dispatch(forgetOtp(data,history));
    }
    const emialOTP = () => {
        console.log('emialOTP');
        var data = {
            email:email,
            user_type:'email',
        }
        dispatch(forgetOtp(data,history));
    }
    const passwordTypeFn = () => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    }
    function loginFun(myElement) {
        const newId = myElement + 'form';
        document.getElementById(myElement).className = "form__tab__btn  line active";
        document.getElementById(newId).className = "form__tabs active";
        if(myElement != 'email'){
            document.getElementById('email').className = "form__tab__btn  line";
            document.getElementById('emailform').className = "form__tabs d-none";
        }
        if(myElement != 'phone'){
            document.getElementById('phone').className = "form__tab__btn  line";
            document.getElementById('phoneform').className = "form__tabs d-none";
        } 
       
    }
    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";
    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";     
    }
    


    return (
        <>
                    <div className="m-3 text-center">
                        <h2 className="form__heading text-dark">Verify Account</h2>
                    </div>
                    <div className="form__tabs__wrapper">
                        <div className="form__tabs__btn">
                            <button className="form__tab__btn active line" id="phone"  onClick={() => {loginFun('phone')}}>{registerlang['0']['mobilebtn'][langchange]} </button>
                            <button className="form__tab__btn Email " id="email" onClick={() => {loginFun('email')}}>{registerlang['0']['emailbtn'][langchange]}</button>
                        </div>
                        <Form onSubmit={handleSubmitForm1}>
                            <div className="form__tabs active" id="phoneform">
                                <div className="tab__content">
                                    

                                    <div className="form__control">
                                        <label for="phone login__number">{registerlang['0']['mobilebtn'][langchange]}</label>
                                        <div className="input__dropdown__wrapper">
                                            <div className="input__dropdown login__number__input">
                                                <div className="form-group">

                                                    <button type="button" className="dropdown__btn" onClick={dropdown}> <span>+{country}</span>
                                                        <svg className="down_svg__icon" width="24" height="24"
                                                            viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                            fill="currentColor">
                                                            <path
                                                                d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                            </path>
                                                        </svg>
                                                    </button>
                                                    <div className="number__dropdown " id='countryDrop'>
                                                        <div className="search__input">
                                                            <label for="search">
                                                                <svg className="search_svg__icon" viewBox="0 0 1024 1024"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" fill="currentColor">
                                                                    <defs>
                                                                        <style></style>
                                                                    </defs>
                                                                    <path
                                                                        d="M719.104 688.896l134.23 134.272a21.333 21.333 0 01-30.166 30.165L688.896 719.104l30.208-30.208zm-249.77-518.23a298.667 298.667 0 110 597.334 298.667 298.667 0 010-597.333zm0 42.667a256 256 0 100 512 256 256 0 000-512z">
                                                                    </path>
                                                                </svg>
                                                            </label>
                                                            <input type="text" id="search"  onChange={(e) => {setsearch(e.target.value)} }  className="search__input" />
                                                        </div>
                                                        <div className="select__countery">
                                                        
                                                        {CountryData1.filter((key) => {
                                                            if(search == ""){
                                                                return key;
                                                            }else if(key.en.toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                                                return key
                                                            }
                                                        }).map(key =>(

                                                            <div className="countery"  onClick={ () => {setcountry(key.mobileCode);dropdownClose()}}>
                                                                <div className="flag" >
                                                                    <img src={key.ico} width='30' />
                                                                    <p className="countery__code" >{key.en}</p>
                                                                </div>
                                                                <div className="number">+{key.mobileCode}</div>
                                                            </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="number"  placeholder="phone"   onChange={(e) => {setphone(e.target.value)}}
                                                    required  />
                                            </div>
                                            <a  className="send__code" onClick={SendOTP}>{registerlang['0']['otp'][langchange]}</a>
                                        </div>
                                    </div>
                                    <div className="form__control">
                                        <label for="verification">{registerlang['0']['mobilelable1'][langchange]}</label>
                                        <input type="password" id="verification" name="verification" onChange={(e) => {setotp(e.target.value)}}  required />
                                    </div>
                                    <div className="form__control">
                                        <label for="verificationa">{registerlang['0']['mobilelable2'][langchange]}</label>
                                        <input className="password__input" type={passwordType} onChange={(e) => {setpassword(e.target.value)}}  id="verificationa"
                                            name="verification" required="" />

                                        <div className="show__password">
                                            <span>
                                            <div className="pass__icon text-dark" onClick={passwordTypeFn}><AiFillEye /></div>

                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="form__control">
                                        {/* <div className="optional__input">
                                            <label for="verificationdsfs">Referral Code (Optional)</label>
                                            <span><svg className="down_svg__icon" width="24" height="24"
                                                    viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor">
                                                    <path fill="rgba(0, 0, 0, 0.54)"
                                                        d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                    </path>
                                                </svg></span>
                                        </div> */}
                                        {/* <label for="verificationdsfs">Referral Code </label>

                                        <input type="text" id="verificationdsfs" name="verification" required="" onChange={(e) => {setreferral(e.target.value)}}  /> */}
                                    </div>
                                    
                                </div>
                                <div className="submit__btn__wrapper sign__up__btn">
                        <button type="submit" className="submit__btn">Submit</button>
                        {/* <button className="receive__sms" href="reset-password.html"
                            className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button> */}
                    </div>
                            </div>
                        </Form>
                        <Form onSubmit={handleSubmitForm}>
                        <div className="form__tabs " id="emailform">
                            <div className="tab__content">
                                <div className="form__control">
                                    <label for="Email">{registerlang['0']['emailbtn'][langchange]}</label>
                                    <div className="email__verification">
                                        <input className="reset__input" type="Email" id="Email" placeholder="Email"
                                        name="Email" onChange={(e) => {setemail(e.target.value)}} required />
                                        <a  onClick={emialOTP} className="send__code">{registerlang['0']['otp'][langchange]}</a>
                                    </div>
                                </div>
                                <div className="form__control">
                                    <label for="verification">{registerlang['0']['emaillable1'][langchange]}</label>
                                    <div className="email__verification">
                                        <input type="password" id="verification" name="verification" onChange={(e) => {setotp(e.target.value)}} required />
                                        
                                    </div>
                                </div>
                                <div className="form__control">
                                    <label for="verificationa">{registerlang['0']['mobilelable2'][langchange]}</label>
                                    <input className="password__input" type={passwordType} onChange={(e) => {setpassword(e.target.value)}}  id="verificationa"
                                        name="verification" required="" />

                                    <div className="show__password">
                                        <span>
                                        <div className="pass__icon text-dark" onClick={passwordTypeFn}><AiFillEye /></div>

                                        </span>
                                    </div>
                                </div>
                                
                                <div className="form__control">
                                    {/* <div className="optional__input">
                                        <label for="verificationdsfs">Referral Code (Optional)</label>
                                        <span><svg className="down_svg__icon" width="24" height="24"
                                                viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor">
                                                <path fill="rgba(0, 0, 0, 0.54)"
                                                    d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                </path>
                                            </svg></span>
                                    </div> */}
                                    {/* <label for="verificationdsfs">Referral Code </label>

                                    <input type="text"  required="" onChange={(e) => {setreferral(e.target.value)}}  /> */}
                                </div>
                                

                            </div>
                        
                            <div className="submit__btn__wrapper sign__up__btn">
                        <button type="submit" className="submit__btn">Submit</button>
                        {/* <button className="receive__sms" href="reset-password.html"
                            className="not__recive forget__password">{registerlang['0']['otp2'][langchange]}</button> */}
                    </div>
                        
                        </div>
                        
                        </Form>
                    </div>



            {/* <Form onSubmit={handleSubmitForm}>
                <div className="panel-body">
                    <div className="details password-form">
                        <div className="form-group">
                            <div className="label-area">
                                <label>Email:</label>
                            </div>
                            <div className="row-holder">
                                <div className="">
                                    <input type="email" type="email" name="email" value={userEmail} onChange={e => setuserEmail(e.target.value)} className="form-control" placeholder="Email" required/>
                                    Click <span type="button" onClick={resendOtp}> Here </span> Resend OTP
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="label-area">
                                <label>OTP:</label>
                            </div>
                            <div className="row-holder">
                            <div className="pass-eye-fields">
                                <Field component={InputField} type="number" name="otp" className="form-control" placeholder="OTP" validate={[required()]} />
                            </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button className="sbmit-btn"> Verify </button>
                        </div>
                       
                        <div className="form-group text-center mb-4">
                            <Link to="/" className="btn btn-primary d-inline w-100 rounded border-0">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </Form> */}
        </>
    );
};

export default reduxForm({ form: 'VerifyAccountForm', enableReinitialize: true })(VerifyAccountForm);