import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { createTicket, getUserDetails } from '../../../redux/actions/dashboard.action'
import { CountryData1 } from '../../public/register/countrycode';
const CreateTicket = ({history}) => {
    const user = useSelector(state => state.dashboard.user);
    const [title,Settitle] =  useState();
    const [message,Setmessage] =  useState();
    const dispatch =  useDispatch();
    const submitData = {
        title: title,
        message:message,
        email:user?.email
    }
    console.log('user?.email',submitData);
    const handleSubmitForm1 = (e) => {
        e.preventDefault();
        dispatch(createTicket(submitData,history));
        document.getElementById("form").reset();

        Setmessage(' ');
        Settitle(' ')
    }

    useEffect(() => {
        dispatch(getUserDetails())
    }, []);
   

    return (
    <div>
        <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/CreateTicket">  Create Ticket </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/Ticket"> View Ticket   </Link>
                    </li>
                   
                </ul>
            </div>
        </div>
        
        <div className="verification-2">
            <div className="container-xl">
                <div className="links-name">
                    <p> Ticket</p>
                    <p> <a href="#">/ Create Ticket </a> </p>
                </div> 
                {/* <div className="alert alert-success">
                    <i className='fas fa-info-circle'></i>
                    <div className="">
                        <h5>  Supplement Individual Information </h5>
                        <p>  To strengthen the security of your account, please supplement the information.</p>
                    </div>
                </div> */}
                <div className="form-box">
                    <form onSubmit={handleSubmitForm1} id='form'>
                        <div className="row">
                          
                            <div className="col-md-12">
                                <label for="fname"> Title </label>
                                <input type="text" className="form-control kyc-inputType"  value={title} name="f-name" onChange={(e) => {Settitle(e.target.value)}} required/>
                            </div>
                          
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="id-sel">Message</label><br />
                                    <textarea name="message" className="form-control kyc-inputType" rows="4" cols="50" value={message} onChange={(e) => {Setmessage(e.target.value)}}></textarea>
                                    
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <button type="submit" className="btn btn-success"> Create Ticket </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CreateTicket