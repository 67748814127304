import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { kycpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import { getUserDetails,kycFormSubmit } from '../../../../redux/actions/dashboard.action'
import { CountryData1 } from '../../../public/register/countrycode';
const Kycform = ({history}) => {
    const user = useSelector(state => state.dashboard.user);
    const [country,Setcountry] =  useState('United States');
    const [search,setsearch] = useState('');
    const [firstname,Setfirstname] =  useState();
    const [lastname,Setlastname] =  useState();
    const [documentType,SetdocumentType] =  useState();
    const [documentNumber,SetdocumentNumber] =  useState();
    const [panCard,SetpanCard] =  useState();
    console.log('country',country);
    const tokenLogin =  localStorage.getItem("tokenLogin");
    const dispatch =  useDispatch();
    
    const handleSubmitForm1 = (e) => {
        if(country == 'India'){
            var kycdata = {
                country: country,
                firstname:firstname,
                lastname:lastname,
                documentType:documentType,
                documentNumber:documentNumber,
                pan_number:panCard,
            }
           
        }else{
            var kycdata = {
                country: country,
                firstname:firstname,
                lastname:lastname,
                documentType:documentType,
                documentNumber:documentNumber,
            }
        }
        e.preventDefault();
        dispatch(kycFormSubmit(kycdata,history));
    }

    useEffect(() => {
        dispatch(getUserDetails())
        // dispatch(getBanners())
        if(user?.country != undefined){ Setcountry(user.country)};
        Setfirstname(user?.firstname);
        Setlastname(user?.lastname);
        SetdocumentType(user?.documentType);
        SetdocumentNumber(user?.documentNumber);
        SetpanCard(user?.pan_number);
        document.getElementById("mySelect").value = user?.documentType;
        if(user?.documentNumber){    document.getElementById("myCheck").checked = true;}

    }, []);
    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";
    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";
    }
	const { langchange } = LangState();
    const submitbtn = () =>{
        if(user.kycStatus == 'PENDING' || user.kycStatus == 'REJECTED'){
            var btn = <button type="submit" className="btn btn-success"> Submit </button>;
        }else{
            var btn = <Link to="/vendor/uploadproof" className="btn btn-success"> Next </Link>;

        }
        return(
            <div className="col-12">
                {btn}
            </div>
        );
    }
    const panCardField = () => {
        return(
            <div className="col-12">
                <label for="id-number">Pan Card Number </label><br/>
                <input type="text" id="id-number" name='idnum' value={panCard} className='form-control kyc-inputType' placeholder="Please Enter Your Pan Card Number"  onChange={(e) => {SetpanCard(e.target.value)}} required/>
            </div>
        );
    }
    return (
    <div>
        <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn4'][langchange]}   </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn5'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData">  {kycpage['0']['nav']['btn6'][langchange]}  </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="verification-2">
            <div className="container-xl">
            <div className="links-name justify-content-between align-items-center">
                    <div className='d-flex'>
                        <p> KYC</p>
                        <p> <a href="#">/ Individual Verification </a> </p>
                    </div>
                    <div className='btnn___area__adhar d-flex'>
                        {/* <Link to='/vendor/kycAadhar' className='nav-link aadhar____btn___one'> verify aadhar with Number </Link> */}
                        {/* <a href='#' className='nav-link aadhar____btn___one ml-4'> abc</a> */}
                    </div>
                </div>
                <div className="alert alert-success">
                    <i className='fas fa-info-circle'></i>
                    <div className="">
                        <h5>  Supplement Individual Information </h5>
                        <p>  To strengthen the security of your account, please supplement the information.</p>
                    </div>
                </div>
                <div className="form-box">
                    <form onSubmit={handleSubmitForm1}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="sel1">Country/Region of Residence</label>
                                    <div className="form-group">

                                        <button type="button" className="dropdown__btn kyc_country_dropdown" onClick={dropdown}> <span>{country}</span>
                                            <svg className="down_svg__icon float-right" width="24" height="24"
                                                viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor">
                                                <path
                                                    d="M201.344 406.656L466.752 672a64 64 0 0090.496 0l265.408-265.344a32 32 0 00-45.312-45.312L512 626.752 246.656 361.344a32 32 0 10-45.312 45.312z">
                                                </path>
                                            </svg>
                                        </button>
                                        <div className="number__dropdown " id='countryDrop'>
                                            <div className="search__input">
                                                <label for="search">
                                                    <svg className="search_svg__icon" viewBox="0 0 1024 1024"
                                                        xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" fill="currentColor">
                                                        <defs>
                                                            <style></style>
                                                        </defs>
                                                        <path
                                                            d="M719.104 688.896l134.23 134.272a21.333 21.333 0 01-30.166 30.165L688.896 719.104l30.208-30.208zm-249.77-518.23a298.667 298.667 0 110 597.334 298.667 298.667 0 010-597.333zm0 42.667a256 256 0 100 512 256 256 0 000-512z">
                                                        </path>
                                                    </svg>
                                                </label>
                                                <input type="text" id="search"  onChange={(e) => {setsearch(e.target.value)} }  className=" search__input kyc_country_search" />
                                            </div>
                                            <div className="select__countery">
                                            
                                            {CountryData1.filter((key) => {
                                                if(search == ""){
                                                    return key;
                                                }else if(key.en.toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                                    return key
                                                }
                                            }).map(key =>(

                                                <div className="countery"  onClick={ () => {Setcountry(key.en);dropdownClose()}}>
                                                    <div className="flag" >
                                                        <img src={key.ico} width='30' />
                                                        <p className="countery__code" >{key.en}</p>
                                                    </div>
                                                    {/* <div className="number">{key.mobileCode}</div> */}
                                                </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <label for="fname"> Country/Region of Residence </label>
                                <input type="text" className="form-control kyc-inputType"  value={country}  onChange={event => Setcountry(event.target.value)} />
                            </div> */}
                            <div className="col-md-6">
                                <label for="fname"> First Name </label>
                                <input type="text" className="form-control kyc-inputType"  value={firstname} name="f-name" onChange={(e) => {Setfirstname(e.target.value)}} required readOnly={false}/>
                            </div>
                            <div className="col-md-6">
                                <label for="lname"> Last Name </label><br />
                                <input type="text" className="form-control kyc-inputType" value={lastname} id="lname" name="l-name" onChange={(e) => {Setlastname(e.target.value)}} required/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="id-sel">Document Type</label><br />
                                    <select className="form-control kyc-selectOption" id="mySelect"  name='doc' onChange={(e) => {SetdocumentType(e.target.value)}}>
                                        <option value='passport' > Passport</option>
                                        <option value='aadhar card' >Aadhar Card </option>
                                        <option value='driving license' >Driver License </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <label for="id-number">ID Number </label><br/>
                                <input type="text" id="id-number" name='idnum' value={documentNumber} className='form-control kyc-inputType' placeholder="Please Enter Your ID Number"  onChange={(e) => {SetdocumentNumber(e.target.value)}} required/>
                            </div>
                            {country == "India" ? panCardField() :' '}
                            <div className="col-12 text-center ">
                                
                                <input type="checkbox"  className='border border-primary' id='myCheck'   />
                                    <label for='myCheck' className="check-leble"  >
                                    I have read and agree to the <a href="#" className='text-success'> the User Identity Verification Disclaimer. </a> 
                                    </label>
                            </div>

                            {submitbtn()}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Kycform