import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import LoginForm from "./LoginForm";
import bg from "../../../_assets/images/bgbanner.jpeg";
import { login } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";
import { PROJECT_NAME } from "../../../_constants";
import "../../../App";
import { LangState } from "../../../LangugeContext";
import { loginlang } from "../../../lang/lang";
import logo from '../../../_assets/images/js_logo.png';
import log from "../../../_assets/logo/login_i.png"

// import "../../../_assets/css/login.css";
// import "../../../_assets/css/form.css";
const Login = ({ history }) => {
  const dispatch = useDispatch();
  const {langchange} = LangState();

  const handleSubmit = (values) => {
    values = { ...values, deviceType: 'WEB', deviceToken: 'WEB' };
    dispatch(login(values, history));
  }

  return (
    <>
    

 <main>
        <section className="reset__password__sec">
            <div className="sec__content">
                <div className="sec__text">
                    <div className="sec__logo">
                    <Link to='/'>  
                    
                        {/* <img src={logo} width='90' height='90' alt="logo" /> */}
                        </Link>
                    </div>
                    <div className="sec__text__inner">
                        <img src={log} className="log"  alt="log" />
                        <h2 className="sec__heading">{loginlang['0']['h1'][langchange]}</h2>
                        <p className="sec__brief">{loginlang['0']['p'][langchange]}</p>
                       
                    </div>
                </div>
                <div className="sec__form">
                    {/* <div className="upper__text">
                        <span>{loginlang['0']['link'][langchange]} <Link to="/register">{loginlang['0']['linkbtn'][langchange]}</Link></span>
                    </div> */}
                    <LoginForm history={history} />
                    <div className="upper__text">
                        <span>{loginlang['0']['link'][langchange]} <Link to="/register">{loginlang['0']['linkbtn'][langchange]}</Link></span>
                    </div>
                </div>
            </div>
        </section>
    </main> 

    </>
  );
};

export default withRouter(Login);