import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import Ab from '../../../_assets/images/img/About_story_1.png';
import abc from '../../../_assets/images/img/About_story_2.png';
import abcd from '../../../_assets/images/img/About_story_3.png';
import abcde from '../../../_assets/images/img/About_story_4.png';
import about1 from '../../../_assets/images/img/About_12.png';
import about2 from '../../../_assets/images/img/About_13.png';
import about3 from '../../../_assets/images/img/About_14.jpg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';

import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../_constants';
const MarginTrade = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <Link to='/about' className='nav-link active'>  About US </Link>
            </li>
            <li className='nav-item'>
              <Link to='/JoinUs' className='nav-link'>  Join US </Link>
            </li>
            

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content about-page'>
            <h2>  MARGIN TRADE <br/> ( Invest with borrowed funds to boost  returns)</h2>
            <h6>EasEasy & Simple Crypto Investment App for India</h6>
            <h6>Buy, Sell, and Trade Bitcoin, Ethereum, Solana, Ripple along with
                  500+ Altcoins with ease on {PROJECT_NAME}.
              </h6>
            <div className='btn-div'>
                <button className='btn btn-btn'>Sport</button>
                <button className='btn btn-btn ml-5'>Register</button>
            </div>  
            {/* <p> CASH FUDX believes that technological progress will revolutionize the way we create and distribute value. One day, everyone will become involved with crypto.</p> */}
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container'>
       
        <div className='about_box-1'>
          <div className='row m-0 align-items-center First_row'>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h3 className='mb-0'>6X LEVERAGE ON 250+ ALTCOINS</h3>
                <h6 className='mb-3'>
                Open long and short positions on 250+ altcoin markets and maximize your potential gains compared to spot trades with up to 6x leverage                  </h6>
                  
                  <h3 className='mb-0'>PROFESSIONAL EXPERIENCE</h3>
                  <h6>
                  Get access to a Massive Liquidity pool and amplify your profits with active trading strategies on market swings of cryptos in your portfolio.                </h6>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='img-about-1' src={about1} width='100%' alt='MarginTrade/img' />
            </div>
          </div>
        </div>
        <div className='about_box-1 mt-5'>
          <div className='row m-0  First_row vision about-page'>
          <div className='col-md-6 border-right'>
              <img className='img-about-1' src={about2} width='100%' alt='MarginTrade/img' />
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <h3 className='mb-0'>Create a {PROJECT_NAME} Account in 4 steps  </h3>
                <h6>Start creating your portfolio today by following the steps below</h6>
               
                <ul style={{color:'#000',marginLeft:'20px'}}>
                  <li><h6>Sign up on {PROJECT_NAME} App.</h6>
                  </li>
                  <li><h6>Verify your bank account.</h6>
                  </li>
                  <li><h6>Link your bank account and deposit INR.</h6> </li>
                  <li><h6>Start buying your favourite Crypto</h6></li>
                </ul>
                <h3 className='mb-0'>Cash Fudx</h3>
                <p>That why {PROJECT_NAME} is Best Crypto Exchange in
                  India. So, start investing in digital currency. Shiba
                  INU is good in each and every way. Crypto Prices
                  are really getting higher in market. Enter in Web
                  3.0Crypto, to trade with ease.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default MarginTrade