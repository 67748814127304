import React,{useEffect,useState} from 'react';
import bannerphne from '../../../../_assets/images/banner-phne.svg';
import appleicon  from '../../../../_assets/images/apple-icon.png';
import appstore from '../../../../_assets/images/app-store.png';
import android from '../../../../_assets/images/android.png';
import googleplay from '../../../../_assets/images/google-play.png';
import AliceCarousel from 'react-alice-carousel';
import Carousel from '../banner/Carousel';
import Cointable from './Cointable';
import CoinSlider from './CoinSlider';
import coinarrowicon from '../../../../_assets/images/coin-arrow-icon.svg';
import Banner from '../banner/Banner';
import { Wave ,Random } from 'react-animated-text';
import TypeAnimation from 'react-type-animation';
import { home_heading,home_sec2 } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import { Link } from 'react-router-dom';

const Component = () => {
    const {langchange} = LangState();
    const [animation,setanimation] = useState()
    useEffect(() => {
         setanimation(home_heading['0'][langchange]);
    },[langchange])
    // console.log('animation',animation);
  return (
    <div>
    <section className="hero__sec">
    <div className="container">
        <div className="sec__content">
            <div className="sec__text">
                <h2 className="sec__heading">
                    {/* Find the Next Crypto Gem on SOLAR TECHNO ALLIANCE */}
                    {/* <Wave text="Find the Next Crypto Gem on SOLAR TECHNO ALLIANCE" effect="verticalFadeIn"  effectChange={2.2}  /> */}
                    <TypeAnimation
                        cursor={false}
                        sequence={[
                        animation,
                        2000,
                        
                        ]}
                        wrapper="a"
                        repeat={1}
                        className='TypeAnimation'
                    />
                </h2>
                <p className="sec__info">
                    {home_heading['1'][langchange]}
                </p>
                <Link to='/register' className="sec__button">
                    {home_heading['2'][langchange]}
                </Link>
                <span className="sec__inner__info">
                    <span className="gift-icon">🎁</span> {home_heading['3'][langchange]}
                </span>
            </div>
            <div className="sec__img">
                <img src={bannerphne} alt="download mobile image" />
                <div className="download__app">
                    <h3 className="download__App__heading">
                        Download App
                    </h3>
                    <div className="download__app__wrapper">
                        <div className="download__app__icon ">
                            <img src={appleicon} alt="image/home" />
                            <span>iOS</span>
                            <div className="qr__code">
                                <span>
                                    <img src="assets/img/app-qr.png" alt="ios/img" />
                                </span>
                            </div>
                        </div>

                        <div className="download__app__icon ">
                            <img src={appstore} alt="download__app/img" />
                            <span>App Store</span>
                            <div className="qr__code">
                                <span>
                                    <img src="assets/img/app-qr.png" alt="App store" />
                                </span>
                            </div>
                        </div>

                        <div className="download__app__icon ">
                            <img src={android} alt="download__app/img" />
                            <span>Android</span>
                            <div className="qr__code">
                                <span>
                                    <img src="assets/img/app-qr.png" alt="Android" />
                                </span>
                            </div>
                        </div>

                        <div className="download__app__icon ">
                            <img src={googleplay} alt="download__app/img" />
                            <span>Google Play</span>
                            <div className="qr__code">
                                <span>
                                    <img src="assets/img/app-qr.png" alt="Google Play" />
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="update__trending__coin">
    <div className="container">
    {/* <Banner/> */}

        <CoinSlider />
        <Cointable />

    </div>
</section>

<section className="sec__first__coin">
    <div className="container">
        <h2 className="sec__heading">
            {home_sec2['0'][langchange]}
        </h2>
        <div className="sec__content">
            <div className="create__account">
                <span className="number">1</span>
                <Link to="/register">{home_sec2['1'][langchange]}</Link>
                <span><img src={coinarrowicon} alt="coinarrow/img" /></span>
            </div>
            <div className="create__account buy__coin">
                <span className="number">2</span>
                <p>{home_sec2['2'][langchange]}</p>
            </div>
        </div>
    </div>
</section>


</div>
  )
}

export default Component