import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { kycpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import { getUserDetails,kycAadharSubmit,kycFormSubmit } from '../../../../redux/actions/dashboard.action'
import { CountryData1 } from '../../../public/register/countrycode';
const KycAadhar = ({history}) => {
    const user = useSelector(state => state.dashboard.user);
   
    const [documentNumber,SetdocumentNumber] =  useState();
    const tokenLogin =  localStorage.getItem("tokenLogin");
    const dispatch =  useDispatch();
    const kycdata = {
      
        aadhar_no:parseInt(documentNumber),
    }
    const handleSubmitForm1 = (e) => {
        e.preventDefault();
        dispatch(kycAadharSubmit(kycdata,history));
    }

    useEffect(() => {
        dispatch(getUserDetails())
        // dispatch(getBanners())
       
    }, []);
    const dropdown = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown active";
    }
    const dropdownClose = () => {
        const navDash = document.getElementById("countryDrop").className = "number__dropdown";
    }
	const { langchange } = LangState();

    return (
    <div>
        <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn4'][langchange]}   </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn5'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData">  {kycpage['0']['nav']['btn6'][langchange]}  </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="verification-2">
            <div className="container-xl">
                <div className="links-name justify-content-between align-items-center">
                    <div className='d-flex'>
                        <p> KYC</p>
                        <p> <a href="#">/ Individual Verification </a> </p>
                    </div>
                    <div className='btnn___area__adhar d-flex'>
                        <Link to='/vendor/kycform' className='nav-link aadhar____btn___one'> verify aadhar with Image </Link>
                        {/* <a href='#' className='nav-link aadhar____btn___one ml-4'> abc</a> */}
                    </div>
                </div> 
                <div className="alert alert-success" >
                    <i className='fas fa-info-circle'></i>
                    <div className="">
                        <h5>  Supplement Individual Information </h5>
                        <p>  To strengthen the security of your account, please supplement the information.</p>
                    </div>
                </div>
                <div className="form-box">
                    <form onSubmit={handleSubmitForm1}>
                        <div className="row">
                            
                            <div className="col-12">
                                <label for="id-number">Aadhar Number </label><br/>
                                <input type="text" id="id-number" name='idnum' value={documentNumber} className='form-control kyc-inputType' placeholder="Please Enter Your Aadhar Number"  onChange={(e) => {SetdocumentNumber(e.target.value)}} required/>
                            </div>
                            {/* <div className="col-12 text-center ">
                                
                                <input type="checkbox"  className='border border-primary' id='myCheck'   />
                                    <label for='myCheck' className="check-leble"  >
                                    I have read and agree to the <a href="#" className='text-success'> the User Identity Verification Disclaimer. </a> 
                                    </label>
                            </div> */}
                            <div className="col-12">
                                <button type="submit" className="btn btn-success"> Submit </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default KycAadhar