import React, { useEffect, useState } from 'react'
// import { db } from '../../../firebaseData';
// import db from '../../../firebaseData'; 
// import { getStorage, ref, list } from "firebase/storage";
// import { getDatabase, ref, query, orderByChild } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set ,query, orderByChild,runTransaction,onValue,push} from "firebase/database";
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../redux/actions/dashboard.action';

const SellChat = (props) => {
  console.log('props',props.props);
  // const { id,buyer_id } = 'useParams()';
  const dispatch =  useDispatch();
  const id = props.props.id;
  const buyer_id  = props.props.buyer_id
    const [message,setmessage] = useState();
    const [data,setdata] = useState();
    const [userdata,setuserdata] = useState();
    const user = useSelector(state => state.dashboard.user);

    const timestamp = Date.now(); // This would be the timestamp you want to format
    const date = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);
    console.log(date);
    useEffect(() => {
      // dispatch(getUserDetails());
        const starCountRef = ref(db, 'users/' );
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          console.log('data data',data);
          setdata(data)
          if(data?.[id]){
            setuserdata(Object.entries(data?.[id]));
          }
        //   updateStarCount( data);
        });
        // pageTokenExample()
    },[id])
   
      const db = getDatabase();
      console.log('db',db);
  const auth = getAuth();
  const userId = id;
  // const topUserPostsRef = query(ref(db, 'users/' + userId), orderByChild('message'));
  function writeUserData() {
    const db = getDatabase();

    const postListRef = ref(db, 'users/'+ id);
    const newPostRef = push(postListRef);

    set(newPostRef, {
      user_id: user._id,
      message:message,
      createdAt:date,
      buyer_id:buyer_id
    });
    
    setmessage(' ')
    // document.getElementById('msg_scrool').scrollIntoView({behavior:"smooth"});;

  }

  const onHandelSubmit =(e) =>{
    e.preventDefault();
    writeUserData()
  }
  console.log('auth',userdata?.[0]?.[1]);

//  Object.entries(data.kuldeep).forEach(([key, value]) => {
//     console.log(`${key}`, value); // "a 5", "b 7", "c 9"
//   }) 

const  chatView = () => {
  // if( userdata?.[0]?.[1]?.user_id == user?._id ||  userdata?.[0]?.[1]?.buyer_id == user?._id){
    return (
      <div>
      <div class="card-body chat_area" >
          {/* <div class="sms_timer text-center" > 2022-07-19 12:00</div> */}
          {/* <div class="client">
              <div class="SMS_Sec">
                  Hello Sunil
              </div>
          </div>
          <div class="User_s">
              <div class="SMS_Sec">
                  Hiii
              </div>
          </div> */}
          {userdata?.map((val,key) =>{
              return(           
                <div className={val?.[1]?.user_id == user?._id ?'User_s' :'client' }  key={key}>
                  <div className='SMS_Sec' >
                    <p id={userdata.length == key+1 ?'msg_scrool' :' '}>{val?.[1]?.message}</p>
                  </div>
                </div>)
              }
          )}

      </div>
      <div class="card-footer border-0">
          <form onSubmit={onHandelSubmit}>
              <div class="form-group ">
                  <input type="text" class="form-control text_input"
                      placeholder="Type your message here ..." id="Typed_sms"  onChange={(e) => setmessage(e.target.value)} value={message}  />
                  <div class="file_upload_Icon">
                      <label for="file_Upload">
                          <i class="fa fa-chain-broken"></i>
                          <input type="file" class="form-control-file" id="file_Upload"
                              style={{display: 'none', opacity: '0'}} />
                      </label>
                  </div>
                  <button type="submit" value={'Send'}  class="btn"><i class="fa fa-paper-plane"></i>
                  </button>
              </div>
          </form>
      </div>

      </div>
    )
  // }else{
  //   return<div className='m-5'>  
  //    <h1 className='text-danger '> Chat Not Found</h1>
      
  //   </div>
  // }
 
} 
  return (
    <>  
    {/* {chatView()} */}
    <div class="card-body chat_area" >
          {/* <div class="sms_timer text-center" > 2022-07-19 12:00</div> */}
          {/* <div class="client">
              <div class="SMS_Sec">
                  Hello Sunil
              </div>
          </div>
          <div class="User_s">
              <div class="SMS_Sec">
                  Hiii
              </div>
          </div> */}
          {userdata?.map((val,key) =>{
              return(           
                <div className={val?.[1]?.user_id == user?._id ?'User_s' :'client' }  key={key}>
                  <div className='SMS_Sec' >
                    <p id={userdata.length == key+1 ?'msg_scrool' :' '}>{val?.[1]?.message}</p>
                  </div>
                </div>)
              }
          )}

      </div>
      <div class="card-footer border-0">
          <form onSubmit={onHandelSubmit}>
              <div class="form-group ">
                  <input type="text" class="form-control text_input"
                      placeholder="Type your message here ..." id="Typed_sms"  onChange={(e) => setmessage(e.target.value)} value={message}   placeholder='message'/>
                  <div class="file_upload_Icon">
                      <label for="file_Upload">
                          <i class="fa fa-chain-broken"></i>
                          <input type="file" class="form-control-file" id="file_Upload"
                              style={{display: 'none', opacity: '0'}} />
                      </label>
                  </div>
                  <button type="submit" value={'Send'}  class="btn"><i class="fa fa-paper-plane"></i>
                  </button>
              </div>
          </form>
      </div>

      {/* </div>/                 */}
    </>
  )
}

export default SellChat