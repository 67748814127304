import React from 'react'
// import About_bg from '../../../_assets/images/img/About_bg.png';
import m1 from '../../../_assets/images/media/1.jpeg';
import m2 from '../../../_assets/images/media/2.jpeg';
import m3 from '../../../_assets/images/media/3.jpeg';
import m4 from '../../../_assets/images/media/4.jpeg';
import m5 from '../../../_assets/images/media/5.jpeg';
import m6 from '../../../_assets/images/media/6.jpeg';
import m7 from '../../../_assets/images/media/7.jpeg';
import m8 from '../../../_assets/images/media/8.jpeg';
import m9 from '../../../_assets/images/media/9.jpeg';
import m10 from '../../../_assets/images/media/10.jpeg';
import m11 from '../../../_assets/images/media/11.jpeg';
import m12 from '../../../_assets/images/media/12.jpeg';
import m13 from '../../../_assets/images/media/13.jpeg';
import m14 from '../../../_assets/images/media/14.jpeg';
import m15 from '../../../_assets/images/media/15.jpeg';
import m16 from '../../../_assets/images/media/16.jpeg';
import m17 from '../../../_assets/images/media/17.jpeg';
import m18 from '../../../_assets/images/media/18.jpeg';
import m19 from '../../../_assets/images/media/19.jpeg';
import about4 from '../../../_assets/images/img/Join_card_3.svg';
import posi1 from '../../../_assets/images/img/Position_1.svg';
import posi2 from '../../../_assets/images/img/Position_2.svg';
import posi3 from '../../../_assets/images/img/Position_3.svg';
import posi4 from '../../../_assets/images/img/Position_4.svg';
import news1 from '../../../_assets/images/news/50-states-today.svg';
import news2 from '../../../_assets/images/news/american-financial-tribune.svg';
import news3 from '../../../_assets/images/news/american-news-observer.svg';
import news4 from '../../../_assets/images/news/american-times-reporter.svg';
import news5 from '../../../_assets/images/news/coast-to-coast-tribune.svg';
import news6 from '../../../_assets/images/news/global-report-journal.svg';
import news7 from '../../../_assets/images/news/north-america-today.svg';
import news8 from '../../../_assets/images/news/state-of-the-union-news.svg';
import news9 from '../../../_assets/images/news/us-daily-ledger.svg';
import news10 from '../../../_assets/images/news/us-times-gazette.svg';
import news11 from '../../../_assets/images/news/world-online.svg';
import news12 from '../../../_assets/images/news/world-report-monitor.svg';


import Header from '../dashboard/Header';
import Footer from '../dashboard/index/Footer';
import { PROJECT_NAME } from '../../../_constants';
const MediaKit = () => {
  // console.log('hiii');
  return (
    <div id='about'>
      <Header></Header>
      {/* Banner  */}
      <div className='about_banner' style={{marginTop:'80px'}}>
        {/* <div className='about_banner_header'>
          <ul className='nav w-100 justify-content-center'>
            <li className='nav-item'>
              <a href='#' className='nav-link active'>  About US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Join US </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'> Community  </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>  Press </a>
            </li>

          </ul>
        </div> */}
        <div className='banner_body' >
          <div className='banner_content'>
            <h2>  Media Kit</h2>
            <h5 className='text-white'> The {PROJECT_NAME} platform is designed for investors of all types, with 24/7 world-class
              <br /> services in your preferred channel and language The {PROJECT_NAME} Global<br />
Community covers 8 countries and 3 languages</h5>
          </div>
        </div>
      </div>
      {/* our story  */}
      <div className='about_container' id='Join_container'>
        
       
        <div className='about_box-1 mt-5  '>
          <div className='row m-0 align-items-center First_row vision'>
          <div className='col-md-6'>
              <img className='img-about-1' src={about4} width='100%' alt='mediakit/img' />
            </div>
            <div className='col-md-6'>
              <div className='about-story-text'>
                <ul style={{color:'#000'}}>
                  <li><h6>The {PROJECT_NAME} platform is designed for investors of all types, with 24/7 world-
                    class services in your preferred channel and language The {PROJECT_NAME} Global
                    Community covers 8 countries and 3 languages.</h6>
                  </li>
                  <li><h6>Interact with global crypto investors and gain access to timely and trusted crypto news.</h6></li>
                  <li><h6>{PROJECT_NAME} is a largest social trading platform which gives treat to your trade.</h6> </li>
                  <li><h6>So, start investing in digital currency.</h6></li>

                </ul>
                
              </div>
            </div>
          </div>
        </div>
        {/* <div className='about_box-1'>
            <div className='text-center mb-5'><h2>NEWS</h2></div>
            <div className='join_position  mb-5 pb-5'>
                <div className='d-flex  align-items-center' style={{justifyContent:'space-evenly'}}>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi1} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi2} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi3} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi4} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                </div>
            </div>
        </div> */}
        <div className='about_box-1'>
            <div className='text-center mb-5'><h2>PRESS</h2></div>
            <div className='join_position  mb-5 pb-5'>
                <div className='position____flex d-flex  align-items-center' style={{justifyContent:'space-evenly'}}>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi1} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi2} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi3} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                    <div className='position_box1_join'>
                        <img className='img-about-1' src={posi4} width='100%' alt='mediakit/img' />
                        <h4> Position -1 </h4> 
                    </div>
                </div>
            </div>
        </div>
        {/* <div className='about_box-1 d-none'>
            <div className='text-center mb-5'><h2>MEDIA COVERAGE/TV</h2></div>
            <div className='join_position  mb-5 pb-5'>
            <div className='row align-items-center text-center'>
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m2} width='100%' alt='mediakit/img' />
                        </div>
                       
                    </div>
                  </div>
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m3} width='100%' alt='mediakit/img' />
                        </div>
                     
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m4} width='100%' alt='mediakit/img' />
                        </div>
                      
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m5} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  


                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m6} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m7} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m8} width='100%' alt='mediakit/img'  />
                        </div>
                        
                    </div>
                  </div>
                  {/* <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m9} width='100%' />
                        </div>
                        
                    </div>
                  </div> */}
                  {/* <div className='col-md-3'>
                    <div className='team '>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m10} width='100%' />
                        </div>
                        
                    </div>
                  </div> */}
                  {/* <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m11} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m12} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m13} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div> */}
                  {/* <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m14} width='100%' />
                        </div>
                        
                    </div>
                  </div> */}
                  {/* <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m15} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m16} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m17} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m18} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m19} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>

                  

                </div>
            </div>
        </div> */}
        <div className='about_box-1'>
            <div className='text-center mb-5'><h2>SOCAIL MEDIA</h2></div>
            <div className='join_position  mb-5 pb-5'>
                <div className='d-flex  align-items-center' style={{justifyContent:'space-evenly'}}>
                    <div className='position_box1_join p-0 d-flex justify-content-center align-items-center'>
                    <i class="fab fa-instagram" style={{fontSize:'10vw'}}></i>
                    </div>
                    <div className='position_box1_join p-0 d-flex justify-content-center align-items-center'>
                      <i class="fab fa-facebook" style={{fontSize:'10vw'}}></i>
                    </div>
                    <div className='position_box1_join p-0 d-flex justify-content-center align-items-center'>
                    <i class="fab fa-twitter-square" style={{fontSize:'10vw'}}></i>

                    </div>
                    <div className='position_box1_join p-0 d-flex justify-content-center align-items-center'>
                    <i class="fab fa-linkedin" style={{fontSize:'10vw'}}></i>

                    </div>
                </div>
            </div>
        </div>      
        {/* <div className='about_box-1'>
            <div className='text-center mb-5'><h2>MEDIA COVERAGE/TV</h2></div>
            <div className='join_position  mb-5 pb-5'>
            <div className='row align-items-center text-center'>
                  
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m2} width='100%' alt='mediakit/img' />
                        </div>
                       
                    </div>
                  </div>
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m3} width='100%' alt='mediakit/img' />
                        </div>
                     
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m4} width='100%' alt='mediakit/img' />
                        </div>
                      
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m5} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  


                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m6} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m7} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m8} width='100%' alt='mediakit/img'  />
                        </div>
                        
                    </div>
                  </div>
                  
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m11} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m12} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m13} width='100%' alt='mediakit/img' />
                        </div>
                        
                    </div>
                  </div>
                 
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m15} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m16} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m17} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m18} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <img className='img-about-1' src={m19} width='100%' alt='mediakit/img' />
                        </div>
                    </div>
                  </div>

                  

                </div>
            </div>
        </div> */}

        {/* <div className='about_box-1 news'>
            <div className='text-center mb-5' id='news'><h2>NEWS</h2></div>
            <div className='join_position  mb-5 pb-5'>
            <div className='row align-items-center text-center'>
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.50statestoday.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news1} width='100%' /></a>
                        </div>
                        
                    </div>

                  </div>
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                          <a href="https://www.americanfinancialtribune.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news2} width='100%' alt='mediakit/img' /></a>
                        </div>
                       
                    </div>
                  </div>
                  <div className='col-md-3'>
                  <div className='team'>
                        <div className='team2 child-div'>
                          <a href="https://www.americanewsobserver.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target='_blanck'><img className='img-about-1' src={news3} width='100%' alt='mediakit/img' /></a>
                        </div>
                     
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.americantimesreporter.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news4} width='100%' alt='mediakit/img' /></a>
                        </div>
                      
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.coasttocoasttribune.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news5} width='100%' alt='mediakit/img' /></a>
                        </div>
                        
                    </div>
                  </div>
                  


                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.globalreporterjournal.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news6} width='100%' alt='mediakit/img' /></a>
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.northamericatoday.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"> <img className='img-about-1' src={news7} width='100%' alt='mediakit/img' /></a>
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                         <a href="https://www.stateoftheunionnews.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore"  target='_blank'> <img className='img-about-1' src={news8} width='100%' alt='mediakit/img'  /></a>
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                          <a href="https://www.usdailyledger.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target='_blank' ><img className='img-about-1' src={news9} width='100%' /></a>
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team '>
                        <div className='team2 child-div'>
                        <a href="https://www.ustimesgazette.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"> <img className='img-about-1' src={news10} width='100%' /></a>
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.worldonlinenewsreports.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news11} width='100%' alt='mediakit/img' /></a>
                        </div>
                        
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='team'>
                        <div className='team2 child-div'>
                        <a href="https://www.worldreportmonitor.com/article/562313197-fudx-coins-listing-world-s-first-blockchain-based-hyperlocal-hospitality-ecosystem-fxc-fudx-listed-on-coinstore" target="_blank"><img className='img-about-1' src={news12} width='100%' alt='mediakit/img' /></a>
                        </div>
                        
                    </div>
                  </div>
                  

                </div>
            </div>
        </div> */}
        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default MediaKit