import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import madel from '../../../../_assets/images/madel.svg';
import visa from '../../../../_assets/images/visa2.png';
import { LangState } from '../../../../LangugeContext';
import { fastBuypage, p2ppage } from '../../../../lang/lang';
import { useDispatch, useSelector } from 'react-redux';
import { p2p_my_posting } from '../../../../redux/actions/p2p.action';

const P2pMyPosting = () => {
    const {langchange} = LangState();
    const dispatch = useDispatch();
    const  my_posting = useSelector(state => state.p2p.my_posting) 
    useEffect(() => {
        dispatch(p2p_my_posting());
    },[]);
    console.log('my_posting 22',my_posting);

    const showRecords = () => {
        if(my_posting?.my_postings != undefined){
          return  my_posting?.my_postings.map((val) => {
            console.log(val?.token_id?.[0]?.coinName);
                return(
                    <tr>
                        <td className="name"> <p> {val?.user_id?.[0]?.firstname?.slice(0, val?.user_id?.[0]?.firstname?.length + 1 - val?.user_id?.[0]?.firstname?.length)} {val?.user_id?.[0]?.lastname?.slice(0, val?.user_id?.[0]?.lastname?.length + 1 - val?.user_id?.[0]?.lastname?.length)} </p> 
                         {/* Best Rate USDT <img src={madel} />  */}
                         {val?.token_id?.[0]?.coinName}
                        </td>
                        <td className="text-center"> {val?.sell_currency}
                        {/* <img src={visa} />   */}
                        </td>
                        <td>  {val?.price}  </td>
                        <td>  {val?.sold_quantity? val?.sold_quantity :0} ~ {val?.quantity} {val?.sell_currency} </td>
                        <td>   {val?.price} {val?.sell_currency}/{val?.token_id?.[0]?.coinName}	</td>
                        <td>{val?.createdAt}</td>
                        <td className="p-0 text-right"> <Link to={'/vendor/MyPostingDetails/'+ val._id} className="btn"> View </Link>   </td>
                    </tr>
                );
            })
        }
    }
  return (
    <div>
        <div className="Fast-trade">
            <div className="container-fast">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/fastBuy"> {fastBuypage['0']['nav']['btn1'][langchange]} </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/p2p"> {fastBuypage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/vendor/thirdParty"> {fastBuypage['0']['nav']['btn3'][langchange] } </Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <div className="tab-new-style">
            <div className="tab-container">
                <div className="left-sidebar" id="left-sidebar">
                    <ul className="nav flex-column"> 
                        <p> P2P </p>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p"> {p2ppage['0']['buy'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/p2p/sell"> {p2ppage['0']['Sell'][langchange] }  </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/order"> {p2ppage['0']['myorders'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/vendor/p2p/myPosting"> {p2ppage['0']['myadve'][langchange] } </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/vendor/p2p/create_posting"> {p2ppage['0']['postadve'][langchange] } </Link>
                        </li>
                        <p> {p2ppage['0']['other'][langchange] } </p>
                        <li className="nav-item">
                            <a className="nav-link" href="#"> {p2ppage['0']['support'][langchange] } </a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vendor/p2p/paymentSetting"> {p2ppage['0']['pay'][langchange] }  </Link>
                        </li> 
                    </ul>
                </div>
                <div className="right-tab">
                    <div className="tab-header">
                        <ul className="nav" role="tab">
                            <li className="nav-item" id="more" > 
                            <a className="nav-link active" data-toggle="tab" href="#USDT"> Posted</a>
                        </li>
                        <li className="nav-item"> 
                            <a className="nav-link " data-toggle="tab" href="#BTC">   Pending </a>
                        </li>
                      
                    </ul>
                </div>
                <div className="tab-content">
                    <div id="USDT" className="tab-pane active">
                        <div className="table-responsive-xl"> 
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th className="w-25"> Trade/ID   </th>
                                    <th>  Currency	  </th>
                                    <th>  Price/Premium  </th>
                                    <th>Remaining/Total</th>
                                    <th> Operation </th>
                                    <th className="text-right">  Time </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                    {showRecords()}
                               
                            </tbody>
                        </table>
                    </div>
                </div>                                        
                
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default P2pMyPosting

