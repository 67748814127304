import { Container, createTheme, LinearProgress, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { createDispatchHook } from 'react-redux';
import { useHistory } from 'react-router';
import { CoinList } from '../../../config/api';
import { Pagination } from '@material-ui/lab';
import { CryptoState } from '../../../CryptoContext';

export function   numberWithCommma(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const CoinTable = () => {
    const history = useHistory();
    const { currency, symbol } = CryptoState();

    const [coins,setcoins] = useState([]);
    const [loading,setloading] = useState(true);
    const [search,setsearch] = useState('');
    const [page,setpage] = useState(1);
    const fatchcoins  = async () => {
        const {data} = await axios.get(CoinList(currency));
        setcoins(data);
        setloading(false);
    }
    console.log( 'see all data coins',coins);
    useEffect(() => {
        fatchcoins();
    },[currency])

    const darkTheme = createTheme({
        palette:{
            primary:{
                main:'#fff',
            },
            type:'dark',
        },
    });
    
    const handleSearch = () => {
        console.log( 'see all data coins',coins);

        return coins.filter(
            (coin) => 
                coin.name.toLowerCase().includes(search) ||
                coin.symbol.toLowerCase().includes(search)
        );
    }

    const useStyles  = makeStyles(() => ({
        row: {
            backgroundColor:'#16171a',
            cursor:'ponter',
            '&:hover':{
                backgroundColor:'#131111',
            },
            fontFamily:'Montserrat',
        },
        Pagination:{
            '& .MuiPaginationItem-root':{
                color:'gold',
            }
        }
    }));
    const classes = useStyles();
  return (
        <ThemeProvider theme={darkTheme}>
            <Container style={{textAlign:'center'}}>
                <Typography variant='h4' style={{margin: 18,fontFamily:'Montserrat'}}>
                    Crypto Currency Price by Market Cap
                </Typography>
                <TextField label='Search For a Crypto Currency..' variant='outlined' style={{marginBottom:20, width:'100%'}}  onChange={(e) => setsearch(e.target.value)}/> 
                <TableContainer >
                    {
                         loading?(<LinearProgress style={{backgroundColor:'gold'}}></LinearProgress>):(
                            <Table>
                                <TableHead style={{backgroundColor:'#EEBC1D'}}>
                                    <TableRow>
                                        {['Coin','Price','24h Change','Markit Cap'].map((head) => (
                                            <TableCell style={{color:'black',fontweight:'700',fontFamily:'Montserrat',}} key={head} align={head === "Coin" ? "" : "right"}  >
                                                {head}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {handleSearch()
                                        .slice((page - 1) * 10, (page - 1) * 10 + 10)
                                        .map((row) => {
                                        const profit = row.price_change_percentage_24h > 0;
                                        return  (
                                            <TableRow onClick={() => history.push(`/coin/${row.symbol}`)} className={classes.row} key={row.name}>
                                                <TableCell component='th' scope='row' style={{display: "flex",gap: 15,}} >
                                                    <img src={row.image} alt={row.name} height='50' style={{marginBottom:10}}/>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span style={{textTransform:'uppercase',fontSize:22,}}>{row.symbol}</span>
                                                        <span style={{color:'darkgray'}}>{row.name}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {symbol}{" "}
                                                    {numberWithCommma(row.current_price.toFixed(2))}
                                                </TableCell>
                                                <TableCell align='right' style={{color:profit > 0 ? 'rgb(14,203,129)':'red',fontWeight:500,}}>
                                                    {profit && '+'}
                                                    {row.price_change_percentage_24h.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="right">
                                                {symbol}{" "} {numberWithCommma(row.market_cap.toString().slice(0,-6))} M
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                         )  
                    }
                </TableContainer>  
                <Pagination style={{padding:20,width:'100%',display:'flex',justifyContent:'center'}} classes={{ul:classes.Pagination}} count={(handleSearch()?.length/10).toFixed(0)} onChange={(_, value) => {
            setpage(value);
            window.scroll(0, 450);
          }} />
            </Container>
        </ThemeProvider>
    );
};

export default CoinTable;
