import React,{useEffect} from 'react';
// import Trade from '../trade/Trade';
import SpotSection from './SpotSection';
import { CryptoState } from '../../../CryptoContext';
import Trade from '../../public/trade/Trade';
import { cancelTradeOrder, getTredeOrder_data } from '../../../redux/actions/Chart.action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { get_2nd_swap_balance_wallet_type, get_swap_balance_wallet_type } from '../../../redux/actions/assets.action';

const OrderTable = (props) => {
    const dispatch = useDispatch()
    const {currencyId,currency} = CryptoState();
    const trade_order = useSelector(state => state.chart.trade_order?.orders)
    useEffect(() => {
        dispatch(getTredeOrder_data({status:'PENDING',tradingCurrency: props?.props?.toUpperCase()}))
    },[]);
    // console.log('trade_order',props?.props.toUpperCase());
    function secondsToHms(c_date) {
		const isoStr = c_date;
		const date1 = new Date(isoStr); 
		const timestamp = date1.getTime();
		var date = new Date(timestamp);
		return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
	}
    const { cancelOrder, setcancelOrder} = CryptoState();

    const cancelOrderFun = (id,istCoin,Coin) => {
        if (window.confirm('Are you sure to cancel order!') == true) {
            dispatch(cancelTradeOrder({order_id:id},setcancelOrder,cancelOrder))

            
        }
    }
    const  history = useHistory();
     
    
  return (
    <div className='table-responsive mb-0'>
    <div className="order__wrapper">
    <div className="order__data active" id='myElement' >
        <div className="order__tabs__Data" style={{background:' #151c30',padding:' 12px 6px'}}>
            <div className="order__tabs__inner__Data">
                <h4>
                    Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                    <img src="assets/svg/dropdown-icon.svg" alt=""/>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
                <div className="dropdown__bg__layer">

                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Pair Currency
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Quantity
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled
                </h4>

            </div>
            {/* <div className="order__tabs__inner__Data">
                <h4>
                    Status
                </h4>
            </div> */}
            <div className="order__tabs__inner__Data cancer__order d-none">
                <button className="cancel__btn">
                    <span>
                        Cancel All
                    </span>
                    <span className="cancel__icon">
                        <img className="cancel-order-icon" src="assets/svg/cancel-order-icon.svg" alt="" />
                    </span>
                    <div className="cancel__Popup">
                        <p>
                            Click here to all the open oders as specified (included order note display
                            here)
                        </p>
                    </div>
                </button>
            </div>
        </div>
        {/* ///////////////////////////////////////////////////////////////////////// */}
        <ul className="inner__data__order pt-1" style={{overflowY: 'scroll', height: '100%',}}>

            {trade_order?.map((val) => {
                return(
                //     <li className="data">
                //     <span style={{overflow:'hidden',width:'90%'}}> {secondsToHms(val?.createdAt)}</span>
                //     <span>{val?.tradingCurrency} / {val?.token_id[0]?.coinName}</span>
                //     <span>{val?.orderType}</span>
                //     <span>{val?.mode}</span>
                //     <span>{val?.price?.toFixed(5)} </span>
                //     <span>{val?.tokenPrice?.toFixed(5)} {val?.token_id[0]?.coinName} </span>
                //     <span>{val?.amount?.toFixed(5)} <br/> {val.tradingCurrency}</span>
                //     <span>0</span>
                //     {/* <span style={{color:'rgb(40 205 149)'}}>Pending</span> */}

                //     <span><button className='btn btn-sm table-btn border' onClick={() => cancelOrderFun(val?._id,val?.token_id[0]?.coinName,val.tradingCurrency)} style={{color:'rgb(40 205 149)'}}>Cancel</button></span>
                // </li>

            <li className="data">
            <span style={{overflow:'hidden',width:'90%'}}> {secondsToHms(val?.createdAt)}</span>
            <span>{val?.tradingCurrency} / USDT
                {/* {val?.token_id[0]?.coinName} */}
            </span>
            <span>{val?.orderType}</span>
            <span>{val?.mode}</span>
            <span>{val?.price?.toFixed(3)} </span>
            <span>{val?.tokenPrice?.toFixed(3)}
                {val?.mode == 'BUY' ? val?.token_id[0]?.coinName : 'USDT'}
            </span>
            <span>{val?.amount?.toFixed(3)} <br/> {val.tradingCurrency}</span>
            <span>0</span>
            {/* <span style={{color:'rgb(40 205 149)'}}>Pending</span> */}
            <span><button className='btn btn-sm table-btn border' onClick={() => cancelOrderFun(val?._id,val?.token_id[0]?.coinName,val.tradingCurrency)} style={{color:'rgb(40 205 149)'}}>Cancel</button></span>
            </li>
                )
            })}
        </ul>

        {/* <Trade currencyId='ETHBTC'/> */}
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginLeft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    >
                </svg>
            </span>
        </div>
    </div>


    
     <div className="order__data stop__orders__data" id='myElement1'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>

            </div>
            {/* <div className="order__tabs__inner__Data">
                <h4>
                    Stop Price
                </h4>
            </div> */}
            <div className="order__tabs__inner__Data">
                <h4>
                    Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled
                </h4>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Unfilled
                </h4>
            </div>
            {/* <div className="order__tabs__inner__Data">
                <h4>
                    Status
                </h4>
            </div> */}
            <div className="order__tabs__inner__Data cancer__order">
                <button className="cancel__btn">
                    <span>
                        Cancel All
                    </span>
                    <span className="cancel__icon">
                        {/* <img className="cancel-order-icon" src="assets/svg/cancel-order-icon.svg" alt="" /> */}
                    </span>
                    <div className="cancel__Popup">
                        <p>
                            Click here to all the open oders as specified (included order note display
                            here)
                        </p>
                    </div>
                </button>
            </div>
        </div>
        <ul className="inner__data__order pt-2">



            {/*Stop  order */}
            {trade_order?.map((val) => {
                return(
                    <li className="data">
                    <span style={{overflow:'hidden',width:'90%'}}> {secondsToHms(val?.createdAt)}</span>
                    <span>{val?.tradingCurrency}</span>
                    <span>{val?.orderType}</span>
                    <span>{val?.mode}</span>
                    <span>{val?.tokenPrice} USDT</span>
                    <span>{val?.amount}</span>
                    <span>0</span>
                    <span>0</span>
                    {/* <span style={{color:'rgb(40 205 149)'}}>Canceled</span> */}

                    <span><button className='btn btn-sm table-btn border '  style={{color:'rgb(40 205 149)'}}>Canceled</button></span>
                </li>
                )
            })}
            
        </ul>
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginleft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                   >
                </svg>

            </span>
        </div>
    </div>
    
    <div className="order__data stop__orders__data" id='myElement2'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                    <option className="dropdown__option">
                        Stop Market
                    </option>
                    <option className="dropdown__option">
                        Stop Limit
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Buy/Sell
                    </option>
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Price
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                Unfilled
                </h4>
            </div>
            <div className="order__tabs__inner__Data cancer__order">
                <div className="order__tabs__inner__Data tabs__dropdown">
                    <button className="pairs__button">
                        <span>Cancel</span>
                         {/* <i className="fas fa-caret-down pl-1"></i> */}
                    </button>
                    {/* <div className="dropdown__content">
                        <option className="dropdown__option">
                            All
                        </option>
                        <option className="dropdown__option">
                            Completed
                        </option>
                        <option className="dropdown__option">
                            Cancelled
                        </option>
                    </div> */}
                </div>
            </div>
        </div>
        
        {/* order history */}
         
        <ul className="inner__data__order">
            {trade_order?.map((val) => {
                return(
                    <li className="data">
                    <span style={{overflow:'hidden',width:'90%'}}> {secondsToHms(val?.createdAt)}</span>
                    <span>{val?.tradingCurrency}</span>
                    <span>{val?.orderType}</span>
                    <span>{val?.mode}</span>
                    <span>{val?.tokenPrice} USDT</span>
                    <span>{val?.amount}</span>
                    <span>0</span>
                    <span>0</span>
                    {/* <span style={{color:'rgb(40 205 149)'}}>Pending</span> */}

                    <span><button className='btn btn-sm table-btn border-0'  style={{color:'rgb(40 205 149)'}}>{ val.status != 'CANCELLED' ? 'Cancel' : 'Cancelled'}</button></span>
                </li>
                )
            })}
            
            
        </ul>
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginLeft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    >
                </svg>
            </span>
        </div>
    </div>
    <div className="order__data trade__history__Data" id='myElement3'>
        <div className="order__tabs__Data">
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled Time
                </h4>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Pairs</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Pairs
                    </option>
                    <option className="dropdown__option">
                        Current Pair
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>All Types</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        All Types
                    </option>
                    <option className="dropdown__option">
                        Limit Order
                    </option>
                    <option className="dropdown__option">
                        Market Order
                    </option>
                    <option className="dropdown__option">
                        Stop Market
                    </option>
                    <option className="dropdown__option">
                        Stop Limit
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data tabs__dropdown">
                <button className="pairs__button">
                    <span>Buy/Sell</span>
                     <i className="fas fa-caret-down pl-1"></i>
                </button>
                <div className="dropdown__content">
                    <option className="dropdown__option">
                        Sell
                    </option>
                    <option className="dropdown__option">
                        Buy
                    </option>
                </div>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                     Price
                </h4>

            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Amount
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Filled
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Unfilled
                </h4>
            </div>
            <div className="order__tabs__inner__Data">
                <h4>
                    Cancel All
                </h4>
            </div>
        </div>
        {/* trade history */}
        <ul className="inner__data__order">
            {trade_order?.map((val) => {
                return(
                    <li className="data">
                    <span style={{overflow:'hidden',width:'90%'}}> {secondsToHms(val?.createdAt)}</span>
                    <span>{val?.tradingCurrency}</span>
                    <span>{val?.orderType}</span>
                    <span>{val?.mode}</span>
                    <span>{val?.tokenPrice} USDT</span>
                    <span>{val?.amount}</span>
                    <span>0</span>
                    <span>0</span>
                    {/* <span style={{color:'rgb(40 205 149)'}}>Pending</span> */}

                    <span><button className='btn btn-sm table-btn border-0'  style={{color:'rgb(40 205 149)'}}>{ val.status != 'CANCELLED' ? 'Cancel' : 'Cancelled'}</button></span>
                </li>
                )
            })}
        </ul>
        <div className="bottom__resul"><span>Found <span>0</span> Results. Only display the recent 30 order
                details in the past three months.</span>
            <span className="history__popup__btn" style={{marginLeft: '4px'}}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    >
                </svg>
            </span>
        </div>
    </div>
     {/* <div className="no-data">
        <img src="assets/svg/no-data.svg" alt="" />
        <span>No Data</span>
    </div> */}
    <div className="history__popup">
        <span>
            For more order details, please check <a href="">Order History</a>.
        </span>
    </div>
    </div>
</div>
  );
};

export default OrderTable;
