import React, { useEffect, useState } from 'react';
import { Container, Row, Col ,Table} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails ,getBanners , activateAccount,BinanceBalance } from '../../../redux/actions/dashboard.action';
import RefferralCard from './referral_card'; // copy fun
import LinkCard from './link_card';
import Banner from './banner';  // slider
import SideNav from './sidenav';
import IndexBox from './indexBox';


import  '../../../_assets/css/pro-2.css';

const Dashboard = () => { 
    const dispatch = useDispatch(); 
    const user = useSelector(state => state.dashboard.user);
   
    useEffect(() => {
        dispatch(getUserDetails())        
    }, []);

    return (
        <>
            <div>
                <Row>
                    <IndexBox></IndexBox>
                </Row>
            </div>
        </>
    )
}

export default Dashboard;