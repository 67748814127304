import React,{useState,useEffect} from 'react';
import { CryptoState } from '../../../CryptoContext';
import axios from 'axios';
// import { HistoricalChart } from '../../../config/api';
import { CircularProgress, createTheme,makeStyles, ThemeProvider } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import type { ChartData, ChartArea } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { chartDays } from '../../../config/data';
import { useParams } from 'react-router';
import { HistoricalChart } from '../../../config/api';
import { useDispatch } from 'react-redux';
// import SelectButton from './SelectButton';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const CoinInfo = ({coin}) => {
    const [historicData,sethistoricalData] = useState([]);
    const [days,setDays] = useState(1);
    const {currency,symbol} = CryptoState();
    // const { coin } = useParams();
    const dispatch = useDispatch();
console.log('coin info pAGE',coin);
    const useStyle = makeStyles((theme) => ({
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 25,
            padding: 20,
            [theme.breakpoints.down("md")]: {
              width: "100%",
              marginTop: 0,
              padding: 20,
              paddingTop: 0,
            },
          },
      }));
      const classes = useStyle();

      const currency2 = 'INR';

    const fatchHistoricalChart = async () =>{
        const { data } = await axios.get(HistoricalChart(coin?.id,days));
        sethistoricalData(data.prices);
        console.log('datadss',data);
    }
    
    useEffect(() => {
      fatchHistoricalChart();


    },[coin]);
    // addEventListener('load', (event) => {});

window.onload = () => {
  fatchHistoricalChart()

 };
    
      const darkTheme = createTheme({
        palette:{
            primary:{
                main:'#fff',
            },
            type:'dark',
        },
    });
    // console.log('historicalData',historicData);



    const labels =  historicData.map((coin) => {
      let date = new Date(coin[0]);
      let time =
        date.getHours() > 12
          ? `${date.getHours() - 12}:${date.getMinutes()} PM`
          : `${date.getHours()}:${date.getMinutes()} AM`;
      return days === 1 ? time : date.toLocaleDateString();
    })
// console.log('historicData historicData',historicData);
   const data1 = {
      labels,
      datasets: [
        
        {
          data: historicData.map((coin) => coin[1]),
          label: `Price ( Past ${days} Days ) in USDT`,
          borderColor: "#EEBC1D",
        },
        // {
        //   label: 'Dataset 2',
        //   data: historicData.map((coin) => coin[1]),
        //   borderColor: 'rgb(53, 162, 235)',
        //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // },
      ],
    };
    
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Line Chart',
        },
      },
    };

  return (
      <ThemeProvider theme={darkTheme}>
          <div className={classes.container}>
              {
                  !historicData ? ( 
                      <CircularProgress style={{color:'gold'}} size='250' thickness={1}/>
                  ):(<>
                        {/* <Line 
                        data={{
                            labels: historicData.map((coin) => {
                              let date = new Date(coin[0]);
                              let time =
                                date.getHours() > 12
                                  ? `${date.getHours() - 12}:${date.getMinutes()} PM`
                                  : `${date.getHours()}:${date.getMinutes()} AM`;
                              return days === 1 ? time : date.toLocaleDateString();
                            }),
            
                            datasets: [
                              {
                                data: historicData.map((coin) => coin[1]),
                                label: `Price ( Past ${days} Days ) in ${currency}`,
                                borderColor: "#EEBC1D",
                              },
                            ],
                          }}
                        /> */}

                    <Line  options={{
                          elements: {
                            point: {
                              radius: 1,
                            },
                          },
                        }} data={data1} />
                  <div style={{display:'flex',marginTop:20,justifyContent:'space-around',width:'100%'}}>
                    {/* {chartDays.map(day =>(
                      
                      // <SelectButton key={day.value}  onClick={ () => {setDays(day.value)}} selected={day.value == days}> {day.label} </SelectButton>
                    ))} */}
                  </div>

                  </>
                )}
          </div>
      </ThemeProvider>
  );
};

export default CoinInfo;
