import React, { useEffect, useState } from 'react';
import { kycpage } from '../../../../lang/lang';
import { LangState } from '../../../../LangugeContext';
import img1 from '../../../../_assets/images/img/security-level-medium.f4dce6ec.svg';
import img2 from '../../../../_assets/images/img/email.6b24ac96.svg';
import img3 from '../../../../_assets/images/img/phone.a24afef6.svg';
import img4 from '../../../../_assets/images/img/check-circle.50ea6ba5.svg';
import img5 from '../../../../_assets/images/img/google.7ba19c58.svg';
import img6 from '../../../../_assets/images/img/api.2a0073e6.svg';
import img7 from '../../../../_assets/images/img/withdraw.84808c5e.svg';
import img8 from '../../../../_assets/images/img/pwd.ce6a5696.svg';
import img9 from '../../../../_assets/images/img/check-circle.50ea6ba5.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../../redux/actions/dashboard.action';
import { TOKEN_PROJECT_NAME } from '../../../../_constants';
import Modal from 'react-responsive-modal';
import { generate_2fa_qrcode,enable_2fa } from '../../../../redux/actions/google2fa.action';
import { toast } from '../../../../components/toast';

const User_profile = ({history}) => {
    const dispatch =useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const qr = useSelector(state => state.google2fa.create2faQr);
    const [otp, setotp] = useState();

    const [open, setOpen] = useState(false);
    const onCloseModal = () => setOpen(false);
    const onOpenModal = () => setOpen(true);
console.log('qr',user);
    const {langchange} = LangState();
    useEffect(() => {

        dispatch(getUserDetails())        
        dispatch(generate_2fa_qrcode())
    },[]);
const handelSubmit = (e) => {
    e.preventDefault();
    console.log('e.preventDefault();',e);
    dispatch(enable_2fa({otp:otp}));
    document.getElementById("form").reset();
    setotp();
} 
const copyToClipboardId = () => {
    var textField = document.createElement('textarea')
    textField.innerText =  qr?.qr_code?.base32;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success('Google Verification Code copied');
}
var verifyImage =    <img src={img4} className="check-it" width="20px" height="20px" />;

  return (
    <div>
         <div className="header-2">
            <div className="container-s">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link active" to="/vendor/user-profile"> {kycpage['0']['nav']['btn1'][langchange]}  </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/security"> {kycpage['0']['nav']['btn2'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link " to="/vendor/kyc">  {kycpage['0']['nav']['btn3'][langchange]} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn4'][langchange]}   </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData"> {kycpage['0']['nav']['btn5'][langchange]}  </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/vendor/NoData">  {kycpage['0']['nav']['btn6'][langchange]}  </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="user-profile mt-5">
        <div className="container con-profile">
            <div className="row m-0">
                <div className="col-12">
                    <div className="edit-profile">
                        <div className="circle-profile">
                            <span> 75</span>
                        </div>
                        <div className="edit-data ml-4">
                            <div className="data-1 d-flex " style={{alignItems: 'center' }}>
                                <p className="mb-0 p-0"> <a href="#"> <i className="fa fa-pencil-square-o "
                                            aria-hidden="true"></i>
                                    </a></p>
                                <p className="ml-3 mb-0 bg-warning" style={{fontSize:'14px', borderRadius: '4px'}}> Unverfled
                                </p>
                            </div>
                            <div className="ids d-flex justify-content-between align-items-center">
                                <div className="id">
                                    <p className="mb-0">UID: <span>{user?.username}</span><i className="fa fa-clone"
                                            aria-hidden="true"></i></p>
                                </div>
                                {/* <div className="name-1">
                                    <p className="mb-0">(UTC+8)<span>Asia/Singapore</span><i
                                            className="fa fa-pencil-square-o"></i></p>
                                </div> */}

                                {/* <div className="name-2">
                                    <!-- <span className=""> KCS Pay Fees </span> -->
                                    <form>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="switch1" />
                                            <label className="custom-control-label " for="switch1">{TOKEN_PROJECT_NAME} Pay Fees</label>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="ower-all-data">
                        <div className="row m-0">
                            <div className="col-lg-6 col-sm-4 some-column">
                                <div className="f-secu d-flex justify-content-between">
                                    <img src={img1} />
                                    <div className="text-secu">
                                        <a href="#"> Security Level </a>
                                        <p className="mb-0"> Medium </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 some-column">
                                <div className="f-secu d-flex justify-content-between align-items-center">
                                    <div className="img-a"> VIP </div>
                                    <div className="text-secu ml-3">
                                        <a href="#"> Trading Fee Level </a>
                                        <div className="d-flex justify-content-between align-items-center pt-2">
                                            <p className="mb-0 p-0 d-flex justify-content-between align-items-center mark">
                                                Mark:<span className="chenge-code"> 0.1% </span> </p>
                                            <p className="mb-0 p-0 d-flex justify-content-between align-items-center mark">
                                                Mark:<span className="chenge-code"> 0.1% </span> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- column --> */}
                <div className="col-12 pt-5 profile-card-column">
                    <div className="row m-0">
                        <div className="col-lg-4 col-md-6">
                            <Link to={'/vendor/verify-email'} >
                                <div className="card d-flex flex-row">
                                    <img src={img2} />
                                    <div className="card-text">
                                        <h6 className="mb-0"> Email </h6>
                                        <p className={user?.email ? 'text-success mb-0 mt-1':'text-danger mb-0 mt-1'}> {user?.email ? user?.email :'disable'}</p>
                                    </div>
                                    <a href="#" className="stretched-link"></a>
                                        {user?.email ?verifyImage:''}

                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <Link to='/vendor/verify-email'>
                            <div className="card d-flex flex-row">
                                <img src={img3} />
                                <div className="card-text">
                                    <h6 className="mb-0"> Phone </h6>
                                    <p className={user?.phone ? 'text-success mb-0 mt-1':'text-danger mb-0 mt-1'} > {user?.phone ? user?.phone:'disable'}</p>
                                </div>
                                <a href="#" className="stretched-link"></a>
                                {user?.phone ?verifyImage:''}
                                {/* <img src={img4} className="check-it" width="20px" height="20px" /> */}
                            </div>
                        </Link>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card d-flex flex-row" onClick={() => onOpenModal()}>
                                <img src={img5} />
                                <div className="card-text">
                                    <h6 className="mb-0"> Google Verification </h6>
                                    <p className={user?.twofa_enable == false ? "mb-0 text-danger" : "mb-0 text-success"}>{user?.twofa_enable == false ?'Disabled':'Enable'} </p>
                                </div>
                                {/* <a href="#" className="stretched-link"></a> */}

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card d-flex flex-row">
                                <img src={img6} />
                                <div className="card-text">
                                    <h6 className="mb-0"> API </h6>
                                    <p className="mb-0"> 0 API Enabled</p>
                                </div>
                                <a href="#" className="stretched-link"></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card d-flex flex-row">
                                <img src={img7} />
                                <div className="card-text">
                                    <h6 className="mb-0"> Withdraw Limit </h6>
                                    <p className="mb-0"> 24h Max. withdraw: 10 BTC</p>
                                </div>
                                <a href="#" className="stretched-link"></a>

                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="card d-flex flex-row" >
                                <img src={img8} />
                                <div className="card-text">
                                    <h6 className="mb-0"> Trading Password </h6>
                                    <p className={user?.trading_password ? 'text-success mb-0 mt-1':'text-danger mb-0 mt-1'} > {user?.trading_password ? 'Enable':'Disabled'}</p>
                                </div>
                                <Link to="trading-otp" className="stretched-link"></Link>

                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <!-- column --> */}
                <div className="col-12 pt-5 table-column">
                    <h6 className="mb-3">Trust this device and IP</h6>
                    <div className="table-responsive-lg">
                        <table className="table table-hover" style={{width: '1200px'}}>
                            <thead>
                                <tr className="text-center">
                                    <th>Trusted Device</th>
                                    <th>Login Region</th>
                                    <th> Recent Activity</th>
                                    <th> Remove Device<i className="fa fa-question-circle-o" aria-hidden="true"></i> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> <i className="fa fa-angle-right mr-4" aria-hidden="true"></i> <i
                                            className="fa fa-desktop mr-3" aria-hidden="true"></i> Windows Chrome 102</td>
                                    <td className="text-center">India Chandigarh(124.**.14)</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> <i className="fa fa-times" aria-hidden="true"></i></td>
                                </tr>
                                <tr>
                                    <td> <i className="fa fa-angle-right mr-4" aria-hidden="true"></i> <i
                                            className="fa fa-desktop mr-3" aria-hidden="true"></i> Windows Chrome 102</td>
                                    <td className="text-center">India Chandigarh(124.**.14)</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> <i className="fa fa-times" aria-hidden="true"></i></td>
                                </tr>
                                <tr>
                                    <td> <i className="fa fa-angle-right mr-4" aria-hidden="true"></i> <i
                                            className="fa fa-desktop mr-3" aria-hidden="true"></i> Windows Chrome 102</td>
                                    <td className="text-center">India Chandigarh(124.**.14)</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> <i className="fa fa-times" aria-hidden="true"></i></td>
                                </tr>
                                <tr>
                                    <td> <i className="fa fa-angle-right mr-4" aria-hidden="true"></i> <i
                                            className="fa fa-desktop mr-3" aria-hidden="true"></i> Windows Chrome 102</td>
                                    <td className="text-center">India Chandigarh(124.**.14)</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> <i className="fa fa-times" aria-hidden="true"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ul className="pagination justify-content-end mt-4">
                        <li className="page-item"><a className="page-link border-0 text-dark " href="javascript:void(0);"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark active" href="javascript:void(0);">1</a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);">2</a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);">3</a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);">4</a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);">5</a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                      </ul>
                </div>
                {/* <!-- column --> */}
                <div className="col-12 pt-5 table-column">
                    <h6 className="mb-3">Recent Login</h6>
                    <div className="table-responsive-lg">
                        <table className="table table-hover " style={{width: '1200px'}}>
                            <thead>
                                <tr>
                                    <th>Login Region</th>
                                    <th className="text-center"  >	Login Device</th>
                                    <th className="text-center"> Time</th>
                                    <th className="text-center"> Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> India Chandigarh(124.**.14)</td>
                                    <td className="text-center"><i className="fa fa-television mr-3" aria-hidden="true"></i>Linux Chrome 101</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> Offline</td>
                                </tr>
                                <tr>
                                    <td> India Chandigarh(124.**.14)</td>
                                    <td className="text-center"><i className="fa fa-television mr-3" aria-hidden="true"></i>Linux Chrome 101</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> Offline</td>
                                </tr>
                                <tr>
                                    <td> India Chandigarh(124.**.14)</td>
                                    <td className="text-center"><i className="fa fa-television mr-3" aria-hidden="true"></i>Linux Chrome 101</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> Offline</td>
                                </tr>
                                <tr>
                                    <td> India Chandigarh(124.**.14)</td>
                                    <td className="text-center"><i className="fa fa-television mr-3" aria-hidden="true"></i>Linux Chrome 101</td>
                                    <td className="text-center">2022/06/01 12:15:19</td>
                                    <td className="text-center"> Offline</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ul className="pagination justify-content-end mt-4 ">
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);"><i className="fa fa-angle-left" aria-hidden="true"></i></a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark active" href="javascript:void(0);">1</a></li>
                        <li className="page-item"><a className="page-link border-0 text-dark" href="javascript:void(0);"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                      </ul>
                </div>
            </div>
        </div>
    </div>

            <Modal open={open} onClose={onCloseModal} animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design', }} center>
                <div className='container'>
                    <div className='row deposit-modal align-items-center justify-content-center'>
                        <div className='col-md-12 text-center'>
                            <h2>Google Verification</h2>
                            {/* <div className='p2p-pay-system'><p className=''>Please make sure that the payment account is real-name certified. The selected payment method will be shown to the trader in a trade.</p></div> */}

                            <img src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' +  qr?.qr_code?.otpauth_url} className='' alt="website qrcode"/>
                            <div className='mt-3 d-flex justify-content-between align-items-center text-left' style={{width: '80%', margin: 'auto'}}>
                                <div className='' style={{width :"85%"}}>
                                    <p style={{overflowWrap: 'break-word'}}> {qr?.qr_code?.base32} </p>
                                </div>
                                <div className=''>
                                    <p className='d-flex' onClick={() => copyToClipboardId()}> <i class='fas fa-copy'></i> </p>
                                </div>

                            </div>
                            
                            
                        </div>
                        <div className='col-6'>
                                <form onSubmit={handelSubmit} id='form' method='POST'>
                                            
                                    <div className="form-group text-left">
                                        <label htmlFor="2fa" ></label>
                                        <input type='text' className='form-control' name='2fa' onChange={(e) => setotp(e.target.value)} value={otp}  placeholder='2fa Code' required />
                                    </div>
                                    
                                    <div className="form-group text-center">
                                        <button type="submit" style={{ backgroundColor: 'rgb(40 205 149)', padding: '5px 18px', fontSize: '18px' }} className=' submit__btn btn text-white' name="submit" >Submit</button>
                                    </div>

                                    </form>
                            </div>
                    </div>
                </div>
            </Modal>
    </div>
  )
}

export default User_profile