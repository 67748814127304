import axios from "axios";
import { toast } from "../components/toast";

export { _fetch as fetch };

function handleError(error, reject) {
  
  if (!error) {
    setTimeout(() => {
      window.localStorage.clear();
      // window.location = `/`;
    }, 2000);

    return toast.error("Something went wrong, Please try again");
  }

  if (error) {
    let status = error.status;
    // console.log('outer error ' ,error)
    if (status === 401) {
      setTimeout(() => {
        window.localStorage.clear();
        window.location = `/`;
      }, 2000);

      return toast.error("Session expired, you are going to be logout");
    }

    if (status === "404 Not Found") {
      return toast.error("Internal Server Error.");
    }

  }

  if (error) {
    toast.error(error.data.message);
  }

  reject(error); 
  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMehod(method, path, body, options, params) {
  let config = {};
  if (options) {
    if (options) {
      config.headers = {...options};
    }
  }
  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  }
  if (method === "post" || method === "put") {
    return axios[method](`${path}`, body, config);
  }
}

function _fetch(method, path, body, options, params) {
  return new Promise((resolve, reject) => {
    return setMehod(method, path, body, options, params)
      .then(function(response) {
        // console.log('outer res' , response)
        handleResponse(response, resolve);
        return;
      })
      .catch(function(error) {
        // console.log('outer eroor' , error)
        handleError(error.response, reject);
        return;
      });
  });
}
