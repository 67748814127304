import { fetch } from '../axios.service';
import { API_HOST } from "../../_constants";

/* AUTH APi's */
export const loginApi = (data, options) => {
  // console.log('login api data',data, options);
  return fetch("post", `${API_HOST}/api/auth/signin`, data, options);
};
export const loginVerifyApi = (data, options) => {
  // console.log('login api data',data, options);
  return fetch("post", `${API_HOST}/api/auth/verify_signin`, data, options);
};


export const checkSponserApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/sponser_details`, data, options);
};
export const registerApi = (data, options) => { 
  console.log('register data 5',data,options)
  return fetch("post", `${API_HOST}/api/auth/signup`, data, options);
};

export const resentOtpApi = (data, options) => { 
  return fetch("post", `${API_HOST}/api/resent_verify_otp`, data, options);
};
export const MobileOtpApi = (data, options) => { 
  return fetch("post",`${API_HOST}/api/auth/registerOtp`,data,options);
};
export const loginOtpApi = (data, options) => { 
  return fetch("post",`${API_HOST}/api/auth/userOtp`,data,options);
};


// yadwinder
export const forgotApi = (data) => {
  return fetch("post", `${API_HOST}/api/auth/forgotpassword`, data);
};
export const forget_Otp_Api = (data) => {
  return fetch("post", `${API_HOST}/api/auth/forgotpasswordotp`, data);
};
export const passwordResetApi = (data) => {
  return fetch("post", `${API_HOST}/api/auth/verify`, data);
};
export const incomeTransactionApi = (data, options) => {
  console.log(data, options);
  return fetch("post", `${API_HOST}/api/income_transaction`, data, options);
  // return fetch("post", `http://165.232.185.158:8080/api/income_transaction`, data, options);
};

export const newTypeincomeTransactionApi = (data,options) => {
  console.log('income_type_transaction');
  return fetch("post", `${API_HOST}/api/income_type_transaction`, data, options);
  // return fetch("post", `http://165.232.185.158:8080/api/income_transaction`, data, options);
};

export const fuelTransactionApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/income_transaction`, data, options);
};

// ////// 
export const createTicketApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/create_ticket`, data, options);
};
export const viewTicketApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/view_ticket`, data, options);
};

export const closeTicketApi = (data, options) => {
  console.log('openTicketApi',data, options);
  return fetch("post", `${API_HOST}/api/closed_tickets`, data, options);
};
export const verifyEmailApi = (data, options) => { 
  return fetch("post", `${API_HOST}/api/auth/verify`, data, options);
};


export const getUserDetails = (data, options) => {
  // return fetch("post", `${API_HOST}/api/user/profile`, data, options);
  return fetch("get", `${API_HOST}/api/v1/userProfile`, data, options);

};
export const generate_2fa_qrcodeApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/auth/generate_2fa_qrcode`, data, options);
};
export const enable_2faApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/auth/enable_2fa`, data, options);
};
export const depositToken = (data, options) => {
  return fetch("get", `${API_HOST}/api/deposit_tokens`, data, options);
};

export const P2pPaymentMethodApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/payment_methods`, data, options);
};
export const P2pPAymentFieldApi = (data,option) =>{
  return fetch("post",`${API_HOST}/api/payment_template`,data,option);
}
export const P2pPaymentFormSubmitApi = (data,option) => {
  return fetch("post",`${API_HOST}/api/save_payment_method`,data,option);
}
export const P2pGetPaymentMethodApi = (data, options) => {
  // return fetch("post", `${API_HOST}/api/user/profile`, data, options);
  return fetch("post", `${API_HOST}/api/user_payment_methods`, data, options);
};
export const Token_address_api = (data, options) => {
  // return fetch("post", `${API_HOST}/api/user/profile`, data, options);
  return fetch("post", `${API_HOST}/api/deposit_address`, data, options);
};
export const deposit_transactions_api = (data, options) => {
  // return fetch("post", `${API_HOST}/api/user/profile`, data, options);
  return fetch("post", `${API_HOST}/api/deposit_transactions`, data, options);
};
export const depositTokenDetails = (data, options) => {
  // return fetch("post", `${API_HOST}/api/user/profile`, data, options);
  return fetch("post", `${API_HOST}/api/deposit_token_details`, data, options);

};
export const acc_data_update_api = (data,options) => {
  return fetch("post", `${API_HOST}/api/auth/verificationotp`,data,options)
}
export const update_acc_data_update_api = (data,options) => {
  return fetch("post", `${API_HOST}/api/auth/verfication`,data,options)
}
export const trading_otp_api = (data,options) => {
  return fetch("post", `${API_HOST}/api/auth/tradingpasswordotp`,data,options)
}
export const update_trading_pass_api = (data,options) => {
  return fetch("post", `${API_HOST}/api/auth/tradingpassword`,data,options)
}
export const check_trading_pass_api = (data,options) => {
  return fetch("post", `${API_HOST}/api/auth/verify_trading_password`,data,options)
}
export const kycform = (data, options) => {
  // console.log('login api data',data, options);
  return fetch("post", `${API_HOST}/api/update_basic_details`, data, options);
};
export const kycAadharform = (data, options) => {
  return fetch("post", `${API_HOST}/api/generate_aadhar_otp`, data, options);
};
export const kycAadharVerifyform = (data, options) => {
  return fetch("post", `${API_HOST}/api/verify_aadhar_otp`, data, options);
};
export const kycProof = (data, options) => {
  return fetch("post", `${API_HOST}/api/upload_proof_images`, data, options);
};
export const kycPanProof = (data, options) => {
  return fetch("post", `${API_HOST}/api/upload_pan_image`, data, options);
};
export const uploadPaymentApi = (data, options,id) => {
  return fetch("post", `${API_HOST}/api/submit_payment_proof/${id}`, data, options);
};
export const kycSignatureProof = (data, options) => {
  return fetch("post", `${API_HOST}/api/upload_signature_image`, data, options);
};
export const p2p_my_posting_api = (data,option) => {
  return fetch("post",`${API_HOST}/api/my_postings`,data,option);
}
export const buy_p2p_api = (data,option) => {
  return fetch("post",`${API_HOST}/api/token_postings`,data,option);
}
export const create_p2p_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/create_p2p_post`,data,option)
}
export const get_withdraw_otp_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/withdrawotp`,data,option)
}
export const submit_withdraw_req_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/withdrawrequest`,data,option)
}
export const available_amount_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/walletbalance`,data,option)
}
export const withdraw_history_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/withdrawHistory`,data,option)
}
export const AllDeposit_history_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/all_token_deposit_transactions`,data,option)
}

export const action_sell_status_api = (data,options) => {
  return fetch("post", `${API_HOST}/api/update_p2p_order_status`,data,options)

}












export const coinAddress = (data, options) => {
  console.log('check data post',options)
  return fetch("get", 'http://crypto.mlmstore.co/Api/Apikey/generate_address?user_id='+options);

};
export const getcoinAddressdata = (data, options,id) => {
  console.log('check data post',options)
  return fetch("get", `http://crypto.mlmstore.co/Api/Apikey/transactions?user_id=${options}&tokenname=${id}`);

};

export const packageApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/packages`, data, options);
};
export const ApiBindApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/user/update_binance_credentials`, data, options);
};
export const Activation = (data, options) => {
  return fetch("post", `${API_HOST}/api/activate_account`, data, options);
};
export const scalingApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/check_roi_status`, data, options);
};
export const NewInvestment = (data, options) => {
  console.log('api data',data,options);
  return fetch("post", `${API_HOST}/api/start_investment`, data, options);
};
export const binanceBalance = (data, options) => {
  return fetch("get", `${API_HOST}/api/binance_balance`, data, options);
};

export const usdtTransactionsApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/user/usdt_transactions`, data, options);
};

export const active_trades_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/active_trades`, data, options);
};


export const active_closed_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/closed_trades`, data, options);
};


//
export const otp_resent = (data, options) => {
  return fetch("post", `${API_HOST}/api/resent_verify_otp`, data, options);
};
 
export const withdrawRequestApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/user/submit_withdraw_request`, data, options);
};
  
export const profitHistoryApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/datewise_profit`, data, options);
};
//

 
export const withdrawRequestsListApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/user/withdraw_requests`, data, options);
};



export const bannersApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/banners`, data, options);
};

export const ActivateAccountApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/activate_account`, data, options);
};

export const walletTransferApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/wallet_transfer`, data, options);
};

export const walletTransactionApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/wallet_transaction`, data, options);
};


// 

export const updateWalletAddressApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/user/update_wallet_address`, data, options);
};

export const updateProfileApi = (data, options) => {
  return fetch("post", `${API_HOST}api/test/user`, data, options);
};




export const get_user_assets_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/user_assets`, data, options);
};
export const transfer_user_assets_api = (data,option) =>{
  return fetch("post", `${API_HOST}/api/transfer_wallet`,data,option);
};
export const get_swap_tokens_api = (data, options) => {
  return fetch("get", `${API_HOST}/api/swap_tokens`, data, options);
};
export const get_news_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/news`, data, options);
};
export const get_calculate_swap_sell_price_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/calculate_swap_sell_price`, data, options);
};
export const get_calculate_swap_buy_price_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/calculate_swap_buy_price`, data, options);
};
export const sell_swap_token_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/sell_swap_token`, data, options);
};
export const buy_swap_token_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/buy_swap_token`, data, options);
};
export const get_swap_transactions_api = (data,options) => {
  return fetch("post",`${API_HOST}/api/swap_transactions`,data,options)
}
export  const get_swap_balance_api  = (data,options) => {
  return fetch("post",`${API_HOST}/api/userwalletbalance`,data,options);
}
export const get_swap_balance_wallet_type_api = (data,options) => {
  return fetch("post",`${API_HOST}/api/useraccountbalance`,data,options);
}
export const get_swap_2nd_balance_wallet_type_api = (data,options) => {
  return fetch("post",`${API_HOST}/api/useraccountbalance`,data,options);
}

export const getCoinPrice_api = (data,options) => {
  return fetch("get",`${API_HOST}/api/token_trade_price/${data}`,options);
}
export const new_swap_transactions_api = (data,options) => {
  return fetch("post" , `${API_HOST}/api/latest_swap_transactions`,data,options);
}
export const tron_price_as_token_api = (data,options) => {
  return fetch("get" , `https://api.coingecko.com/api/v3/simple/price?ids=${data}&vs_currencies=btc,usd,inr,bnb`)
}
export const wallet_details_api = (data,options) => {
  return fetch("post",`${API_HOST}/api/userwallet`,data,options)
}

export const submit_buy_p2p_api = (data,option) =>{
  return fetch("post", `${API_HOST}/api/place_p2p_order`,data,option);
};
export const sell_p2p_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/p2p_sell_orders`,data,options);
}
export const order_p2p_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/p2p_purchase_orders`,data,options);
}
export const view_post_details_p2p_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/p2p_post_orders`,data,options);
}
export const view_user_order_p2p_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/p2p_order_details`,data,options);
}
export const view_Referral_api = (data, options) => {
  return fetch("get", `${API_HOST}/api/refferals`,data,options);
}
export const get_trade_order_api = (data,option) => {
  return fetch("post", `${API_HOST}/api/trading_token_orders`,data,option);
}
export const get_ViewAllOrder_api = (data,option) => {  
  return fetch("post", `${API_HOST}/api/trading_orders`,data,option);
}
export const cancelTradeOrder_api = (data,option) => {  
  return fetch("post", `${API_HOST}/api/cancel_order`,data,option);
}
// chart apis
export const tradeCoinAction_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/place_trading_sell_order`,data,options);
}




/*****Old Apis */

export const verifyOtpApi = (data) => {
  return fetch("post", `${API_HOST}/v1/restaurant/verifyOtp`, data);
};

// export const passwordResetApi = (data) => {
//   return fetch("post", `${API_HOST}/v1/restaurant/resetPassword`, data);
// };

/* RESTAURANT API's  */
export const onlineStatusRestaurantApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/editRestaurantOnlieStatus`, data, options);
};

export const restaurantProfileApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/user`, data, options);
};

/* MENU APi's */
export const addMenuApi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addMenu`, data, options);
};

export const uploadCsvFileApi = (data, options) => {
  return fetch("post", `${API_HOST}/v1/restaurantMenu/importMenuUsingCSV`, data, options);
};


export const getMenuApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/menuList${query}`, {}, options);
};


export const deleteMenuApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteMenu${query}`, {}, options);
};

export const updateAvailibleStatusApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/editMenuOnlieStatus`, data, options);
};

export const getMenuDetailApi = (query, options) => {
  return fetch("get", `${API_HOST}/common/getRestaurantMenuDetail${query}`, {}, options);
};

/* DIsh API's */
export const getDishesApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/DishesList${query}`, {}, options);
};

export const addDishApi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addDishes`, data, options);
};

export const addDishImagesApi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addDisheImages`, data, options);
};

export const deleteDishApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteDishes${query}`, {}, options);
};

export const getDishDetailApi = (query, options) => {
  return fetch("get", `${API_HOST}/common/getRestaurantDisheDetail${query}`, {}, options);
};

export const dishOnlineStatusApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/editDishesOnlieStatus`, data, options);
};

/* ORDER API's */
export const getOrdersApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getOrderList${query}`, {}, options);
};

export const orderAcceptedApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/orderAccepted`, data, options);
};

export const rejectOrderApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/orderRejected`, data, options);
};

export const orderDetailApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getOrderDetail${query}`, {}, options);
};

/* CUSTOMIZATION API's */
export const addToppingTitleAPi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addToppingTitle`, data, options);
};

export const getToppingApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/getTopping${query}`, {}, options);
};

export const addToppingItemAPi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addToppinItem`, data, options);
};

export const updateToppingApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/setDefaultToppinItem`, data, options);
};

export const deleteToppingApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteTopping${query}`, {}, options);
};

export const deleteToppingTitleApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteToppingTitle${query}`, {}, options);
};

/* Sub Topping */
export const addSubToppingItemAPi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addSubToppinItem`, data, options);
};

export const getSubToppingApi = (query, options) => {
  return fetch("get", `${API_HOST}/common/getRestaurantSubToppings${query}`, {}, options);
};

export const deleteSubToppingTitleApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteSubTopping${query}`, {}, options);
};

/* Finance */ 
export const transactionApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getTransactionData${query}`, {}, options);
};

/* Settings */
export const changePasswordApi = (data, options) => {
  return fetch("put", `${API_HOST}/v1/restaurant/changePassword`, data, options);
};

export const reviewRatingApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/getReviewList${query}`, {}, options);
};

/* Notification */
export const notificationApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getNotificationList${query}`, {}, options);
};

/* Working Time */
export const updateWorkingHoursApi = (data, options) => {
  return fetch("post", `${API_HOST}/v1/restaurant/insertAndUpdateWorkingTime`, data, options);
};

export const updateWorkingStatusOfDayApi = (data, options) => {
  return fetch("put", `${API_HOST}/v1/restaurant/updateWorkingStatusOfDay`, data, options);
};

export const workingHoursApi = (data, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getWorkingTime`, data, options);
};