import { makeStyles } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import { LangState } from '../../../../LangugeContext';
import dimond from '../../../../_assets/images/dimond.png';
import { assetsnav, encouragementpage, leveragedpage } from '../../../../lang/lang';

const UseStyles = makeStyles({
    assetsBtn: {
        marginTop:'80px',
        display: 'flex',
        background: 'rgba(125, 135, 146, 0.07840000000000003)',
        alignItems: 'center'
    },
    btnDesign:{
        width: '1200px',
        margin:  '0 auto',
        display: 'flex',
        marginBottom: '-16px',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        // width: '100%',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0px',
          },

    },
    btnUl:{
        display: 'flex',
        marginBottom: '1em',
    },
    btnTag:{
        height: '64px!important',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '.1px',
        borderRadius: '0!important',
        color: 'hsla(0,0%,100%,.6)',
        padding: '0 12px',
    },
    MuiButtonlabel:{
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    MuiTouchRippleroot:  {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        },
  });
const encouragement = () => {
    const classes = UseStyles();
    const {langchange} = LangState();
    return (
    <div>
        <div className={classes.assetsBtn}>
            <div className={classes.btnDesign}>
                <ul className={classes.btnUl}>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/assets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Assets'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/mainAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Main'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/tradeAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Trading'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/marginAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Margin'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                </ul>

                <ul className={classes.btnUl}>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/financialAccount' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Financial'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/depositassest' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/withdrawAssets' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Withdraw'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/record' style={{color:'rgba(0, 20, 42, 0.6)'}}> {assetsnav['0']['Deposit_Withdrawal'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                    <button className={classes.btnTag}>
                        <span className={classes.MuiButtonlabel}>
                            <li>
                                <Link to='/vendor/bonus' style={{color:'#EBC75B'}}> {assetsnav['0']['Bonus'][langchange]}</Link>
                            </li>
                        </span>
                        <span className={classes.MuiTouchRippleroot}></span>
                    </button>
                </ul>
            </div>
        </div>
        <div class="box___3B-ao">
            <div class="wrapper___1F-PJ">
                <div>
                    <div class="nav___32RUA">
                        <Link to="/vendor/bonus">
                            <i className='far fa-arrow-alt-circle-left	'></i> {encouragementpage['0']['return'][langchange]} </Link>
                        <div class="divider___1niI8 ant-divider ant-divider-vertical">
                            
                        </div>
                        <span> {encouragementpage['0']['Bonus'][langchange]}  </span>
                    </div>
                    <div class="ant-card header___3npqr ant-card-bordered ant-card-wider-padding ant-card-padding-transition">
                        <div class="ant-card-body">
                            <div class="flex___7jQQS">
                                <div class="flexItem___2AmbS">
                                    <div class="amount___2boO6">
                                        <div class="left___2hFZV">
                                            <img src={dimond} />
                                        </div>
                                        <div class="right___1Ucca">
                                            <p class="head___2vytd">
                                                <span>{encouragementpage['0']['Holding'][langchange]} </span>
                                                <i class="fa fa-question"  style={{width: '13px', height: '13px', color: 'rgb(51, 51, 51)'}}></i>

                                                <i class="fa fa-shopping-cart" style={{width: '13px', height: '13px', color: 'rgb(155, 155, 155)'}}></i>

                                            </p>
                                            <p class="center___2ITm8">
                                                <span title="0">0</span>
                                                <span>KCS</span>
                                            </p>
                                            <p class="bottom___3b4nd" title="0">
                                                <span>{encouragementpage['0']['Estimated'][langchange]}:&nbsp; <span>
                                                        <span>≈ 0</span>
                                                        <span style={{marginLeft: '4px'}}>AUD</span>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider___3PgqS ant-divider ant-divider-vertical"></div>
                                <div class="flexItem___2AmbS flexAround___3YQOI">
                                    <div class="item___2WhKh">
                                        <p class="head___2vytd">
                                            <span>{encouragementpage['0']['Bonus'][langchange]}</span>
                                            <span class="date___-ky9g">(2022/03/07)</span>
                                        </p>
                                        <p class="center___2ITm8">
                                            <span title="0">0</span>
                                            <span>KCS</span>
                                        </p>
                                        <p class="bottom___3b4nd" title="0">
                                            <span>{encouragementpage['0']['Estimated'][langchange]}:&nbsp; <span>
                                                    <span>≈ 0</span>
                                                    <span style={{marginLeft: '4px'}}>AUD</span>
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="item___2WhKh">
                                        <p class="head___2vytd">
                                            <span>{encouragementpage['0']['total'][langchange]}</span>
                                        </p>
                                        <p class="center___2ITm8">
                                            <span title="0">0</span>
                                            <span>KCS</span>
                                        </p>
                                        <p class="bottom___3b4nd" title="0">
                                            <span> {encouragementpage['0']['Estimated'][langchange]}:&nbsp; <span>
                                                    <span>≈ 0</span>
                                                    <span style={{marginLeft: '4px'}}>AUD</span>
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ant-card ant-card-bordered ant-card-wider-padding ant-card-padding-transition">
                        <div class="ant-card-body" style={{minHeight: '700px'}}>
                            <div class="option___4C9ej">
                                <p>{encouragementpage['0']['p'][langchange]}</p>
                                <button disabled="" type="button" class="ant-btn  kc-button ant-btn-primary" style={{backgroundColor: ' #73b6f6',borderRadius:'4px',color:'#fff'}}>
                                    <span>{encouragementpage['0']['btn'][langchange]} ({encouragementpage['0']['total'][langchange]} 0)</span>
                                    <canvas class="water-wave-canvas"></canvas>
                                </button>
                            </div>
                            <div class="table___POLxu">
                                <div class="ant-table-wrapper  ">
                                    <div class="ant-spin-nested-loading">
                                        <div class="ant-spin-container">
                                            <div class="ant-table ant-table-default ant-table-empty ant-table-scroll-position-left">
                                                <div class="ant-table-content">
                                                    <div class="ant-table-body">
                                                        <table class="" >
                                                            
                                                            <thead class="ant-table-thead">
                                                                <tr>
                                                                    <th class="">
                                                                        <span>{encouragementpage['0']['table']['label1'][langchange]}</span>
                                                                    </th>
                                                                    <th class="">
                                                                        <span>{encouragementpage['0']['table']['label2'][langchange]}</span>
                                                                    </th>
                                                                    <th class="">
                                                                        <span>{encouragementpage['0']['table']['label3'][langchange]}</span>
                                                                    </th>
                                                                    <th class="">
                                                                        <span>{encouragementpage['0']['table']['label3'][langchange]}</span>
                                                                    </th>
                                                                    <th class="">
                                                                        <span>{encouragementpage['0']['table']['label4'][langchange]}</span>
                                                                    </th>
                                                                    <th class="" style={{textAlign: 'center'}}>
                                                                        <span>{encouragementpage['0']['table']['label5'][langchange]}</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="ant-table-tbody"></tbody>
                                                        </table>
                                                    </div>
                                                    <div class="ant-table-placeholder">
                                                        <img src="https://assets.staticimg.com/kucoin-main-web/1.6.0/static/noReferralData.3155a5af.svg" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default encouragement