import { Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CryptoState } from '../../../CryptoContext';
import { get_2nd_swap_balance_wallet_type, get_swap_balance_wallet_type, get_wallet_details } from '../../../redux/actions/assets.action';
import { tradeCoinAction } from '../../../redux/actions/Chart.action';

const SpotSectionMarkit = (props) => {
    var { id,pair } = useParams();
    const { pairName,cancelOrder, setcancelOrder} = CryptoState();

    const isSignedIn = useSelector(state => state.persist.isSignedIn);
    const isAuthenticated = isSignedIn;
   
    const swap_balance  = useSelector(state => state.assets.balance_with_type);
    const swap_2nd_balance  = useSelector(state => state.assets.swap_2nd_balance);

    const [coinName,setCoinName] = useState('usdt')

    const [usdt,setusdt] = useState(1)
    const [btc,setbtc] = useState(0) 
    const [buyBalance,setbuyBalance] = useState(0) 
    const [sellBalance,setsellBalance] = useState(0) 

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(get_wallet_details())
        dispatch(get_swap_balance_wallet_type({tokenName: pairName,wallet_type:'trading_wallet'},setbuyBalance));
        // console.log('markit id',id);
        dispatch(get_2nd_swap_balance_wallet_type({tokenName: props.props,wallet_type:'trading_wallet'},setsellBalance))


    },[])
    const fixDesit = (val,state) =>{
        // console.log('val % 1',val % 1);
        var data  = Math.floor(val*1000000)/1000000;
        state(data)
    }
    // const usdtPriceFun = (val) => {
    //     console.log('val',val);
    //     setusdt(val);
    //     var newval  = val *   wallet_details3.prices.btc;
    //     setbtc(newval);

    // }
    // const usdtWithPersent = (val) => {
    //     const setNewPrice = swap_balance.balance / 100 * val;
    //     console.log('val',setNewPrice);
    //     setusdt(setNewPrice);
    //     // var newval  = setNewPrice *   wallet_details3.prices.btc;
    //     // setbtc(newval);
    // }
    // const btcPriceFun = (val) => {
    //     setbtc(val);
    //     var newval  = (val / wallet_details3.prices.btc)*   wallet_details3.prices.usd;
    //     setusdt(newval);
    // }
    const addUsdt = () => {
        if(usdt > 0){
            var calData = (parseFloat(usdt) + .1);
            var data =  Math.floor(calData*1000000)/1000000
            setusdt(data);
       }
    }
    const SubcUsdt = () => {
        if(usdt > 0.1){
            var calData = (parseFloat(usdt) - .1);
            var data =  Math.floor(calData*1000000)/1000000

            setusdt(data);
        }
    }
    // const addBtc = () => {
        
    //     var calData = (parseFloat(btc) + .1);
    //     setbtc(calData);
        
    // }
    // const SubcBtc = () => {
    //     var calData = (parseFloat(btc) - .1);
    //     setbtc(calData);
       
    // }
    // sell coin fun///////////////////////////////////////////////////////////////////////////////////
    const [sellUsdt,setsellUsdt] = useState(1);
    const [sellBtc,setsellBtc] = useState(0);

    const addsellUsdt = () => {
        if(sellUsdt > 0){
            var calData = (parseFloat(sellUsdt) + .1);
            var data =  Math.floor(calData*1000000)/1000000
            setsellUsdt(data);
        }
    }
    const SubcsellUsdt = () => {
        if(sellUsdt > 0.1){
            var calData = (parseFloat(sellUsdt) - .1);
            var data =  Math.floor(calData*1000000)/1000000
            setsellUsdt(data);
        }
    }
    // const addsellBtc = () => {
        
    //     var calData = (parseFloat(sellBtc) + .1);
    //     setsellBtc(calData);
        
    // }
    // const SubcsellBtc = () => {
    //     var calData = (parseFloat(sellBtc) - .1);
    //     setsellBtc(calData);
       
    // }
    // action / function start buy sell etc///////////////////////////////////////////////////////////////////

    const buyMarketCoin = () => {
        var test = window.confirm('Are you sure you want to place this order!');
        if(test === true){
            var data = {
                token_id: swap_balance?.token_id?.[0],
                mode:'BUY',
                tradingCurrency:props.props ,
                amount:usdt ,
                orderType:'MARKET',
            }

            dispatch(tradeCoinAction(data,pair,setsellBalance,setbuyBalance));
        }
    }
    const sellMarketCoin = () => {
        var test = window.confirm('Are you sure you want to place this order!');
        if(test === true){
            var data = {
                token_id: swap_2nd_balance?.token_id?.[0],
                mode:'SELL',
                tradingCurrency:props.props,
                amount:sellUsdt,
                orderType:'MARKET',
            }
            
            dispatch(tradeCoinAction(data,pair,setsellBalance,setbuyBalance));
        }
    }
    // console.log('props.props',props.props);
    const sellUsdtWithPersent = (val) => {
        const setNewPrice = swap_2nd_balance.balance / 100 * val;
        console.log('val',setNewPrice);
        setsellUsdt(setNewPrice);
        // var newval  = setNewPrice *   wallet_details3.prices.btc;
        // setbtc(newval);
    }
    const usdtWithPersent = (val) => {
        const setNewPrice = swap_balance.balance / 100 * val;
        console.log('val',setNewPrice);
        setusdt(setNewPrice);
        // var newval  = setNewPrice *   wallet_details3.prices.btc;
        // setbtc(newval);
    }
  return (
      <>
        <div className="price__wrapper active">
            <div className="price__input">
                <div className="price__box">
                    <input type="number" readonly placeholder="Best Market Price" readOnly/>
                </div>
                <div className="price__box">
                    <input type="number" placeholder="Amount" value={usdt} onChange={(e) =>  fixDesit(e.target.value,setusdt)} />
                    <span>{coinName.toUpperCase()}</span>
                    <div className="increase__decrease__value">
                        <button onClick={() => {addUsdt()}}>+</button>
                        <button onClick={() => {SubcUsdt()}}>-</button>
                    </div>
                </div>
                <div className="percentage">
                    <span onClick={() => usdtWithPersent(25)} style={{cursor:'pointer'}}>25%</span>
                    <span onClick={() => usdtWithPersent(50)} style={{cursor:'pointer'}}>50%</span>
                    <span onClick={() => usdtWithPersent(75)} style={{cursor:'pointer'}}>75%</span>
                    <span onClick={() => usdtWithPersent(100)} style={{cursor:'pointer'}}>100%</span>
                </div>
                <div className="availabe">
                    <div className="inner__item">
                        <span>
                            Available：
                        </span>
                        <span>
                            {buyBalance?.toFixed(6) } USDT
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                        fill="#2DBD96" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    {/* <div className="inner__item">
                        <span>
                            Volume:
                        </span>
                        <span>
                            0 USDT
                        </span>
                    </div> */}
                </div>
                <button className="Login__Sing__in__btn" onClick={() => buyMarketCoin()}>
                    <a className="login" >Buy </a>
                    
                </button>

            </div>
            <div className="price__input">
                <div className="price__box">
                    <input type="number" readonly placeholder="Best Market Price" readOnly />
                </div>
                <div className="price__box">
                    <input type="number" placeholder="Amount" value={sellUsdt} onChange={(e) =>  fixDesit(e.target.value, setsellUsdt  )} />
                    <span> {props.props}</span>
                    <div className="increase__decrease__value">
                        <button  onClick={() => {addsellUsdt()}}>+</button>
                        <button  onClick={() => {SubcsellUsdt()}}>-</button>
                    </div>
                </div>
                <div className="percentage">
                    <span onClick={() => sellUsdtWithPersent(25)} style={{cursor:'pointer'}}>25%</span>
                    <span onClick={() => sellUsdtWithPersent(50)} style={{cursor:'pointer'}}>50%</span>
                    <span onClick={() => sellUsdtWithPersent(75)} style={{cursor:'pointer'}}>75%</span>
                    <span onClick={() => sellUsdtWithPersent(100)} style={{cursor:'pointer'}}>100%</span>
                </div>
                <div className="availabe">
                    <div className="inner__item">
                        <span>
                            Available：
                        </span>
                        <span>
                            {sellBalance?.toFixed(6)} {props.props}
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.88744 0.799805L7.12818 1.4506L8.71329 3.2998C8.9913 3.62413 8.76085 4.1252 8.33367 4.1252H0.507812V5.1252H11.5949L7.88744 0.799805ZM11.5079 8.625V7.625H0.300781L4.15433 11.4786L4.86144 10.7714L3.56849 9.47856C3.2535 9.16358 3.47658 8.625 3.92204 8.625H11.5079Z"
                                        fill="#2DBD96" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    {/* <div className="inner__item">
                        <span>
                            Volume:
                        </span>
                        <span>
                            0 USDT
                        </span>
                    </div> */}
                </div>
                <button className="Login__Sing__in__btn" onClick={() => sellMarketCoin()}>
                    
                    <a className="login">Sell</a>
                </button>

            </div>
        </div>  
      </>
  );
};

export default SpotSectionMarkit;
