import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Dashboard from "../../pages/private/dashboard/Dashboard";
import assets from "../../pages/private/assets/assets";
import mainAccount from "../../pages/private/assets/mainAccount";
import tradeAccount from "../../pages/private/assets/tradeAccount";
import marginAccount from "../../pages/private/assets/marginAccount";
import financialAccount from "../../pages/private/assets/financialAccount";
import depositassest from "../../pages/private/assets/deposit";
import withdrawAssets from "../../pages/private/assets/withdrawAssets";
import record from "../../pages/private/assets/record";
import bonus from "../../pages/private/assets/bonus";
import kyc from "../../pages/private/dashboard/kyc/kyc";
import Kycform from "../../pages/private/dashboard/kyc/kycform";
import lend from "../../pages/private/dashboard/earn/lend";
import encouragement from "../../pages/private/dashboard/earn/encouragement";
import fastBuy from "../../pages/private/buyCrypto/fastBuy";
import thirdParty from "../../pages/private/buyCrypto/thirdParty";
import spotlightCenter from "../../pages/private/dashboard/earn/spotlightCenter";
import p2p from "../../pages/private/buyCrypto/p2p";
import Miningpool from "../../pages/private/dashboard/earn/miningpool";
import leveraged from "../../pages/private/derivatives/leveraged";
import cloudMining from "../../pages/private/dashboard/earn/cloudMining";
import elecMining from "../../pages/private/dashboard/earn/elecMining";
import orderMining from "../../pages/private/dashboard/earn/orderMining";
import MiningDashboard from "../../pages/private/dashboard/earn/MiningDashboard";
import miningOverview from "../../pages/private/dashboard/earn/miningOverview";
import miningSetting from "../../pages/private/dashboard/earn/miningSetting";
import market from "../../pages/private/market/market";
import depositfiat from "../../pages/private/assets/depositfiat";
import ProofUpload from "../../pages/private/dashboard/kyc/ProofUpload";
import P2pSell from "../../pages/private/buyCrypto/p2pfiles/P2pSell";
import P2pOrder from "../../pages/private/buyCrypto/p2pfiles/P2pOrder";
import PaymentSetting from "../../pages/private/buyCrypto/p2pfiles/PaymentSetting";
import P2pMyPosting from "../../pages/private/buyCrypto/p2pfiles/P2pMyPosting";
import Create_posting from "../../pages/private/buyCrypto/p2pfiles/Create_posting";
import Security from "../../pages/private/buyCrypto/otherpages/Security";
import User_profile from "../../pages/private/buyCrypto/otherpages/User_profile";
import VerifyEmail from "../../pages/private/buyCrypto/otherpages/VerifyEmail";
import VerifyDataOtp from "../../pages/private/buyCrypto/otherpages/VerifyDataOtp";
import TradingOtp from "../../pages/private/TradingPassword/TradingOtp";
import ChangeTrading from "../../pages/private/TradingPassword/ChangeTrading";
import NoData from '../../pages/private/empty/NoData';
import SwapCurrency from "../../pages/private/assets/SwapCurrency";
import SwapTable from "../../pages/private/assets/SwapTable";
import SellOrders from "../../pages/private/buyCrypto/p2pfiles/SellOrders";
import MyPostingDetails from "../../pages/private/buyCrypto/p2pfiles/MyPostingDetails";
import CoinPage from "../../pages/public/dashboard/CoinPage";
import ChatLogin from "../../pages/private/firebase chat/ChatLogin";
import Chat from "../../pages/private/firebase chat/Chat";
import SellOrders_Chat from "../../pages/private/firebase chat/SellOrders&Chat";
import RefferralCard from "../../pages/private/dashboard/referral_card";
import Referral from "../../pages/private/assets/Referral";
import About from "../../pages/public/otherpage/About";
import JoinUs from "../../pages/public/otherpage/JoinUs";
import MediaKit from "../../pages/public/otherpage/MediaKit";
import AffiliateProgram from "../../pages/public/otherpage/AffiliateProgram";
import BugBounty from "../../pages/public/otherpage/BugBounty";
import TechnicalSupport from "../../pages/public/otherpage/TechnicalSupport";
import SpotTrade from "../../pages/public/otherpage/SpotTrade";
import MarginTrade from "../../pages/public/otherpage/MarginTrade";
import Contract from "../../pages/public/otherpage/Contract";
import Landing from "../../pages/public/otherpage/Landing";
import TradingBot from "../../pages/public/otherpage/TradingBot";
import FudxEarn from "../../pages/public/otherpage/FudxEarn";
import service from "../../pages/public/otherpage/service";

import SupportCentre from "../../pages/public/otherpage/SupportCentre";
import Fee from "../../pages/public/otherpage/Fee";
import VipPrevilege from "../../pages/public/otherpage/VipPrevilege";
import TokenListing from "../../pages/public/otherpage/TokenListing";
import FudxLabs from "../../pages/public/otherpage/FudxLabs";
import Spotlight from "../../pages/public/otherpage/Spotlight";
import MarketProgram from "../../pages/public/otherpage/MarketProgram";
import P2PMerchant from "../../pages/public/otherpage/P2PMerchant";
import TermsofUse from "../../pages/public/otherpage/TermsofUse";
import PrivacyPolicy from "../../pages/public/otherpage/PrivacyPolicy";
import ContactUs from "../../pages/public/otherpage/ContactUs";
import RiskDisclosure from "../../pages/public/otherpage/RiskDisclosure";
import Soon from "../../pages/public/empty/soon";
import CreateTicket from "../../pages/private/ticket/CreateTicket";
import Order from "../../pages/private/order/order";
import Ticket from "../../pages/private/ticket/Ticket";
import TradeHistory from "../../pages/private/order/TradeHistory";
import OrderHistory from "../../pages/private/order/OrderHistory";
import CoinInfo from "../../pages/private/market/CoinInfo";
import kycAadhar from "../../pages/private/dashboard/kyc/kycAadhar";
import kycAadharVerify from "../../pages/private/dashboard/kyc/kycAadharVerify";
import KycAadhar from "../../pages/private/dashboard/kyc/kycAadhar";
import KycAadharVerify from "../../pages/private/dashboard/kyc/kycAadharVerify";
import Withdrawrecord from "../../pages/private/assets/Withdrawrecord";
import SelfieProofUpload from "../../pages/private/dashboard/kyc/SelfieProofUpload";
import PanProofUpload from "../../pages/private/dashboard/kyc/PanProofUpload";

class PrivateRoutes extends Component {
    
    render() {
        return (
            <div className="PublicArea__content ">
                <Header  ></Header> 
                <div  className="" id="sidebar">
                    <Route path="/user/dashboard" component={Dashboard} exact={true} />
                    <Route path="/vendor/dashboard" component={assets} exact={true} />
                    <Route path="/vendor/assets" component={assets} exact={true} />
                    <Route path="/vendor/mainAccount" component={mainAccount} exact={true} />
                    <Route path="/vendor/tradeAccount" component={tradeAccount} exact={true} />
                    <Route path="/vendor/marginAccount" component={marginAccount} exact={true} />
                    <Route path="/vendor/financialAccount" component={financialAccount} exact={true} />
                    <Route path="/vendor/depositassest" component={depositassest} exact={true} />
                    <Route path="/vendor/depositfiat" component={depositfiat} exact={true} />
                    <Route path="/vendor/withdrawAssets" component={withdrawAssets} exact={true} />
                    <Route path="/vendor/record" component={record} exact={true} />
                    <Route path="/vendor/Withdrawrecord" component={Withdrawrecord} exact={true} />
                    <Route path="/vendor/bonus" component={bonus} exact={true} />
                    <Route path="/vendor/Referral" component={Referral} exact={true} />
                    <Route path="/vendor/kyc" component={kyc} exact={true} />
                    <Route path="/vendor/uploadproof" component={ProofUpload} exact={true} />
                    <Route path="/vendor/SelfieProofUpload" component={SelfieProofUpload} exact={true} />
                    <Route path="/vendor/PanProofUpload" component={PanProofUpload} exact={true} />
                    <Route path="/vendor/kycform" component={Kycform} exact={true} />
                    <Route path="/vendor/kycAadhar" component={KycAadhar} exact={true} />
                    <Route path="/vendor/kycAadharVerify/:id" component={KycAadharVerify} exact={true} />

                    <Route path="/vendor/lend" component={lend} exact={true} />
                    <Route path="/vendor/encouragement" component={encouragement} exact={true} />
                    <Route path="/vendor/fastBuy" component={fastBuy} exact={true} />
                    <Route path="/vendor/thirdParty" component={thirdParty} exact={true} />
                    <Route path="/vendor/spotlightCenter" component={spotlightCenter} exact={true} />
                    <Route path="/vendor/p2p" component={p2p} exact={true} />
                    <Route path="/vendor/p2p/sell" component={P2pSell} exact={true} />
                    <Route path="/vendor/p2p/order" component={P2pOrder} exact={true} />
                    <Route path="/vendor/p2p/paymentSetting" component={PaymentSetting} exact={true} />
                    <Route path="/vendor/p2p/myPosting" component={P2pMyPosting} exact={true} />
                    <Route path="/vendor/p2p/create_posting" component={Create_posting} exact={true} />

                    <Route path="/vendor/miningpool" component={Miningpool} exact={true} />
                    <Route path="/vendor/leveraged" component={leveraged} exact={true} />
                    <Route path="/vendor/cloudMining" component={cloudMining} exact={true} />
                    <Route path="/vendor/elecMining" component={elecMining} exact={true} />
                    <Route path="/vendor/orderMining" component={orderMining} exact={true} />
                    <Route path="/vendor/MiningDashboard" component={MiningDashboard} exact={true} />
                    <Route path="/vendor/miningOverview" component={miningOverview} exact={true} />
                    <Route path="/vendor/miningSetting" component={miningSetting} exact={true} />
                    <Route path="/vendor/market" component={market} exact={true} />
                    <Route path='/vendor/CoinInfo/:coin' component={CoinInfo} exact={true} />

                    <Route path="/vendor/security" component={Security} exact={true} />
                    <Route path="/vendor/user-profile" component={User_profile} exact={true} />
                    <Route path="/vendor/verify-email" component={VerifyEmail} exact={true} />
                    <Route path="/vendor/verify-data" component={VerifyDataOtp} exact={true} />
                    <Route path="/vendor/trading-otp" component={TradingOtp} exact={true} />
                    <Route path="/vendor/trading-change/:id" component={ChangeTrading} exact={true} />
                    <Route path="/vendor/NoData" component={NoData} exact={true} />
                    <Route path="/vendor/soon" component={Soon} exact={true} />

                    <Route path="/vendor/swap-currency" component={SwapCurrency} exact={true} />
                    <Route path="/vendor/SwapTable" component={SwapTable} exact={true} />
                    <Route path="/vendor/SellOrders/:id" component={SellOrders} exact={true} />
                    <Route path='/vendor/MyPostingDetails/:id' component={MyPostingDetails} exact={true} />
                    <Route path='/vendor/coin/:id/:pair' component={CoinPage} exact={true} />
                    <Route path="/vendor/ChatLogin" component={ChatLogin} exact={true} />
                    <Route path="/vendor/Chat/:id/:buyer_id" component={Chat} exact={true} />
                    <Route path="/vendor/order-chat/:id" component={SellOrders_Chat} exact={true} />

                    <Route path="/vendor/CreateTicket" component={CreateTicket} exact={true} />
                    <Route path="/Vendor/Ticket" component={Ticket} exact={true}/>
                    <Route path="/vendor/Order" component={Order} exact={true} />
                    <Route path="/vendor/trade-history" component={TradeHistory} exact={true} />
                    <Route path="/vendor/order-history" component={OrderHistory} exact={true} />

                    <Route path="/vendor/about" component={About} exact={true} />
                    <Route path="/vendor/JoinUs" component={JoinUs} exact={true} />
                    <Route path="/vendor/media-kit" component={MediaKit} exact={true} />
                    <Route path="/vendor/affiliate-program" component={AffiliateProgram} exact={true} />
                    <Route path="/vendor/bug-bounty" component={BugBounty} exact={true} />
                    <Route path="/vendor/technical-support" component={TechnicalSupport} exact={true} />
                    <Route path="/vendor/spot-trade" component={SpotTrade} exact={true} />
                    <Route path="/vendor/margin-trade" component={MarginTrade} exact={true} />
                    <Route path="/vendor/contract" component={Contract} exact={true} />
                    <Route path="/vendor/landing" component={Landing} exact={true} />
                    <Route path="/vendor/trading-bot" component={TradingBot} exact={true} />
                    <Route path="/vendor/fudx-earn" component={FudxEarn} exact={true} />
                    <Route path="/vendor/support-centre" component={SupportCentre} exact={true} />
                    <Route path="/vendor/fee" component={Fee} exact={true} />
                    <Route path="/vendor/vip-previlege" component={VipPrevilege} exact={true} />
                    <Route path="/vendor/TokenListing" component={TokenListing} exact={true} />
                    <Route path='/vendor/fudx-labs' component={FudxLabs} exact={true} />
                    <Route path="/vendor/spotlight" component={Spotlight} exact={true} />
                    <Route path="/vendor/MarketProgram" component={MarketProgram} exact={true} />
                    <Route path="/vendor/P2PMerchant" component={P2PMerchant} exact={true} />
                    <Route path="/vendor/TermsofUse" component={TermsofUse} exact={true} />
                    <Route path="/vendor/PrivacyPolicy" component={PrivacyPolicy} exact={true} />
                    <Route path="/vendor/ContactUs" component={ContactUs} exact={true} />
                    <Route path="/vendor/RiskDisclosure" component={RiskDisclosure} exact={true} />
                    <Route path="/vendor/service" component={service} exact={true} />

                    {/* <Footer /> */}
                </div>
            </div>
                
        );
    }
}

export default withRouter(PrivateRoutes);
